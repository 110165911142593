<template>
  <div>  
  <div class="d-flex flex-stack flex-wrap">
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
          Task Types
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
            </li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskManagement' }">Task Management</router-link></li>  
            <li class="breadcrumb-item text-gray-600">Task Types</li> 
        </ul> 
    </div>  
    <div class="d-flex align-items-center py-1">
        <router-link :to="{ name: 'TaskManagement', params: { type: 'TaskManagement' } }">
          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
              <span class="ms-2 fs-7"> Return back to Settings </span>
          </button>
      </router-link>
    </div> 
  </div> 
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6">
              <div class="card-title">
                <router-link to="/TaskTypesCreate">
                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                      <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                      </span>
                      Add Task Type
                    </button>
                </router-link>
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                      <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Task Types</strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                  </div>
                  <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                      <div class="fw-bolder me-5">
                      <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                      <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                  </div>
              </div>
          </div>
          <div class="card-body">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer"> 
                <div class="table-responsive min-h-300px">
                  
                <DxDataGrid :show-borders="true" 
                        key-expr="id" 
                        :data-source="lists" 
                        :allow-column-reordering="true"  
                        :allow-column-resizing="true" 
                        :column-auto-width="true" 
                        :row-alternatin-enabled="true"
                        :hover-state-enabled="true" 
                        :show-row-lines="true" 
                        @exporting="this.$onExporting($event , 'Property-Types-List')">>

                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>      
                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                        <DxPaging :page-size="20"/>
                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                        <DxSearchPanel :visible="true"/>
                        <DxScrolling
                            mode="virtual"
                            row-rendering-mode="virtual"
                            
                        />
                        <DxColumn data-field="auth_type" caption="Authorized User" header-cell-template="authorized-user-header" cell-template="authorized_user-cell-template"/> 
                        <DxColumn data-field="main_task_type" caption="Main Task Type" header-cell-template="main-task-type-header" cell-template="main_task_type-cell-template"/> 
                        <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                        <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/> 
                        <DxColumn data-field="task_group_category_name" caption="Category" cell-template="task_group_category_name-cell-template"/> 
                        <DxColumn data-field="task_group_name" caption="Group"  cell-template="task_group_name-cell-template"/> 
                        <DxColumn data-field="priority" caption="Priority" alignment="center" cell-template="priority-cell-template"/>
                        <DxColumn data-field="period_type" caption="Period Type" header-cell-template="period-type-header" cell-template="period_type-cell-template"/>  
                        <DxColumn data-field="amount_type_name" caption="Amount" cell-template="amount_type-cell-template"/> 
                        <DxColumn data-field="hide" caption="Is Hide?" alignment="center" cell-template="hide-cell-template" :allow-filtering="false"/> 

                        <!-- Header Cell Templates -->
                        <template #authorized-user-header>
                            <div> Authorized </div> <div> User </div>
                        </template> 
                        <template #main-task-type-header>
                            <div> Main </div> <div> Task Type </div>
                        </template>
                        <template #period-type-header>
                            <div> Period </div> <div> Type </div>
                        </template>

                        <!-- Cell Templates -->
                        <template #authorized_user-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 mb-1" @click="updateType(data.data)">
                                {{data.data.auth_type}}
                            </a>
                        </template>
                        <template #main_task_type-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 mb-1" @click="updateType(data.data)">
                                <span class="badge badge-light mt-3" v-if="data.data.main_task_type == 1">System</span>
                                <span class="badge badge-light mt-3" v-if="data.data.main_task_type == 2">Controller</span>
                                <span class="badge badge-light mt-3" v-if="data.data.main_task_type == 3">Company</span> 
                            </a>
                        </template>
                        <template #code-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 mb-1" @click="updateType(data.data)">
                                <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                    <i class="bi bi-pencil text-gray-900"></i> 
                                </span> 
                                {{ data.data.code }}
                            </a>
                        </template>
                        <template #name-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                {{ data.data.name }}
                            </a>
                        </template>
                        <template #task_group_category_name-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                {{ data.data.task_group_category_name }}
                            </a>
                        </template>
                        <template #task_group_name-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                {{ data.data.task_group_name }}
                            </a>
                        </template>
                        <template #priority-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 text-center fw-bold mb-1" @click="updateType(data.data)">
                                <span class="badge badge-primary" v-if="data.data.priority == 1">Normal</span>
                                <span class="badge badge-success" v-if="data.data.priority == 2">Low</span>
                                <span class="badge badge-danger" v-if="data.data.priority == 3">High</span>
                            </a>
                        </template>
                        <template #period_type-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                <span class="badge badge-secondary" v-if="data.data.period_type == 1">Free Period</span>
                                <span class="badge badge-secondary" v-if="data.data.period_type == 2">Daily Period</span>
                                <span class="badge badge-secondary" v-if="data.data.period_type == 3">Weekly Period</span> 
                                <span class="badge badge-secondary" v-if="data.data.period_type == 4">Monthly Period</span> 
                                <span class="badge badge-secondary" v-if="data.data.period_type == 5">Annual Period</span> 
                                <span class="badge badge-secondary" v-if="data.data.period_type == 6">Periodic Period</span> 
                                <br>
                                <span class="badge badge-primary mt-3" v-if="data.data.period_type == 1"><small>{{ previewDate(data.data.period_start) }} - {{ previewDate(data.data.period_end) }}</small></span>
                                <span class="badge badge-primary mt-3" v-if="data.data.period_type == 2"><small>{{ previewHours(data.data.period_daily_start_time) }} - {{ previewHours(data.data.period_daily_end_time) }}</small></span>
                                <span class="badge badge-primary mt-3" v-if="data.data.period_type == 3">
                                  <small>Every 
                                    <span v-if="data.data.period_weekly_date == 1">Monday,</span>
                                    <span v-if="data.data.period_weekly_date == 2">Tuesday,</span>
                                    <span v-if="data.data.period_weekly_date == 3">Wednesday,</span>
                                    <span v-if="data.data.period_weekly_date == 4">Thursday,</span>
                                    <span v-if="data.data.period_weekly_date == 5">Friday,</span>
                                    <span v-if="data.data.period_weekly_date == 6">Satuday,</span>
                                    <span v-if="data.data.period_weekly_date == 7">Sunday,</span> 
                                    {{ previewHours(data.data.period_daily_start_time) }} - {{ previewHours(data.data.period_daily_end_time) }}
                                  </small>
                                </span> 
                                <span class="badge badge-primary mt-3" v-if="data.data.period_type == 4"><small>Every Month's {{ data.data.period_monthly_date }}</small></span> 
                                <span class="badge badge-primary mt-3" v-if="data.data.period_type == 5"><small>{{ previewAnnualDate(data.data.period_annual_date) }}</small></span>
                            </a>
                        </template>
                        <template #amount_type-cell-template="{ data }">
                            <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                              class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                <span v-if="!data.data.amount_type > 0 || !data.data.is_have_value">-</span>
                                <span class="badge badge-light-info" v-if="data.data.is_have_value"> {{ data.data.amount_type_name }} </span> 
                                <br>
                                <h4 class="text mt-4" v-if="data.data.amount_value != 0"> {{ data.data.amount_value }} </h4>
                            </a>
                        </template> 
                        <template #hide-cell-template="{ data }">
                              <input class="form-check-input" type="checkbox" value="" id="hide" v-model="data.data.hide" @change="setListItemHide(data.data)">
                        </template>
                        
                        <DxSummary>
                            <DxTotalItem
                                column="id"
                                summary-type="count"
                                show-in-column="auth_type"
                            /> 
                        </DxSummary>
                </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div> 
</div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `TATL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxScrolling  } from 'devextreme-vue/data-grid';

 
export default {
  name: "TaskTypesList",
  components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxScrolling 
  },
  data() {
    return {
      lists: [],
      searchbar: '',
      isListProgressing: false,
      pageSizes: [10, 20, 50, 100, 'all'],
      page_size: 10,
      page: 1,
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    previewAnnualDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMM Do')
        }
      }
    },
    previewHours(){
       return (value)=>{
       if (value) {
          return moment(new Date(value)).format('HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      this.$setSessionStorage('data' + '_TATL001', null);
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 1
      };
      axios.post("/api/TaskTypes/GetTaskTypes", params, {'Content-Type': 'application/json'}).then((response) => { 
        this.lists = response.data;
        this.$setSessionStorage('data'+ '_TATL001', response.data);
        this.$Progress.finish();
        setTimeout(() => {
              this.isListProgressing = false;
          }, 500);
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
        setTimeout(() => {
              this.isListProgressing = false;
          }, 500);
      });
    },
    updateType(item){ 
        if (item.full_access == 0) return;
        this.$setSessionStorage("record_id", item.id);
        this.$router.push({ name: 'TaskTypesUpdate' });
    },
    deleteType(id) { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: id
            };
            axios.get('/api/TaskTypes/DeleteTaskType/' + queryParameter.id)
                .then(() => { 
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })  
    },
    setListItemHide(data) {
      this.$swal.fire({
            title: data.hide == true ? 'Are you sure to hide this selected data from the lists?' : 'Are you sure to show this selected data from the lists?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
          }).then((result) => { 
              if (result.isConfirmed) {
                    const params = {
                      session_id: this.$getUser().session_id,
                      list_type: 6,
                      hide: data.hide,
                      id: data.id
                    };      
                    params.hide = params.hide == true ? 1: 0;
                    axios.post('/api/Lists/SetListItemHide', params, {'Content-Type': 'application/json'})
                      .then((response) => { 
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                              this.getData();
                          } 
                      })
                      .catch(function (error) {
                          console.log(error);
                          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                      });
              }
              else{
                  data.hide = !data.hide;
              }
          });  
    }
  },
  created() { 
  if(this.$getSessionStorage('refreshList')){
      this.getData();
      this.$removeSessionStorage('refreshList'); 
  } 
  else{
  this.lists = [];
  }
  }
}; 
</script>