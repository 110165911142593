<template>
  <div>   
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-4">
              <div class="card-title">
                  <button type="button" class="btn btn-light-primary border border-primary me-3" data-bs-toggle="modal" data-bs-target="#addAccountingPlan" @click="this.createPlan();" :disabled="this.accountingYearList.length == 0">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                      </svg>
                    </span>
                    Add
                  </button>
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                      <button type="button" class="btn btn-light-primary border border-primary" @click="this.getAccountingPlans()" data-kt-indicator="on" :disabled="this.accountingYearList.length == 0">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                    <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                  </div>
                  <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                      <div class="fw-bolder me-5">
                      <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                      <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                  </div>
              </div>
          </div>
          <div class="card-body">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer"> 
                <div class="d-flex flex-wrap my-1 bg-lighten p-3 mb-4 border">  
                  <div class="d-flex my-0">   
                      <label class="fw-bold fs-6 mt-2">
                      Accounting Periods:
                      </label>
                      &nbsp; &nbsp; 
                      <select v-if="this.accountingYearList.length > 0" class="form-select form-select-sm mr-5 w-250px" v-model="filterForm.accountingYearSelection" @change="onChangePlan()">
                          <option v-for="item in accountingYearList" :value="item" :key="item.id">
                              {{ item.period_year_short }}
                          </option>
                      </select>
                      <span v-if="this.accountingYearList.length == 0" class="text-danger fw-bold pt-2"> No period found... </span>
                  </div>  
                </div> 
                <div class="table-responsive min-h-300px">
                  <DxDataGrid
                          :show-borders="true"
                          key-expr="id"
                          :data-source="lists"
                          :allow-column-reordering="true" 
                          :allow-column-resizing="true"
                          :column-auto-width="true"
                          :row-alternatin-enabled="true"
                          data-row-template="dataRowTemplate"
                          :hover-state-enabled="true"
                          :show-row-lines="true"
                          @exporting="this.$onExporting($event , 'Company-accounting-plans')">
                          
                          <DxFilterRow :visible="true"/>
                          <DxHeaderFilter :visible="true"/>
                          <DxGroupPanel :visible="true"/>      
                          <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                          <DxPaging :page-size="10000"/>
                          <DxPager
                              :visible="false"
                              :allowed-page-sizes="pageSizes"
                              :show-page-size-selector="false"
                              :show-info="false"
                              :show-navigation-buttons="false"
                          /> 
                          <DxSearchPanel :visible="true"/>
                          <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                            />
                          <DxColumn data-field="plan_level" caption="Level"/> 
                          <DxColumn data-field="final_code" caption="Code"/> 
                          <DxColumn data-field="name" caption="Name" /> 
                          <DxColumn data-field="account_type_name" caption="Account Type"/>
                          <DxColumn data-field="" caption="Transfer Parameters" header-cell-template="account_transfer_parameters"/>

                          <template #account_transfer_parameters>
                              <div>Transfer</div><div>Parameters</div>
                          </template>

                          <template #dataRowTemplate="{ data: data }"> 
                              <tr class="align-middle border border-gray-200" :class="{ 'p-3 bg-material-gray-200': data.data.plan_level == 1}" >
                                  <td class="border border-end border-gray-200 p-3"> 
                                      <a href="#" class="text-hover-primary mb-1" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan">
                                          <span class="badge badge-dark" v-if="data.data.plan_level == 1">
                                              Level: {{ data.data.plan_level }}
                                          </span>
                                          <span class="badge badge-secondary" v-if="data.data.plan_level == 2">
                                              Level: {{ data.data.plan_level }} <span
                                                  v-if="data.data.level_2_id !== null && data.data.level_2_id !== ''">.2</span>
                                          </span>
                                          <span class="badge badge-light" v-if="data.data.plan_level == 3">
                                              Level: {{ data.data.plan_level }}
                                          </span>
                                      </a>
                                  </td>
                                  <td class="border border-end border-gray-200 p-3">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan">                                          
                                          <h4 v-if="data.data.plan_level == 1"> {{ data.data.final_code }} </h4>
                                          <h6 v-if="data.data.plan_level > 1"> {{ data.data.final_code }} </h6>
                                      </a>
                                  </td>
                                  <td class="border border-end border-gray-200 p-3">                                                
                                      <a href="#" class="text-gray-900 text-hover-primary fw-bolder" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan"
                                          v-if="data.data.plan_level == 1">
                                          <h5 class="mb-0 text-hover-primary"> {{ data.data.name }} </h5>
                                      </a>
                                      <a href="#" class="text-gray-700 text-hover-info fw-bolder" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan"
                                          v-if="data.data.plan_level == 2">
                                          &nbsp;&nbsp; {{ data.data.name }}
                                      </a>
                                      <a href="#" class="text-gray-600 text-hover-success" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan"
                                          v-if="data.data.plan_level == 3">
                                          &nbsp;&nbsp;&nbsp;&nbsp; {{ data.data.name }}
                                      </a>
                                  </td>
                                  <td class="border border-end border-gray-200 p-3">                                                
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan">
                                          <span v-if="data.data.account_type_name !== 'Undefined'"> 
                                              {{data.data.account_type_name }} <br>
                                          </span>
                                          <small class="me-2"> {{ data.data.detail_type_name }} </small>
                                          <small class="me-2"> {{ data.data.currency }}</small>
                                          <small class="me-2"> {{ data.data.sales_type_name}}</small>
                                          <small class="me-2"> {{ data.data.vat_type_name }}</small>
                                          <small class="me-2"> {{ data.data.cheque_type_name }}</small>
                                          <small class="me-2"> {{ data.data.vat_calculated_type_name }}</small>
                                      </a>
                                  </td>
                                  <td class="border border-end border-gray-200 p-3">                                                
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan">
                                          <small class="me-2" v-if="data.data.is_opening_account == 2"> <i class="bi bi-check"></i> Opening </small>
                                          <small class="me-2" v-if="data.data.is_closing_account == 2"> <i class="bi bi-check"></i> Closing </small>
                                          <small class="me-2" v-if="data.data.is_profit_loss_account == 2">
                                              <br> <i class="bi bi-check"></i> Profit & Loss
                                          </small>
                                          <small v-if="data.data.profit_loss_transaction_type_name !== ''"> ({{data.data.profit_loss_transaction_type_name}}) </small>
                                      </a>
                                  </td>
                              </tr>
                          </template>
                  </DxDataGrid>
                  <!-- <table class="table table-rounded border gy-7 gs-7 table-row-bordered">
                  <thead>
                      <tr class="text-start fw-bolder fs-7 text-uppercase gs-0"> 
                          <th>Level</th>
                          <th class="w-100px">Code</th>
                          <th>Name</th>
                          <th>Account Type</th>
                    </tr>
                  </thead>
                  <tbody v-if="lists" > 
                    <tr v-for="item in searchableList" v-bind:key="item" @click="this.updatePlan(item.id);" class="cursor-pointer" :class="{ 'table-active fs-6': item.plan_level == 1}" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan"> 
                          <td class="text-gray-600">
                              <span class="badge badge-dark" v-if="item.plan_level == 1">
                                Level: {{ item.plan_level }} 
                              </span>
                              <span class="badge badge-secondary" v-if="item.plan_level == 2">
                                Level: {{ item.plan_level }} <span v-if="item.level_2_id !== null && item.level_2_id !== ''">.2</span>
                              </span>
                              <span class="badge badge-light" v-if="item.plan_level == 3">
                                Level: {{ item.plan_level }} 
                              </span>
                          </td> 
                          <td class="text-gray-600 p-4">
                              <h4 v-if="item.plan_level == 1"> {{ item.final_code }} </h4>
                              <h6 v-if="item.plan_level > 1"> {{ item.final_code }} </h6>
                          </td>  
                          <td>
                              <a class="text-gray-900 text-hover-primary fw-bolder" v-if="item.plan_level == 1">
                                 <h5 class="mb-0 text-hover-primary"> {{ item.name }}  </h5>
                              </a>
                              <a class="text-gray-700 text-hover-info fw-bolder" v-if="item.plan_level == 2">
                                &nbsp;&nbsp;  {{ item.name }}   
                              </a>
                               <a class="text-gray-600 text-hover-success" v-if="item.plan_level == 3">
                                &nbsp;&nbsp;&nbsp;&nbsp;  {{ item.name }}  
                              </a> 
                          </td>
                          <td>
                            <span v-if="item.account_type_name !== 'Undefined'"> {{ item.account_type_name }}  <br> </span>
                            <small class="me-2"> {{ item.detail_type_name }} </small>
                            <small class="me-2"> {{ item.currency }}</small>
                            <small class="me-2"> {{ item.sales_type_name}}</small>
                            <small class="me-2"> {{ item.vat_type_name }}</small>
                            <small class="me-2"> {{ item.cheque_type_name }}</small>
                          </td>
                    </tr> 
                  </tbody> 
                  <tfoot>
                    <tr>
                      <td colspan="4"> {{ this.lists.length }} records </td> 
                    </tr>
                  </tfoot>
                  <tbody v-if="!this.lists">
                        <tr>
                            <td colspan="4">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                    <div class="fs-6 text-gray-800 fw-bolder">No results yet.</div>
                                    <div class="fw-bold text-gray-400">Please use "List" button to get the latest data.</div>
                                </div>
                            </td>
                        </tr>
                  </tbody>
                  </table> -->
                </div>
              </div> 
          </div>
      </div> 

      <!-- Modal: Add Accounting Plan To Company -->
      <div class="modal fade" tabindex="-1" id="addAccountingPlan" style="display: none" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
          <div class="modal-dialog modal-xl modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">Create Accounting Plan</h5> 
                      <div class="btn btn-icon btn-sm btn-active-icon-primary"
                          data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                          <span class="svg-icon svg-icon-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                      transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                  <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                      transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                              </svg>
                          </span>
                      </div>
                  </div>  
                  <div class="modal-body">
                    <CompanyPlanCreate ref="addAccountingPlan"/>
                  </div>  
              </div>
          </div>
      </div>
      <!-- Modal: Update Accounting Plan To Company -->
      <div class="modal fade" tabindex="-1" id="updateAccountingPlan" style="display: none" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
          <div class="modal-dialog modal-xl modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">Update Accounting Plan</h5> 
                      <div class="btn btn-icon btn-sm btn-active-icon-primary"
                          data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                          <span class="svg-icon svg-icon-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                      transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                  <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                      transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                              </svg>
                          </span>
                      </div>
                  </div>  
                  <div class="modal-body">
                    <CompanyPlanUpdate ref="updateAccountingPlan" />
                  </div>  
              </div>
          </div>
      </div>
  </div>  

  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `PLL002`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
import CompanyPlanCreate from '../CompanyAccountingPlans/PlanCreate.vue';
import CompanyPlanUpdate from '../CompanyAccountingPlans/PlanUpdate.vue';


 
export default {
  name: "PlanList",
  components:{
    CompanyPlanCreate,
    CompanyPlanUpdate,
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel,DxScrolling
  },
  data() {
    return {
      lists: [],
      accountingYearList: [],
      searchbar: '',
      isListProgressing: false,
      filterForm: {
        accountingYearSelection: null
      },
      pageSizes: [50, 100, 200, 'all'],
      page_size: 10,
      page: 1
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
      this.$removeSessionStorage("plan_record_id");
      this.accountingYearList = [];
      this.isListProgressing = true;
      this.lists = [];
      this.$Progress.start();
      const queryParameter = {
        id:  this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
      .then((response) => {  
        this.accountingYearList = response.data; 
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
        if(this.$getSessionStorage('active_period') === null || this.$getSessionStorage('active_period') === ''){
          this.filterForm.accountingYearSelection = this.accountingYearList[0]; 
          this.$setSessionStorage('active_period', this.filterForm.accountingYearSelection); 
        }
        else{ 
          this.filterForm.accountingYearSelection = this.$getSessionStorage('active_period');
        }
        
        if (this.accountingYearList.length > 0) {
          this.getAccountingPlans();
        } else {
          this.$Progress.finish();
        }
      })
      .catch(function (error) {
          console.log(error);
          setTimeout(() => {
            this.isListProgressing = false;
        }, 500);
      });
    },
    getAccountingYears(){
      this.accountingYearList = []; 
      const queryParameter = {
        id:  this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
      .then((response) => {  
        this.accountingYearList = response.data;
        if(this.$getSessionStorage('active_period') === null || this.$getSessionStorage('active_period') === ''){
          this.filterForm.accountingYearSelection = this.accountingYearList[0];
          this.$setSessionStorage('active_period', this.filterForm.accountingYearSelection); 
        }
        else{
          this.filterForm.accountingYearSelection = this.$getSessionStorage('active_period');
        }
      })
      .catch(function (error) {
          console.log(error);
      }); 
    }, 
    getAccountingPlans(){ 
        this.lists = [];
        if (this.filterForm.accountingYearSelection == null) {          
          this.$swal("Warning", " Accounting Period must be selected..!", 'warning' );
          return;
        }
        this.$Progress.start(); 
        const queryParameter = {
          company_id: this.$getSessionStorage("cp_record_id"),
          accounting_period_id: this.filterForm.accountingYearSelection.id,
          session_id: this.$getUser().session_id,
        };   
        axios.post('/api/CompanyAccountingPlans/Plans', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => { 
          this.lists = response.data;
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
        });
    },
    updatePlan(id){ 
        this.$setSessionStorage("plan_record_id", id);
        this.$refs.updateAccountingPlan.getData();        
    },  
    createPlan(){  
        this.$refs.addAccountingPlan.getData();        
    }, 
    onChangePlan(){ 
      this.$setSessionStorage('active_period', this.filterForm.accountingYearSelection); 
      this.$swal("Success", "Active accounting year has been changed to: <h3><strong>" + this.filterForm.accountingYearSelection.period_year_short +'</strong></h3>', 'success');   
      this.getAccountingPlans();
    },
  },
  created() {
    this.getAccountingYears();
  }
}; 
</script>
