<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Data DLL Template                
              </h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DLLTemplatesList' }">Data DLL Templates</router-link></li>  
                  <li class="breadcrumb-item text-gray-600">Update Data DLL Template</li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/DLLTemplatesList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                  <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Template Name
                                                </label>
                                                <input type="text" class="form-control " name="code" v-model="form.template_name" required>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                    Description
                                                </label>
                                                <textarea rows="2" class="form-control " name="description" v-model="form.description"></textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Is Active?
                                                </label>
                                                <div class="form-check mt-3 mb-2">
                                                    <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.is_active" />
                                                    <small class="text-danger">If this option is checked as passive, DLL will not be able to use for Data Import.</small> 
                                                </div> 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                        <div class="fv-row mb-7 row"> 
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mb-0 required">
                                                DLL Type
                                            </label>
                                            <Select2 v-model="form.dll_type" :options="dllTypes" :settings="{width: '100%'}" />
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-2">
                                                <span>DLL Upload</span>
                                            </label> 
                                             <DxFileUploader
                                                  multiple="false"
                                                  upload-mode="instantly"
                                                  :visible="true"
                                                  :accept="form.dll_type == 1 ? '.xls,.xlsx' : '.xml'"
                                                  :upload-headers="getUploadHeaders()"
                                                  upload-url="/api/DLLTemplates/UploadDLLFile"
                                                  :ref="'fileUploader'"
                                                  :on-uploaded="onUploadCompleted"
                                                  uploadButtonText="Upload DLL File"
                                              />

                                              <div class="alert alert-dismissible bg-success d-flex flex-column flex-sm-row w-100 p-5 mb-10" v-if="form.file_name != null && form.file_name != undefined && form.file_name != ''">
                                                <span class="svg-icon svg-icon-dark svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                </svg>
                                                </span>

                                                  <div class="d-flex flex-column ps-4 pe-0 pe-sm-10">
                                                      <h4 class="fw-semibold">A working DLL file found!</h4>
                                                      <span><i class="fas fa-cogs text-dark"></i> <strong> {{ form.file_name }} </strong> has been attached to this template. If you upload an another DLL file, old version will be removed.</span>
                                                  </div>
                                              </div>
                                              
                                        </div>
                                        </div> 
                                    </form>
                                </div>
                            </div>
                        </div> 
                        <div class="col-lg-12">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> Update DLL </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>                 
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
  </div>
</template>


<script>
// Component Alias: `DLU001`
import axios from 'axios' 
  

import { DxFileUploader } from 'devextreme-vue/file-uploader';
// import Swal from 'sweetalert2';
 
export default {
  name: "DLLTemplateCreate",
  components: {
    DxFileUploader
  },
  data() {
    return {
        lists: [],
        isProgressing: false,
        form: { 
            template_name: null,
            file_name: null,
            description: null,
            is_active: true,
            dll_type: 1
        },
        dllTypes: [
            { id: 0, text: 'SELECT A TYPE'},
            { id: 1, text: 'EXCEL/XLS/XLSX'},
            { id: 2, text: 'XML'}
        ]
    };
  },
  methods: {
    getData(){
        const params = {
            id: this.$getSessionStorage("record_id"),
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/DLLTemplates/GetDLLTemplateById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null){  
                response.data.is_active = response.data.is_active == 1 ? true : false;
                this.form = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });         
    },
    saveData(){
        this.isProgressing = true;
        const params = this.form; 
        const that = this;
        params.session_id = this.$getUser().session_id;
        params.is_active = params.is_active == true ? 1 : 0;
        axios.post('/api/DLLTemplates/UpdateDLLTemplate', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$setSessionStorage("record_id", response.data);
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('refreshList', true);    
                this.$router.push({ name: 'DLLTemplatesUpdate' });
                this.getData();
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    getUploadHeaders(){
        return { 
            session_id: this.$getUser().session_id,
            id: this.$getSessionStorage("record_id")
        };
    },
    onUploadCompleted(){
      this.$swal("Success", "DLL Uploaded Successfully..!", 'success'); 
      this.getData();
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
}; 
</script>