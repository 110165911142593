<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Consolidation Reports
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600">Consolidation Reports</li> 
          </ul> 
      </div>
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                    <div class="col-lg-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="isViewLocalAmounts">
                            <label class="fs-5 fw-bold form-label mb-3" for="isDefault">
                                Is View Local Amounts
                            </label>
                        </div> 
                    </div>
                  </div>
                  <div class="card-toolbar">                    
                    <button type="button" class="btn btn-light-primary border border-primary me-3" @click="getData()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-muted">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                        </span> 
                        <span v-if="!this.isListProgressing"> List</span>
                        <span class="indicator-progress" v-if="this.isListProgressing">
                            Getting Data...<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                    </button>
                  </div>
              </div>
              <div class="card-body pt-0">
                <DxDataGrid
                  id="gridContainer"
                  :data-source="controllerList"
                  :remote-operations="true"
                  @row-prepared="onRowPrepared" 
                  :show-borders="true">
                  <DxPaging :page-size="10000"/>
                  <DxScrolling
                      mode="virtual"
                      row-rendering-mode="virtual"
                      
                  />
                  <DxColumn data-field="controller_account" header-cell-template="controller_account-header"/>
                  <DxColumn data-field="customers"/>
                  <DxColumn data-field="suppliers"/>
                  <DxColumn data-field="cashes"/>
                  <DxColumn data-field="banks"/>
                  <DxColumn data-field="personnels"/>
                  <DxColumn data-field="expenses"/>
                  <DxColumn data-field="others"/>
                  <!-- <DxColumn data-field="currency"/> -->
                  
                  <!-- Header Cell Templates -->
                  <template #controller_account-header>
                    <div> Controller </div><div> Account </div>
                  </template> 

                  <DxMasterDetail
                    :enabled="true"
                    :autoExpandAll="true"
                    template="master-detail"
                  />
                  <template #master-detail="{ data }">
                    <DxDataGrid
                      id="gridContainer"
                      :data-source="companyList(data.data)"
                      @row-prepared="onRowPrepared" 
                      :show-borders="true"                      
                      :allow-column-reordering="true"  
                      :allow-column-resizing="true" 
                      :column-auto-width="true" 
                      :row-alternatin-enabled="true"
                      :hover-state-enabled="true" 
                      :show-row-lines="true"
                      :ref="'dataGrid'">

                      <DxFilterRow :visible="true"/>
                      <DxHeaderFilter :visible="true"/>
                      <DxGroupPanel :visible="true"/>
                      <DxSearchPanel :visible="true"/>
                      <DxScrolling
                          mode="virtual"
                          row-rendering-mode="virtual"
                          
                      />
                      <DxColumn data-field="company_account" header-cell-template="company_account-header"/>
                      <DxColumn
                        alignment="center"
                        caption="Consolidation"
                        :fixed="false"
                        fixed-position="center"
                        header-cell-template="consolidation-header" cssClass="bg-light-indigo">
                        <DxColumn cssClass="bg-light-indigo" data-field="customer_consolidation_amount" alignment="right" caption="Customer" cell-template="customer_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="supplier_consolidation_amount" alignment="right" caption="Supplier" cell-template="supplier_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="cash_consolidation_amount" alignment="right" caption="Cash" cell-template="cash_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="bank_consolidation_amount" alignment="right" caption="Bank" cell-template="bank_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="personnel_consolidation_amount" alignment="right" caption="Personnel" cell-template="personnel_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="expense_consolidation_amount" alignment="right" caption="Expense" cell-template="expense_consolidation_amount-cell-template"/>
                        <DxColumn cssClass="bg-light-indigo" data-field="other_consolidation_amount" alignment="right" caption="Other" cell-template="other_consolidation_amount-cell-template"/>
                      </DxColumn>
                      <DxColumn v-if="isViewLocalAmounts"
                        alignment="center"
                        caption="Balances"
                        :fixed="false"
                        fixed-position="center"
                        header-cell-template="balances-header" cssClass="bg-light-success">
                        <DxColumn cssClass="bg-light-success" data-field="customer_balance" alignment="right" caption="Customer" cell-template="customer_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="supplier_balance" alignment="right" caption="Supplier" cell-template="supplier_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="cash_balance" alignment="right" caption="Cash" cell-template="cash_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="bank_balance" alignment="right" caption="Bank" cell-template="bank_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="personnel_balance" alignment="right" caption="Personnel" cell-template="personnel_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="expense_balance" alignment="right" caption="Expense" cell-template="expense_balance-cell-template"/>
                        <DxColumn cssClass="bg-light-success" data-field="other_balance" alignment="right" caption="Other" cell-template="other_balance-cell-template"/>
                      </DxColumn>

                      <!-- Header Cell Templates -->
                      <template #company_account-header>
                        <div> Company </div><div> Account </div>
                      </template>
                      <template #consolidation-header>
                        <div>{{data.data.consolidation_currency}} Consolidation </div>
                      </template>
                      <template #balances-header>
                        <div>{{data.data.currency}} Balances </div>
                      </template>

                      <!-- Cell templates -->
                      <template #customer_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.customer_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #supplier_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.supplier_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #cash_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.cash_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #bank_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.bank_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #personnel_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.personnel_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #expense_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.expense_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      <template #other_consolidation_amount-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.other_consolidation_amount}} {{data.data.consolidation_currency}}
                          </span>   
                      </template>
                      
                      <template #customer_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.customer_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #supplier_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.supplier_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #cash_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.cash_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #bank_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.bank_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #personnel_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.personnel_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #expense_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.expense_balance}} {{data.data.currency}}
                          </span>   
                      </template>
                      <template #other_balance-cell-template="{ data }">
                          <span class="align-middle text-gray-900 text-start">
                              {{data.data.other_balance}} {{data.data.currency}}
                          </span>   
                      </template>

                      <DxSummary :calculate-custom-summary="setSummaries">
                        <DxTotalItem column="id" summary-type="count" show-in-column="company_account"/>

                        <DxGroupItem :show-in-group-footer="true" name="customer_consolidation_amount" summary-type="custom" show-in-column="customer_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="customer_consolidation_amount" summary-type="custom" show-in-column="customer_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="supplier_consolidation_amount" summary-type="custom" show-in-column="supplier_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="supplier_consolidation_amount" summary-type="custom" show-in-column="supplier_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="cash_consolidation_amount" summary-type="custom" show-in-column="cash_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="cash_consolidation_amount" summary-type="custom" show-in-column="cash_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="bank_consolidation_amount" summary-type="custom" show-in-column="bank_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="bank_consolidation_amount" summary-type="custom" show-in-column="bank_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="personnel_consolidation_amount" summary-type="custom" show-in-column="personnel_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="personnel_consolidation_amount" summary-type="custom" show-in-column="personnel_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="expense_consolidation_amount" summary-type="custom" show-in-column="expense_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="expense_consolidation_amount" summary-type="custom" show-in-column="expense_consolidation_amount"/>
                        <DxGroupItem :show-in-group-footer="true" name="other_consolidation_amount" summary-type="custom" show-in-column="other_consolidation_amount" display-format="{0}"/> 
                        <DxTotalItem name="other_consolidation_amount" summary-type="custom" show-in-column="other_consolidation_amount"/>

                        <DxGroupItem :show-in-group-footer="true" name="customer_balance" summary-type="custom" show-in-column="customer_balance" display-format="{0}"/> 
                        <DxTotalItem name="customer_balance" summary-type="custom" show-in-column="customer_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="supplier_balance" summary-type="custom" show-in-column="supplier_balance" display-format="{0}"/> 
                        <DxTotalItem name="supplier_balance" summary-type="custom" show-in-column="supplier_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="cash_balance" summary-type="custom" show-in-column="cash_balance" display-format="{0}"/> 
                        <DxTotalItem name="cash_balance" summary-type="custom" show-in-column="cash_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="bank_balance" summary-type="custom" show-in-column="bank_balance" display-format="{0}"/> 
                        <DxTotalItem name="bank_balance" summary-type="custom" show-in-column="bank_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="personnel_balance" summary-type="custom" show-in-column="personnel_balance" display-format="{0}"/> 
                        <DxTotalItem name="personnel_balance" summary-type="custom" show-in-column="personnel_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="expense_balance" summary-type="custom" show-in-column="expense_balance" display-format="{0}"/> 
                        <DxTotalItem name="expense_balance" summary-type="custom" show-in-column="expense_balance"/>
                        <DxGroupItem :show-in-group-footer="true" name="other_balance" summary-type="custom" show-in-column="other_balance" display-format="{0}"/> 
                        <DxTotalItem name="other_balance" summary-type="custom" show-in-column="other_balance"/>
                      </DxSummary>
                    </DxDataGrid>
                  </template>
                </DxDataGrid>
              </div>
          </div> 
      </div> 
  </div> 
</template>

<script>
import axios from 'axios';
  

import { DxDataGrid, DxPaging, DxColumn, DxMasterDetail, DxSummary, DxTotalItem, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "ConsolidationReports",
    components:{ DxDataGrid, DxColumn, DxPaging, DxMasterDetail, DxSummary, DxTotalItem, DxScrolling },
  data() {
    return {
      lists: [],
      isListProgressing: false,
      isViewLocalAmounts: false
    };
  },
  computed: {
    controllerList() {
      return this.lists.filter(p => { 
        return p.row_type == 1;
      });
    },
    companyList() {
      return(value) => {
        return this.lists.filter(p => { 
          return p.row_type == 2 && p.id == value.id;
        });
      }
    }
  },
  methods: {
    getData() {
      this.isListProgressing = true;
      const parameters = {
        session_id: this.$getUser().session_id
      };  
      const that = this;
      axios.post('/api/SystemReports/ConsolidationReport', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.lists = response.data;
          }
      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(function() {
        that.isListProgressing = false;
      });
    },
    onRowPrepared(e){
      if(e.rowType === 'data') {  
        if(e.data.row_type == 1) { 
          e.rowElement.classList.add('bg-material-gray-200');  
          e.rowElement.classList.add('fw-bolder');  
        }
      } 
    },
    setSummaries(options){
      if(options.name == "customer_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var customer_consolidation_amount = parseFloat(options.value.customer_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += customer_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "supplier_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var supplier_consolidation_amount = parseFloat(options.value.supplier_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += supplier_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "cash_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var cash_consolidation_amount = parseFloat(options.value.cash_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += cash_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "bank_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var bank_consolidation_amount = parseFloat(options.value.bank_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += bank_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "personnel_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var personnel_consolidation_amount = parseFloat(options.value.personnel_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += personnel_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "expense_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var expense_consolidation_amount = parseFloat(options.value.expense_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += expense_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "other_consolidation_amount"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var other_consolidation_amount = parseFloat(options.value.other_consolidation_amount.replaceAll(',', ''));
          (options.totalValue += other_consolidation_amount);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "customer_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var customer_balance = parseFloat(options.value.customer_balance.replaceAll(',', ''));
          (options.totalValue += customer_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "supplier_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var supplier_balance = parseFloat(options.value.supplier_balance.replaceAll(',', ''));
          (options.totalValue += supplier_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "cash_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var cash_balance = parseFloat(options.value.cash_balance.replaceAll(',', ''));
          (options.totalValue += cash_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "bank_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var bank_balance = parseFloat(options.value.bank_balance.replaceAll(',', ''));
          (options.totalValue += bank_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "personnel_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var personnel_balance = parseFloat(options.value.personnel_balance.replaceAll(',', ''));
          (options.totalValue += personnel_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "expense_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var expense_balance = parseFloat(options.value.expense_balance.replaceAll(',', ''));
          (options.totalValue += expense_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
      if(options.name == "other_balance"){
        if(options.summaryProcess === 'start'){options.totalValue = 0;}
        else if(options.summaryProcess === 'calculate'){
          var other_balance = parseFloat(options.value.other_balance.replaceAll(',', ''));
          (options.totalValue += other_balance);
        }
        else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
      }
    },
  },
  created() { 
    this.getData();
  }
}; 
</script>