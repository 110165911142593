<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Security Settings</h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600">
                        <router-link :to="{ name: 'Settings' }">Settings</router-link>
                    </li>
                    <li class="breadcrumb-item text-gray-600">
                        <router-link :to="{ name: 'SystemSettings' }">System Settings</router-link>
                    </li>
                    <li class="breadcrumb-item text-gray-600">Security Settings</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/SystemSettings">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to Settings </span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="row">
                    <div class="tab-content" id="myTabContent">
                        <div class="content flex-row-fluid mt-4" id="kt_content">
                            <div class="card mb-2 pt-6" >
                                <div class="card-title ms-7">
                                    <h5>Security Settings Active/Passive</h5>
                                </div>
                                <div class="ms-7 pb-3 d-flex">
                                    <div class="form-check mb-3 me-7">
                                        <label class="form-check-label fw-bold" for="generalRateLimit">General Rate Limiting </label>
                                        <input class="form-check-input" type="checkbox" id="generalRateLimit" v-model="securitySettingForm.general" @change="toggleActiveMode(1)">
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label fw-bold" for="ipRateLimit">Ip Rate Limiting </label>
                                        <input class="form-check-input" type="checkbox" id="ipRateLimit" v-model="securitySettingForm.ip" @change="toggleActiveMode(2)">
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header border-0 pt-6">
                                    <div class="card-title">
                                        <router-link to="/SecuritySettingsCreate">
                                            <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                <span class="svg-icon svg-icon-muted">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                Add Security Settings
                                            </button>
                                        </router-link>
                                    </div>
                                    <div class="card-toolbar">
                                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                            <button type="button" class="btn btn-light-primary border border-primary" @click="getList()" data-kt-indicator="on">
                                                <span class="svg-icon svg-icon-muted">
                                                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor" />
                                                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor" />
                                                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <strong v-if="!this.isListProgressing"> List </strong>
                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div class="table-responsive min-h-300px">
                                            <DxDataGrid :show-borders="true" key-expr="id" :data-source="security_settings_details"
                                                :allow-column-reordering="true" :allow-column-resizing="true"
                                                :column-auto-width="true" :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" :show-row-lines="true"
                                                @exporting="this.$onExporting($event, 'security-Settings-List')">

                                                <DxFilterRow :visible="true" />
                                                <DxHeaderFilter :visible="true" />
                                                <DxGroupPanel :visible="true" />
                                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false" />
                                                <DxPaging :page-size="10000" />
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true" />
                                                <DxSearchPanel :visible="true" />

                                                <DxColumn data-field="rate_endpoint" caption="Endpoint" cell-template="rate_endpoint-cell-template" alignment="left" />
                                                <DxColumn data-field="rate_ip" caption="IP" cell-template="rate_ip-cell-template"/>
                                                <DxColumn data-field="rate_period" caption="Period" alignment="center" cell-template="rate_period-cell-template" :allow-filtering="false" />
                                                <DxColumn data-field="rate_limit" caption="Limit" alignment="center" cell-template="rate_limit-cell-template" :allow-filtering="false" />
                                                <DxColumn data-field="is_active" caption="Limit" alignment="center" cell-template="is_active-cell-template" :allow-filtering="false" />

                                                <template #rate_ip-cell-template="{ data }">
                                                    <a href="#" class="text-gray-900 text-hover-primary mb-1"  @click="updateItem(data.data)">
                                                        {{ data.data.rate_ip }}
                                                    </a>
                                                </template>
                                                <template #rate_endpoint-cell-template="{ data }">
                                                    <a href="#" class="text-gray-900 text-hover-primary mb-1"  @click="updateItem(data.data)">
                                                        {{ data.data.rate_endpoint }}
                                                    </a>
                                                </template>
                                                <template #rate_period-cell-template="{ data }">
                                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateItem(data.data)">
                                                        {{ data.data.rate_period }}
                                                    </a>
                                                </template>
                                                <template #rate_limit-cell-template="{ data }">
                                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateItem(data.data)">
                                                        {{ data.data.rate_limit }}
                                                    </a>
                                                </template>
                                                <template #is_active-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data)">
                                                        <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_active">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="updateItem(data.data)">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </template>
                                            </DxDataGrid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';

export default {
    name: "SecuritySettings",
    components: {
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel
    },
    data() {
        return {
            securitySettingForm: {
                general: false,
                ip: false
            },
            security_settings_details:[]
        };
    },
    methods: {
        getData(){
            const params = {
                session_id: this.$getUser().session_id
            }
            axios.post('/api/SecuritySettings/GetSecuritySettings', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    this.securitySettingForm.general = response.data.find(x => x.rate_type == 1).is_active == 1 ? true : false;
                    this.securitySettingForm.ip = response.data.find(x => x.rate_type == 2).is_active == 1 ? true : false;
                })
        },
        toggleActiveMode(rate_type){
            const params = {
                session_id: this.$getUser().session_id,
                rate_type: rate_type,
                is_active: rate_type == 1 ? this.securitySettingForm.general : this.securitySettingForm.ip
            }
            params.is_active = params.is_active ? 1 : 0;
            axios.post('/api/SecuritySettings/CreateSecuritySettings', params, {'Content-Type': 'application/json'});
        },
        getList(){
            const params = {
                session_id :this.$getUser().session_id
            };
            axios.post('/api/SecuritySettings/GetSecuritySettingsDetails', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.security_settings_details = response.data
            })
        },
        updateItem(item){
            this.$setSessionStorage("record_id", item.id);
            this.$router.push({ name: 'SecuritySettingsUpdate' });
        }
    },
    created(){
        this.getData();
    }
};
</script>