<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Liability Type 
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>   
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'LiabilityTypeList' }">Liability Type</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Update Liability Type</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/LiabilityTypeList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link> 
            &nbsp;
            <div class="dropdown">
                <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    Actions
                </button>
                <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteType(form.id)"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>Delete </a>
                    </li>
                </ul>
            </div>  
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mb-2 required">
                                                            Liability Group
                                                        </label>
                                                        <Select2 v-model="form.parent_id" :options="liabilityGroups" :settings="{width: '100%'}" @select="this.onChangeLiabilityGroups($event)" />
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Code
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                    <div class="col-lg-12" v-if="this.form.groupSelection.source == 2">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Transaction Type
                                                        </label>
                                                        <Select2 v-model="form.transaction_type" :options="transactionTypeList" :settings="{width: '100%'}" />
                                                    </div> 
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Is Auto-generate?
                                                        </label>
                                                        <Select2 v-model="form.is_auto_generated" :options="isAutoGeneratedList" :disabled="this.form.groupSelection.source == 3" :settings="{ width: '100%' }"/>
                                                    </div>     
                                                    <div class="" :class="{'col-lg-12': form.value_criteria == 0, 'col-lg-6': form.value_criteria != 0 && form.value_criteria != 1 && form.value_criteria != 2 && form.value_criteria != 3}">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Value Criterias
                                                        </label>
                                                        <Select2 v-model="form.value_criteria" :options="valueCriteriaList" :settings="{width: '100%'}"
                                                        @select="form.value_type = 0" :disabled="this.form.groupSelection.source == 3"/>
                                                    </div>  
                                                    <div class="col-lg-6" v-if="form.value_criteria != 0 && form.value_criteria != 1 && form.value_criteria != 2 && form.value_criteria != 3 && form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Value Type
                                                        </label>
                                                        <Select2 v-model="form.value_type" :options="filteredValueList" :settings="{ width: '100%' }"/>
                                                    </div>  
                                                    <div class="col-lg-5" v-if="form.value_criteria != 0 && form.value_criteria != 7 && form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Starting Date(Day/Month)
                                                        </label>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2 py-3 fv-row fv-plugins-icon-container pe-0">
                                                                    <Popper hover placement="top">
                                                                        <input class="form-check-input" type="checkbox" value="" id="isPriority" v-model="form.isUseCalculationDate">
                                                                        <template #content>
                                                                            <span class="fs-7" v-if="!form.isUseCalculationDate"> Click to use this date </span>
                                                                            <span class="fs-7" v-if="form.isUseCalculationDate"> Click to use the date set for the company </span>
                                                                        </template>
                                                                    </Popper>
                                                                </div>
                                                                <div class="col-lg-10 fv-row fv-plugins-icon-container ps-0">
                                                                    <Datepicker v-model="form.calculation_date" @update:modelValue="onChangeCalculationDate()" inputClassName="form-control" 
                                                                    format="dd MMM" autoApply :clearable="false" weekNumbers :disabled="!form.isUseCalculationDate && form.value_criteria != 7"
                                                                    :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div class="col-lg-5" v-if="form.value_criteria == 7 && form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Starting Date(Day/Month)
                                                        </label>
                                                        <Datepicker v-model="form.calculation_date" @update:modelValue="onChangeCalculationDate()" inputClassName="form-control" 
                                                            format="dd MMM" autoApply :clearable="false" weekNumbers :disabled="!form.isUseCalculationDate && form.value_criteria != 7"
                                                            :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>
                                                    </div> 
                                                    <div class="col-lg-3" v-if="form.value_criteria != 0 && form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Payment Days
                                                        </label>
                                                        <input type="number" class="form-control " name="name" min="0"
                                                            v-model="form.payment_day_option" @change="onChangeCalculationDate()">
                                                    </div>
                                                    <div class="col-lg-4" v-if="form.value_criteria != 0 && form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Value Date (Day/Month)
                                                        </label>
                                                        <Datepicker v-model="form.value_date" inputClassName="form-control" format="dd MMM" autoApply 
                                                        :clearable="false" weekNumbers :disabled="!form.isUseCalculationDate && form.value_criteria != 7"
                                                        :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker> 
                                                    </div>
                                                    <div class="col-lg-3" v-if="form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Period Month 
                                                        </label>
                                                        <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()" v-model="form.period_month" @change="onChangeCalculationDate()"/>
                                                    </div>
                                                    <div class="col-lg-5" v-if="form.value_criteria != 8">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Payment Value
                                                        </label>
                                                        <Select2 v-model="form.payment_value_id" :options="paymentValueList" :settings="{width: '100%'}" />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-4">
                                                            Amount Type
                                                        </label>
                                                        <Select2 v-model="form.amount_type " :options="amountTypeList" :settings="{width: '100%'}" />
                                                    </div>
                                                </div>
                                                <div class="separator separator-dashed my-6"></div>
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label">Icon</label>
                                                        <br>
                                                        <div class="btn-group" role="group">
                                                        <button class="btn btn-secondary btn-sm p-2 mt-1" data-bs-toggle="modal" data-bs-target="#iconmodal">
                                                            <span v-if="this.form.icon == null || this.form.icon == ''">Select an Icon</span>
                                                            <span v-if="this.form.icon != null && this.form.icon != ''">
                                                                <i :class="this.form.icon + ' fs-2x'"></i>
                                                            </span>
                                                        </button>
                                                            <span class="position-absolute top-100 start-100 translate-middle badge badge-circle badge-secondary cursor-pointer z-index-1"
                                                                v-if="this.form.icon != null && this.form.icon != ''" @click="onIconSelection(null)">
                                                                <i class="bi bi-x fw-bold fs-1"> </i>
                                                            </span>
                                                        </div>
                                                    </div> 
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label">Color</label>
                                                        <br>
                                                        <div class="btn-group" role="group">   
                                                            <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string"/>                                                         
                                                            <span class="position-absolute top-100 start-100 translate-middle badge badge-circle badge-secondary cursor-pointer"
                                                                v-if="this.form.color != null && this.form.color != '' && this.form.color != '#009ef7'" @click="onColorSelection()">
                                                                <i class="bi bi-x fw-bold fs-1"> </i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label"> Border Color</label>
                                                        <br>
                                                        <div class="btn-group" role="group">
                                                            <color-input type="text" placeholder="" name="name" v-model="form.border_color" format="hex string"/> 
                                                            <span class="position-absolute top-100 start-100 translate-middle badge badge-circle badge-secondary cursor-pointer"
                                                                v-if="this.form.border_color !== null && this.form.border_color !== ''" @click="onChangeBorderColor()">
                                                                <i class="bi bi-x fw-bold fs-1"> </i>
                                                            </span>
                                                        </div> 
                                                    </div> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Payment Title</span>
                                                            <!-- Ödeme için URL -->
                                                        </label>
                                                        <textarea rows="2" class="form-control " name="description" v-model="form.payment_title"></textarea>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Payment URL</span>
                                                        </label>
                                                        <input type="text" class="form-control " name="name" v-model="form.payment_url">
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description Title</span>
                                                            <!-- Mevzuat için url -->
                                                        </label>
                                                        <textarea rows="2" class="form-control " name="description" v-model="form.description_title"></textarea>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description URL</span>
                                                        </label>
                                                        <input type="text" class="form-control " name="name" v-model="form.description_url">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Update Liability Type </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="iconmodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <form class="d-flex align-items-center position-relative m-0" autocomplete="off">
                            <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search icons" v-model="searchbar">
                        </form>
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                    <div class="row" v-if="icons">
                        <div class="card mb-1 col-lg-2" v-for="item of searchableList" v-bind:key="item">
                            <div class="card-body align-middle text-center "> 
                                <a class="btn btn-light p-4 text-center cursor-pointer" @click="onIconSelection(item)" data-bs-dismiss="modal" aria-label="Close">
                                <i :class="item + ' fs-2x'"></i> <br>
                                </a> <br>
                                <small class="mt-2">{{ item }}</small>
                            </div> 
                        </div> 
                    </div>  
                </div> 
                <div class="modal-footer"> 
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `EXTU001`
import axios from 'axios' 

import Swal from 'sweetalert2';
import icons from '../../../../assets/icons/icons.json';
import moment from 'moment';

 
export default {
  name: "LiabilityTypeUpdate",
  data() {
    return {
      searchbar: '',
      lists: [],
      icons: icons,
      isOpenActionsDropdown: false, 
      isProgressing: false,
      paymentValueList:[],
      liabilityGroups : [],
      form: { 
        id: null,
        code: null,
        name: null,
        description: null,
        parent_id: '-1',
        value_criteria: 0,
        calculation_date: new Date(),
        payment_day_option: null,
        value_date: new Date(),
        color: '#009ef7',
        icon: null,
        payment_title: null,
        payment_url: null,
        description_title: null,
        description_url: null,
        period_month: 1,
        value_type: 0,
        payment_value_id: '-1',
        is_auto_generated: 1,
        isUseCalculationDate: false,
        is_use_calculation_date: 0,
        border_color: null,
        amount_type : 1,
        transaction_type : 0,
        groupSelection: {}
      },
      valueCriteriaList: [
        {id: 0, text: 'No Criteria'},
        {id: 1, text: 'Incorporated In (Day/Month)'}, 
        {id: 2, text: 'Accounting Start Period (Day/Month)'}, 
        {id: 3, text: 'Accounting End Period (Day/Month)'}, 
        {id: 4, text: 'Accounting Fee (Day/Month)'}, 
        {id: 5, text: 'VAT Start Period (Day/Month)'}, 
        {id: 6, text: 'VAT End Period (Day/Month)'}, 
        {id: 7, text: 'Payroll'}
      ],
      transactionTypeList: [
        { id: 0, text: 'Select an option'},
        { id: 1, text: 'Sales Invoice'},
        { id: 2, text: 'Purchase Invoice'},
        { id: 3, text: 'Sales Return Invoice'},
        { id: 4, text: 'Purchase Return Invoice'},
        { id: 5, text: 'Expense'},
        { id: 6, text: 'Incoming Cheque'},
        { id: 7, text: 'Outgoing Cheque'},
      ],  
      valueList: [
        {id: 0, text: 'Select an option'},
        {id: 1, text: 'Monthly'}, 
        {id: 2, text: 'Yearly'},
        {id: 3, text: 'Periodic'},
      ],
      isAutoGeneratedList: [
        {id: 1, text: 'Yes'},
        {id: 2, text: 'No'}, 
      ],
      amountTypeList: [
        {id: 0, text: 'No Amount'},
        {id: 1, text: 'Payment'},
        {id: 2, text: 'Collection'}, 
      ],
    };
  },
  computed: {
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    filteredValueList() {
        if (this.form.value_criteria == 4) {
            return this.valueList.filter(p => { 
                return p.id != 3;
            });
        }        
        if (this.form.value_criteria == 7) {
            return this.valueList.filter(p => { 
                return p.id != 2;
            });
        }        
        else{
            return this.valueList;
        }  
    }
  },
  methods: {
    getData() { 
      this.liabilityGroups = [];
      this.paymentValueList = [];
      this.$Progress.start();
      this.$setSessionStorage('refreshList', true);
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 1,
        list_type: 19 // Liability Group: 19
      };
      axios.post('/api/Lists/GetLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.liabilityGroups = response.data; 
          this.liabilityGroups.unshift({ id: '-1', text: 'Select an option'});

          const queryParameter = {
            id: this.$getSessionStorage("record_id"),
            session_id: this.$getUser().session_id
          };
          axios.post('/api/LiabilityTypes/GetLiabilityTypeById', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => {   
                this.form = { 
                    id: response.data.id,
                    name: response.data.name,
                    code: response.data.code,
                    description: response.data.description,
                    parent_id: response.data.parent_id,
                    value_criteria: response.data.value_criteria,
                    calculation_date: response.data.calculation_date,
                    payment_day_option: response.data.payment_day_option,
                    value_date: response.data.value_date,
                    color: response.data.color,
                    icon: response.data.icon,
                    payment_title: response.data.payment_title,
                    payment_url: response.data.payment_url,
                    description_title: response.data.description_title,
                    description_url: response.data.description_url,
                    value_type: response.data.value_type,
                    period_month: response.data.period_month,
                    payment_value_id: (response.data.payment_value_id !== '' && response.data.payment_value_id !== null) ? response.data.payment_value_id : '-1',
                    is_auto_generated: response.data.is_auto_generated,
                    isUseCalculationDate: response.data.is_use_calculation_date == 1 ? true : false,
                    border_color: response.data.border_color,
                    amount_type : response.data.amount_type,
                    transaction_type : response.data.transaction_type
                };
                this.liabilityGroups.forEach(item => {
                    if (item.id == this.form.parent_id) { 
                        this.form['groupSelection'] = item;
                    } 
                });

                if(this.form.groupSelection.source == 3) { 
                    this.form.is_auto_generated = 2;
                    this.form.value_criteria = 0;
                }
                axios.post('/api/FixedPaymentValue/GetFixedPaymentValue', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    this.paymentValueList = response.data;
                    this.paymentValueList.unshift({ id: '-1', text: 'No Value'});
                    this.$Progress.finish();
                }) 
                .catch(function (error) {
                    console.log(error); 
                    this.$Progress.finish();
                })
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");  
                this.$Progress.finish(); 
            });  
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.finish();
        }); 
    },
    saveData(){
      this.isProgressing = true;
      const that = this;
      const params = {
        session_id: this.$getUser().session_id,
        id: this.form.id,
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
        parent_id: this.form.parent_id !== '-1' ? this.form.parent_id : null,
        value_criteria: this.form.value_criteria,
        calculation_date: this.form.calculation_date,
        payment_day_option: this.form.payment_day_option,
        value_date: this.form.value_date,
        color: this.form.color,
        icon: this.form.icon,
        payment_title: this.form.payment_title,
        payment_url: this.form.payment_url,
        payment_value_id: this.form.payment_value_id !== '-1' ? this.form.payment_value_id : null,
        description_title: this.form.description_title,
        description_url: this.form.description_url,
        period_month: this.form.period_month,
        value_type: this.form.value_type,
        is_auto_generated: this.form.is_auto_generated,
        is_use_calculation_date: this.form.isUseCalculationDate == true ? 1 : 0,
        border_color: this.form.border_color,
        amount_type : this.form.amount_type,
        transaction_type : this.form.transaction_type
      };
      axios.post('/api/LiabilityTypes/UpdateLiabilityType', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){   
                this.$swal("Success", "Data saved succesfully..!", 'success');    
                this.$setSessionStorage('refreshList', true);    
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) 
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteType(id) { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
              icon: 'warning'
        }).then((result) => { 
            if (result.isConfirmed) {
            const queryParameter = {
                id: id,
                session_id: this.$getUser().session_id,
            }; 
            axios.post('/api/LiabilityTypes/DeleteLiabilityType', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => {   
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                    new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                    this.$setSessionStorage('refreshList', true);
                    this.$router.push({ name: 'LiabilityTypeList' }); 
                }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        })  
    },    
    onIconSelection(item){
      this.form.icon = item;  
    },
    onColorSelection(){
      this.form.color = '#009ef7';
    },  
    onChangeBorderColor(){
      this.form.border_color = null;
    },
    onChangeCalculationDate(){
        if (parseInt(this.form.payment_day_option) > 0) {
            this.form.value_date = moment(this.form.calculation_date, "YYYY-MM-DD").add(parseInt(this.form.payment_day_option), 'days');
        }
    },
    onChangeLiabilityGroups(item){
        this.form['groupSelection'] = item;
        if (item.source == 3) { this.form.value_criteria = 0; }
        if(this.form.groupSelection.source == 3) { this.form.is_auto_generated = 2; }
    }
  },
  created() { 
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>