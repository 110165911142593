<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Add Template
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DataImportTemplates' }">Data Import Templates</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Add Template</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/DataImportTemplates">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to List</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0">
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Category
                                                        </label>
                                                        <Select2 v-model="form.category_list" :options="categoryList"
                                                            :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.category_list">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Source
                                                        </label>
                                                        <Select2 v-model="form.source" :options="sourceList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control" placeholder="" name="name"
                                                            v-model="form.name">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                        Description
                                                        </label>
                                                        <textarea class="form-control" name="description" v-model="form.description"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        Clone Template
                                                    </label>
                                                    <Select2 v-model="form.clone_template" :options="cloneTemplateList"
                                                        :settings="{ width: '100%' }" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-0">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-lg btn-success " @click="this.saveTemplate()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span>
                                <strong v-if="!this.isProgressing"> Save Template</strong>
                                <span class="indicator-progress" v-if="this.isProgressing"> Saving Template... <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    </div>
</div>
</template>
  
  
<script>
  

 
export default {
  data() {
    return {
        categoryList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Accounts'},
            { id: 2, text: 'Bank Statement'},
            { id: 5, text: 'Collection'},
            { id: 3, text: 'Insurance Policy'},
            { id: 4, text: 'Invoice'},
        ],
        sourceList: [],
        cloneTemplateList: [{ id: '-1', text: 'Select an option' },],
        isHide: true,
        form: { 
            category_list: 0,
            source: 1,
            clone_template: '-1',
            description: null
        },
    };
  },
  methods: {
    saveTemplate() {
      this.$setSessionStorage('refreshList', true);
      this.$swal("Success", "Template saved succesfully..!", 'success');
      this.$router.replace('/UpdateImportTemplate');
    },
  },
}; 
</script>