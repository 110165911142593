<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3">
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Member Logs</h1>
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
          <li class="breadcrumb-item text-gray-600">
            <a href="#" class="text-gray-600 text-hover-primary">
              <router-link :to="{ name: 'Home' }">Home</router-link>
            </a>
          </li>
          <li class="breadcrumb-item text-gray-600">
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
          </li>
          <li class="breadcrumb-item text-gray-600">
            <router-link :to="{ name: 'SystemSettings' }">General Settings</router-link>
          </li>
          <li class="breadcrumb-item text-gray-600">Member Logs</li>
        </ul>
      </div>
      <div class="d-flex align-items-center py-1">
        <router-link to="/SystemSettings">
          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
            <span class="ms-2 fs-7"> Return back to Settings </span>
          </button>
        </router-link>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header card-header-stretch">
        <div class="card-title">
          <i class="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0"><span class="path1"></span><span
              class="path2"></span><span class="path3"></span><span class="path4"></span><span
              class="path5"></span><span class="path6"></span></i>

          <h3 class="fw-bold m-0 text-gray-800">New Member's Activity Logs</h3>
        </div>
        <div class="card-toolbar">
            <div class="d-flex justify-content-end align-self-center">
              <Popper hover placement="top">
                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3"
                  @click="this.setCriteriasToDefault()">
                  <i class="bi bi-eraser-fill fs-4"> </i>
                </button>
                <template #content>
                  <span class="text-gray-800"> Reset All Criterias </span>
                </template>
              </Popper>
              <button type="button" class="btn btn-primary me-3" @click="this.getLogs()"
                data-kt-indicator="on">
                <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                      fill="currentColor" />
                    <path opacity="0.3"
                      d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                      fill="currentColor" />
                  </svg>
                </span>
                <span v-if="!this.isLoading"> List Member Logs </span>
                <span class="indicator-progress" v-if="this.isLoading">
                  Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>

        </div>
      </div>
      <div class="card-body">
        <DxDataGrid :show-borders="true" 
          key-expr="id" 
          :data-source="logs" 
          :allow-column-reordering="true"  
          :allow-column-resizing="true" 
          :column-auto-width="true" 
          :row-alternatin-enabled="true"
          :hover-state-enabled="true" 
          :show-row-lines="true" 
          @exporting="this.$onExporting($event , 'Member-Logs')">

          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true"/>
          <DxGroupPanel :visible="true"/>      
          <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
          <DxPaging :page-size="10"/>
          <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
          <DxSearchPanel :visible="true"/>
          <DxScrolling
            mode="virtual"
            row-rendering-mode="virtual"
          />
          <DxColumn data-field="created_at" data-type="date" alignment="right" format="dd.MM.yyyy hh:mm"/>
          <DxColumn data-field="statu"/>
          <DxColumn data-field="user_mail"/>
          <DxColumn data-field="name_surname"/>
          <DxColumn data-field="phone"/>
          <DxColumn data-field="company_country_code"/>
          <DxColumn data-field="company_name"/>
          <DxColumn data-field="company_vkn"/>
          <DxColumn data-field="ip_address"/>
          <DxColumn data-field="referrer_url"/>
          <DxColumn data-field="description"/>
          <DxColumn data-field="is_gdpr_confirmed"/>
          <DxColumn data-field="is_privacy_policy_confirmed"/>
          <DxColumn data-field="terms_condition_confirmation"/>
          <DxColumn data-field="membership_aggrement_confirmed"/>
          <DxColumn data-field="phone_country_code"/>
          <DxColumn data-field="gender"/>
          <DxColumn data-field="primary_person"/>
          <DxColumn data-field="company_district"/>
          <DxColumn data-field="uprn"/>
          <DxColumn data-field="udprn"/>
          <DxColumn data-field="sid"/>
        </DxDataGrid> 
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

export default {
  name: "MemberLogsList",
  components: {
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      pageSize: 100,
      logs: [],
      isLoading: false,
    };
  },
  computed: {
    showPageSizeSelector() {
      return this.logs.length > this.pageSize;
    },
    pageSizes() {
      let arr = [];
      let x = 1;
      let loop = (this.logs.length / this.pageSize);
      for (let i = 1; i <= loop && i <= 5; i++) {
          arr.push(this.pageSize * x);
          x++;
      }
      if(loop > 5) arr.push(100);
      if(loop > 1) arr.push('all');
      return arr;
    }
  },
  methods: {
    getLogs() {
      this.isLoading = true;
      const parameters = {
        session_id: this.$getUser().session_id
      }
      const that = this;
      axios.post('/api/Logs/GetMemberLogs', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.logs = response.data;
          }
      })
      .catch(function() {
          that.isLoading = false;
      })
      .finally(function () {
          that.isLoading = false;
      });
    },
    setCriteriasToDefault() {
      this.logs = [];
    }
  }
};
</script>