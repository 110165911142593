<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Rate Types</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>  
                <li class="breadcrumb-item text-gray-600">Rate Types</li> 
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/ListTypes">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> Return back to Settings </span>
            </button>
        </router-link>
      </div>  
    </div>
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6"> 
            <div class="card-title">
                 
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">   
                        <button type="button" class="btn btn-light-primary border border-primary ms-4" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                  </div> 
              </div>
          </div>
          <div class="card-body pt-0">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">                  
                    <DxDataGrid :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            @exporting="this.$onExporting($event , 'Currencies')">>

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                            <DxColumn data-field="actions" caption="Actions" :width="100" cell-template="actions-cell-template" alignment="center" :allow-filtering="false"/>
                            <DxColumn data-field="title" caption="Title" cell-template="title-cell-template"/> 
                            <DxColumn data-field="is_default" caption="Is Default?" alignment="center" cell-template="is_default-cell-template" :allow-filtering="false"/>
 
                            <!-- Cell Templates -->
                            <template #title-cell-template="{ data }"> 
                              {{data.data.title}} 
                            </template>
                            <template #is_default-cell-template="{ data }">
                                <input class="form-check-input" type="checkbox" value="" id="is_default" v-model="data.data.is_default" @change="updateCurrenyTypeDefault(data.data)">
                            </template>
                            <template #actions-cell-template="{ data }">
                              <div class="d-grid gap-4 d-md-flex justify-content-md-center" v-if="data.data.type == 3">
                                <button class="btn btn-primary btn-xs" type="button" data-bs-toggle="modal" data-bs-target="#update_rate_type" @click="getRateTypeById(data.data.id)">
                                  Update
                                </button>
                              </div>
                            </template>
                    </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div> 

    <div class="modal fade" aria-hidden="true" style="display: none;" id="update_rate_type" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-fullscreen-xl-down">        
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title fw-bolder col-md-10">
              <span class="fw-bolder">Update Rate Type</span>
            </h3>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <label class="fs-6 fw-bold form-label mt-3">Api Key</label>
                <input type="text" class="form-control" placeholder="" name="apikey" v-model="modalForm.apikey"> 
              </div>
              <div class="col-lg-12">
                <label class="fs-6 fw-bold form-label mt-3">Refresh Time <small>(sec)</small> </label>
                <input type="number" class="form-control" placeholder="" name="refresh_time" v-model="modalForm.refresh_time"> 
              </div>
              <div class="col-lg-12">
                <div class="d-grid gap-2 mt-5">
                  <button type="button" class="btn btn-primary btn-lg" @click="updateData()">
                    <span class="indicator-label">Update </span> 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    
  </div>  
</template>

<script>
// Component Alias: `CURR01`  
import axios from 'axios' 
  
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

import moment from 'moment';
 
export default {
  name: "CurrencyList", 
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists:[],
      searchbar: '',
      logs: [],
      isListProgressing: false,
      modalForm: {
        id: null,
        apikey: null,
        refresh_time: 0
      }
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.title.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm:ss')
        }
      }
    }
  },
  methods: {
    getData(){
      const params = {
        session_id: this.$getUser().session_id
      };  
      axios.post('/api/Lists/RateTypesAll', params, {'Content-Type': 'application/json'})
      .then((typeResponse) => { 
        this.lists = typeResponse.data; 
      });
    },
    updateCurrenyTypeDefault(item) {  
      this.$Progress.start();
      const params = {
        session_id: this.$getUser().session_id,
        id: item.id,
        is_default: item.is_default ? 1 : 0
      };
      axios.post('/api/Lists/UpdateRateTypeDefault', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.$Progress.finish();
        this.lists = response.data;
      })
      .catch(function (error) {
        console.log(error); 
        this.$Progress.fail();
      });
    },
    getRateTypeById(id) {
      const parameters = {
        session_id: this.$getUser().session_id,
        id: id
      };  
      axios.post('/api/Lists/GetRateTypeById', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.modalForm = response.data;
         }
      })
      .catch(function (error) {
         console.log(error);
      });
    },
    updateData() {
      const parameters = {
        session_id: this.$getUser().session_id,
        id: this.modalForm.id,
        apikey: this.modalForm.apikey,
        refresh_time: this.modalForm.refresh_time
      };  
      axios.post('/api/Lists/UpdateRateType', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.lists = response.data;
            this.$refs.closeModal.click();
         }
      })
      .catch(function (error) {
         console.log(error);
      });
    }
  }, 
  created() { 
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
      this.lists = [];
    } 
  }
}; 
</script>