<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Task Group
              </h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskManagement' }">Task Management</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskGroupsList' }">Task Group</router-link></li>  
                  <li class="breadcrumb-item text-gray-600">Create Task Group</li>
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/TaskGroupsList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10"> 
                    <div class="row">
                        <div class="col-lg-12 mt-0"> 
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Code
                                                </label>
                                                <input type="text" class="form-control " name="code"
                                                    v-model="form.code">
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Name
                                                </label>
                                                <input type="text" class="form-control " name="name"
                                                    v-model="form.name">
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Group Category
                                                </label>
                                                <Select2 v-model="this.form.group_category_id" :options="groupCategories" @select="onChangeGroupCategory()" :settings="{ width: '100%' }"/>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Task / Notification
                                                </label>
                                                <Select2 v-model="this.form.is_task_or_notification" :options="taskViewOptionList" :settings="{ width: '100%' }"/>
                                            </div>
                                            <div class="col-lg-12" v-if="this.form.group_category_id !== '-1' && this.form.group_category_id !== null">
                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                    Main Task Type
                                                </label>
                                                <Select2 v-model="this.form.main_task_type" :options="mainTaskTypes" disabled :settings="{ width: '100%' }"/>
                                            </div> 
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                    <span>Description</span>
                                                </label>
                                                <textarea rows="2" class="form-control " name="description" v-model="form.description"></textarea>
                                            </div> 
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-12"> 
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-success"  id="kt_invoice_submit_button"  @click="saveData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> Save Task Group</strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>                  
                        </div>
                    </div>
                </div>
            </div> 
            </div>
        </div>
      </div>
  </div>
</template>

<script>
// Component Alias: `TAGC001`
import axios from 'axios'  

// import Swal from 'sweetalert2';

 
export default {
  name: "TaskGroupsCreate",
  data() {
    return {
      lists: [], 
      groupCategories: [],
      isProgressing: false,
      form: { 
          name: null,
          code: null,
          description: null,
          group_category_id: '-1',
          main_task_type: 0,
          is_task_or_notification: 0
      },      
      mainTaskTypes: [ { id: 1, text: 'System' },{ id: 2, text: 'Controller' }, { id: 3, text: 'Company' }],      
      taskViewOptionList: [{ id: 0, text: 'Select an option' }, { id: 1, text: 'Task' }, { id: 2, text: 'Notification' }],
    };
  },
  methods: {
    getData() {
        this.lists = []; 
        this.groupCategories = [];
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);
        const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        }
        axios.post("/api/TaskManagement/TaskGroupCategories", params, {'Content-Type': 'application/json'}).then((response) => {
          this.groupCategories = response.data; 
          this.groupCategories.unshift({ id: '-1' , text: 'Select an option'});
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
        });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;
      params.session_id = this.$getUser().session_id;
      if (params.group_category_id == '-1') { params.group_category_id = null; }
      axios.post('/api/TaskGroups/CreateTaskGroup', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data); 
                this.$setSessionStorage('refreshList', true);    
                this.$router.push({ name: 'TaskGroupsUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    onChangeGroupCategory() {
        if (this.form.group_category_id == -1 || this.form.group_category_id == null) return;
        const queryParameter = {
            id: this.form.group_category_id,
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/TaskManagement/GetTaskGroupCategoriesById', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.form.main_task_type = response.data[0].main_task_type;
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>