<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Controller Users 
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Controller Users</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link :to="{ name: 'ControllerSettings' }">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> Return back to Settings </span>
              </button>
          </router-link>
      </div>
    </div>  
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6">
              <div class="card-title">
                <router-link to="/ControllerUserCreate">
                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg></span>
                        Add Controller User
                    </button>
                </router-link>
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                      <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                        </svg>
                        </span>
                            List Controller Users
                            <span class="indicator-progress" v-if="this.isLoading">
                                is loading... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                      </button>
                  </div> 
              </div>
          </div>
          <div class="card-body">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">
                  <DxDataGrid :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                          data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Controller-Users-List')">> 
                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>      
                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                        <DxPaging :page-size="20"/>
                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                        <DxSearchPanel :visible="true"/>       
                        <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                        />
                        <DxColumn data-field="name_surname" caption="Name Surname"/> 
                        <DxColumn data-field="user_mail" caption="Email"/>
                        <DxColumn data-field="phone" caption="Phone"/> 
                        <DxColumn data-field="system_account_name" caption="System Account" /> 
                        <DxColumn data-field="controller_account_name" caption="Controller Account" /> 
                        <DxColumn data-field="user_assignment_name" caption="User Assignment" /> 
                        <DxColumn data-field="login_method_name" caption="Login Method" alignment="center"/>
                        <DxColumn data-field="is_owner" caption="Is Owner?" />
                        <DxColumn data-field="is_chatbi_user" caption="Is ChatBi User?" alignment="center" :allow-filtering="false"/> 
                        <DxColumn data-field="is_active" caption="Is Active?" />
                        <DxColumn data-field="created_at" caption="Created Date" alignment="right"/>  

                        <template #dataRowTemplate="{ data: data }">
                            <td class="text-gray-800">
                                <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    <span> {{ data.data.name_surname }} </span>
                                    &nbsp;
                                    <span v-if="data.data.permission_type == 0">
                                        <span class="badge badge-light-warning">Not Defined</span> 
                                    </span> 
                                    <span v-if="data.data.permission_type == 1">
                                        <span class="badge badge-light-primary">Admin</span> 
                                    </span> 
                                    <span v-if="data.data.permission_type == 2">
                                        <span class="badge badge-light-primary">Client</span> 
                                    </span> 
                                </a>
                            </td> 
                            <td class="text-gray-800">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    {{ data.data.user_mail }}
                                </a>
                            </td>
                            <td class="text-gray-800">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    {{ data.data.phone }}
                                </a>
                            </td>  
                            <td class="text-gray-800">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    {{ data.data.system_account_name }}
                                </a>
                            </td>  
                            <td class="text-gray-800">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    {{ data.data.controller_account_name }}
                                </a>
                            </td>    
                                <td class="text-gray-800">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                        {{ data.data.user_assignment_name }}
                                    </a>
                                </td>
                            <td class="text-gray-800 text-center">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                           
                                    <span :class="data.data.login_method == 0 ? 'badge badge-warning' : 'badge badge-primary'">
                                       {{ data.data.login_method_name }}
                                    </span>
                                </a>
                            </td>  
                            <td class="text-gray-800 text-center">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    <span v-if="data.data.is_owner == 1">
                                        <i class="las la-check fs-2x text-success"></i>
                                    </span>  
                                    <span v-if="data.data.is_owner == 0">
                                        <i class="las la-times fs-2x text-danger"></i>
                                    </span> 
                                </a>
                            </td>
                            <td class="text-gray-800 text-center">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                      
                                    <span v-if="data.data.is_chatbi_user">
                                            <i class="las la-check fs-2x text-success"></i>
                                    </span>  
                                    <span v-if="!data.data.is_chatbi_user">
                                        <i class="las la-times fs-2x text-danger"></i>
                                    </span> 
                                </a>
                            </td>  
                            <td class="text-gray-800 text-center">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    <span v-if="data.data.is_active == 1">
                                        <i class="las la-check fs-2x text-success"></i>
                                    </span>  
                                    <span v-if="data.data.is_active == 0">
                                        <i class="las la-times fs-2x text-danger"></i>
                                    </span> 
                                </a>
                            </td>
                            <td class="text-gray-800 text-end">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                    {{ previewDate(data.data.created_at) }}
                                </a>
                            </td>
                        </template>
                  </DxDataGrid>   
                </div>
              </div> 
          </div>
      </div> 
  </div>
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `CUL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
    name: "ControllerUserList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
  data() {
    return {
      isOpenActionsDropdown: false,
      lists: [],
      searchbar: '',
      isLoading: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name_surname.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isLoading = true;
      this.$setSessionStorage('data' + '_CUL001', null);
      const params = {
        session_id: this.$getUser().session_id
      }
      axios.post("/api/Users/ControllerUsers", params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.lists = response.data;
          this.lists.forEach(element => {
            element.company_account_id = this.$unlock(element.company_account_id);
            element.controller_account_id = this.$unlock(element.controller_account_id);
            element.system_account_id = this.$unlock(element.system_account_id);
            element.user_assignment_id = this.$unlock(element.user_assignment_id);
            element.user_mail = this.$unlock(element.user_mail);
            element.name_surname = this.$unlock(element.name_surname);
            element.text = this.$unlock(element.text);
            element.phone = this.$unlock(element.phone);
            element.system_account_name = this.$unlock(element.system_account_name);
            element.controller_account_name = this.$unlock(element.controller_account_name);
            element.user_assignment_name = this.$unlock(element.user_assignment_name);
          });
          this.$setSessionStorage('data'+ '_CUL001', response.data);
          this.$Progress.finish();
          this.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.$Progress.fail();
          this.isLoading = false;
        });
    },
    updateUser(id){ 
        this.$setSessionStorage("record_id", id);
        this.$router.push({ name: 'ControllerUserUpdate' });
    },
    deleteUser(id) { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: id
            };
            axios.get('/api/Users/DeleteUser/' + queryParameter.id)
                .then(() => { 
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })  
    },  
    onClickDropdown(item) {
        this.lists.forEach(element => {
            if (item.id !== element.id) {
                element.isOpenActionsDropdown = false;
            }
        });
        item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    }
},
  created() {
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
        this.lists = [];
    }
    this.isLoading = false;
  }
}; 
</script>