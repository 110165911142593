<template>
  <div>   
     <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
                Update Task Type
              </h1>  
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskManagement' }">Task Management</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskTypesList' }">Task Types</router-link></li>  
                  <li class="breadcrumb-item text-gray-600">Update Task Type</li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
          <router-link to="/TaskTypesList">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> Return back to List</span>
            </button>
          </router-link> 
          &nbsp;
          <div class="dropdown">
            <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
            <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
            </svg></span>
                Actions
            </button>
            <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }">   
                <li><a class="dropdown-item fs-4 text-danger" href="#" @click="deleteType(form.id)">
                  <span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                  </span>Delete</a>
                </li>
            </ul>
          </div> 
        </div>
     </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
          <div class="card card-xxl-stretch">
            <div class="p-10">
              <form @submit.prevent="submit">
                <div class="row">
                  <div class="d-flex flex-row row">
                      <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                              <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Code
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.code"> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Name
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.name"> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3"> Country </label>
                                        <select class="form-control" v-model="form.countrySelection">
                                          <option v-for="item in countryList" :value="item" :key="item.code">
                                            {{ item.name }}<span v-if="item.code != null" >({{item.code}})</span>
                                          </option>
                                        </select>
                                    </div> 
                                  </div> 
                              </div>
                          </div> 
                      </div>
                      <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                              <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                      <label class="fs-6 fw-bold form-label mt-3 required">Main Task Type
                                      </label>
                                      <select class="form-control" v-model="form.mainTaskTypeSelection" @change="onChangeMainTaskType()">
                                        <option v-for="item in mainTaskTypes" :value="item" :key="item.id">
                                          {{ item.name }}
                                        </option>
                                      </select>
                                    </div> 
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Task / Notification
                                        </label>
                                        <select class="form-control" v-model="form.isTaskOrNotificationSelection" @change="onChangeMainTaskType()">
                                          <option v-for="item in taskOrNotificationList" :value="item" :key="item.id">
                                            {{ item.name }}
                                          </option>
                                        </select>
                                    </div> 
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Task Group
                                        </label>
                                        <select class="form-control" v-model="form.groupSelection" @change="onChangeTaskGroup()">
                                                <option v-for="item in groups" :value="item" :key="item.id">
                                                  {{ item.name }}
                                                </option>
                                        </select>
                                    </div> 
                                    <div class="col-lg-12 mt-2" v-if="form.groupSelection !== undefined && form.groupSelection !== null && form.groupSelection !== ''">
                                      <span v-if="form.groupSelection.description !== undefined && form.groupSelection.description !== null && form.groupSelection.description !== ''">
                                        <i class="bi bi-info-circle text-primary fs-7"></i>
                                        <small class="text-gray-700 ms-2"> {{form.groupSelection.description}} </small>
                                      </span>
                                    </div> 
                                  </div> 
                              </div>
                          </div> 
                      </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">
                      <div class="fv-row mb-7 fv-plugins-icon-container">
                            <label class="fw-bold mb-2">Message</label>
                            <input type="text" class="form-control" placeholder="" name="name" v-model="form.message"> 
                        </div> 
                      <div class="fv-row mb-7 fv-plugins-icon-container">
                          <label class="fs-6 fw-bold form-label mb-2">Description</label>
                          <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.description" /> 
                      </div> 

                      <div class="col-lg-12">
                          <div class="form-check mt-3">
                            <input class="form-check-input" type="checkbox" value="" id="isMainTask" v-model="form.is_parent_task_type" @change="this.getTaskTypes()">
                            <label class="form-check-label" for="isMainTask">
                                This is a Parent Task <br>
                              <small>If the Task is a detail task, please uncheck `Parent Task` and select an option from below.</small>
                            </label>
                          </div> 
                      </div>
                      <div class="col-lg-12 mt-5" v-if="form.is_parent_task_type !== true">
                          <label class="fs-6 fw-bold form-label mb-2 required">Parent Task
                          </label>  
                          <select class="form-control" v-model="form.parentTaskTypeSelection">
                            <option v-for="item in taskTypes" :value="item" :key="item.code">
                            <span v-if="item.id !== null"> {{ item.code }} - {{ item.name }} ({{ item.task_group_category_name}} / {{ item.task_group_name }}) ({{ item.country_code }}) </span>
                            <span v-if="item.id === null"> {{ item.name }}</span>
                            </option>
                          </select>
                      </div>
                    </div>  
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">                      
                      <h4 v-if="form.isTaskOrNotificationSelection.id != 2">Task Options</h4>
                      <h4 v-if="form.isTaskOrNotificationSelection.id == 2">Notification Options</h4>   
                      <div class="row mt-5">
                        <div class="col-lg-4 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3 required">Priority
                            </label>
                            <select class="form-control" v-model="form.prioritySelection">
                                    <option v-for="item in priorities" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                        </div> 
                        <!-- Default: No -->
                        <div class="col-lg-3 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Confirmation 
                            </label>
                            <select class="form-control" v-model="form.isConfirmationSelection">
                                    <option v-for="item in confirmationList" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                        </div> 
                        <div class="col-lg-3 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Icon</label>
                            <br>
                            <div class="btn-group" role="group">
                              <button class="btn btn-secondary btn-sm p-2" data-bs-toggle="modal" data-bs-target="#iconmodal">
                                  <span v-if="this.form.icon == null || this.form.icon == ''">Select an Icon</span>
                                  <span v-if="this.form.icon != null && this.form.icon != ''">
                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                  </span>
                              </button>
                              <button class="btn btn-sm btn-danger" v-if="this.form.icon != null && this.form.icon != ''" @click="onIconSelection(null)">
                                Remove Icon
                              </button>
                            </div>
                        </div>
                        <div class="col-lg-2 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Color</label>
                            <br>
                            <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string"/> 
                        </div>  
                      </div>  
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">
                      <h4>Period Options</h4>
                      <div class="row mt-5">
                        <div class="col-lg-6" v-if="form.isTaskOrNotificationSelection.id != 2">
                          <label class="fs-6 fw-bold form-label mt-3 required">Period Type
                            </label>
                          <select class="form-control" v-model="form.periodTypeSelection" @change="onPeriodChanged()">
                              <option v-for="item in periods" :value="item" :key="item.id">
                                {{ item.name }}
                              </option>
                          </select>
                        </div>
                        <div class="col-lg-6" v-if="form.isTaskOrNotificationSelection.id != 2">
                          <label class="fs-6 fw-bold form-label mt-3 required">Period Close Method
                            </label>
                          <select class="form-control" v-model="form.periodCloseTypeSelection">
                                  <option v-for="item in periodCloseTypes" :value="item" :key="item.id">
                                    {{ item.name }}
                                  </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7">
                          <label class="fs-6 fw-bold form-label mt-3 required">Transaction Type
                            </label>
                          <select class="form-control" v-model="form.transactionLocationSelection">
                                  <option v-for="item in transactionLocationList" :value="item" :key="item.id">
                                    {{ item.text }}
                                  </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7 && this.locationDetailListByFilter.length > 0">
                          <label class="fs-6 fw-bold form-label mt-3 required">Transaction Detail
                            </label>
                          <select class="form-control" v-model="form.transactionDetailSelection">
                              <option v-for="item in locationDetailListByFilter" :value="item" :key="item.id">
                                {{ item.text }}
                              </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7">
                          <label class="fs-6 fw-bold form-label mt-3 required">Date Option
                            </label>
                          <select class="form-control" v-model="form.dateOptionSelection">
                                  <option v-for="item in dateOptionList" :value="item" :key="item.id">
                                    {{ item.text }}
                                  </option>
                            </select>
                        </div>
                      </div>
                      <div class="row" v-if="form.isTaskOrNotificationSelection.id == 2">
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            Notification Starting Period
                          </label>
                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                        </div>
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            Notification Ending Period
                          </label>
                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_end" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                        </div> 
                        <div class="col-lg-12 mt-5">
                          <div class="form-check mt-3 form-check-custom form-check-success">
                              <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                              <label class="form-check-label" for="satCheck">
                                For Saturdays, add to next working day
                              </label>
                            </div>
                            <div class="form-check mt-3 form-check-custom form-check-warning">
                              <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                              <label class="form-check-label" for="sunCheck">
                                For Sundays, add to next working day
                              </label>
                            </div>
                            <div class="form-check mt-3 form-check-custom form-check-primary">
                              <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                              <label class="form-check-label" for="holCheck">
                                For public holidays, add to next working day
                              </label>
                            </div>
                        </div>
                      </div> 
                      <div v-if="form.periodTypeSelection && form.isTaskOrNotificationSelection.id != 2">
                        <!-- PERIOD TYPE: FREE -->
                        <div class="row" v-if="form.periodTypeSelection.id == 1"> 
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">Task Starting Period
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                          </div>
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">Task Ending Period
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_end" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                          </div>  
                          <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: DAILY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 2"> 
                          <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: WEEKLY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 3"> 
                          <div class="col-lg-4 mb-3">
                              <label class="fs-6 fw-bold form-label mt-3 required">Day
                              </label>
                              <select class="form-control" v-model="form.periodWeeklyDateSelection">
                                    <option v-for="item in days" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                          </div> 
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-12 mt-5">
                            <div class="form-check form-check-custom form-check-success">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="radPub" checked v-model="form.is_skip_holiday" value="false" @change="onHolidayRadioChecked(false)">
                                <label class="form-check-label" for="radPub">
                                  Include public holidays
                                </label>
                              </div>
                              <div class="form-check form-check-custom form-check-danger mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="radHol" v-model="form.is_skip_holiday" value="true" @change="onHolidayRadioChecked(true)">
                                <label class="form-check-label" for="radHol">
                                  For public holidays, add to next working day
                                </label>
                              </div> 
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: MONTHLY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 4"> 
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Every month's:
                                </label>
                                <select class="form-control" v-model="form.periodMonthlyDateSelection">
                                    <option v-for="item in monthDays" :value="item" :key="item">
                                      {{ item }}
                                    </option>
                              </select>
                            </div>
                            <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                            </div>
                        </div> 
                        <!-- PERIOD TYPE: ANNUAL -->
                        <div class="row" v-if="form.periodTypeSelection.id == 5"> 
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Date
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_annual_date" inputClassName="form-control" format="MMM-dd" :clearable="false" :monthChangeOnScroll="'inverse'" :enableTimePicker="false"  :flow="['month', 'calendar']"></Datepicker> 
                            </div>
                            <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-12 mt-5">
                              <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                                <div class="form-check mt-3 form-check-custom form-check-danger">
                                  <input class="form-check-input" type="checkbox" value="" id="comCheck" v-model="form.is_acquire_company">
                                  <label class="form-check-label" for="comCheck">
                                    Acquire information from Company's accounting periods
                                  </label>
                                </div>
                            </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="d-flex flex-row row">
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                <div class="card-body">
                                  <h4>Reminder Options</h4>
                                  <div class="row">
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                        <label class="fs-6 fw-bold form-label mt-3">Reminder Day (before)</label>
                                        <CurrencyInput type="text" class="form-control" placeholder="" name="name" v-model="form.reminder_day" :precision="0" @focus="$event.target.select()" :min="0"/> 
                                    </div>  
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                        <label class="fs-6 fw-bold form-label mt-3">Reminder Hour (before)</label>
                                        <CurrencyInput type="text" class="form-control" placeholder="" name="name" v-model="form.reminder_hours" :precision="0" @focus="$event.target.select()" :min="0"/> 
                                    </div> 
                                  </div>
                                </div>
                            </div> 
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                <div class="card-body">
                                    <div class="row">                                    
                                      <h4>
                                        <span v-if="form.isTaskOrNotificationSelection.id != 2"> Task </span> 
                                        <span v-if="form.isTaskOrNotificationSelection.id == 2"> Notification </span> 
                                        Contents
                                      </h4>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_date">
                                        <label class="form-check-label" for="flexCheckDefault">
                                          Date
                                        </label>
                                      </div> 
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_message">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Message
                                        </label>
                                      </div>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_meeting">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Meeting
                                        </label>
                                      </div>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_value">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Amount
                                        </label>
                                      </div> 
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5" v-if="form.is_have_value">
                    <div class="card card-body bg-lighten border border-gray-300">                      
                      <h4>Amount</h4> 
                      <div class="row">
                        <div class="col-lg-6 mt-5">
                            <label class="fs-6 fw-bold form-label mt-3 required">Amount Type
                            </label>
                            <select class="form-control" v-model="form.amountTypeSelection" @change="onAmountTypeChanged()">
                                <option v-for="item in amountTypes" :value="item" :key="item">
                                  {{ item.name }}
                                </option> 
                            </select>
                        </div> 
                        <div class="col-lg-6" v-if="form.amountTypeSelection !== null">
                          <div class="col-lg-12 mt-5" v-if="form.amountTypeSelection.id == 2">
                              <label class="fs-6 fw-bold form-label mt-3 required">Value
                              </label>
                              <input type="text" class="form-control" placeholder="" name="name" v-model="form.amount_value"> 
                          </div>
                          <div class="col-lg-12 mt-5" v-if="form.amountTypeSelection.id == 3">
                              <label class="fs-6 fw-bold form-label mt-3 required">Amount Parameter
                              </label>
                              <select class="form-control" v-model="form.amountParameterSelection">
                                <option v-for="item in amountParametersList" :value="item" :key="item">
                                  {{ item.name }}
                                </option>  
                              </select>
                          </div> 
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
                <div class=" d-grid gap-2">
                  <button type="submit" href="#" class="btn btn-primary mt-5" id="kt_invoice_submit_button" @click="saveData()" data-kt-indicator="on">
                      <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      </svg>
                      </span>
                        <strong v-if="!this.isProgressing"> Update Task Type </strong> 
                          <span class="indicator-progress" v-if="this.isProgressing">
                              Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span> 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div> 


     <div class="modal fade" tabindex="-1" id="iconmodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <div class="col-lg-6">
                      <form class="d-flex align-items-center position-relative m-0" autocomplete="off">
                          <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                              </svg>
                          </span>
                          <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search icons" v-model="searchbar">
                      </form>
                  </div>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                      <span class="svg-icon svg-icon-2x"></span>
                  </div> 
              </div> 
              <div class="modal-body">
                    <div class="row" v-if="icons">
                        <div class="card mb-1 col-lg-2" v-for="item of searchableList" v-bind:key="item">
                          <div class="card-body align-middle text-center "> 
                            <a class="btn btn-light p-4 text-center cursor-pointer" @click="onIconSelection(item)" data-bs-dismiss="modal" aria-label="Close">
                              <i :class="item + ' fs-2x'"></i> <br>
                            </a> <br>
                            <small class="mt-2">{{ item }}</small>
                          </div> 
                        </div> 
                  </div>  
              </div> 
              <div class="modal-footer"> 
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
    </div>
  </div> 
</template> 
<script>
// Component Alias: `TATU001`
import axios from 'axios' 
  
import icons from '../../../assets/icons/icons.json';
import countries from '../../../assets/countries/countries.json';
import moment from 'moment';
// import Swal from 'sweetalert2';

 
export default {
  name: "TaskTypesUpdate",
  data() {
    return {
      searchbar: '',
      isOpenActionsDropdown: false,
      icons: icons,
      lists: [], 
      groups: [],
      priorities: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Normal'}, { id: 2, name: 'Low Priority'}, { id: 3, name: 'High Priority'}],
      mainTaskTypes: [ { id: 0, name: 'Select task group' }, { id: 1, name: 'System' },{ id: 2, name: 'Controller' }, { id: 3, name: 'Company' }],
      periods: [ { id: 0, name: 'Select an option' }, { id: 1, name: 'Free Period' },{ id: 2, name: 'Daily Period' }, { id: 3, name: 'Weekly Period' }, { id: 4, name: 'Monthly Period ' }, { id: 5, name: 'Annual Period' }, { id: 6, name: 'Periodic Period' }, { id: 7, name: 'Transaction Period' }],
      periodCloseTypes: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Automatic'}, { id: 2, name: 'Manuel'}], 
      days: [ { id: 0, name: 'Select an option' }, { id: 1, name: 'Sunday' }, { id: 2, name: 'Monday' },{ id: 3, name: 'Tuesday' },{ id: 4, name: 'Wednesday' },{ id: 5, name: 'Thursday' },{ id: 6, name: 'Friday' },{ id: 7, name: 'Saturday' } ],
      monthDays: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      taskOrNotificationList: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Task' }, { id: 2, name: 'Notification' }],
      İsProgressing: false,
      form: { 
          id: null,
          name: null,
          code: null,
          message: null,
          description: null, 
          groupSelection: { id: 0, name: 'Select an option' },
          priority: 1,
          prioritySelection: null,
          main_task_type: 1,
          is_task_or_notification: 0,
          mainTaskTypeSelection: null,
          period_type: null,
          periodTypeSelection: { id: 0, text: 'Select an option'},
          period_close_type: null,
          periodCloseTypeSelection: null,
          icon: null,
          color: '#009ef7',
          period_start: null,
          period_end: null,
          reminder_day: 1,
          reminder_hours: 1,
          is_have_date: true,
          is_have_value: false,
          is_have_message: true,
          is_have_meeting: false,
          is_skip_saturday: false,
          is_skip_sunday: false,
          is_skip_holiday: false,
          is_acquire_company: false, 
          period_annual_date: new Date(),
          period_monthly_date: null,
          period_weekly_date  : null,
          period_daily_start_time: new Date(),
          period_daily_end_time : new Date(),
          periodWeeklyDateSelection: null,
          periodMonthlyDateSelection: null,
          countrySelection: null,
          country_code: null,
          is_parent_task_type: true,
          parentTaskTypeSelection: null,
          amount_type: 0,
          amountTypeSelection : null,
          amount_parameter: null,
          amountParameterSelection: null,
          transactionLocationSelection: { id: 0, text: 'Select an option'},
          transactionDetailSelection: { id: 0, text: 'Select an option'},
          dateOptionSelection: { id: 0, text: 'Select an option'},
          amount_value: '0',
          transaction_location: null,
          transaction_location_detail: null,
          transaction_date_option: null,
          isTaskOrNotificationSelection: { id: 0, name: 'Select an option' },
          isConfirmationSelection: { id: 1, name: 'No' },
          is_confirmation: 1
      },
      countryList: countries,
      taskTypes: [],
      amountTypes: [{ id: 1, name: 'Free Amount'}, { id: 2, name: 'Default Amount'}, { id:3, name: 'Get Amount from Company'}, { id: 4, name: 'Transaction Amount'}],
      amountParametersList: [{ id: -1, name: 'Select an option'}, { id:1, name: 'Get value from Accounting Fee'}],
      transactionLocationList: [
        { id: 0, text: 'Select an option'},
        { id: 1, text: 'Invoice Transaction'},
        { id: 2, text: 'Cash Transaction'},
        { id: 3, text: 'Bank Transaction'},
        { id: 4, text: 'Expense Transaction'},  /* Transaction Detail yok. Gelen giden yok */
        { id: 5, text: 'Cheque Transaction'},
      ],
      transactionDetailList: [
        { id: 0, text: 'Select an option', type: 0},
        { id: 1, text: 'Sales Invoice', type: 1},
        { id: 2, text: 'Purchase Invoice', type: 1},
        { id: 3, text: 'Sales Return Invoice', type: 1},
        { id: 4, text: 'Purchase Return Invoice', type: 1},
        { id: 5, text: 'Incoming', type: 2},
        { id: 6, text: 'Outgoing', type: 2},
      ],
      dateOptionList: [
        { id: 0, text: 'Select an option'},
        { id: 1, text: 'Record Date'},
        { id: 2, text: 'Due Date'},
      ],
      confirmationList: [
        { id: 1, name: 'No'},
        { id: 2, name: 'Yes'},
      ]
    };
  },
  computed: {
    locationDetailListByFilter(){ 
        // Invoice
        if(this.form.transactionLocationSelection.id == 1){ 
            return this.transactionDetailList.filter(p => { 
              return p.type == 1 || p.type == 0;
            });
        } 
        // Cash, Bank, Cheque
        else if(this.form.transactionLocationSelection.id == 2 || this.form.transactionLocationSelection.id == 3 || this.form.transactionLocationSelection.id == 5) { 
            return this.transactionDetailList.filter(p => { 
              return p.type == 2 || p.type == 0;
            });
        }
        else {
            return [];
        }  
    },
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    }
  },
  methods: {
    getData() { 
      this.$Progress.start();
      this.lists = []; 
      this.$setSessionStorage('refreshList', true);
      const queryParameter = {
        id: this.$getSessionStorage("record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/TaskTypes/GetTaskTypeById', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
          this.groups = [];
          this.form = response.data[0];
          this.form.groupSelection = this.groups[0];
          this.form.prioritySelection = this.priorities[0];
          this.form.mainTaskTypeSelection = this.mainTaskTypes[0];
          this.form.periodTypeSelection = this.periods[0];
          this.form.periodCloseTypeSelection = this.periodCloseTypes[0];
          this.form.periodWeeklyDateSelection = this.days[0];
          this.form.periodMonthlyDateSelection = this.monthDays[0]; 
          this.form.country_code = response.data[0].country_code;
          this.form.countrySelection = this.countryList[0];
          this.form.transactionLocationSelection = this.transactionLocationList[0];
          this.form.transactionDetailSelection = this.transactionDetailList[0];
          this.form.dateOptionSelection = this.dateOptionList[0];
          this.form.isTaskOrNotificationSelection = this.taskOrNotificationList[0];
          this.form.isConfirmationSelection = this.confirmationList[0];

          if(this.form.parent_task_type_id !== ''){
            this.amountTypes.push({
              id: 4,
              name: 'Get amount from Parent Value'
            });
          }
          this.form.amountTypeSelection = this.amountTypes[0];
          this.form.amountParameterSelection = this.amountParametersList[0];

              if (this.form.main_task_type == 3) {
                this.periods.push({ id: 7, name: 'Transaction Period' });
                this.amountTypes.push({ id: 4, name: 'Transaction Amount'});
              } else {
                this.periods= this.periods.filter(p => { 
                  return p.id !== 7;
                });
                this.amountTypes= this.amountTypes.filter(p => { 
                  return p.id !== 4;
                });
              }
              const params = {
                session_id : this.$getUser().session_id,
                get_access_status: 0,
                main_task_type: this.form.main_task_type,
                is_task_or_notification: 0

              };
              axios.post("/api/TaskGroups/GetTaskGroups", params, {'Content-Type': 'application/json'}).then((taskGroupResponse) => {
                  this.groups = taskGroupResponse.data; 

                  this.groups.forEach((value) => {
                    if(this.form.task_group_id == value.id){
                      this.form.groupSelection = value;
                    }
                  });
                  this.priorities.forEach((value) => {
                    if(this.form.priority == value.id){
                      this.form.prioritySelection = value;
                    }
                  });
                  this.mainTaskTypes.forEach((value) => {
                    if(this.form.main_task_type == value.id){
                      this.form.mainTaskTypeSelection = value;
                    }
                  });
                  this.taskOrNotificationList.forEach((value) => {
                    if(this.form.groupSelection.is_task_or_notification == value.id){
                      this.form.isTaskOrNotificationSelection = value;
                    }
                  });
                  this.periods.forEach((value) => {
                    if(this.form.period_type == value.id){
                      this.form.periodTypeSelection = value;
                    }
                  });
                  this.periodCloseTypes.forEach((value) => {
                    if(this.form.period_close_type == value.id){
                      this.form.periodCloseTypeSelection = value;
                    }
                  }); 
                  this.days.forEach((value) => {
                    if(this.form.period_weekly_date == value.id){
                      this.form.periodWeeklyDateSelection = value;
                    }
                  }); 
                  this.monthDays.forEach((value) => {
                    if(this.form.period_monthly_date == value){
                      this.form.periodMonthlyDateSelection = value;
                    }
                  });  
                  this.countryList.forEach((value) => { 
                    if(response.data[0].country_code == value.code){
                      this.form.countrySelection = value;
                    }
                  });     
                  this.amountParametersList.forEach((value) => { 
                    if(response.data[0].amount_parameter == value.id){
                      this.form.amountParameterSelection = value;
                    }
                  });   
                  this.amountTypes.forEach((value) => { 
                    if(response.data[0].amount_type == value.id){
                      this.form.amountTypeSelection = value;
                    }
                  });
                  
                  this.transactionLocationList.forEach((value) => {
                    if(this.form.transaction_location == value.id){
                      this.form.transactionLocationSelection = value;
                    }
                  });
                  this.transactionDetailList.forEach((value) => {
                    if(this.form.transaction_location_detail == value.id){
                      this.form.transactionDetailSelection = value;
                    }
                  });
                  this.dateOptionList.forEach((value) => {
                    if(this.form.transaction_date_option == value.id){
                      this.form.dateOptionSelection = value;
                    }
                  });
                  this.confirmationList.forEach((value) => {
                    if(this.form.is_confirmation == value.id){
                      this.form.isConfirmationSelection = value;
                    }
                  });
                  
                  this.form.period_daily_start_time = { 
                      hours: new Date(response.data[0].period_daily_start_time).getHours(),
                      minutes: new Date(response.data[0].period_daily_start_time).getMinutes()
                  };
                  this.form.period_daily_end_time = { 
                      hours: new Date(response.data[0].period_daily_end_time).getHours(),
                      minutes: new Date(response.data[0].period_daily_end_time).getMinutes()
                  }; 

                    this.taskTypes = [];
                    const queryParameter = {
                      session_id: this.$getUser().session_id,
                    };
                    axios.post('/api/TaskTypes/GetBasicParentTaskTypes', queryParameter, {'Content-Type': 'application/json'})
                      .then((taskTypeListResponse) => { 
                            this.taskTypes = taskTypeListResponse.data;   
                            this.taskTypes.unshift({ id: null, name: 'Select an option'});
                            this.taskTypes.forEach((value) => { 
                              if(response.data[0].parent_task_type_id == value.id){
                                this.form.parentTaskTypeSelection = value;
                              }
                            });
                      })
                      .catch(function (error) {
                          console.log(error);
                      }); 

                  this.$Progress.finish();
                })
                .catch(function (error) {
                  console.log(error);
                  this.$Progress.finish();
                });
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    },
    getTaskTypes(){
       this.taskTypes = []; 
       this.amountTypes = [];
       const queryParameter = {
         session_id: this.$getUser().session_id,
      };
       axios.post('/api/TaskTypes/GetBasicParentTaskTypes', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => { 
              this.taskTypes = response.data;   
              this.taskTypes.unshift({ id: null, name: 'Select an option'});
  
              if(this.form.is_parent_task_type == true){
                
                 this.form.parentTaskTypeSelection = this.taskTypes[0];
                 this.amountTypes = [
                    { id: 1, name: 'Free Amount'}, 
                    { id: 2, name: 'Default Amount'}, 
                    { id: 3, name: 'Get Amount from Company'}
                  ]; 
              }
              else{
                 this.amountTypes = [
                    { id: 1, name: 'Free Amount'}, 
                    { id: 2, name: 'Default Amount'}, 
                    { id: 3, name: 'Get Amount from Company'}, 
                    { id: 4, name: 'Get amount from Parent Value' }
                  ];
              }
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },
    saveData(){   
      this.isProgressing = true;
      const params = this.form; 
      const that = this;    
      params.id = this.$getSessionStorage("record_id");    
      params.task_group_id = params.groupSelection.id;
      params.priority = params.prioritySelection.id;
      params.main_task_type = params.mainTaskTypeSelection.id;
      params.is_task_or_notification = params.isTaskOrNotificationSelection.id;
      params.period_type = params.periodTypeSelection.id;
      params.period_close_type = params.periodCloseTypeSelection.id; 
      params.period_weekly_date = params.periodWeeklyDateSelection.id;
      params.period_monthly_date = params.periodMonthlyDateSelection;
      params.period_start = moment(params.period_start).format('YYYY-MM-DD[T]HH:mm:ss');
      params.period_end = moment(params.period_end).format('YYYY-MM-DD[T]HH:mm:ss');
      params.country_code = params.countrySelection.code;
      params.transaction_location = params.transactionLocationSelection.id;
      params.transaction_location_detail = params.transactionDetailSelection.id;
      params.transaction_date_option = params.dateOptionSelection.id;
      params.is_confirmation = params.isConfirmationSelection.id;

      const period_daily_start_time = new Date(); 
      if(params.period_daily_start_time.hours !== null){
        period_daily_start_time.setUTCHours(params.period_daily_start_time.hours);
        period_daily_start_time.setUTCMinutes(params.period_daily_start_time.minutes);
        period_daily_start_time.setUTCSeconds(0);
        params.period_daily_start_time = period_daily_start_time;
      }

      const period_daily_end_time = new Date();
      if(params.period_daily_end_time.hours !== null){
        period_daily_end_time.setUTCHours(params.period_daily_end_time.hours);
        period_daily_end_time.setUTCMinutes(params.period_daily_end_time.minutes);
        period_daily_end_time.setUTCSeconds(0);
        params.period_daily_end_time = period_daily_end_time;
      }

      if(params.period_type == 1 || params.period_type == 6){
         params.period_daily_start_time = new Date();
         params.period_daily_end_time = new Date();
      }

      if(params.is_parent_task_type === true){
        params.parent_task_type_id = null;
      }
      else{ 
        params.parent_task_type_id = this.form.parentTaskTypeSelection.id;
      }
 
      params.amount_type = params.amountTypeSelection.id; 
      params.amount_parameter = params.amountParameterSelection.id; 
      params.session_id = this.$getUser().session_id;
    
      axios.post('/api/TaskTypes/UpdateTaskType', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('refreshList', true);        
                this.getData();  
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })  .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });     
    },
    onIconSelection(item){
      this.form.icon = item;  
    },
    onPeriodChanged(){
      this.form.is_skip_saturday= false;
      this.form.is_skip_sunday= false;
      this.form.is_skip_holiday= false;
      this.form.is_acquire_company= false;
      this.form.period_annual_date= new Date();
      this.form.periodMonthlyDateSelection = this.monthDays[0];
      this.form.periodWeeklyDateSelection  = this.days[0];
      this.form.period_daily_start_time= { hours: 0, minutes: 0, seconds: 0 };
      this.form.period_daily_end_time = { hours: 0, minutes: 0, seconds: 0 };  
    },
    onHolidayRadioChecked(value){
      this.form.is_skip_holiday = value;
    },
    onAmountTypeChanged(){  
      this.form.amount_parameter = null;
      this.form.amount_value = '0';
      this.form.amountParameterSelection = this.amountParametersList[0];
    },
    deleteType(lists) { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: lists,
                session_id: this.$getUser().session_id,
            };
            axios.post('/api/TaskTypes/DeleteTaskType', queryParameter, {'Content-Type': 'application/json'})
                .then(() => {  
                    this.$router.push({ name: 'TaskTypesList' });
                    this.$setSessionStorage('refreshList', true);
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })  
    },
    onChangeTaskGroup() {
      if (this.form.groupSelection.id == null) {
        this.form.mainTaskTypeSelection = this.mainTaskTypes[0];
      }

      this.mainTaskTypes.forEach(element => {
        if (element.id == this.form.groupSelection.group_main_task_type) {
          this.form.mainTaskTypeSelection = element;
        }
      });

      this.taskOrNotificationList.forEach(element => {
        if (element.id == this.form.groupSelection.is_task_or_notification) {
          this.form.isTaskOrNotificationSelection = element;
        }
      });
    },
    onChangeTransactionLocation() {
      this.form.transactionDetailSelection = this.transactionDetailList[0];
    },
    onChangeMainTaskType() {
      if (this.form.mainTaskTypeSelection.id == 3) {
        this.periods.push({ id: 7, name: 'Transaction Period' });
        this.amountTypes.push({ id: 4, name: 'Transaction Amount'});
      } else {
        this.periods= this.periods.filter(p => { 
          return p.id !== 7;
        });
        this.amountTypes= this.amountTypes.filter(p => { 
          return p.id !== 4;
        });
      }      
      this.groups = [];
      this.$Progress.start();
      const params = {
        session_id : this.$getUser().session_id,
        get_access_status: 0,
        main_task_type: this.form.mainTaskTypeSelection.id,
        is_task_or_notification: this.form.isTaskOrNotificationSelection.id,
      };
      axios.post("/api/TaskGroups/GetTaskGroups", params, {'Content-Type': 'application/json'}).then((response) => {
        this.groups = response.data;
        this.groups.unshift({ id: null, name: 'Select an option'});
        this.form.groupSelection = this.groups[0];
        this.$Progress.finish();
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
      });   
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>