<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3">
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">System Logs</h1>
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
          <li class="breadcrumb-item text-gray-600">
            <a href="#" class="text-gray-600 text-hover-primary">
              <router-link :to="{ name: 'Home' }">Home</router-link>
            </a>
          </li>
          <li class="breadcrumb-item text-gray-600">
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
          </li>
          <li class="breadcrumb-item text-gray-600">
            <router-link :to="{ name: 'SystemSettings' }">General Settings</router-link>
          </li>
          <li class="breadcrumb-item text-gray-600">System Logs</li>
        </ul>
      </div>
      <div class="d-flex align-items-center py-1">
        <router-link to="/SystemSettings">
          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
            <span class="ms-2 fs-7"> Return back to Settings </span>
          </button>
        </router-link>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header card-header-stretch">
        <div class="card-title">
          <i class="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0"><span class="path1"></span><span
              class="path2"></span><span class="path3"></span><span class="path4"></span><span
              class="path5"></span><span class="path6"></span></i>

          <h3 class="fw-bold m-0 text-gray-800">System Activity Logs</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row mb-4 mt-4">
          <div class="d-flex col-lg-9">
              <div class="col-lg me-4">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>Start Date</span>
                </label>
                <Datepicker v-model="this.logFilterParams.start_date" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }"
                  v-maska="'##.##.####'" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false"
                  weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false">
                </Datepicker>
              </div>
              <div class="col-lg me-4">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>End Date</span>
                </label>
                <Datepicker v-model="this.logFilterParams.end_date" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }"
                  v-maska="'##.##.####'" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false"
                  weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>
              </div>
              <div class="col-lg me-4">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>Log Type</span>
                </label>
                <Select2 v-model="this.logFilterParams.log_type" :options="this.logTypeList"/>
              </div>
               <div class="col-lg me-4">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>Controller Account</span>
                </label>
                <Select2 v-model="this.logFilterParams.controller_account_id" :options="this.logControllerAccountList" @select="getCompanyAccounts($event)"/>
              </div>
               <div class="col-lg me-4">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>Company Account</span>
                </label>
                <Select2 v-model="this.logFilterParams.company_account_id" :options="this.logCompanyAccountList" @select="getUserAccountsList($event)"/>
              </div>
              <div class="col-lg">
                <label class="fs-6 fw-bold form-label mt-3">
                  <span>User Account</span>
                </label>
                <Select2 v-model="this.logFilterParams.user_account_id" :options="this.logUserAccountsList"/>
              </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex justify-content-end align-self-center mt-10">
              <Popper hover placement="top">
                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3"
                  @click="this.setCriteriasToDefault()">
                  <i class="bi bi-eraser-fill fs-4"> </i>
                </button>
                <template #content>
                  <span class="text-gray-800"> Reset All Criterias </span>
                </template>
              </Popper>
              <button type="button" class="btn btn-primary me-3" @click="this.getLogs()"
                data-kt-indicator="on">
                <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                      fill="currentColor" />
                    <path opacity="0.3"
                      d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                      fill="currentColor" />
                  </svg>
                </span>
                <span v-if="!this.isLoading"> List System Logs </span>
                <span class="indicator-progress" v-if="this.isLoading">
                  Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <DxDataGrid :show-borders="true" 
          key-expr="id" 
          :data-source="logs" 
          :allow-column-reordering="true"  
          :allow-column-resizing="true" 
          :column-auto-width="true" 
          :row-alternatin-enabled="true"
          :hover-state-enabled="true" 
          :show-row-lines="true" 
          :ref="'dataGrid'"
          @exporting="this.$onExporting($event , 'Logs')">
          <DxPaging :page-size="this.pageSize"/>
          <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>

          <DxFilterRow :visible="true" />
          <DxHeaderFilter :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxColumn data-field="createdAt"  data-type="date" alignment="right" format="dd.MM.yyyy HH:mm:ss" />
          <DxColumn data-field="userName" /> 
          <DxColumn data-field="companyName" /> 
          <DxColumn data-field="controllerCompanyName" /> 
          <DxColumn data-field="logLocation" />  
          <DxColumn data-field="logType" /> 
          <DxColumn data-field="logOperation" /> 
          <DxColumn data-field="accountName" />
          <DxColumn data-field="description" />
          <DxColumn data-field="documentNo" />
          <DxColumn data-field="logLocationDetail" />
          <DxColumn data-field="criteriaData" />
          
        </DxDataGrid>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxPager, DxPaging } from 'devextreme-vue/data-grid';

export default {
  name: "SystemLogsList",
  components: {
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxPager, DxPaging
  },
  data() {
    return {
      pageSize: 100,
      logs: [],
      searchbar: "",
      userData: this.$getUser(),
      logTypeList: [],
      userTypeList: [ 
        { id: '-1', text: 'ALL'},
        { id: 1, text: 'Controller'},
        { id: 2, text: 'Company'},
      ],
      logUserAccountsList: [],
      logControllerAccountList: [],
      logCompanyAccountList: [],
      logFilterParams: {
        start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
        end_date: moment(),
        log_type: '-1',
        login_session: '-1',
        project_type:'-1',
        user_account_id:'-1',
        controller_account_id: '-1',
        company_account_id: '-1'
      },
      isLoading: false,
    };
  },
  computed: {
    isSystemAdmin() {
      return (
        this.$getUser().permission_type == 1 &&
        this.$getUser().account_type == 1
      );
    },
    previewDate() {
      return (value) => {
        if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm:ss')
        }
      }
    },
    previewDateOnly() {
      return (value) => {
        if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewHoursOnly() {
      return (value) => {
        if (value) {
          return moment(new Date(value)).format('HH:mm:ss')
        }
      }
    },
    showPageSizeSelector() {
      return this.logs.length > this.pageSize;
    },
    pageSizes() {
      let arr = [];
      let x = 1;
      let loop = (this.logs.length / this.pageSize);
      for (let i = 1; i <= loop && i <= 5; i++) {
          arr.push(this.pageSize * x);
          x++;
      }
      if(loop > 5) arr.push(100);
      if(loop > 1) arr.push('all');
      return arr;
    }
  },
  methods: {
    getData() {
      this.logFilterParams.start_date = (this.logFilterParams.start_date == null || this.logFilterParams.start_date == '') ? moment() : this.logFilterParams.start_date;
      this.logFilterParams.end_date = (this.logFilterParams.end_date == null || this.logFilterParams.end_date == '') ? moment() : this.logFilterParams.end_date;
      const parameters = {
        session_id: this.$getUser().session_id
      }
      axios.post('/api/Logs/GetLogTypes', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.logTypeList = response.data;
            this.logTypeList.unshift({ id: '-1', text: 'ALL' });
            this.getControllerAccounts();
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getLogs() {
      this.isLoading = true;
      const parameters = {
        session_id: this.$getUser().session_id,
        start_date: this.logFilterParams.start_date,
        end_date: this.logFilterParams.end_date,
        log_type: this.logFilterParams.log_type == '-1' ? 0 : this.logFilterParams.log_type,
        project_type: this.logFilterParams.project_type == '-1' ? 0 : this.logFilterParams.project_type,
        user_account_id: this.logFilterParams.user_account_id == '-1' ? null : this.logFilterParams.user_account_id,
        company_account_id: this.logFilterParams.company_account_id == '-1' ? null : this.logFilterParams.company_account_id,
        controller_account_id: this.logFilterParams.controller_account_id == '-1' ? null : this.logFilterParams.controller_account_id
      }
      parameters.start_date = moment(parameters.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
      parameters.end_date = moment(parameters.end_date).format('YYYY-MM-DD[T]HH:mm:ss');
      const that = this;
      axios.post('/api/Logs/GetLogs', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.logs = response.data;
          }
      })
      .catch(function() {
          that.isLoading = false;
      })
      .finally(function () {
          that.isLoading = false;
      });
    },
    setCriteriasToDefault() {
      this.logFilterParams = {
        start_date: new Date(),
        end_date: new Date(),
        log_type: '-1',
        login_session: '-1',
        project_type: '-1',
        user_account_id: '-1',
        controller_account_id: '-1',
        company_account_id: '-1'
      }
      this.getLogs();
    },
    getUserAccount(projectType, company_account_id){
      const params = {
        session_id: this.$getUser().session_id,
        project_type: projectType == '-1' ? 0 : projectType,
        company_account_id: company_account_id
      }
      axios.post('/api/Accounts/GetUserAccountsByCompanyAccountID', params, {'Content-Type': 'application/json'}).then((response) => { 
        this.logUserAccountsList = response.data;
        this.logUserAccountsList.unshift({ id: '-1', text: 'ALL' });
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getUserAccountsList(e){
      this.getUserAccount(this.logFilterParams.project_type, e.id)
    },
    getControllerAccounts(){
      this.logControllerAccountList = [];
      this.logCompanyAccountList = [];
      this.logUserAccountsList = [];
      const params = {
            session_id: this.$getUser().session_id
        };
        axios.post("/api/AccountingPlans/GetAuthControllerAccounts", params, {'Content-Type': 'application/json'}).then((response) => {
            this.logControllerAccountList = response.data;
            this.logCompanyAccountList.unshift({ id: -1, text: 'ALL'});
            this.logUserAccountsList.unshift({ id: -1, text: 'ALL'});
            this.logFilterParams.company_account_id = '-1';
            this.logFilterParams.user_account_id = '-1';
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    getCompanyAccounts(e){ 
      this.logCompanyAccountList = [];
      this.logUserAccountsList = [];
      const params = {
        session_id: this.$getUser().session_id,
        controller_id: e.id
      }
      axios.post("/api/Accounts/CompanyAccounts", params, {'Content-Type': 'application/json'})
      .then((companyResponse) => { 
        this.logCompanyAccountList = companyResponse.data;
        this.logCompanyAccountList.forEach(element => {
          element.company_name = this.$unlock(element.company_name);
          element.text = this.$unlock(element.text);
          element.company_vkn = this.$unlock(element.company_vkn);
          element.primary_person = this.$unlock(element.primary_person);
          element.company_gsm = this.$unlock(element.company_gsm);
          element.company_landphone = this.$unlock(element.company_landphone);
          element.company_email = this.$unlock(element.company_email);
          element.company_address = this.$unlock(element.company_address);
          element.company_city = this.$unlock(element.company_city);
          element.company_town = this.$unlock(element.company_town);
          element.company_state = this.$unlock(element.company_state);
          element.company_region = this.$unlock(element.company_region);
          element.company_postcode = this.$unlock(element.company_postcode);
          element.company_country_code = this.$unlock(element.company_country_code);
          element.controller_id = this.$unlock(element.controller_id);
          element.controller_account_name = this.$unlock(element.controller_account_name);
          element.system_access_name = this.$unlock(element.system_access_name);
          element.controller_access_name = this.$unlock(element.controller_access_name);
        });
        this.logCompanyAccountList.unshift({ id: -1, text: 'ALL'});
        this.logUserAccountsList.unshift({ id: -1, text: 'ALL'});
        this.logFilterParams.company_account_id = '-1';
        this.logFilterParams.user_account_id = '-1';
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  created() {
    this.getData();
  },
};
</script>