<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Companies House List</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600">Settings</li> 
                <li class="breadcrumb-item text-gray-600">Controller Functions</li> 
                <li class="breadcrumb-item text-gray-600">Companies House List</li> 
            </ul> 
        </div>
    </div>

    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="row g-6 g-xl-9 text-center"> 
             <div class="col-xl-12 mb-xl-10">
                <div class="card shadow">
                    <div class="card-header">
                        <div class="card-title">
                            Companies House List
                        </div>          
                    </div> 
                    <div class="card-body pt-0">
                        <div class="table-responsive min-h-300px"> 

                                <DxDataGrid
                                        :show-borders="true"
                                        key-expr="id"
                                        :data-source="lists"
                                        :column-auto-width="true"
                                        :row-alternatin-enabled="true"
                                        data-row-template="dataRowTemplate"
                                        :hover-state-enabled="true"
                                        :show-row-lines="true" 
                                        :ref="'dataGrid'"
                                        @exporting="this.$onExporting($event , 'Companies-House-List')">
                                    
                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="10000"/>
                                        <DxPager
                                            :visible="false"
                                            :allowed-page-sizes="pageSizes"
                                            :show-page-size-selector="false"
                                            :show-info="false"
                                            :show-navigation-buttons="false"
                                        />
                                        <DxFilterRow :visible="true"/>
                                        <DxSearchPanel :visible="true"/>
                                        <DxSorting mode="none"/>
                                        <DxGrouping :context-menu-enabled="true"/>
                                        <DxGroupPanel :visible="true"/>

                                        <DxColumn :allow-exporting="false"/>
                                        <DxColumn data-field="company_name" caption="Incorporated On" header-cell-template="company_name-header"/>
                                        <DxColumn data-field="company_vkn" caption="Company Number" header-cell-template="company_vkn-header"/>
                                        <DxColumn data-field="incorporated_at" caption="Incorporated On" header-cell-template="incorporated_at-header" alignment="right"/>
                                        <DxColumn data-field="last_confirmation_statement" caption="Last Confirmation Statement" header-cell-template="last_confirmation_statement-header" alignment="right"/>
                                        <DxColumn data-field="next_confirmation_statement" caption="Next Confirmation Statement" header-cell-template="next_confirmation_statement-header" alignment="right"/>
                                        <DxColumn data-field="next_confirmation_due_date" caption="Next Confirmation Due Date" header-cell-template="next_confirmation_due_date-header" alignment="right"/>
                                        <DxColumn caption="Days Remaining" header-cell-template="confirmation_days_remaining-header" alignment="right"/>
                                        <DxColumn data-field="last_account_date" caption="Last Account Date" header-cell-template="last_account_date-header" alignment="right"/>
                                        <DxColumn data-field="next_account_date" caption="Next Account Date" header-cell-template="next_account_date-header" alignment="right"/>
                                        <DxColumn caption="Days Remaining" header-cell-template="account_days_remaining-header" alignment="right"/>
                                        <DxColumn data-field="next_account_due_date" caption="Next Account Due Date" header-cell-template="next_account_due_date-header" alignment="right"/>
                                        <DxColumn data-field="is_vat_active_name" caption="VAT Status" header-cell-template="is_vat_active-header" alignment="left"/>
                                        <DxColumn data-field="vat_start_period" caption="VAT Start Period" header-cell-template="vat_start_period-header" alignment="right"/>
                                        <DxColumn data-field="vat_end_period" caption="VAT End Period" header-cell-template="vat_end_period-header" alignment="right"/>
                                        <DxColumn data-field="accounting_fee_monthly" caption="Accounting Fee" header-cell-template="accounting_fee_monthly-header"/>
                                        <DxColumn data-field="official_company_type" caption="Company Type" header-cell-template="official_company_type-header"/>
                                        <DxColumn data-field="primary_person" caption="Primary Person" header-cell-template="primary_person-header"/>
                                        <DxColumn data-field="data_update_date" caption="Data Last Update" header-cell-template="data_update_date-header" alignment="right"/>
                                        <DxColumn caption="Actions" alignment="center" :allow-exporting="false"/>

                                       
                                        <template #company_name-header> <div>Company</div><div>Name</div> </template>
                                        <template #company_vkn-header> <div>Company</div><div>Number</div> </template>
                                        <template #incorporated_at-header> <div>Incorporated</div><div>On</div> </template>
                                        <template #last_confirmation_statement-header> <div>Last Confirmation</div><div>Statement</div> </template>
                                        <template #next_confirmation_statement-header> <div>Next Confirmation</div><div>Statement</div> </template>
                                        <template #next_confirmation_due_date-header> <div>Next Confirmation</div><div>Due Date</div> </template>
                                        <template #confirmation_days_remaining-header> <div>Days</div><div>Remaining</div> </template>
                                        <template #last_account_date-header> <div>Last Account</div><div>Date</div> </template>
                                        <template #next_account_date-header> <div>Next Account</div><div>Date</div></template>
                                        <template #account_days_remaining-header> <div>Days</div><div>Remaining</div></template>
                                        <template #next_account_due_date-header><div>Next Account</div><div> Due Date</div> </template>
                                        <template #is_vat_active-header><div> VAT</div><div> Status</div> </template>
                                        <template #vat_start_period-header><div> VAT</div><div> Start Period</div> </template>
                                        <template #vat_end_period-header><div> VAT</div><div> End Period</div> </template>
                                        <template #accounting_fee_monthly-header><div> Accounting</div><div> Fee</div> </template>
                                        <template #official_company_type-header><div> Company</div><div> Type</div> </template>
                                        <template #primary_person-header><div> Primary</div><div> Person</div> </template>
                                        <template #data_update_date-header><div> Last</div><div> Update</div> </template>

                                    

                                        <template #dataRowTemplate="{ data: data }">
                                            <tr class="align-middle border border-gray-200">
                                                    <td class="text-center w-30px border border-end border-gray-200 align-middle">
                                                        <button class="btn btn-xs btn-secondary btn-hover-primary " @click="updateInfoOnline(data.data)">
                                                            Update Info
                                                        </button> 
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <span class="text-gray-800 fw-bold">{{ data.data.company_name }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <span class="text-gray-800 fw-bold">{{ data.data.company_vkn }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.incorporated_at) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.last_confirmation_statement) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.next_confirmation_statement) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.next_confirmation_due_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ daysRemaining(data.data.next_confirmation_due_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.last_account_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.next_account_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ daysRemaining(data.data.next_account_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.next_account_due_date) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <div>
                                                            <span> {{ data.data.is_vat_active_name }} </span>
                                                        </div>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.vat_start_period) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <span>{{ previewOnlyDate(data.data.vat_end_period) }}</span>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <div>
                                                            <span v-if="data.data.accounting_fee_monthly == '' || data.data.accounting_fee_monthly == null || data.data.accounting_fee_monthly == 0">Yearly</span>
                                                            <span v-if="data.data.accounting_fee_monthly !== '' && data.data.accounting_fee_monthly !== null && data.data.accounting_fee_monthly != 0">Monthly</span>
                                                        </div>
                                                    </td> 
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <small>{{ data.data.official_company_type }}</small>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                        <small>{{ data.data.primary_person }}</small>
                                                    </td>
                                                    <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                        <small>{{ previewDate(data.data.data_update_date) }}</small>
                                                    </td>
                                                    <td class="text-center w-30px border border-end border-gray-200 align-middle">
                                                        <button class="btn btn-xs btn-secondary btn-hover-info" @click="showCompanyHistory(data.data)" data-bs-toggle="modal" data-bs-target="#company_history_modal">
                                                            Show History
                                                        </button>
                                                    </td>
                                            </tr>
                                        </template>

                                        <DxStateStoring
                                                :enabled="true"
                                                type="custom"
                                                :storage-key="this.storageKey"
                                                :ref="dataGrid" 
                                                :saving-timeout="1000"
                                                :custom-load="LoadDXState"
                                            />
                                </DxDataGrid>    
                                </div> 
                    </div>  
                </div>
            </div>
        </div>
    </div>  

    <!-- COMPANY HISTORY MODAL -->
    <div class="modal fade" tabindex="-1" id="company_history_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xxl modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Company History</h5>                     
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg>
                      </span>
                  </div>
              </div> 
              <div class="modal-body scroll-y pt-1">
                <div class="table-responsive min-h-300px"> 
                        <DxDataGrid
                                :show-borders="true"
                                key-expr="id"
                                :data-source="historyList"
                                :column-auto-width="true"
                                :row-alternatin-enabled="true"
                                data-row-template="dataRowTemplate"
                                :hover-state-enabled="true"
                                :show-row-lines="true" 
                                :ref="'dataGrid'">   
                            
                                <DxPaging :page-size="10000"/>
                                <DxPager
                                    :visible="false"
                                    :allowed-page-sizes="pageSizes"
                                    :show-page-size-selector="false"
                                    :show-info="false"
                                    :show-navigation-buttons="false"
                                />
                                <DxFilterRow :visible="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxSorting mode="none"/>
                                <DxGrouping :context-menu-enabled="true"/>
                                <DxGroupPanel :visible="true"/>

                                <DxColumn data-field="company_name" caption="Incorporated On" header-cell-template="company_name-header"/>
                                <DxColumn data-field="company_vkn" caption="Company Number" header-cell-template="company_vkn-header"/>
                                <DxColumn data-field="incorporated_at" caption="Incorporated On" header-cell-template="incorporated_at-header" alignment="right"/>
                                <DxColumn data-field="last_confirmation_statement" caption="Last Confirmation Statement" header-cell-template="last_confirmation_statement-header" alignment="right"/>
                                <DxColumn data-field="next_confirmation_statement" caption="Next Confirmation Statement" header-cell-template="next_confirmation_statement-header" alignment="right"/>
                                <DxColumn data-field="next_confirmation_due_date" caption="Next Confirmation Due Date" header-cell-template="next_confirmation_due_date-header" alignment="right"/>
                                <DxColumn caption="Days Remaining" header-cell-template="confirmation_days_remaining-header" alignment="right"/>
                                <DxColumn data-field="last_account_date" caption="Last Account Date" header-cell-template="last_account_date-header" alignment="right"/>
                                <DxColumn data-field="next_account_date" caption="Next Account Date" header-cell-template="next_account_date-header" alignment="right"/>
                                <DxColumn caption="Days Remaining" header-cell-template="account_days_remaining-header" alignment="right"/>
                                <DxColumn data-field="next_account_due_date" caption="Next Account Due Date" header-cell-template="next_account_due_date-header" alignment="right"/>
                                <DxColumn data-field="is_vat_active_name" caption="VAT Status" header-cell-template="is_vat_active-header" alignment="left"/>
                                <DxColumn data-field="vat_start_period" caption="VAT Start Period" header-cell-template="vat_start_period-header" alignment="right"/>
                                <DxColumn data-field="vat_end_period" caption="VAT End Period" header-cell-template="vat_end_period-header" alignment="right"/>
                                <DxColumn data-field="accounting_fee_monthly" caption="Accounting Fee" header-cell-template="accounting_fee_monthly-header"/>
                                <DxColumn data-field="official_company_type" caption="Company Type" header-cell-template="official_company_type-header"/>
                                <DxColumn data-field="primary_person" caption="Primary Person" header-cell-template="primary_person-header"/>
                                <DxColumn data-field="data_update_date" caption="Data Last Update" header-cell-template="data_update_date-header" alignment="right" :allow-exporting="false"/>

                            
                                <template #company_name-header> <div>Company</div><div>Name</div> </template>
                                <template #company_vkn-header> <div>Company</div><div>Number</div> </template>
                                <template #incorporated_at-header> <div>Incorporated</div><div>On</div> </template>
                                <template #last_confirmation_statement-header> <div>Last Confirmation</div><div>Statement</div> </template>
                                <template #next_confirmation_statement-header> <div>Next Confirmation</div><div>Statement</div> </template>
                                <template #next_confirmation_due_date-header> <div>Next Confirmation</div><div>Due Date</div> </template>
                                <template #confirmation_days_remaining-header> <div>Days</div><div>Remaining</div> </template>
                                <template #last_account_date-header> <div>Last Account</div><div>Date</div> </template>
                                <template #next_account_date-header> <div>Next Account</div><div>Date</div></template>
                                <template #account_days_remaining-header> <div>Days</div><div>Remaining</div></template>
                                <template #next_account_due_date-header><div>Next Account</div><div> Due Date</div> </template>
                                <template #is_vat_active-header><div> VAT</div><div> Status</div> </template>
                                <template #vat_start_period-header><div> VAT</div><div> Start Period</div> </template>
                                <template #vat_end_period-header><div> VAT</div><div> End Period</div> </template>
                                <template #accounting_fee_monthly-header><div> Accounting</div><div> Fee</div> </template>
                                <template #official_company_type-header><div> Company</div><div> Type</div> </template>
                                <template #primary_person-header><div> Primary</div><div> Person</div> </template>
                                <template #data_update_date-header><div> Last</div><div> Update</div> </template>

                            

                                <template #dataRowTemplate="{ data: data }">
                                    <tr class="align-middle border border-gray-200"> 
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <span class="text-gray-800 fw-bold">{{ data.data.company_name }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <span class="text-gray-800 fw-bold">{{ data.data.company_vkn }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.incorporated_at) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.last_confirmation_statement) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.next_confirmation_statement) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.next_confirmation_due_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ daysRemaining(data.data.next_confirmation_due_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.last_account_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.next_account_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ daysRemaining(data.data.next_account_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.next_account_due_date) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <div>
                                                    <span>{{ data.data.is_vat_active_name }}</span>
                                                </div>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.vat_start_period) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <span>{{ previewOnlyDate(data.data.vat_end_period) }}</span>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <div>
                                                    <span v-if="data.data.accounting_fee_monthly == '' || data.data.accounting_fee_monthly == null || data.data.accounting_fee_monthly == 0">Yearly</span>
                                                    <span v-if="data.data.accounting_fee_monthly !== '' && data.data.accounting_fee_monthly !== null && data.data.accounting_fee_monthly != 0">Monthly</span>
                                                </div>
                                            </td> 
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <small>{{ data.data.official_company_type }}</small>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-start"> 
                                                <small>{{ data.data.primary_person }}</small>
                                            </td>
                                            <td class="w-20px border border-end border-gray-200 p-2 text-end"> 
                                                <small>{{ previewDate(data.data.data_update_date) }}</small>
                                            </td>
                                    </tr>
                                </template>
                        </DxDataGrid>    
                    </div> 
              </div>
          </div>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';
   
import { DxDataGrid, DxExport, DxPaging, DxPager, DxColumn, DxStateStoring, DxSorting, DxSearchPanel, DxFilterRow, DxGrouping, DxGroupPanel  } from 'devextreme-vue/data-grid';

import moment from 'moment';

export default {
  name: "CompaniesHouseList", 
  components:{
        DxDataGrid, DxExport, DxPaging, DxPager, DxColumn, DxStateStoring, DxSorting, DxSearchPanel, DxFilterRow, DxGrouping, DxGroupPanel
  },
  data() {
    return {
      lists:[],
      historyList: [],
      userData: this.$getUser()
    };
  }, 
  computed: {  
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        previewOnlyDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        daysRemaining(){
            return (value)=>{
            if (value) 
                { 
                    return moment(new Date(value)).diff(moment(), 'days')
                }
            }
        }
    },
    methods: {
        getData(){
            const companyParams = {
                session_id: this.$getUser().session_id,
                controller_id: this.$getUser().controller_account_id
            }
            axios.post("/api/Accounts/CompanyAccounts", companyParams, {'Content-Type': 'application/json'})
            .then((companyResponse) => { 
                this.lists = companyResponse.data;
                this.lists.forEach(element => {
                    element.company_name = this.$unlock(element.company_name);
                    element.text = this.$unlock(element.text);
                    element.company_vkn = this.$unlock(element.company_vkn);
                    element.primary_person = this.$unlock(element.primary_person);
                    element.company_gsm = this.$unlock(element.company_gsm);
                    element.company_landphone = this.$unlock(element.company_landphone);
                    element.company_email = this.$unlock(element.company_email);
                    element.company_address = this.$unlock(element.company_address);
                    element.company_city = this.$unlock(element.company_city);
                    element.company_town = this.$unlock(element.company_town);
                    element.company_state = this.$unlock(element.company_state);
                    element.company_region = this.$unlock(element.company_region);
                    element.company_postcode = this.$unlock(element.company_postcode);
                    element.company_country_code = this.$unlock(element.company_country_code);
                    element.controller_id = this.$unlock(element.controller_id);
                    element.controller_account_name = this.$unlock(element.controller_account_name);
                    element.system_access_name = this.$unlock(element.system_access_name);
                    element.controller_access_name = this.$unlock(element.controller_access_name);
                });
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        updateInfoOnline(item){
            const parameters = {
                SessionId: this.$getUser().session_id,
				CompanyNumber: item.company_vkn,
                ControllerId: this.$getUser().controller_account_id,
                CompanyId: item.id
			}
			axios.post('api/Accounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
			.then(() => { 
                this.getData();
			});
        },
        showCompanyHistory(item){
            const companyParams = {
                session_id: this.$getUser().session_id,
                controller_id: this.$getUser().controller_account_id,
                company_no: item.company_vkn
            }
            axios.post("/api/Accounts/CompanyAccountHistories", companyParams, {'Content-Type': 'application/json'})
            .then((companyResponse) => { 
                this.historyList = companyResponse.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        }
  }, 
  created() { 
    this.getData();
  }
}; 
</script>