<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Countries</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CountryList' }">Countries</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Create Country</li> 
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/CountryList">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List </span>
            </button>
        </router-link>
      </div>  
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
      <RequiredField></RequiredField>
      <div class="row">
          <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-12 mt-0"> 
                          <div class="d-flex flex-row row">
                              <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                  <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                      <div class="card-body">
                                          <div class="row">
                                              <div class="col-lg-6">
                                                  <label class="fs-6 fw-bold form-label mt-3 required">
                                                      Code
                                                  </label>
                                                  <input type="text" class="form-control " name="code"
                                                      v-model="form.code">
                                              </div>
                                              <div class="col-lg-6">
                                                  <label class="fs-6 fw-bold form-label mt-3 required">
                                                      Name
                                                  </label>
                                                  <input type="text" class="form-control " name="name"
                                                      v-model="form.name">
                                              </div>
                                          </div> 
                                      </div>
                                  </div> 
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="d-grid gap-2">
                              <button type="button" class="btn btn-success"  id="kt_invoice_submit_button"  @click="saveData()" data-kt-indicator="on">
                                  <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  </svg>
                                  </span> 
                                  <strong v-if="!this.isProgressing"> Save Country </strong> 
                                      <span class="indicator-progress" v-if="this.isProgressing">
                                          Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span> 
                              </button>
                          </div>                
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>    
  </div>  
</template>

<script>
// Component Alias: `CURR01`  
import axios from 'axios' 
  
import Swal from 'sweetalert2';

 
export default {
  name: "CountryCreate", 
  data() {
    return {
      searchbar: '',
      isProgressing: false,
      form: {
        code: null,
        name: null
      }
    };
  },
  methods: {
    getData() {  
      this.$setSessionStorage('refreshList', true);   
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;
      params.session_id = this.$getUser().session_id;
      axios.post('/api/Lists/CreateCountry', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('record_id', response.data); 
                new Swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'CountryUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    }
  },
  created(){
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>