<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Countries</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>  
                <li class="breadcrumb-item text-gray-600">Countries</li> 
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/ListTypes">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> Return back to Settings </span>
            </button>
        </router-link>
      </div>  
    </div>
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6">
              <div class="card-title">
                  <router-link to="/CountryCreate">
                      <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                        </span>Add Country
                      </button>
                  </router-link>
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                  </div> 
              </div>
          </div>
          <div class="card-body pt-0">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">                  
                    <DxDataGrid :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            @exporting="this.$onExporting($event , 'Countries-List')">

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                                
                            />
                            <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                            <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/> 
                            <DxColumn data-field="is_show" caption="Is Show?" alignment="center" cell-template="show-cell-template" :allow-filtering="false"/> 
                            <DxColumn data-field="is_priority" caption="Is Priority?" alignment="center" cell-template="is_priority-cell-template" :allow-filtering="false"/> 

                            <!-- Header Cell Templates -->

                            <!-- Cell Templates -->
                            <template #code-cell-template="{ data }">
                                <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                  class="text-gray-900 mb-1" @click="updateCountry(data.data)">
                                    <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                        <i class="bi bi-pencil text-gray-900"></i> 
                                    </span> 
                                    {{ data.data.code }}
                                </a>
                            </template>
                            <template #name-cell-template="{ data }">
                                <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                  class="text-gray-900 fw-bold mb-1" @click="updateCountry(data.data)">
                                    {{ data.data.name }}
                                </a>
                            </template> 
                            <template #is_priority-cell-template="{ data }">
                                <input class="form-check-input" type="checkbox" value="" id="is_priority" v-model="data.data.is_priority" :disabled="data.data.full_access == 0" @change="updateCountryItem(data.data, 2)">
                            </template>
                            <template #show-cell-template="{ data }">
                                  <input class="form-check-input" type="checkbox" value="" id="show" v-model="data.data.is_show" :disabled="data.data.full_access == 0" @change="updateCountryItem(data.data, 1)">
                            </template>
                    </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div> 
    
  </div>  
</template>

<script>
// Component Alias: `CURR01`  
import axios from 'axios' 
  
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
  name: "CountryList", 
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists:[],
      searchbar: '',
      logs: [],
      isListProgressing: false,
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    }
  },
  methods: {
    getData() {  
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const params = {
          session_id: this.$getUser().session_id,
          get_access_status: 1
      };    
      axios.post('/api/Lists/Countries', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.lists = response.data;
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
        console.log(error); 
        this.$Progress.fail();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      });
    },
    updateCountryItem(item, type) {
      this.$swal.fire({
            title: type == 1 ? (item.is_show == true ? 'Are you sure to show this data from the lists?' : 'Are you sure to hide this data from the lists?') : 'Are you sure?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
            if (result.isConfirmed) {              
              const params = {
                session_id: this.$getUser().session_id,
                code: item.code,
                is_show: null,
                is_priority: null
              };    
              params.is_show = item.is_show == true ? 1 : 0;
              params.is_priority = item.is_priority == true ? 1 : 0;
              axios.post('/api/Lists/UpdateCountryPriority', params, {'Content-Type': 'application/json'})
              .then((response) => { 
                this.lists = response.data;
                this.$Progress.finish();
              })
              .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
              });
            }
            else{
                if(type == 1) item.is_show = !item.is_show;
                else item.is_priority = !item.is_priority;
            }
        });
    },  
    updateCountry(item){
      if (item.full_access == 0) return;
      this.$setSessionStorage("record_id", item.id);
      this.$router.push({ name: 'CountryUpdate' }); 
    },
  }, 
  created() { 
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
    this.lists = [];
    } 
  }
}; 
</script>