<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Data DLL Templates
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary">
                      <router-link :to="{ name: 'Home' }">Home</router-link>
                  </a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600">Data DLL Templates</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/SystemSettings">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> Return back to Settings </span>
           </button>
            </router-link>
        </div> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <router-link to="/DLLTemplatesCreate">
                                <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>Add DLL</button>
                            </router-link>
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List DLLs </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-0">
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        
                      <DxDataGrid :show-borders="true" 
                              key-expr="id" 
                              :data-source="lists" 
                              :allow-column-reordering="true"  
                              :allow-column-resizing="true" 
                              :column-auto-width="true" 
                              :row-alternatin-enabled="true"
                              :hover-state-enabled="true" 
                              :show-row-lines="true" 
                              @exporting="this.$onExporting($event , 'DLL-Templates')">

                              <DxFilterRow :visible="true"/>
                              <DxHeaderFilter :visible="true"/>
                              <DxGroupPanel :visible="true"/>      
                              <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                              <DxPaging :page-size="20"/>
                              <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                              <DxSearchPanel :visible="true"/>

                              <DxColumn data-field="file_name" caption="File Name" header-cell-template="file_name-header" cell-template="file_name-cell-template"/> 
                              <DxColumn data-field="template_name" caption="Template Name" header-cell-template="template_name-header" cell-template="template_name-cell-template"/> 
                              <DxColumn data-field="description" caption="Description" header-cell-template="description-header" cell-template="description-cell-template"/> 
                              <DxColumn data-field="is_active" caption="Is Active?" header-cell-template="is_active-header" cell-template="is_active-cell-template"/> 

                              <!-- Header Cell Templates -->
                              <template #file_name-header>
                                  <div> File Name </div>
                              </template> 
                                <template #template_name-header>
                                  <div> Template Name </div>
                              </template> 
                                <template #description-header>
                                  <div> Description </div>
                              </template> 
                                <template #is_active-header>
                                  <div> Is Active? </div>
                              </template> 
                             

                              <!-- Cell Templates -->
                              <template #file_name-cell-template="{ data }">
                                  <a href="#" class="text-gray-900 mb-1" @click="updateDLLTemplate(data.data)">
                                    <i class="fas fa-cogs text-dark me-3"></i>
                                      {{data.data.file_name}}
                                  </a>
                              </template>
                              <template #template_name-cell-template="{ data }">
                                  <a href="#" class="text-gray-900 mb-1" @click="updateDLLTemplate(data.data)">
                                     <strong> {{data.data.template_name}} </strong>
                                  </a>
                              </template>
                              <template #description-cell-template="{ data }">
                                  <a href="#" class="text-gray-900 mb-1" @click="updateDLLTemplate(data.data)">
                                      {{data.data.description}}
                                  </a>
                              </template>
                              <template #is_active-cell-template="{ data }">
                                  <a href="#" class="text-gray-900 mb-1" @click="updateDLLTemplate(data.data)">
                                      <span class="badge badge-success" v-if="data.data.is_active == 1">
                                        DLL is Active
                                      </span>
                                      <span class="badge badge-light" v-if="data.data.is_active == 0">
                                         DLL is Passive
                                      </span>
                                  </a>
                              </template>
                      </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div> 
    
  </div>  
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `DLL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';

 
export default {
  name: "DLLTemplateList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel
  },
  data() {
    return {
      lists:[],
      searchbar: '',
      isListProgressing: false,
      logs: []
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMMM Do, YYYY')
        }
      }
    }
  },
  methods: {
    getData() {  
        this.$Progress.start();
        this.lists = [];
        this.isListProgressing = true;
        this.$setSessionStorage('data' + '_DLL001', null);
        const params = {
            session_id: this.$getUser().session_id
        }
        axios.post("/api/DLLTemplates/GetDLLTemplate", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.lists = response.data;
                this.$setSessionStorage('data'+ '_DLL001', response.data); 
                this.$Progress.finish();
                setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
            .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
                setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    updateDLLTemplate(item){
        this.$setSessionStorage("record_id", item.id);
        this.$router.push({ name: 'DLLTemplatesUpdate' });
    },
    deleteDLLTemplate(id) { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: id
            };
            axios.get('/api/DLLTemplates/DeleteDLLTemplate/' + queryParameter.id)
                .then(() => { 
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })  
    }
}, 
  created() {
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
        this.lists = [];
    }
  }
}; 
</script>