<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Controller Authorization</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>
                <li class="breadcrumb-item text-gray-600">Controller Authorization</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/SystemSettings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div>
    </div>

    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="card">
            <div class="card-body pt-0">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex flex-column mt-5">
                            <div class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row w-100 p-5">
                                <div class="d-flex flex-column ">
                                    <h5 class="fw-semibold mb-0">Select the system user and match it with the Controller Account you will authorize.</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <label class="fs-6 fw-bold form-label mt-3">
                            System User
                        </label>
                        <div class="input-group mb-5">
                            <Select2 v-model="form.user_id" :options="systemUserList" :settings="{ width: '300px' }" @select="onChangeSelection()"/>  
                            <button class="btn btn-light-primary border border-primary" type="button" @click="onListAccounts();" :disabled="form.user_id == -1">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                List
                            </button> 
                        </div>
                    </div>
                    
                    <div class="col-lg-12 pt-10 p-5">
                        <h4 class="card-title">
                           Select Controller
                        </h4>
                        <div class="table-responsive min-h-300px">
                        <DxDataGrid 
                            :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            @exporting="this.$onExporting($event , 'Contoller-Authorization-List')">
                            
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxSearchPanel :visible="true"/> 
                            
                                <!-- checked olanları disabled gibi bir renkle işaretle -->
                                
                                <DxColumn data-field="system_check" caption="" cell-template="system_check-cell-template"/> 
                                <DxColumn data-field="sharing_modename" caption="Controller's Sharing Mode" cell-template="sharing_modename-cell-template" header-cell-template="sharing_modename-header" alignment="left"/> 
                                <DxColumn data-field="licence_id" caption="Licence ID" alignment="left" cell-template="licence_id-cell-template"/> 
                                <DxColumn data-field="company_name" caption="Company Name" cell-template="company_name-cell-template" header-cell-template="company_name-header"/> 
                                <DxColumn data-field="company_vkn" caption="Company Account ID" cell-template="company_vkn-cell-template" header-cell-template="company_vkn-header"/> 
                                <DxColumn data-field="primary_person" caption="Primary Person" cell-template="primary_person-cell-template" header-cell-template="primary_person-header"/>
                                <DxColumn data-field="company_country_code" caption="Country" cell-template="company_country_code-cell-template"/>  
                                <DxColumn data-field="currency_code" caption="Currency" cell-template="currency_code-cell-template"/>  
                                <DxColumn data-field="company_city" caption="City" cell-template="company_city-cell-template"/>
                                <DxColumn data-field="activation_date" caption="Activation Date" alignment="right" cell-template="activation_date-cell-template" header-cell-template="activation_date-header"/>  

                                <template #sharing_modename-header>
                                    <div>Controller's</div><div>Sharing Mode</div>
                                </template>
                                 <template #company_name-header>
                                    <div>Company</div><div>Name</div>
                                </template>
                                 <template #company_vkn-header>
                                    <div>Company</div><div>Account ID</div>
                                </template>
                                 <template #primary_person-header>
                                    <div>Primary</div><div>Person</div>
                                </template>
                                 <template #activation_date-header>
                                    <div>Activation</div><div>Date</div>
                                </template>
                                
                                <!-- Cell Templates -->
                                <template #system_check-cell-template="{ data }">
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.system_check" @change="onSaveAuthorization(data.data)"/>
                                        </div>
                                    </span>
                                </template>
                                 <template #sharing_modename-cell-template="{ data }">
                                    <span>
                                         {{ data.data.sharing_modename }}
                                    </span>
                                </template>
                                <template #licence_id-cell-template="{ data }">
                                    <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.licence_id }}
                                    </span>
                                </template>
                                <template #company_name-cell-template="{ data }">
                                    <span class="text-gray-800 fw-bolder text-hover-primary mb-1">
                                        {{ data.data.company_name }}
                                    </span>
                                </template>
                                <template #company_vkn-cell-template="{ data }">
                                    <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.company_vkn }}
                                    </span>
                                </template>
                                <template #primary_person-cell-template="{ data }">
                                   <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.primary_person }}
                                    </span>
                                </template>
                                <template #company_country_code-cell-template="{ data }">
                                    <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.company_country_code }}
                                    </span>
                                </template>
                                <template #currency_code-cell-template="{ data }">
                                    <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.currency_code }}
                                    </span>
                                </template>
                                <template #company_city-cell-template="{ data }">
                                    <span class="text-gray-800 text-hover-primary mb-1">
                                        {{ data.data.company_city }}
                                    </span>
                                </template>
                                <template #activation_date-cell-template="{ data }">
                                    <span class="text-gray-800 text-end text-hover-primary mb-1">
                                        {{ previewDate(data.data.activation_date) }}
                                    </span>
                                </template>
                        </DxDataGrid>     
                    </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>  
  </div>  
</template>

<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import { DxDataGrid, DxExport, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';

import moment from 'moment'; 
 
 
export default {
  name: "ControllerAuthorizationList",
  components:{
    DxDataGrid, DxExport, DxColumn, DxSearchPanel
  },
  data() {
    return {
      lists:[],
      systemUserList: [],
      searchbar: '',
      form: {
        user_id: -1
      }
    };
  }, 
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
        getData() { 
            const params = {
                session_id: this.$getUser().session_id,
            }
            axios.post("/api/Users/SystemUsers", params, {'Content-Type': 'application/json'})
                .then((response) => {    
                this.systemUserList = response.data;
                this.systemUserList.forEach(element => {
                    element.company_account_id = this.$unlock(element.company_account_id);
                    element.controller_account_id = this.$unlock(element.controller_account_id);
                    element.system_account_id = this.$unlock(element.system_account_id);
                    element.user_mail = this.$unlock(element.user_mail);
                    element.name_surname = this.$unlock(element.name_surname);
                    element.phone = this.$unlock(element.phone);
                    element.system_account_name = this.$unlock(element.system_account_name);
                    element.text = this.$unlock(element.text);
                    element.user_assignment_name = this.$unlock(element.user_assignment_name);
                });
                this.systemUserList.unshift({ id: -1, text: 'Select an option'});
            })
            .catch(function (error) {
                console.log(error); 
            });
        },
        updateItem(){
        },
        onChangeSelection(){
            this.lists = [];
        },
        onListAccounts() {
            this.$Progress.start();
            this.isListProgressing = true;
            this.lists = [];
            const params = {
                session_id: this.$getUser().session_id,
                user_id: this.form.user_id 
            }
            axios.post("/api/Access/GetControllers", params, {'Content-Type': 'application/json'}).then((response) => {
                this.lists = response.data;
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        onSaveAuthorization(item){ 
            const params = {
                session_id: this.$getUser().session_id,
                controller_id: item.id,
                user_id: this.form.user_id,
                is_checked: (item.system_check == true ? 1 : 0)
            } 
            axios.post("/api/Access/CreateAuthorization", params, {'Content-Type': 'application/json'}).then((response) => {
               if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.onListAccounts();
               }
            })
            .catch(function (error) {
                console.log(error); 
            });
        }
    }, 
    created() { 
        this.getData();
    }
}; 
</script>