<template> 
  <div>     
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Company Accounts
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CompanySettings' }">Company Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600">Company Accounts</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/CompanySettings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div> 
    </div>
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6">
              <div class="card-title">
                <a :href="userData.company_url + '/NewCompany'" class="btn btn-light-primary border border-primary" target="_blank">
                    <span class="svg-icon svg-icon-muted svg-icon-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z" fill="currentColor"/>
                        <path opacity="0.3" d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z" fill="currentColor"/>
                        </svg>
                    </span>
                    Create New Company Account
                </a>
                <!-- <router-link to="/CompanyAccountCreate">
                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                    </svg></span>
                    Add Company Account
                    </button>
                </router-link> -->
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                    <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                        </svg>
                        </span>
                        <strong v-if="!this.isListProgressing"> List Company Accounts </strong> 
                        <span class="indicator-progress" v-if="this.isListProgressing">
                            Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                    </button>
                  </div>
                  <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                      <div class="fw-bolder me-5">
                      <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                      <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                  </div>
              </div>
          </div>
          <div class="card-body">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">                    
                    <DxDataGrid :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                            data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Company-Account-List')">
                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="20"/>
                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"                                    
                            />
                            <DxColumn data-field="is_active" caption="Active?" alignment="center" :allow-filtering="false" /> 
                            <DxColumn data-field="company_vkn" caption="Company Number" /> 
                            <DxColumn data-field="company_name" caption="Company Name"/> 
                            <DxColumn data-field="company_type_name" caption="Company Type Name" header-cell-template="company_type_name-header"/> 
                            <DxColumn data-field="account_office_reference" caption="Acc.Office Ref."/> 
                            <DxColumn data-field="controller_account_name" caption="Controller Account" header-cell-template="controller_account_name-header"/> 
                            <DxColumn data-field="primary_person" caption="Primary Person" header-cell-template="primary_person-header"/> 
                            <DxColumn data-field="company_gsm" caption="Mobile" /> 
                            <DxColumn data-field="company_email" caption="Email" /> 
                            <DxColumn data-field="company_country_code" caption="Country" />
                            <DxColumn data-field="system_access_name" caption="System Access Account" header-cell-template="system_access_name-header" />
                            <DxColumn data-field="controller_access_name" caption="Controller Access Account"  header-cell-template="controller_access_name-header" />
                            <DxColumn data-field="created_at" caption="Created At" data-type="date" alignment="right" format="dd.MM.yyyy" />

                            <template #controller_account_name-header>
                                <div> Controller </div> <div> Account</div>
                            </template>
                            <template #primary_person-header>
                                <div> Primary </div> <div> Person</div>
                            </template>
                            <template #system_access_name-header>
                                <div> System </div> <div> Access Account</div>
                            </template>
                            <template #controller_access_name-header>
                                <div> Controller </div> <div> Access Account</div>
                            </template>
                            <template #company_type_name-header>
                                <div> Company </div> <div> Type Name</div>
                            </template>

                            <template #dataRowTemplate="{ data: data }">
                                <td class="text-gray-800 cursor-pointer justify-content-center text-center" @click="updateUser(data.data.id)">                                    
                                    <span class="badge badge-sm badge-success" v-if="data.data.is_active">Active</span>
                                    <span class="badge badge-sm badge-danger" v-if="!data.data.is_active">Passive</span>
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_vkn }}
                                </td>
                                <td class="text-gray-800 fw-bold cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_name }}
                                </td>
                                <td class="text-gray-800 fw-bold cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_type_name }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.account_office_reference }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.controller_account_name }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.primary_person }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_gsm }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_email }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.company_country_code }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.system_access_name }}
                                </td>
                                <td class="text-gray-800 cursor-pointer" @click="updateUser(data.data.id)">
                                    {{ data.data.controller_access_name }}
                                </td>
                                <td class="text-gray-800 cursor-pointer text-end" @click="updateUser(data.data.id)">
                                    {{ previewDate(data.data.created_at) }}
                                </td>
                            </template> 
                    </DxDataGrid>  
                </div>
              </div> 
          </div>
      </div> 
  </div> 
    <div class="modal fade" tabindex="-1" id="logmodal">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Logs</h5>                
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>

            <div class="modal-body">
                 <div class="table-responsive min-h-300px">
                  <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover">
                  <thead>
                      <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                          <th class="sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1" style="width: 1% !important;">ID</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Log Type</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Location</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Sys.Acc.ID</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Cont.Acc.ID</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Comp.Acc.ID</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">User ID</th>
                          <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Created Date</th>
                    </tr>
                  </thead>
                  <tbody class="fw-bold text-gray-600" v-if="logs"> 
                    <tr v-for="item of logs" v-bind:key="item"> 
                          <td>
                                {{ item.id }}
                          </td> 
                           <td>
                               <span class="badge badge-light-success" v-if="item.log_type == 'GET'">{{ item.log_type }}</span>
                               <span class="badge badge-light-info" v-if="item.log_type == 'UPDATE'">{{ item.log_type }}</span>
                               <span class="badge badge-light-primary" v-if="item.log_type == 'CREATE'">{{ item.log_type }}</span>
                               <span class="badge badge-light-danger" v-if="item.log_type == 'DELETE'">{{ item.log_type }}</span>
                               <span class="badge badge-light-warning" v-if="item.log_type == 'LOGIN'">{{ item.log_type }}</span> 
                          </td> 
                           <td>
                               <span class="badge badge-light text-muted">{{ item.log_location }}</span> 
                          </td> 
                           <td>
                                {{ item.system_account_id }}
                          </td> 
                           <td>
                                {{ item.controller_account_id }}
                          </td> 
                          <td>
                                {{ item.company_account_id }}
                          </td>  
                          <td>
                                {{ item.user_id }}
                          </td>  
                           <td>
                                {{ item.created_at }}
                          </td>  
                      </tr> 
                  </tbody> 
                    <tbody v-if="logs.length == 0">
                        <tr>
                            <td colspan="8">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                    <div class="fs-6 text-gray-800 fw-bolder">No results yet.</div>
                                </div>
                            </td>
                        </tr>
                  </tbody>
                  </table>
                </div> 
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
    </div>
  </div>  
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `CAL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
    name: "CompanyAccountList", 
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
  data() {
    return {
        userData: this.$getUser(),
        lists:[],
        searchbar: '',
        isListProgressing: false,
        logs: [],
        page: 1,
        page_size: 10,
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.company_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() {  
        this.$Progress.start();
        this.isListProgressing = true;
        this.lists = [];
        this.$setSessionStorage('data' + '_CAL001', null);
        const companyParams = {
            session_id: this.$getUser().session_id
        }
        axios.post("/api/Accounts/CompanyAccounts", companyParams, {'Content-Type': 'application/json'}).then((response) => { 
                this.lists = response.data;
                this.lists.forEach(element => {
                    element.company_name = this.$unlock(element.company_name);
                    element.text = this.$unlock(element.text);
                    element.company_vkn = this.$unlock(element.company_vkn);
                    element.primary_person = this.$unlock(element.primary_person);
                    element.company_gsm = this.$unlock(element.company_gsm);
                    element.company_landphone = this.$unlock(element.company_landphone);
                    element.company_email = this.$unlock(element.company_email);
                    element.company_address = this.$unlock(element.company_address);
                    element.company_city = this.$unlock(element.company_city);
                    element.company_town = this.$unlock(element.company_town);
                    element.company_state = this.$unlock(element.company_state);
                    element.company_region = this.$unlock(element.company_region);
                    element.company_postcode = this.$unlock(element.company_postcode);
                    element.company_country_code = this.$unlock(element.company_country_code);
                    element.controller_id = this.$unlock(element.controller_id);
                    element.controller_account_name = this.$unlock(element.controller_account_name);
                    element.system_access_name = this.$unlock(element.system_access_name);
                    element.controller_access_name = this.$unlock(element.controller_access_name);
                });
                this.$setSessionStorage('data'+ '_CAL001', response.data); 
                this.$Progress.finish();
                setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
            .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
                setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    updateUser(id){ 
        this.$setSessionStorage("cp_record_id", id);
        this.$router.push({ name: 'CompanyAccountUpdate' });
    },
    deleteUser(id) { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: id
            };
            axios.get('/api/Accounts/DeleteAccount/' + queryParameter.id)
                .then(() => { 
                    this.getData();
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })  
    },
    getLogData(id){
        this.logs = [];
        this.$Progress.start();
        const params = {
          id: id
        };
        axios.get('/api/Logs/GetAccountLogs/' + params.id)
          .then((response) => {  
            this.logs = response.data;
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");  
            this.$Progress.finish(); 
        });  
    }
}, 
  created() {
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList');
    }
    else{
        this.lists = [];
    } 
  }
}; 
</script>