<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Policy Group
              </h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PolicyListTypes' }">Policy List Types</router-link></li>   
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PolicyGroupList' }">Policy Group</router-link></li> 
                  <li class="breadcrumb-item text-gray-600">Create Policy Group</li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/PolicyGroupList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                  <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mb-2 required">
                                                            Policy Branch
                                                        </label>
                                                        <Select2 v-model="form.parent_id" :options="policyBranchList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Coverage Main
                                                        </label>
                                                        <Select2 v-model="form.main_branch_id" :options="coverageMainList" :settings="{ width: '100%' }" @select="getPolicyParentList()" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Coverage Detail
                                                        </label>
                                                        <Select2 v-model="form.policy_branch_id" :options="coverageDetailList" :settings="{ width: '100%' }" />
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Code
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-check mt-3">
                                                            <input class="form-check-input fs-6 fw-bold form-label mt-3" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                            <label class="fs-6 fw-bold form-label mt-3" for="isDefault">
                                                                Default
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="p-5"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Save Policy Group</strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `EXTC001`
import axios from 'axios'  
 
// import Swal from 'sweetalert2'; 

 
export default {
  name: "PolicyGroupCreate",
  data() {
    return {
        policyBranchList: [{id: '-1', text: 'Select an option'}],
        coverageMainList: [{id: '-1', text: 'Select an option'}],
        coverageDetailList: [{id: '-1', text: 'Select an option'}],
        isProgressing: false,
        form: { 
            name: null,
            code: null,
            description: null,
            session_id: null,
            parent_id: '-1',
            is_default: false,
            tmp_session_id: null,
            main_branch_id: '-1',
            policy_branch_id: '-1'
        }
    };
  },
  methods: { 
    getData(){
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);
        const parameters = {
            session_id: this.$getUser().session_id,
            get_access_status: 1,
            list_type: 2 // Policy Branch: 2
        };
        const parametersTwo = {
            session_id: this.$getUser().session_id,
            get_access_status: 1,
            list_type: 3 // Coverage Main: 3
        };        
        const requestOne =  axios.post('/api/PolicyLists/GetLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/PolicyLists/GetLists', parametersTwo, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.policyBranchList = responses[0].data; 
            this.policyBranchList.unshift({id: '-1', text: 'Select an option'});
            this.coverageMainList = responses[1].data; 
            this.coverageMainList.unshift({id: '-1', text: 'Select an option'});
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        }); 
    },
    getPolicyParentList() {
        this.form.policy_branch_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 4, // Coverage Detail: 4 
            parent_id: this.form.main_branch_id
        };
        axios.post("/api/PolicyLists/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.coverageDetailList = response.data;
            this.coverageDetailList.unshift({ id: '-1', text: 'Select an option'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;     
      params.list_type = 14; // Policy Group: 14
      params.session_id = this.$getUser().session_id;
      params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
      if(params.parent_id == '-1') { params.parent_id = null; }
      if(params.main_branch_id == '-1') { params.main_branch_id = null; }
      if(params.policy_branch_id == '-1') { params.policy_branch_id = null; }
      axios.post('/api/PolicyLists/CreateListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'PolicyGroupUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
         .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true);
    this.$setSessionStorage('tmp_session_id', null);
    this.getData();
  }
}; 
</script>