<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            General Currency Settings
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Update Consolidation Settings</li> 
          </ul> 
      </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/SystemSettings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="row">
          <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
              <div class="p-10">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                    <div class="fv-row mb-7 row">
                                      <div class="col-lg-6">
                                        <div class="fv-row">
                                          <label class="fs-6 fw-bold form-label ">
                                            Consolidation Rate List
                                          </label>
                                          <Select2 v-model="form.consolidation_rate_type_selection" :options="rateTypelist" @select="onChangeRateType($event)" :settings="{ width: '100%' }"/>
                                          <small class="text-danger">Make sure that the currency you specify is included in the exchange rate list you select.</small>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                        <div class="fv-row">
                                          <label class="fs-6 fw-bold form-label ">
                                            Consolidation Currency
                                          </label> 
                                          <Select2 v-model="form.consolidation_currency" :options="currencylist" @select="onChangeCurrency()" :settings="{ width: '100%' }"/>
                                          <small v-if="isShowError" class="text-danger">The currency to be consolidated was not found in the rate list. Please check the rate service.</small>
                                        </div>
                                      </div>
                                      <div class="separator separator-dashed my-8 border-gray-300"></div>
                                      <div class="col-lg-12">
                                        <div class="fv-row">
                                          <label class="fs-6 fw-bold form-label ">
                                              Currency Search Type & Calculation Method
                                          </label>
                                          <Select2 v-model="form.currency_search_type" :options="currencySearchlist" :settings="{ width: '100%' }"/>
                                        </div>
                                      </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                  <div class="d-grid gap-2 "> 
                      <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on">
                          <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                          <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                          </svg>
                          </span>
                            <strong v-if="!this.isProgressing"> Save Consolidation Settings</strong> 
                              <span class="indicator-progress" v-if="this.isProgressing">
                                  Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span> 
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div> 
  </div> 
</template>

<script>
import axios from 'axios' 


 
export default {
  name: "ListingParameters",
  data() {
    return {
      isOpenActionsDropdown: false,
      currencylist: [], 
      rateTypelist: [], 
      currencySearchlist: [
        {id: 1, text: "Local currency / Other currency (Dividing by Foreign Exchange Value)"},
        {id: 2, text:  "Other currency / Local currency (Multiplying by Foreign Exchange Value)"}
      ],
      isProgressing: false,
      form: {
        consolidation_rate_type_selection: 0,
        currency_search_type: 2,
        search_type_method: 1,
        consolidation_rate_type: 0,
        consolidation_currency: '-1',
        list_date_range: 10,
        is_allow_registration_number_duplication: 0,  
      },
      isShowError: false
    };
  },
  methods: {
    getData() { 
      const parameters = {
        session_id: this.$getUser().session_id,
        get_access_status: 0
      };      
      const requestOne =  axios.post('/api/Lists/RateTypesAll', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/Lists/Currencies', parameters, {'Content-Type': 'application/json'});       
      const requestThree =  axios.post('/api/SystemParameters/GetGeneralSystemParameters', parameters, {'Content-Type': 'application/json'});       
      axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
        this.rateTypelist= responses[0].data;
        this.currencylist = responses[1].data;
        this.rateTypelist.unshift({id: 0, text: 'Select an option'}); 
        this.currencylist.unshift({id: '-1', text: 'Select an option'}); 

        this.form = {
          list_date_range: responses[2].data.list_date_range,
          is_allow_registration_number_duplication: responses[2].data.is_allow_registration_number_duplication,
          consolidation_rate_type: responses[2].data.consolidation_rate_type,
          consolidation_currency: (responses[2].data.consolidation_currency !== '' && responses[2].data.consolidation_currency !== null) ? responses[2].data.consolidation_currency : '-1',
          currency_search_type: responses[2].data.currency_search_type
        };

        this.rateTypelist.forEach(element => {
          if(element.type == responses[2].data.consolidation_rate_type) {
            this.form.consolidation_rate_type_selection = element.id;
          }
        });
      }))
      .catch(errors => { 
          console.error(errors); 
      });
    },
    onChangeRateType(event) {
      this.form.consolidation_rate_type = event.type;
      this.onChangeCurrency();
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form;
      params.session_id = this.$getUser().session_id;
      const that = this;     
      if(params.consolidation_currency == '-1') params.consolidation_currency = null;
      axios.post('/api/SystemParameters/UpdateGeneralSystemParameters', params, {'Content-Type': 'application/json'})
      .then(() => { 
        this.$swal("Success", "Data saved succesfully..!", 'success');
      })
      .catch(function (error) {
        console.log(error);
        this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function () { 
        setTimeout(() => {
          that.isProgressing = false;
        }, 500);
      }); 
    },
    onChangeCurrency() {
      const parameters = {
        session_id: this.$getUser().session_id,
        consolidation_currency: this.form.consolidation_currency,
        bank_id: this.form.consolidation_rate_type
      };  
      axios.post('/api/SystemParameters/CheckConsolidationCurrency', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.isShowError = false;
        } else {
          this.isShowError = true;
        }
      })
      .catch(function (error) {
         console.log(error);
      });
    }
  },
  mounted() { 
    this.getData();
  }
}; 
</script>