<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Create Task Type 
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskManagement' }">Task Management</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'TaskTypesList' }">Task Types</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Create Task Type</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link to="/TaskTypesList">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
              <span class="ms-2 fs-7"> Return back to List</span>
              </button>
          </router-link> 
      </div>
  </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
          <div class="card card-xxl-stretch">
             <div class="p-10">
              <form @submit.prevent="submit">
                <div class="row">
                  <div class="d-flex flex-row row">
                      <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                              <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Code
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.code"> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Name
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.name"> 
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3"> Country </label>
                                        <select class="form-control" v-model="form.countrySelection">
                                          <option v-for="item in countryList" :value="item" :key="item.code">
                                            {{ item.name }}<span v-if="item.code != null" >({{item.code}})</span>
                                          </option>
                                        </select>
                                    </div> 
                                  </div> 
                              </div>
                          </div> 
                      </div>
                      <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                          <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                              <div class="card-body">
                                  <div class="row">
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                      <label class="fs-6 fw-bold form-label mt-3 required">Main Task Type
                                      </label>
                                      <select class="form-control" v-model="form.mainTaskTypeSelection" @change="onChangeMainTaskType()">
                                        <option v-for="item in mainTaskTypes" :value="item" :key="item.id">
                                          {{ item.name }}
                                        </option>
                                      </select>
                                    </div> 
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Task / Notification
                                        </label>
                                        <select class="form-control" v-model="form.isTaskOrNotificationSelection" @change="onChangeMainTaskType()">
                                                <option v-for="item in taskOrNotificationList" :value="item" :key="item.id">
                                                  {{ item.name }}
                                                </option>
                                        </select>
                                    </div> 
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">Task Group
                                        </label>
                                        <select class="form-control" v-model="form.groupSelection" @change="onChangeTaskGroup()">
                                            <option v-for="item in groups" :value="item" :key="item.id">
                                              {{ item.name }}
                                            </option>
                                        </select>
                                    </div> 
                                    <div class="col-lg-12 mt-2" v-if="form.groupSelection !== undefined && form.groupSelection !== null && form.groupSelection !== ''">
                                      <span v-if="form.groupSelection.description !== undefined && form.groupSelection.description !== null && form.groupSelection.description !== ''">
                                        <i class="bi bi-info-circle text-primary fs-7"></i>
                                        <small class="text-gray-700 ms-2"> {{form.groupSelection.description}} </small>
                                      </span>
                                    </div> 
                                  </div> 
                              </div>
                          </div> 
                      </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">
                      <div class="fv-row mb-7 fv-plugins-icon-container">
                            <label class="fw-bold mb-2">Message</label>
                            <input type="text" class="form-control" placeholder="" name="name" v-model="form.message"> 
                        </div> 
                      <div class="fv-row mb-7 fv-plugins-icon-container">
                          <label class="fs-6 fw-bold form-label mb-2">Description</label>
                          <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.description" /> 
                      </div> 

                      <div class="col-lg-12">
                          <div class="form-check mt-3">
                            <input class="form-check-input" type="checkbox" value="" id="isMainTask" v-model="form.is_parent_task_type" @change="this.getTaskTypes()">
                            <label class="form-check-label" for="isMainTask">
                                This is a Parent Task <br>
                              <small>If the Task is a detail task, please uncheck `Parent Task` and select an option from below.</small>
                            </label>
                          </div> 
                      </div>
                      <div class="col-lg-12 mt-5" v-if="form.is_parent_task_type !== true">
                          <label class="fs-6 fw-bold form-label mb-2 required">Parent Task
                          </label>  
                          <select class="form-control" v-model="form.parentTaskTypeSelection">
                            <option v-for="item in taskTypes" :value="item" :key="item.code">
                            <span v-if="item.id !== null"> {{ item.code }} - {{ item.name }} ({{ item.task_group_category_name}} / {{ item.task_group_name }}) ({{ item.country_code }}) </span>
                            <span v-if="item.id === null"> {{ item.name }}</span>
                            </option>
                          </select>
                      </div>
                    </div>  
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">
                      <h4 v-if="form.isTaskOrNotificationSelection.id != 2">Task Options</h4>
                      <h4 v-if="form.isTaskOrNotificationSelection.id == 2">Notification Options</h4>    
                      <div class="row mt-5">
                        <div class="col-lg-4 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3 required">Priority
                            </label>
                            <select class="form-control" v-model="form.prioritySelection">
                                    <option v-for="item in priorities" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                        </div> 
                        <!-- Default: No -->
                        <div class="col-lg-3 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Confirmation 
                            </label>
                            <select class="form-control" v-model="form.isConfirmationSelection">
                                    <option v-for="item in confirmationList" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                        </div> 
                        <div class="col-lg-3 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Icon</label>
                            <br>
                            <div class="btn-group" role="group">
                              <button class="btn btn-secondary btn-sm p-2" data-bs-toggle="modal" data-bs-target="#iconmodal">
                                  <span v-if="this.form.icon == null || this.form.icon == ''">Select an Icon</span>
                                  <span v-if="this.form.icon != null && this.form.icon != ''">
                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                  </span>
                              </button>
                              <button class="btn btn-sm btn-danger" v-if="this.form.icon != null && this.form.icon != ''" @click="onIconSelection(null)">
                                Remove Icon
                              </button>
                            </div>
                        </div>
                        <div class="col-lg-2 fv-plugins-icon-container">
                            <label class="fs-6 fw-bold form-label mt-3">Color</label>
                            <br>
                            <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string"/> 
                        </div>  
                      </div>  
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="card card-body bg-lighten border border-gray-300">
                      <h4>Period Options</h4>
                      <div class="row mt-5">
                        <div class="col-lg-6" v-if="form.isTaskOrNotificationSelection.id != 2">
                          <label class="fs-6 fw-bold form-label mt-3 required">Period Type
                            </label>
                          <select class="form-control" v-model="form.periodTypeSelection" @change="onPeriodChanged()">
                              <option v-for="item in periods" :value="item" :key="item.id">
                                {{ item.name }}
                              </option>
                          </select>
                        </div>
                        <div class="col-lg-6" v-if="form.isTaskOrNotificationSelection.id != 2">
                          <label class="fs-6 fw-bold form-label mt-3 required">Period Close Method
                            </label>
                          <select class="form-control" v-model="form.periodCloseTypeSelection">
                                  <option v-for="item in periodCloseTypes" :value="item" :key="item.id">
                                    {{ item.name }}
                                  </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7">
                          <label class="fs-6 fw-bold form-label mt-3 required">Transaction Type
                            </label>
                          <select class="form-control" v-model="form.transactionLocationSelection">
                                  <option v-for="item in transactionLocationList" :value="item" :key="item.id">
                                    {{ item.text }}
                                  </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7 && this.locationDetailListByFilter.length > 0">
                          <label class="fs-6 fw-bold form-label mt-3 required">Transaction Detail
                            </label>
                          <select class="form-control" v-model="form.transactionDetailSelection">
                              <option v-for="item in locationDetailListByFilter" :value="item" :key="item.id">
                                {{ item.text }}
                              </option>
                            </select>
                        </div>
                        <div class="col-lg-6" v-if="form.periodTypeSelection.id == 7">
                          <label class="fs-6 fw-bold form-label mt-3 required">Date Option
                            </label>
                          <select class="form-control" v-model="form.dateOptionSelection">
                                  <option v-for="item in dateOptionList" :value="item" :key="item.id">
                                    {{ item.text }}
                                  </option>
                            </select>
                        </div>
                      </div>
                      <div class="row" v-if="form.isTaskOrNotificationSelection.id == 2">
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            Notification Starting Period
                          </label>
                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                        </div>
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            Notification Ending Period
                          </label>
                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_end" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                        </div> 
                        <div class="col-lg-12 mt-5">
                          <div class="form-check mt-3 form-check-custom form-check-success">
                              <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                              <label class="form-check-label" for="satCheck">
                                For Saturdays, add to next working day
                              </label>
                            </div>
                            <div class="form-check mt-3 form-check-custom form-check-warning">
                              <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                              <label class="form-check-label" for="sunCheck">
                                For Sundays, add to next working day
                              </label>
                            </div>
                            <div class="form-check mt-3 form-check-custom form-check-primary">
                              <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                              <label class="form-check-label" for="holCheck">
                                For public holidays, add to next working day
                              </label>
                            </div>
                        </div>
                      </div> 
                      <div v-if="form.periodTypeSelection && form.isTaskOrNotificationSelection.id != 2">
                        <!-- PERIOD TYPE: FREE -->
                        <div class="row" v-if="form.periodTypeSelection.id == 1"> 
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">Task Starting Period
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                          </div>
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">Task Ending Period
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_end" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                          </div>  
                          <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: DAILY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 2"> 
                          <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: WEEKLY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 3"> 
                          <div class="col-lg-4 mb-3">
                              <label class="fs-6 fw-bold form-label mt-3 required">Day
                              </label>
                              <select class="form-control" v-model="form.periodWeeklyDateSelection">
                                    <option v-for="item in days" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                              </select>
                          </div> 
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                          </div>
                          <div class="col-lg-12 mt-5">
                            <div class="form-check form-check-custom form-check-success">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="radPub" checked v-model="form.is_skip_holiday" value="false" @change="onHolidayRadioChecked(false)">
                                <label class="form-check-label" for="radPub">
                                  Include public holidays
                                </label>
                              </div>
                              <div class="form-check form-check-custom form-check-danger mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="radHol" v-model="form.is_skip_holiday" value="true" @change="onHolidayRadioChecked(true)">
                                <label class="form-check-label" for="radHol">
                                  For public holidays, add to next working day
                                </label>
                              </div> 
                          </div>
                        </div> 
                        <!-- PERIOD TYPE: MONTHLY -->
                        <div class="row" v-if="form.periodTypeSelection.id == 4"> 
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Every month's:
                                </label>
                                <select class="form-control" v-model="form.periodMonthlyDateSelection">
                                    <option v-for="item in monthDays" :value="item" :key="item">
                                      {{ item }}
                                    </option>
                              </select>
                            </div>
                            <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                            </div>
                        </div> 
                        <!-- PERIOD TYPE: ANNUAL -->
                        <div class="row" v-if="form.periodTypeSelection.id == 5"> 
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Date
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_annual_date" inputClassName="form-control" format="MMM-dd" :clearable="false" :monthChangeOnScroll="'inverse'" :enableTimePicker="false"  :flow="['month', 'calendar']"></Datepicker> 
                            </div>
                            <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mt-3 required">Starts at:
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_start_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-4">
                                <label class="fs-6 fw-bold form-label mt-3 required">Ends at:
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_daily_end_time" inputClassName="form-control" timePicker />
                            </div>
                            <div class="col-lg-12 mt-5">
                              <div class="form-check mt-3 form-check-custom form-check-success">
                                <input class="form-check-input" type="checkbox" value="" id="satCheck" v-model="form.is_skip_saturday">
                                <label class="form-check-label" for="satCheck">
                                  For Saturdays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-warning">
                                <input class="form-check-input" type="checkbox" value="" id="sunCheck" v-model="form.is_skip_sunday">
                                <label class="form-check-label" for="sunCheck">
                                  For Sundays, add to next working day
                                </label>
                              </div>
                              <div class="form-check mt-3 form-check-custom form-check-primary">
                                <input class="form-check-input" type="checkbox" value="" id="holCheck" v-model="form.is_skip_holiday">
                                <label class="form-check-label" for="holCheck">
                                  For public holidays, add to next working day
                                </label>
                              </div>
                                <div class="form-check mt-3 form-check-custom form-check-danger">
                                  <input class="form-check-input" type="checkbox" value="" id="comCheck" v-model="form.is_acquire_company">
                                  <label class="form-check-label" for="comCheck">
                                    Acquire information from Company's accounting periods
                                  </label>
                                </div>
                            </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                    <div class="d-flex flex-row row">
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                <div class="card-body">
                                  <h4>Reminder Options</h4>
                                  <div class="row">
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                        <label class="fs-6 fw-bold form-label mt-3">Reminder Day (before)</label>
                                        <CurrencyInput type="text" class="form-control" placeholder="" name="name" v-model="form.reminder_day" :precision="0" @focus="$event.target.select()" :min="0"/> 
                                    </div>  
                                    <div class="col-lg-12 fv-plugins-icon-container">
                                        <label class="fs-6 fw-bold form-label mt-3">Reminder Hour (before)</label>
                                        <CurrencyInput type="text" class="form-control" placeholder="" name="name" v-model="form.reminder_hours" :precision="0" @focus="$event.target.select()" :min="0"/> 
                                    </div> 
                                  </div>
                                </div>
                            </div> 
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                <div class="card-body">
                                    <div class="row">                                    
                                      <h4>
                                        <span v-if="form.isTaskOrNotificationSelection.id != 2"> Task </span> 
                                        <span v-if="form.isTaskOrNotificationSelection.id == 2"> Notification </span> 
                                        Contents
                                      </h4>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_date">
                                        <label class="form-check-label" for="flexCheckDefault">
                                          Date
                                        </label>
                                      </div> 
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_message">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Message
                                        </label>
                                      </div>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_meeting">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Meeting
                                        </label>
                                      </div>
                                      <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" v-model="form.is_have_value">
                                        <label class="form-check-label" for="flexCheckChecked">
                                          Amount
                                        </label>
                                      </div> 
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-5" v-if="form.is_have_value">
                    <div class="card card-body bg-lighten border border-gray-300">                      
                      <h4>Amount</h4> 
                      <div class="row">
                        <div class="col-lg-6 mt-5">
                            <label class="fs-6 fw-bold form-label mt-3 required">Amount Type
                            </label>
                            <select class="form-control" v-model="form.amountTypeSelection" @change="onAmountTypeChanged()">
                                <option v-for="item in amountTypes" :value="item" :key="item">
                                  {{ item.name }}
                                </option> 
                            </select>
                        </div> 
                        <div class="col-lg-6" v-if="form.amountTypeSelection !== null">
                          <div class="col-lg-12 mt-5" v-if="form.amountTypeSelection.id == 2">
                              <label class="fs-6 fw-bold form-label mt-3 required">Value
                              </label>
                              <input type="text" class="form-control" placeholder="" name="name" v-model="form.amount_value"> 
                          </div>
                          <div class="col-lg-12 mt-5" v-if="form.amountTypeSelection.id == 3">
                              <label class="fs-6 fw-bold form-label mt-3 required">Amount Parameter
                              </label>
                              <select class="form-control" v-model="form.amountParameterSelection">
                                <option v-for="item in amountParametersList" :value="item" :key="item">
                                  {{ item.name }}
                                </option>  
                              </select>
                          </div> 
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
                <div class=" d-grid gap-2">
                  <button type="submit" href="#" class="btn btn-success mt-5" id="kt_invoice_submit_button" @click="saveData()" data-kt-indicator="on">
                      <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      </svg>
                      </span>
                        <strong v-if="!this.isProgressing"> Save Task Type</strong> 
                          <span class="indicator-progress" v-if="this.isProgressing">
                              Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span> 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div> 


    <div class="modal fade" tabindex="-1" id="iconmodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <div class="col-lg-6">
                      <form class="d-flex align-items-center position-relative m-0" autocomplete="off">
                          <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                              </svg>
                          </span>
                          <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search icons" v-model="searchbar">
                      </form>
                  </div>
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div> 
              <div class="modal-body">
                    <div class="row" v-if="icons">
                        <div class="card mb-1 col-lg-2" v-for="item of searchableList" v-bind:key="item">
                          <div class="card-body align-middle text-center "> 
                            <a class="btn btn-light p-4 text-center cursor-pointer" @click="onIconSelection(item)" data-bs-dismiss="modal" aria-label="Close">
                              <i :class="item + ' fs-2x'"></i> <br>
                            </a> <br>
                            <small class="mt-2">{{ item }}</small>
                          </div> 
                        </div> 
                  </div>  
              </div> 
              <div class="modal-footer"> 
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
    </div>
  </div> 
</template>


<script>
// Component Alias: `TATC001`
import axios from 'axios';
import icons from '../../../assets/icons/icons.json';
import countries from '../../../assets/countries/countries.json';
import moment from 'moment';

// import Swal from 'sweetalert2';

 
export default {
  name: "TaskTypesCreate",
  data() {
    return {
      searchbar: '',
      icons: icons,
      lists: [], 
      groups: [],
      priorities: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Normal'}, { id: 2, name: 'Low Priority'}, { id: 3, name: 'High Priority'}],
      mainTaskTypes: [ { id: 0, name: 'ALL' }, { id: 1, name: 'System' },{ id: 2, name: 'Controller' }, { id: 3, name: 'Company' }],
      periods: [ { id: 0, name: 'Select an option' }, { id: 1, name: 'Free Period' },{ id: 2, name: 'Daily Period' }, { id: 3, name: 'Weekly Period' }, { id: 4, name: 'Monthly Period ' }, { id: 5, name: 'Annual Period' }, { id: 6, name: 'Periodic Period' }],
      periodCloseTypes: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Automatic'}, { id: 2, name: 'Manuel'}], 
      days: [ { id: 0, name: 'Select an option' }, { id: 1, name: 'Sunday' }, { id: 2, name: 'Monday' },{ id: 3, name: 'Tuesday' },{ id: 4, name: 'Wednesday' },{ id: 5, name: 'Thursday' },{ id: 6, name: 'Friday' },{ id: 7, name: 'Saturday' } ],
      monthDays: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      taskOrNotificationList: [{ id: 0, name: 'Select an option' }, { id: 1, name: 'Task' }, { id: 2, name: 'Notification' }],
      İsProgressing: false,
      form: { 
          name: null,
          code: null,
          message: null,
          description: null, 
          groupSelection: { id: 0, name: 'Select an option' },
          priority: 1,
          prioritySelection: null,
          main_task_type: 1,
          mainTaskTypeSelection: null,
          period_type: null,
          periodTypeSelection: { id: 0, text: 'Select an option'},
          period_close_type: null,
          periodCloseTypeSelection: null,
          icon: null,
          color: '#009ef7',
          period_start: null,
          period_end: null,
          reminder_day: 1,
          reminder_hours: 1,
          is_have_date: true,
          is_have_value: false,
          is_have_message: true,
          is_have_meeting: false,
          is_skip_saturday: false,
          is_skip_sunday: false,
          is_skip_holiday: false,
          is_acquire_company: false, 
          period_annual_date: new Date(),
          period_monthly_date: null,
          period_weekly_date  : null,
          period_daily_start_time: new Date(),
          period_daily_end_time : new Date(),
          periodWeeklyDateSelection: null,
          periodMonthlyDateSelection: null,
          countrySelection: null,
          is_parent_task_type: true,
          parentTaskTypeSelection: { id: null, name: 'Select an option'},
          amount_type: 0,
          amountTypeSelection : null,
          amount_parameter: null,
          amountParameterSelection: null,
          amount_value: '0',
          transactionLocationSelection: { id: 0, text: 'Select an option'},
          transactionDetailSelection: { id: 0, text: 'Select an option'},
          dateOptionSelection: { id: 0, text: 'Select an option'},          
          transaction_location: null,
          transaction_location_detail: null,
          transaction_date_option: null,
          isTaskOrNotificationSelection: { id: 0, name: 'Select an option' },
          is_confirmation: 1,
          isConfirmationSelection:{ id: 1, name: 'No' }
      },
      countryList: countries,
      taskTypes: [],
      amountTypes: [{ id: 1, name: 'Free Amount'}, { id: 2, name: 'Default Amount'}, { id:3, name: 'Get Amount from Company'}],
      amountParametersList: [{ id: -1, name: 'Select an option'}, { id:1, name: 'Get value from Accounting Fee'}],
      transactionLocationList: [
        { id: 0, text: 'Select an option'},
        { id: 1, text: 'Invoice Transaction'},
        { id: 2, text: 'Cash Transaction'},
        { id: 3, text: 'Bank Transaction'},
        { id: 4, text: 'Expense Transaction'},
        { id: 5, text: 'Cheque Transaction'},
      ],
      transactionDetailList: [
        { id: 0, text: 'Select an option', type: 0},
        { id: 1, text: 'Sales Invoice', type: 1},
        { id: 2, text: 'Purchase Invoice', type: 1},
        { id: 3, text: 'Sales Return Invoice', type: 1},
        { id: 4, text: 'Purchase Return Invoice', type: 1},
        { id: 5, text: 'Incoming', type: 2},
        { id: 6, text: 'Outgoing', type: 2},
      ],
      dateOptionList: [
        { id: 0, text: 'Select an option'},
        { id: 1, text: 'Record Date'},
        { id: 2, text: 'Due Date'},
      ],
      confirmationList: [
        { id: 1, name: 'No'},
        { id: 2, name: 'Yes'},
      ]
    };
  },
  computed: {
    locationDetailListByFilter(){ 
        // Invoice
        if(this.form.transactionLocationSelection.id == 1){ 
            return this.transactionDetailList.filter(p => { 
              return p.type == 1 || p.type == 0;
            });
        } 
        // Cash, Bank, Cheque
        else if(this.form.transactionLocationSelection.id == 2 || this.form.transactionLocationSelection.id == 3 || this.form.transactionLocationSelection.id == 5) { 
            return this.transactionDetailList.filter(p => { 
              return p.type == 2 || p.type == 0;
            });
        }
        else {
            return [];
        }  
    },
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    }
  },
  methods: {
    getData() {
      this.lists = []; 
      this.groups = [];
      this.$Progress.start();
      this.form.countrySelection = this.countryList[0];
      this.$setSessionStorage('refreshList', true);
      const params = {
        session_id : this.$getUser().session_id,
        get_access_status: 0,
        main_task_type: 0,
        is_task_or_notification: 0
      };
      axios.post("/api/TaskGroups/GetTaskGroups", params, {'Content-Type': 'application/json'}).then((response) => {
          this.groups = response.data;
          this.groups.unshift({ id: null, name: 'Select an option'});
          this.$Progress.finish();

          this.form.groupSelection = this.groups[0];
          this.form.prioritySelection = this.priorities[0];
          this.form.mainTaskTypeSelection = this.mainTaskTypes[0];
          this.form.periodTypeSelection = this.periods[0];
          this.form.periodCloseTypeSelection = this.periodCloseTypes[0];
          this.form.periodWeeklyDateSelection = this.days[0];
          this.form.periodMonthlyDateSelection = this.monthDays[0];
          this.form.period_start = new Date();
          this.form.period_end = new Date(); 
          this.form.amountTypeSelection = this.amountTypes[0];
          this.form.amountParameterSelection = this.amountParametersList[0];
          this.form.transactionLocationSelection = this.transactionLocationList[0];
          this.form.transactionDetailSelection = this.transactionDetailList[0];
          this.form.dateOptionSelection = this.dateOptionList[0];
          this.form.isConfirmationSelection = this.confirmationList[0];
        })
        .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
        });
    },
    onAmountTypeChanged(){  
      this.form.amount_parameter = null;
      this.form.amount_value = '0';
      this.form.amountParameterSelection = this.amountParametersList[0];
    },
    getTaskTypes(){
       this.taskTypes = [];
       const queryParameter = {
         session_id: this.$getUser().session_id,
      };
       axios.post('/api/TaskTypes/GetBasicParentTaskTypes', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => { 
              this.taskTypes = response.data;   
              this.taskTypes.unshift({ id: null, name: 'Select an option'});
              this.form.parentTaskTypeSelection = this.taskTypes[0];
               if(this.form.parent_task_type_id !== ''){
                  this.amountTypes.push({
                    id: 4,
                    name: 'Get amount from Parent Value'
                  });
                }
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;      
      params.task_group_id = params.groupSelection.id;
      params.priority = params.prioritySelection.id;
      params.main_task_type = params.mainTaskTypeSelection.id;
      params.period_type = params.periodTypeSelection.id;
      params.period_close_type = params.periodCloseTypeSelection.id;
      params.period_weekly_date = params.periodWeeklyDateSelection.id;
      params.period_monthly_date = params.periodMonthlyDateSelection;
      params.period_start = moment(params.period_start).format('YYYY-MM-DD[T]HH:mm:ss');
      params.period_end = moment(params.period_end).format('YYYY-MM-DD[T]HH:mm:ss');
      params.country_code = params.countrySelection.code;
      params.transaction_location = params.transactionLocationSelection.id;
      params.transaction_location_detail = params.transactionDetailSelection.id;
      params.transaction_date_option = params.dateOptionSelection.id;
      params.is_confirmation = params.isConfirmationSelection.id;

      const period_daily_start_time = new Date(); 
      if(params.period_daily_start_time.hours !== null){
        period_daily_start_time.setUTCHours(params.period_daily_start_time.hours);
        period_daily_start_time.setUTCMinutes(params.period_daily_start_time.minutes);
        period_daily_start_time.setUTCSeconds(0);
        params.period_daily_start_time = period_daily_start_time;
      }

      const period_daily_end_time = new Date();
      if(params.period_daily_end_time.hours !== null){
        period_daily_end_time.setUTCHours(params.period_daily_end_time.hours);
        period_daily_end_time.setUTCMinutes(params.period_daily_end_time.minutes);
        period_daily_end_time.setUTCSeconds(0);
        params.period_daily_end_time = period_daily_end_time;
      }

      if(params.period_type == 1 || params.period_type == 6){
         params.period_daily_start_time = new Date();
         params.period_daily_end_time = new Date();
      }

      if(params.is_parent_task_type === true){
        params.parent_task_type_id = null;
      }
      else{ 
        params.parent_task_type_id = this.form.parentTaskTypeSelection.id;
      } 

      params.amount_type = params.amountTypeSelection.id; 
      params.amount_parameter = params.amountParameterSelection.id;
      
      params.task_type_id = null;
      params.company_id = null;
      params.accounting_period_id = null; 

      params.session_id = this.$getUser().session_id;
      axios.post('/api/TaskTypes/CreateTaskType', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.$setSessionStorage('record_id', response.data); 
                this.$setSessionStorage('refreshList', true);         
                this.$router.push({ name: 'TaskTypesList' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    onIconSelection(item){
      this.form.icon = item;  
    }, 
    onPeriodChanged(){
        this.form.is_skip_saturday= false;
        this.form.is_skip_sunday= false;
        this.form.is_skip_holiday= false;
        this.form.is_acquire_company= false;
        this.form.period_annual_date= new Date(); 
        this.form.period_daily_start_time= new Date();
        this.form.period_daily_end_time = new Date();
        this.periodWeeklyDateSelection = this.monthDays[0];
        this.periodMonthlyDateSelection = this.days[0];
        this.form.period_daily_start_time= { hours: 0, minutes: 0, seconds: 0 };
        this.form.period_daily_end_time = { hours: 0, minutes: 0, seconds: 0 };  
    },
    onHolidayRadioChecked(value){
      this.form.is_skip_holiday = value;
    },
    onChangeTaskGroup() {
      if (this.form.groupSelection.id == null) {
        this.form.mainTaskTypeSelection = this.mainTaskTypes[0];
      }

      this.mainTaskTypes.forEach(element => {
        if (element.id == this.form.groupSelection.group_main_task_type) {
          this.form.mainTaskTypeSelection = element;
        }
      });
      this.taskOrNotificationList.forEach(element => {
        if (element.id == this.form.groupSelection.is_task_or_notification) {
          this.form.isTaskOrNotificationSelection = element;
        }
      });
    },
    onChangeTransactionLocation() {
      this.form.transactionDetailSelection = this.transactionDetailList[0];
    },
    onChangeMainTaskType() {
      if (this.form.mainTaskTypeSelection.id == 3) {
        this.periods.push({ id: 7, name: 'Transaction Period' });
        this.amountTypes.push({ id: 4, name: 'Transaction Amount'});
      } else {
        this.periods= this.periods.filter(p => { 
          return p.id !== 7;
        });
        this.amountTypes= this.amountTypes.filter(p => { 
          return p.id !== 4;
        });
      }      
      this.groups = [];
      this.$Progress.start();
      const params = {
        session_id : this.$getUser().session_id,
        get_access_status: 0,
        main_task_type: this.form.mainTaskTypeSelection.id,
        is_task_or_notification: this.form.isTaskOrNotificationSelection.id,
      };
      axios.post("/api/TaskGroups/GetTaskGroups", params, {'Content-Type': 'application/json'}).then((response) => {
        this.groups = response.data;
        this.groups.unshift({ id: null, name: 'Select an option'});
        this.form.groupSelection = this.groups[0];
        this.$Progress.finish();
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
      });   
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>