<template>
  <footer class="mt-auto py-4 d-flex flex-lg-column" id="kt_footer">
    <div class="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">{{fullYear}}© Polisoft Software Ltd</span>
        <a href="https://polisoft.co.uk" target="_blank" class="text-gray-800 text-hover-primary">
        | CreatorCUBE v{{userData.version}} CT
        </a>
      </div> 
      
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1"> {{ userData.controller_account_name }} </span>
      </div> 

      <div class="flex-grow-1 bd-highlight d-none">
        <!-- Idle for Screen Lock -->
        <Vidle v-if="this.userData.is_locked !== 1 && this.userData.is_lock_screen_active == 1 && userData.lock_screen_time > 0" @idle="onScreenLock" :wait="3" :duration="lockScreenTime" />
      </div>
      <div class="flex-grow-1 bd-highlight d-none">
        <!-- Idle for Logout -->
        <Vidle v-if="this.userData.is_automatic_logout_active == 1 && userData.automatic_logout_time > 0" @idle="forceSignOut" @remind="onremind" :reminders="[10]" :wait="3" :duration="automaticLogoutTime" />
      </div> 
    </div>
  </footer>
</template>  
<style>
</style>
<script>
</script>
<script> 
  

export default {
  name: "NavFooter",
  data() {
    return {
      userData: this.$getUser(),
      automaticLogoutTime: this.$getUser().automatic_logout_time ? this.$getUser().automatic_logout_time * 60 : 0,
      lockScreenTime: this.$getUser().lock_screen_time ? this.$getUser().lock_screen_time * 60 : 0,
      fullYear: new Date().getFullYear()
    };
  },
  methods: {
    onremind() {
      let timerInterval;
      this.$swal.fire({
        title: 'Session expiring soon!',
        html: 'You have been inactive for <span class="fw-bolder">'+ this.userData.automatic_logout_time +'</span> minutes. You will be automatically signed out in <b></b> seconds. If session expires, all of unsaved data will be lost. Click <span class="fw-bolder">Stay Logged In</span> to continue.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Stay Logged In',
        allowOutsideClick: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: () => {
          const b = this.$swal.getHtmlContainer().querySelector('b');
          b.textContent = 10;
          timerInterval = setInterval(() => {
            b.textContent = (this.$swal.getTimerLeft() / 1000).toFixed(0)
          }, 1000)
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      });
    },	   
    forceSignOut() {      
      this.$parent.isScreenLocked = false;      
      this.$removeUser('user_' + this.$getUser().session_id);
      sessionStorage.clear();
      this.$router.push({ name: 'Logout', params: { m: this.userData.automatic_logout_time } });
    },
    onScreenLock() {
      this.userData.is_locked = 1;
      this.$parent.isScreenLocked = true;
    },
  },
  mounted() {
    this.emitter.on("changeScreenLockStatus", item => {
      this.userData.is_locked = item;
    });
  }
};
</script>