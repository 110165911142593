<template> 
  <div> 
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Accounting Voucher Operations</h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
            </li>  
            <li class="breadcrumb-item text-primary">Accounting</li> 
            <li class="breadcrumb-item text-primary">Accounting Transaction</li>
            <li class="breadcrumb-item text-gray-600">Accounting Voucher Operations</li>    
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <!-- <StatsBar></StatsBar> -->
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <router-link to="/AccountingVoucherCreate">
                        <button type="button" class="btn btn-light-primary border border-primary me-3">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                            </span>
                            Add New Voucher
                        </button>
                    </router-link> 
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> Reset All Criterias </span>
                            </template>
                        </Popper>
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> List Transactions </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>                        
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="row p-3">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2 bg-light">
                        <div class="fv-row mb-7 row">       
                             <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>Accounting Period</span> 
                                </label>   
                                <Select2 v-model="form.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event, true)"/>  
                            </div>                     
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>Start Date</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date"></Datepicker>  
                            </div>
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>End Date</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date"></Datepicker>
                            </div>
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>Record Type</span>
                                </label>
                                <Select2 v-model="form.is_manuel" :options="transactionTypeList" :settings="{width: '100%'}" />
                            </div>
                            <div class="col-lg">  
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>Transaction Location</span>
                                </label>
                                <Select2 v-model="form.SOURCE" :options="transactionLocationList" :settings="{width: '100%'}" />
                            </div>                            
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>Voucher Type</span>
                                </label>
                                <Select2 v-model="form.docket_type" :options="voucherTypeList"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div>

                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                        <DxDataGrid :show-borders="true" 
                                    key-expr="source_id" 
                                    :data-source="transactionList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true"
                                    :ref="'dataGrid'"
                                    @exporting="this.$onExporting($event , 'Accounting-Transaction-List')"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="20"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>  
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                                <DxColumn data-field="record_date" caption="Record Date" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" cell-template="record_date-cell-template"/> 
                                <DxColumn data-field="source_name" caption="Source Name" header-cell-template="source-name-header" cell-template="source_name-cell-template"/> 
                                <DxColumn data-field="voucher_type_name" caption="Voucher Type Name" header-cell-template="voucher-type-header" cell-template="voucher_type_name-cell-template"/>
                                <DxColumn data-field="docket_no" caption="Voucher Number" header-cell-template="voucher-number-header" alignment="right" cell-template="docket_no-cell-template"/> 
                                <DxColumn data-field="serial_no" caption="S.N." alignment="right" cell-template="serial_no-cell-template"/> 
                                <DxColumn data-field="is_manuel_name" caption="Record Type" header-cell-template="record-type-header" cell-template="is_manuel_name-cell-template"/> 
                                <DxColumn data-field="total_debit" caption="Total Debit" header-cell-template="total-debit-header" alignment="right" cell-template="total_debit-cell-template"/> 
                                <DxColumn data-field="total_credit" caption="Total Credit" header-cell-template="total-credit-header" alignment="right" cell-template="total_credit-cell-template"/> 
                                <DxColumn data-field="transaction_amount" caption="Transaction Amount" header-cell-template="transaction-amount-header" alignment="right" cell-template="transaction_amount-cell-template"/>

                                <template #record-date-header>
                                    <div>Record</div><div>Date</div>
                                </template>
                                <template #source-name-header>
                                    <div>Source</div><div>Name</div>
                                </template>
                                <template #voucher-number-header>
                                    <div>Voucher</div><div>Number</div>
                                </template>
                                <template #voucher-type-header>
                                    <div>Voucher</div><div>Name</div>
                                </template>
                                <template #record-type-header>
                                    <div>Record</div><div>Type</div>
                                </template>
                                <template #total-debit-header>
                                    <div>Total</div><div>Debit</div>
                                </template>
                                <template #total-credit-header>
                                    <div>Total</div><div>Credit</div>
                                </template>
                                <template #transaction-amount-header>
                                    <div>Transaction</div><div>Amount</div>
                                </template>

                                 <!-- Cell Templates -->
                                <template #record_date-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ previewDate(data.data.record_date) }}
                                    </a>
                                </template>
                                <template #source_name-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)">
                                        <span class="badge badge-circle badge-light me-3">
                                            <i class="bi bi-pencil text-gray-900"></i> 
                                        </span> 
                                        <span class="text-gray-800"> 
                                            {{ data.data.source_name }}
                                        </span> 
                                    </a>
                                </template>
                                <template #voucher_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                      {{ data.data.voucher_type_name }}
                                    </a>
                                </template>
                                <template #docket_no-cell-template="{ data }">  
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.docket_no }}
                                    </a>
                                </template>
                                <template #serial_no-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.serial_no }}
                                    </a>
                                </template>
                                <template #is_manuel_name-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.is_manuel_name }}
                                    </a>
                                </template>
                                <template #total_debit-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.total_debit }}
                                    </a>
                                </template>
                                <template #total_credit-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.total_credit }}
                                    </a>
                                </template>
                                <template #transaction_amount-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-900 text-hover-primary" @click="updateItem(data.data.source_id)"> 
                                        {{ data.data.transaction_amount }}
                                    </a>
                                </template> 

                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                        </DxDataGrid>                   
                    </div>
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
import axios from 'axios';  
import moment from 'moment';
  

import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "AccountingVoucherList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxScrolling
    },
	data() {
        return {
            storageKey: 'dx_' + this.$options.name,
            searchbar: '',
            isListProgressing: false,
            isOpenActionsDropdown: false,      
            page: 1,
            page_size: 10,
            summary: {},
            lists: [],
            accountingYearList: [],
            accountingYearSelection: null,
            accountingYears: [],
            form:  {
                start_date: moment().subtract((parseInt(this.$getSessionStorage("selected_company").list_date_range)), 'days'),
                end_date: moment(),
                is_manuel: 3,
                SOURCE: 0,
                period_id: null,
                periodSelection: {},
                docket_type: -1
            },
            transactionList: [],
            transactionTypeList: [
                { id: 3, text: 'ALL'},
                { id: 0, text: 'Automatic'},
                { id: 1, text: 'Manuel'},
                { id: 2, text: 'Mixed'}
            ],
            transactionLocationList: [
                { id: 0, text: 'ALL'},
                { id: 1, text: 'Manuel'},
                { id: 2, text: 'All Invoices'},
                { id: 3, text: 'Sales Invoice'},
                { id: 4, text: 'Purchase Invoice'},
                { id: 5, text: 'Sales Return Invoice'},
                { id: 6, text: 'Purchase Return Invoice'},
                { id: 7, text: 'Cash Transaction'},
                { id: 8, text: 'Bank Transaction'},
                { id: 10, text: 'Turnover Transaction'},
                { id: 11, text: 'Difference Transaction'},
                { id: 12, text: 'Cheque Transaction'},
                { id: 13, text: 'Expense Transaction'},
                { id: 14, text: 'Offsetting Transaction'},
            ],
            voucherTypeList: [],
            isAccountingYearLoading: false
        };
  },
  computed: {  
    searchableList() {
      return this.transactionList.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewOnlyDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        getReportTypeValue(){
          return (value)=>{
                if (value) {
                    return this.reportTypes.find(p => { 
                        return p.id == value;
                    }).text; 
              }
          } 
        },
        getPeriodMonthValue(){
             return (value)=>{
                if (value) {
                    var period = this.periodList.find(p => { 
                        return p.id == value && p.id != '-1';
                    }); 
                    if(period){
                        return period.text;
                    }
                    else{
                        return null;
                    }
              }
          } 
        }
  },
  methods: { 
    getData() {
        this.lists = [];
        this.$Progress.start();  
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = ( this.form.end_date == null || this.form.end_date == '') ? moment () : this.form.end_date;
        const params = {
            session_id: this.$getUser().session_id,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            is_manuel: this.form.is_manuel,
            SOURCE: this.form.SOURCE,
            period_id: this.form.period_id,
            docket_type: this.form.docket_type
        };  
        if(params.is_manuel == 3) { params.is_manuel = null }
        if(params.SOURCE == 0) { params.SOURCE = null }
        params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');  
        axios.post('api/CompanyManagement/GetSummary', params, {'Content-Type': 'application/json'}).then((response) => {
            this.transactionList = [];
            this.transactionList = response.data; 
            this.$setSessionStorage('criterias'+ '_CACTTRS001', this.form);
            this.$setSessionStorage('tmp_period_selection', params.period_id); 
            this.$Progress.finish();
        setTimeout(() => {
            this.isListProgressing = false;
        }, 500);
        })
        .catch(function () { 
            this.$Progress.finish();
        setTimeout(() => {
            this.isListProgressing = false;
        }, 500);
        });
        
    }, 
    updateItem(id){ 
        this.$setSessionStorage('record_id', id);
        this.$router.push({ name: 'AccountingVoucherUpdate' });
    },
    getAccountingYears(){
        this.isAccountingYearLoading = true;
        this.companyAccountingPeriods = [];
        const queryParameter = {
            id: this.$getSessionStorage("selected_company").id,
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
                this.accountingYears = response.data;
                if(this.$getSessionStorage('criterias'+ '_CACTTRS001') == '' || this.$getSessionStorage('criterias'+ '_CACTTRS001') == null || this.$getSessionStorage('criterias' + '_CACTTRS001') == undefined) {
                    this.form['periodSelection'] = this.accountingYears[0];
                    this.form.period_id = this.form.periodSelection.id;
                    this.$setSessionStorage('tmp_period_selection', this.form.period_id);
                    this.accountingYears.forEach((period) => {
                        if(period.id == this.$getSessionStorage("selected_company").activeYear){
                            this.form.period_id = period.id;
                            this.form['periodSelection'] = period; 
                            this.form.start_date = this.form['periodSelection'].start_date;
                            this.form.end_date = this.form['periodSelection'].end_date;
                            this.$setSessionStorage('tmp_period_selection', this.form.period_id);
                        }
                    }); 
                }
                if(this.$getSessionStorage('refreshList')) {
                    this.getData();
                }
                this.$removeSessionStorage('refreshList'); 
                setTimeout(() => {
                    this.isAccountingYearLoading = false;
                }, 1000); 
        })
        .catch(function (error) {
            this.isAccountingYearLoading = false;
            console.log(error);
        }); 
    },  
    onChangeAccountYear(e, isChangeForced){
        this.form['periodSelection'] = e; 
        if((e.id != this.$getSessionStorage("selected_company").activeYear) || isChangeForced){
            this.form.end_date = moment(e.end_date);
            this.form.start_date = moment(e.start_date);
        } 
    },
    setCriteriasToDefault() {   
        this.form = {
            start_date: moment().subtract((parseInt(this.$getSessionStorage("selected_company").list_date_range)), 'days'),
            end_date: moment(),
            is_manuel: 3,
            SOURCE: 0,
            period_id: null,
            periodSelection: {},
            docket_type: -1
        };
        this.$removeSessionStorage('criterias'+ '_CACTTRS001');
        this.transactionList = [];
        this.getAccountingYears();
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    getLists() {
        const params = {
           session_id: this.$getUser().session_id,
           tip: 17 //Voucher Type
        };        
        axios.post('/api/Lists/GetConst', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.voucherTypeList = response.data; 
            this.voucherTypeList.unshift({ id: -1, text: 'ALL'}); 

        })
        .catch(function (error) {
            console.log(error); 
        });
    }
  },
  created() {  
    this.getLists();
    this.$removeSessionStorage('record_id');  
    try{ 
        if(this.$getSessionStorage('refreshList')){  
            const criteriaData = this.$getSessionStorage('criterias'+ '_CACTTRS001');  
            if (criteriaData !== null && criteriaData !== '' && criteriaData !== undefined)
            {
                this.form = {
                    start_date: moment(criteriaData.start_date),
                    end_date: moment(),
                    is_manuel: criteriaData.is_manuel,
                    SOURCE: criteriaData.SOURCE,
                    period_id: criteriaData.period_id,
                    periodSelection: criteriaData.periodSelection,
                    docket_type: criteriaData.docket_type
                }
            }
        }
        else {
            this.$removeSessionStorage('criterias'+ '_CACTTRS001');
            this.transactionList = [];
        }
        this.getAccountingYears();
    }
    catch{ this.getData(); }
  },
  mounted(){ 
    try{ 
        if(this.$getSessionStorage('criterias'+ '_CACTTRS001') == '' || this.$getSessionStorage('criterias'+ '_CACTTRS001') == null || this.$getSessionStorage('criterias' + '_CACTTRS001') == undefined) {
            if(this.$getUser().list_date_range !== null && this.$getUser().list_date_range !== undefined && this.$getUser().list_date_range !== ''){
                this.form.start_date = moment().subtract((parseInt(this.$getSessionStorage("selected_company").list_date_range)), 'days');
            } 
        }
    }
    catch(Err){ this.getData();}
  }
}; 
</script>