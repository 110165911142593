<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Default Liability Types
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CompanyManagement' }">Company Management</router-link></li>
              <li class="breadcrumb-item text-gray-600">Default Liability Types</li> 
          </ul> 
      </div> 
      <div class="d-flex align-items-center py-1">
            <router-link :to="{ name: 'CompanyManagement', params: { type: 'CompanyManagement' } }">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                  <span class="ms-2 fs-7"> Return back to Settings </span>
              </button>
          </router-link>
      </div>  
  </div>  
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0">                 
            <div class="row col-lg-6 p-5">
                <div class="fv-row row">
                    <div class="col-lg-8">
                        <label class="fs-6 fw-bold form-label mt-3">
                            <span>Company Types</span>
                        </label>
                        <Select2 v-model="form.company_type_id" :options="companyTypes" :settings="{width: '100%'}" @select="getData()" />
                    </div>
                </div>
            </div>
          </div>
          <div class="card-body pt-0">
              <div class="col-lg-12">
                <div class="p-5">
                    <div class="d-flex flex-stack flex-wrap mb-5">
                        <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                            <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" data-bs-target="#select_liability_modal" @click="liabilitySelectionModal()"
                              v-if="this.form.company_type_id !== '-1'">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                            transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                            fill="currentColor">
                                        </rect>
                                    </svg>
                                </span>
                                Select New Liability
                            </button>
                            <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteType()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                </span>
                                Remove
                            </button>
                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllTypes()" :disabled="this.lists.length == 0">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                </span>
                                Remove All (!)
                            </button>
                        </div>
                    </div>
                    <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">                            
                          <DxDataGrid :show-borders="true" 
                                  key-expr="code" 
                                  :data-source="lists" 
                                  :allow-column-reordering="true"  
                                  :allow-column-resizing="true" 
                                  :column-auto-width="true" 
                                  :row-alternatin-enabled="true"
                                  :hover-state-enabled="true" 
                                  :show-row-lines="true" 
                                  @exporting="this.$onExporting($event , 'Default-Liability-Type-List')">

                                  <DxFilterRow :visible="true"/>
                                  <DxHeaderFilter :visible="true"/>
                                  <DxGroupPanel :visible="true"/>      
                                  <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                  <DxPaging :page-size="20"/>
                                  <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                  <DxSearchPanel :visible="true"/>
                                  
                                  <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                  <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                                  <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/> 
                                  <DxColumn data-field="liability_group_name" caption="Liability Group" header-cel-template="liability_group_name-header" cell-template="liability_group_name-cell-template"/>
                                  <DxColumn data-field="value_criteria" caption="Value Criteria" alignment="left" header-cel-template="value_criteria-header" cell-template="value_criteria-cell-template"/> 
                                  <DxColumn data-field="value_type_name" caption="Value Type" alignment="left" header-cell-template="value_type-header" cell-template="value_type-cell-template"/>  
                                  <DxColumn data-field="hide" caption="Is Hide?" alignment="center" cell-template="hide-cell-template" :allow-filtering="false"/> 
                                  

                                  <!-- Header Cell Templates -->
                                  <template #authorized-user-header>
                                      <div> Authorized </div> <div> User </div>
                                  </template> 
                                  <template #liability_group_name-header>
                                      <div> Liability </div> <div> Group </div>
                                  </template>                                  
                                  <template #value_criteria-header>
                                      <div> Value </div> <div> Criteria </div>
                                  </template>
                                  <template #value_type-header>
                                      <div> Value </div> <div> Type </div>
                                  </template>

                                  <!-- Cell Templates -->
                                  <template #is-selected-cell-template="{ data }"> 
                                      <span>
                                          <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.code !== null && data.data.code !== ''">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                          </div>
                                      </span>
                                  </template>
                                  <template #code-cell-template="{ data }">
                                      <span class="text-gray-900 mb-1">
                                          <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                              <i class="bi bi-pencil text-gray-900"></i> 
                                          </span> 
                                          {{ data.data.code }}
                                      </span>
                                  </template>
                                  <template #name-cell-template="{ data }">
                                      <span class="text-gray-900 fw-bold mb-1">
                                          {{ data.data.name }}
                                      </span>
                                  </template>
                                  <template #liability_group_name-cell-template="{ data }">
                                      <span class="text-gray-900 fw-bold mb-1">
                                          {{ data.data.liability_group_name }}
                                      </span>
                                  </template>
                                  <template #value_criteria-cell-template="{ data }">
                                      <span :class="{'text-hover-primary text-start': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                        class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                          {{ valueCriteriaName(data.data.value_criteria) }}
                                      </span>
                                  </template>
                                  <template #value_type-cell-template="{ data }">
                                      <span :class="{'text-hover-primary text-start': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                        class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">                                        
                                          <span> {{ data.data.value_type_name }} </span>
                                      </span>
                                  </template> 
                                  <template #hide-cell-template="{ data }">
                                        <input class="form-check-input" type="checkbox" disabled value="" id="hide" v-model="data.data.hide">
                                  </template> 
                                  <DxSummary>
                                      <DxTotalItem
                                          column="id"
                                          summary-type="count"
                                          show-in-column="auth_type"
                                      /> 
                                  </DxSummary>
                          </DxDataGrid>
                        </div>
                        <div class="row">
                            <div
                                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                <div class="dataTables_info" id="kt_datatable_example_1_info" role="status"
                                    aria-live="polite">1 records</div>
                            </div>
                        </div>
                    </div>
                </div>        
              </div>
          </div>
      </div> 
  </div>  


  <div class="modal fade" tabindex="-1" id="select_liability_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xxl modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Select Liability Types</h5>                     
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div> 
              <div class="modal-body scroll-y pt-1" :class="{'overlay overlay-block': this.isSelectedLiabilityTypeSaving == true}"> 
                <div class="row pb-5">
                  <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mt-3 text-gray-600">
                          <span>Liability Group</span>
                      </label>
                      <Select2 v-model="modalForm.liability_group_id" :options="liabilityGroups" @select="getLiabilityTypes()" :settings="{width: '100%'}" />
                  </div>
                  <div class="col-lg-12 gy-5">
                    
                    <DxDataGrid :show-borders="true" 
                            key-expr="id" 
                            :data-source="liabilityTypesList" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            @exporting="this.$onExporting($event , 'Liability-Type-List')"
                            :ref="'detailDataGrid'">

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="1000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            
                            <DxColumn data-field="is_selected" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :width="80" :allow-exporting="false"/> 
                            <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                            <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/>
                            <DxColumn data-field="liability_group_name" caption="Liability Group" header-cell-template="liability_group_name-header" cell-template="liability_group_name-cell-template"/>
                            <DxColumn data-field="value_criteria" caption="Value Criteria" header-cell-template="value_criteria-header" cell-template="value_criteria-cell-template"/>
                            <DxColumn data-field="value_type_name" caption="Value Type" header-cell-template="value_type-header" cell-template="value_type-cell-template"/>  
                            <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/>

                            <!-- Header Cell Templates -->
                            <template #is-selected-header>                                
                              <div class="form-check form-check-sm form-check-custom form-check-solid">
                                  <input class="form-check-input" type="checkbox" v-model="modalForm.isSelectedAll" @change="onSelectAll()"> 
                              </div>
                            </template>
                            <template #liability_group_name-header>
                                <div> Liability </div> <div> Group </div>
                            </template>
                            <template #value_criteria-header>
                                <div> Value </div> <div> Criteria </div>
                            </template>
                            <template #value_type-header>
                                <div> Value </div> <div> Type </div>
                            </template>

                            <!-- Cell Templates -->
                            <template #is_selected-cell-template="{ data }">                                
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="" v-model="data.data.isSelected"> 
                                </div>
                            </template>
                            <template #code-cell-template="{ data }">
                                <span class="text-gray-900 mb-1">
                                    {{ data.data.code }}
                                </span>
                            </template>
                            <template #name-cell-template="{ data }">
                                <span class="text-gray-900 fw-bold mb-1">
                                    {{ data.data.name }}
                                </span>
                            </template>
                            <template #liability_group_name-cell-template="{ data }">
                                <span class="text-gray-900 fw-bold mb-1">
                                    {{ data.data.liability_group_name }}
                                </span>
                            </template>                            
                            <template #value_criteria-cell-template="{ data }">
                                <span :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                  class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">
                                    {{ valueCriteriaName(data.data.value_criteria) }}
                                </span>
                            </template>
                            <template #value_type-cell-template="{ data }">
                                <span :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                  class="text-gray-900 fw-bold mb-1" @click="updateType(data.data)">                                        
                                    <span> {{ data.data.value_type_name }} </span>
                                </span>
                            </template> 
                            <template #description-cell-template="{ data }">
                                <span class="text-gray-900 fw-bold mb-1">
                                    {{ data.data.description }}
                                </span>
                            </template>
                            
                            <DxSummary>
                                <DxTotalItem
                                    column="id"
                                    summary-type="count"
                                    show-in-column="is_selected"
                                /> 
                            </DxSummary>
                    </DxDataGrid>
                  </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isSelectedLiabilityTypeSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
              </div> 
              <div class="modal-footer"> 
                   <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                   <button type="button"  class="btn btn-success" @click="saveSelectedLiabilityTypes()" :disabled="isAddToListButtonDisabled == null || isAddToListButtonDisabled == undefined">Add To List</button>
              </div>
          </div>
      </div>
    </div>
  </div> 
</template>
<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `DCTL001`  
import axios from 'axios' 
 
import moment from 'moment' 
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem  } from 'devextreme-vue/data-grid';

 
export default {
  name: "DefaultLiabilityTypesList",
  components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem 
  },
  data() {
    return {
      lists: [],
      liabilityTypesList: [],
      selectedLiabilityTypes: [],
      searchbar: '',
      companyTypes: [],
      liabilityGroups: [],
      isListProgressing: false,
      pageSizes: [10, 20, 50, 100, 'all'],
      page_size: 10,
      page: 1,
      form: {
        company_type_id: '-1'
      },
      modalForm: {
        country_code: '-1',
        liability_group_id: '-1',
        main_task_type: 0,
        isSelectedAll: false
      },
      countryList: [{id: null, text: 'ALL' }],      
      mainTaskTypes: [ { id: 0, text: 'ALL' }, { id: 1, text: 'System' },{ id: 2, text: 'Controller' }, { id: 3, text: 'Company' }],
      isSelectedLiabilityTypeSaving: false,
      valueCriteriaList: [
        {id: 0, text: 'No Criteria'},
        {id: 1, text: 'Incorporated In (Day/Month)'}, 
        {id: 2, text: 'Accounting Start Period (Day/Month)'}, 
        {id: 3, text: 'Accounting End Period (Day/Month)'}, 
        {id: 4, text: 'Accounting Fee (Day/Month)'}, 
        {id: 5, text: 'VAT Start Period (Day/Month)'}, 
        {id: 6, text: 'VAT End Period (Day/Month)'}, 
        {id: 7, text: 'Payroll'}
      ]
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    previewAnnualDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMM Do')
        }
      }
    },
    previewHours(){
       return (value)=>{
       if (value) {
          return moment(new Date(value)).format('HH:mm')
        }
      }
    },
    isItemButtonDisabled() {
      return this.lists.find(item =>item.isSelected == true );
    },
    isAddToListButtonDisabled() {
      return this.liabilityTypesList.find(item =>item.isSelected == true );
    },
    valueCriteriaName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.valueCriteriaList.find(element => {
            return element.id == value
          }).text;
        }
      }
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const parameters = {
        session_id: this.$getUser().session_id,
        company_type_id: this.form.company_type_id
      };  
      axios.post('/api/DefaultCompanyLiabilityTypes/GetAllDefaultLiabilityTypes', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        this.lists = response.data;
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      });
    },
    getLists() {
      this.$Progress.start();
      this.companyTypes = [];
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 0
      };    
      axios.post('/api/CompanyManagement/CompanyTypes', params, {'Content-Type': 'application/json'}).then((companyTypesResponse) => {
        this.companyTypes = companyTypesResponse.data;
        this.companyTypes.unshift({ id: '-1', text: 'Select an option'});
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      });
    },
    deleteType() { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {            
            this.lists.forEach((item) => {
              if(item.isSelected) {
                const queryParameter = {
                    id: item.default_company_liability_type_id,
                    session_id: this.$getUser().session_id,
                };
                axios.post('/api/DefaultCompanyLiabilityTypes/DeleteDefaultCompanyLiabilityType', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => { 
                  if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                    this.getData();
                  }
                })
                .catch(function (error) {
                    console.log(error);
                });
              }
            });
          }
        })  
      },
      deleteAllTypes() { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {            
            const queryParameter = {
              company_type_id: this.form.company_type_id,
              session_id: this.$getUser().session_id,
            };
            axios.post('/api/DefaultCompanyLiabilityTypes/DeleteDefaultCompanyLiabilityTypeMulti', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                this.getData();
              }
            })
            .catch(function (error) {
                console.log(error);
            });
          }
        })  
      },
      getLiabilityTypes(){ 
          this.$Progress.start();
          this.liabilityTypesList = [];
          const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 0,
            liability_group: this.modalForm.liability_group_id,
            company_type: this.form.company_type_id
          };
          if (params.liability_group == '-1') {params.liability_group = null; }
          axios.post("/api/DefaultCompanyLiabilityTypes/GetLiabilityTypesForSelection", params, {'Content-Type': 'application/json'}).then((response) => {  
            this.liabilityTypesList = response.data; 
            this.$Progress.finish();
          })
          .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
          });
      },
      liabilitySelectionModal() {
          this.$Progress.start();
          this.modalForm.country_code = '-1';
          this.modalForm.liability_group_id = '-1';
          this.modalForm.isSelectedAll = false;
          this.isSelectedLiabilityTypeSaving = false;          
          this.$refs.detailDataGrid.instance.clearFilter();
          const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 1,
            list_type: 19 // Liability Group Type: 19
          };
          axios.post('/api/Lists/GetLists', params, {'Content-Type': 'application/json'}).then((response) => {
            this.liabilityGroups = response.data;
            this.liabilityGroups.unshift({ id: '-1', text: 'ALL'});
            this.liabilityTypesList = [];
            const params = {
              session_id: this.$getUser().session_id,
              get_access_status: 0,
              liability_group: this.modalForm.liability_group_id,
              company_type: this.form.company_type_id
            };
            if (params.liability_group == '-1') {params.liability_group = null; }
            axios.post("/api/DefaultCompanyLiabilityTypes/GetLiabilityTypesForSelection", params, {'Content-Type': 'application/json'}).then((response) => {  
              this.liabilityTypesList = response.data; 
              this.$Progress.finish();
            })
            .catch(function (error) {
              console.log(error); 
              this.$Progress.fail();
            });
          })
          .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
          });
      },
      saveSelectedLiabilityTypes(){
        this.isSelectedLiabilityTypeSaving = true;
        const that = this;
        this.selectedLiabilityTypes = [];
        this.liabilityTypesList.forEach((value) => {
          if(value.isSelected){
             this.selectedLiabilityTypes.push(value.id);
          }
        }); 
        const params = {
          company_type_id: this.form.company_type_id,
          LiabilityTypes: this.selectedLiabilityTypes, 
          session_id: this.$getUser().session_id
        };
        axios.post('/api/DefaultCompanyLiabilityTypes/CreateDefaultCompanyLiabilityType', params, {'Content-Type': 'application/json'})
          .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.isSelectedLiabilityTypeSaving = false;
                this.$refs.closeModal.click();
                this.getData();
              }
              this.isSelectedLiabilityTypeSaving = false;
          })
          .catch(function (error) {
              this.isSelectedLiabilityTypeSaving = false;
              console.log(error);
              this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!"); 
          })
          .finally(function () { 
            setTimeout(() => {
                that.isSelectedLiabilityTypeSaving = false;
            }, 500);
        });
      },
      onSelectItem(item) {
          this.lists.forEach((value) => {
              if (item.code !== value.code) { 
                  value.isSelected = false;
              }
          });
      },
      onSelectAll() {
        this.liabilityTypesList.forEach((value) => {
          value.isSelected = this.modalForm.isSelectedAll;
        });
      }
    },
  created() {
    this.getLists();    
  }
}; 
</script>