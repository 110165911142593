<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Controller Operation Groups</h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerOperationGroupsList' }">Controller Operation Groups</router-link></li> 
                  <li class="breadcrumb-item text-gray-600">Create Controller Operation Groups </li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/ControllerOperationGroupsList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active mb-xxl-6" id="kt_accounting_1" role="tabpanel">
                    <div class="card-body">
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <form id="kt_ecommerce_settings_general_form"
                                            class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                            <div class="fv-row row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Code
                                                    </label>
                                                    <input type="text" class="form-control " name="code" v-model="form.code">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Name
                                                    </label>
                                                    <input type="text" class="form-control " name="name" v-model="form.name">
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-check mt-5">
                                                        <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                        <label class="fs-6 fw-bold form-label" for="isDefault">
                                                            Default
                                                        </label>
                                                    </div> 
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> 
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <form id="kt_ecommerce_settings_general_form"
                                            class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                            <div class="fv-row row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        Type
                                                    </label>
                                                    <Select2 v-model="form.list_type" :options="listTypes" :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Description</span>
                                                    </label>
                                                    <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mb-xxl-6" id="kt_accounting_1" role="tabpanel">
                    <div class="card-body"> 
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                            <div class="p-5">
                                <div class="d-flex flex-stack flex-wrap mb-5">
                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                            data-bs-target="#kt_modal_add_operation_group_user"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                            @click="this.openAddUserModal()">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            Add User
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteUser()" :disabled="isUserButtonDisabled == null || isUserButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove
                                        </button>
                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllUsers()" :disabled="this.groupUsersList.length == 0">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove All (!)
                                        </button>
                                    </div>
                                </div>
                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive">
                                        <DxDataGrid :show-borders="true" 
                                                    key-expr="user_id" 
                                                    :data-source="groupUsersList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" > 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="false"/>
                                                <DxPaging :page-size="1000"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                    
                                                />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                                                <DxColumn data-field="user_name" caption="User Name" cell-template="user_name-cell-template"/>
                                                <DxColumn data-field="user_mail" caption="Email" alignment="left" cell-template="user_mail-cell-template"/> 
                                                <DxColumn data-field="is_owner" caption="Is Owner?" cell-template="is_owner-cell-template" :allow-filtering="false" alignment="left"/>
                                                
                                                <!-- Cell Templates -->
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #user_name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.user_name }}
                                                    </span>
                                                </template>
                                                <template #user_mail-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.user_mail }}
                                                    </span>
                                                </template>
                                                <template #is_owner-cell-template="{ data }"> 
                                                    <span v-if="data.data.is_owner == 1">
                                                        <i class="las la-check fs-2 text-success"></i>
                                                    </span>  
                                                    <span v-if="data.data.is_owner == 0">
                                                        <i class="las la-times fs-2 text-danger"></i>
                                                    </span> 
                                                </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel" v-if="this.form.list_type == 1">
                    <div class="card-body"> 
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                            <div class="p-5">
                                <div class="d-flex flex-stack flex-wrap mb-5">
                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                            data-bs-target="#kt_modal_add_liability_group" @click="openLiabilityGroupModal()"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            Add Liability Group
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteType()" :disabled="isTypeButtonDisabled == null || isTypeButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove
                                        </button>
                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllTypes()" :disabled="this.typesList.length == 0">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove All (!)
                                        </button>
                                    </div>
                                </div>
                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive">
                                        <DxDataGrid :show-borders="true" 
                                                    key-expr="type_id" 
                                                    :data-source="typesList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" > 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="false"/>
                                                <DxPaging :page-size="1000"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                    
                                                />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                                                <DxColumn data-field="type_name" caption="Group Name" cell-template="type_name-cell-template"/>
                                                
                                                <!-- Cell Templates -->
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectTypeItem(data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #type_name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.type_name }}
                                                    </span>
                                                </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="p-5"> 
                        <div class="row">
                            <div class="col-lg-12"> 
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Save Operation Group </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>

    <!-- Add Liability Group -->
    <div class="modal fade" id="kt_modal_add_liability_group" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_operation_group_user_header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Add Liability Group                    
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeTypeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="liabilityGroupList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" > 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="false"/>
                                <DxGroupPanel :visible="false"/>
                                <DxPaging :page-size="1000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                
                                <DxColumn data-field="isSelected" :width="80" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :allow-filtering="false" alignment="center"/>
                                <DxColumn data-field="text" caption="Group Name" cell-template="group_name-cell-template"/>
                                
                                <!-- Header Cell Templates -->
                                <template #is-selected-header>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input" type="checkbox" v-model="typeForm.isSelectedAll" @change="onSelectAll('liabilityGroup')"> 
                                    </div>
                                </template>

                                <!-- Cell Templates -->
                                <template #is_selected-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                        </div>
                                    </span>
                                </template>
                                <template #group_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.text }}
                                    </span>
                                </template>
                            </DxDataGrid>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-flush mt-4" id="kt_add_user_button">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-light-success border border-success btn-lg"
                                        @click="this.addType()">
                                        <span class="indicator-label">Add Liability Group</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add User -->
    <div class="modal fade" id="kt_modal_add_operation_group_user" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_operation_group_user_header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Add User                    
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <DxDataGrid :show-borders="true" 
                                key-expr="user_id" 
                                :data-source="controllerUserList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" > 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="false"/>
                                <DxGroupPanel :visible="false"/>
                                <DxPaging :page-size="1000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                
                                <DxColumn data-field="isSelected" :width="80" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :allow-filtering="false" alignment="center"/>
                                <DxColumn data-field="user_name" caption="User Name" cell-template="user_name-cell-template"/>
                                <DxColumn data-field="user_mail" caption="Email" alignment="left" cell-template="user_mail-cell-template"/> 
                                <DxColumn data-field="is_owner" caption="Is Owner?" cell-template="is_owner-cell-template" :allow-filtering="false" alignment="left"/>

                                <!-- Header Cell Templates -->
                                <template #is-selected-header>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input" type="checkbox" v-model="userForm.isSelectedAll" @change="onSelectAll('users')"> 
                                    </div>
                                </template>
                                <!-- Cell Templates -->
                                <template #is_selected-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.user_id !== null && data.data.user_id !== ''">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                        </div>
                                    </span>
                                </template>
                                <template #user_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.user_name }}
                                    </span>
                                </template>
                                <template #user_mail-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.user_mail }}
                                    </span>
                                </template>
                                <template #is_owner-cell-template="{ data }"> 
                                    <span v-if="data.data.is_owner == 1">
                                        <i class="las la-check fs-2 text-success"></i>
                                    </span>  
                                    <span v-if="data.data.is_owner == 0">
                                        <i class="las la-times fs-2 text-danger"></i>
                                    </span> 
                                </template>
                            </DxDataGrid>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-flush mt-4" id="kt_add_user_button">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-light-success border border-success btn-lg"
                                        @click="this.addUser()">
                                        <span class="indicator-label">Add User to List</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
 

 
export default {
  name: "ControllerOperationGroupsCreate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling
  },
  data() {
    return {
        isProgressing: false,
        isItemSaving: false,
        groupUsersList: [],
        typesList: [],
        selectedUsers: [],
        selectedTypes: [],
        listTypes: [{id: 1, text: 'Liability'}],
        liabilityGroupList: [],
        controllerUserList: [],
        form: {
            session_id: this.$getUser().session_id,
            name: null,
            code: null,
            description: null,
            list_type: 1,            
            is_default: false,
            tmp_session_id: null,
        },
        userForm: {
            user_id: null,
            operation_group_id: null
        },
        typeForm: {
            type_id: null,
            operation_group_id: null
        }
    };
  },
  computed: {
    isUserButtonDisabled() {
       return this.groupUsersList.find(item => item.isSelected == true );
    },
    isTypeButtonDisabled() {
       return this.typesList.find(item => item.isSelected == true );
    }
  },
  methods: {
    openLiabilityGroupModal(){
        this.isItemSaving = false;
        this.typeForm.isSelectedAll = false;
        const liabilityGroupParams = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        };
        axios.post('/api/OperationGroups/GetLiabilityGroups', liabilityGroupParams, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.liabilityGroupList = response.data;
        })        
        .catch(function (error) {
            console.log(error); 
            this.$Progress.finish();
        });
    },
    openAddUserModal(){
        this.isItemSaving = false;
        this.userForm.isSelectedAll = false;
        const params = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        }
        axios.post("/api/OperationGroups/GetControllerUsers", params, {'Content-Type': 'application/json'})
        .then((response) => { 
                this.controllerUserList = response.data;
        })
        .catch(function (error) {
            console.log(error); 
        });
    }, 
    saveData(){
        this.isProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            code: this.form.code,
            name: this.form.name,
            description: this.form.description,
            list_type: this.form.list_type,
            is_default: this.form.is_default == true ? 1 : 0,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        }; 
        const that = this;
        params['tmp_session_id'] = this.$getSessionStorage('tmp_session_id');
        axios.post('/api/OperationGroups/Create', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true);
                this.$router.push({ name: 'ControllerOperationGroupsUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) 
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    addUser(){
        this.selectedUsers = [];
        this.controllerUserList.forEach((value) => {
            if(value.isSelected){
                this.selectedUsers.push(value.user_id);
            }
        }); 
        if (this.selectedUsers.length == 0) {            
            this.$swal("Failed", " User must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            Users: this.selectedUsers,
        };
        const that = this;
        axios.post('/api/OperationGroups/AddUser', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.groupUsersList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeModal.click();
            } 
            this.isItemSaving = false;
        }) 
        .catch(function (error) {
        this.isItemSaving = false;
            console.log(error);
            this.Progress.finish();
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteUser(){
        this.groupUsersList.forEach((item) => {
            if (item.isSelected) {
                this.userForm.user_id = item.user_id;
                this.userForm.operation_group_id = item.operation_group_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    user_id: this.userForm.user_id,
                    operation_group_id: this.userForm.operation_group_id,
                }; 
                axios.post('/api/OperationGroups/DeleteOperationGroupUser', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.groupUsersList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllUsers(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('/api/OperationGroups/DeleteAllOperationGroupUser', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.groupUsersList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    addType(){
        this.selectedTypes = [];
        this.liabilityGroupList.forEach((value) => {
            if(value.isSelected){
                this.selectedTypes.push(value.id);
            }
        }); 
        if (this.selectedTypes.length == 0) {            
            this.$swal("Failed", " Liability Group must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            Types: this.selectedTypes,
        };        
        const that = this;
        axios.post('/api/OperationGroups/AddType', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.typesList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeTypeModal.click();
            } 
            this.isItemSaving = false;
        }) 
        .catch(function (error) {
            this.isItemSaving = false;
            console.log(error);
            this.Progress.finish();
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteType(){
        this.typesList.forEach((item) => {
            if (item.isSelected) {
                this.typeForm.type_id = item.type_id;
                this.typeForm.operation_group_id = item.operation_group_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    type_id: this.typeForm.type_id,
                    operation_group_id: this.typeForm.operation_group_id,
                }; 
                axios.post('/api/OperationGroups/DeleteOperationGroupType', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.typesList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllTypes(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('/api/OperationGroups/DeleteAllOperationGroupTypes', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.typesList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    onSelectItem(item) { 
        this.groupUsersList.forEach((value) => {  
            if (item.user_id !== value.user_id) { 
                value.isSelected = false;
            }
        });
    },
    onSelectTypeItem(item) {
        this.typesList.forEach((value) => {
            if (item.type_id !== value.type_id) { 
                value.isSelected = false;
            }
        });
    },
    onSelectAll(listType) {
        if (listType == 'users') {
            this.controllerUserList.forEach((value) => {
                value.isSelected = this.userForm.isSelectedAll;
            });
        }
        if (listType == 'liabilityGroup') {
            this.liabilityGroupList.forEach((value) => {
                value.isSelected = this.typeForm.isSelectedAll;
            });
        }
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
  }
}; 
</script>