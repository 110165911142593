<template>
<div class="d-flex flex-column flex-root">
	<div class="d-flex flex-column flex-lg-row flex-column-fluid">
		<div class="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
			<div class="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style="background-image: url(/assets/theme/media/logos/auth-bg.png)">
				<div class="d-flex flex-center py-10 py-lg-20 mt-lg-20">
					<a href="#">
						<img alt="Logo" src="assets/theme/media/logos/logo.png" class="h-100px">
					</a>
				</div>
				<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            <!--begin::Title-->
            <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7"> 
                Fast, Efficient and Productive
            </h1>  
            <!--end::Title-->

            <!--begin::Text-->
            <div class="d-none d-lg-block text-white fs-base text-center">
                In this kind of post, <a href="#" class="opacity-75-hover text-warning fw-bold me-1">the blogger</a> 

                introduces a person they’ve interviewed <br> and provides some background information about 
                
                <a href="#" class="opacity-75-hover text-warning fw-bold me-1">the interviewee</a> 
                and their <br> work following this is a transcript of the interview.  
            </div>
            <!--end::Text-->
        </div>
			</div>
		</div>
    
		<div class="d-flex flex-column flex-lg-row-fluid py-10">
			<div class="d-flex flex-center flex-column flex-column-fluid py-10 py-lg-20 mt-lg-20">
				<div class="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
          <form v-if="!isSendResetLink" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework fw-row" @submit.prevent="onBeforeSubmit()">
            <div class="text-center mb-10">
                <h1 class="text-dark fw-bolder mb-3">
                    Forgot Password ?
                </h1>
                <div class="text-gray-500 fw-semibold fs-6">
                    Enter your e-mail address to receive a password reset link.
                </div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container">
              <input type="text" placeholder="Email" name="email" autocomplete="off" class="form-control bg-white" v-model="form.user_mail" :class="{'border border-danger': isValidEmail == false}"> 
              <small class="text-danger" v-if="!isValidEmail && form.user_mail !== null && form.user_mail !== '' ">Email is not valid</small>
            </div>

            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
              <button @click="onBeforeSubmit()" type="button" class="btn btn-primary me-4" data-kt-indicator="on" :disabled="isValidEmail !== true">                      
                <span v-if="!this.isProgressing"> Reset Password </span>
                <span class="indicator-progress" v-if="this.isProgressing">
                  Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
              <router-link to="/Login">
                <a href="#" class="btn btn-light">
                    Cancel
                </a>
              </router-link>
            </div>
          </form>
          <div v-if="isSendResetLink" class="p-20 p-lg-20 text-center">
            <h1 class="fw-bolder text-gray-800 mb-5">
              Reset link has been sent.
            </h1>
            <div class="fs-6 mb-5">
              <span class="fw-semibold text-gray-500 mb-5">To set your new password, please click the reset password link that we sent to your e-mail address.</span>
            </div>
            <div class="mb-11">
              <img src="/assets/theme/media/illustrations/unitedpalms-1/16.png" class="mw-100 mh-200px theme-light-show" alt="">
              <img src="/assets/theme/media/illustrations/unitedpalms-1/16-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
            </div>
            <div class="fs-6 mb-5">
              <span class="fw-semibold text-gray-500">Didn’t receive an email? <br> Please check your spam folder or</span>

              <a href="#" class="link-primary fw-bold" @click="onTryAgain()"> try again</a>
            </div>
            <div class="mb-0">
              <router-link to="/Login">
                <button type="button" class="btn btn-sm btn-primary">
                  Back to Login
                </button>
              </router-link>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { load } from 'recaptcha-v3';

export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        user_mail: ''
      },
      isSendResetLink: false,
      isProgressing: false,
      isVerifyRecaptcha: false,
      rc_sk: ''
    };
  },
  computed: {
    isValidEmail() {
      return (this.form.user_mail == null || this.form.user_mail == '' || this.form.user_mail == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.user_mail);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg8.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');
      
      axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isVerifyRecaptcha = response.data.is_view_rc_fp == 1 ? true : false;
            this.rc_sk = this.$unlock(response.data.sk);
            this.loadReCaptcha();
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async loadReCaptcha() {
			if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
        const recaptcha = await load(this.rc_sk);
        if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
        else recaptcha.showBadge();
      }
    },    
    async onBeforeSubmit() {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');
        const parameters = {
          token: token,
          sk: this.rc_sk
        };
        const that = this;
        axios.post("/api/Auth/CheckRC", parameters, { "Content-Type": "application/json" })
        .then((response) => {
          if (response.data.success == true && response.data.score > 0.5) {
            this.sendResetLink();
          } else {            
            that.$swal("Failed", "ReCaptcha error..!", 'error'); 
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        this.sendResetLink();
      }
    },
    sendResetLink() {
      if(!this.form.user_mail) {
        this.$swal.fire({
          icon: 'error',
          title: 'Email address is required!'
        })
      } else {
        this.isProgressing = true;
        const parameters = {
          user_mail: this.form.user_mail
        };  
        const that = this; 
        axios.post('/api/Auth/SendResetLink', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isSendResetLink = true;
          }
        })
        .catch(function() {
          new Swal( { icon: 'error', title: 'Error...', text: 'The mail server information is not defined or contains incorrect information. Please make sure the information is correct and try again...!' });
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProgressing = false;
          }, 500);
        }); 
      }
    },
    onTryAgain() {
      this.form.user_mail = null;
      this.isSendResetLink = false;
    }
  },
  created() {
     this.getData();
  }
};
</script>