<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Documents
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600">Company</li> 
              <li class="breadcrumb-item text-gray-600">Documents</li> 
          </ul> 
      </div>
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">
         <!-- ALL DOCUMENTS VIEW -->
         <div class="card" v-if="this.activeMainView == 'all_documents'">
            <div class="card mb-3 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm mx-3 p-2 mt-3" 
                 style="background-image: url('assets/theme/media/svg/shapes/abstract-4.svg')">
                  <div class="card-body pt-0 pb-0">
                      <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-1x justify-content-start align-items-start py-5 mb-2" role="tablist">  
                          <li class="nav-item" role="presentation">
                                <div class="symbol symbol-45px me-5 justify-content-start"><i class="fas fa-file fs-2x me-4" style="color: rgb(0, 158, 247);"></i></div>
                          </li>
                          <li class="nav-item" role="presentation">
                              <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(0)">Today</a>
                          </li>
                          <li class="nav-item" role="presentation">
                              <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(1)">Yesterday</a>
                          </li>
                          <li class="nav-item" role="presentation">
                              <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(3)">Past 3 Days</a>
                          </li>
                          <li class="nav-item" role="presentation">
                              <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary active fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(10)">Past 10 Days</a>
                          </li>
                          <li class="nav-item" role="presentation">
                              <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(30)">Past 30 Days</a>
                          </li>
                      </ul>
                      <div>                
                          <div class="row py-3">
                              <div class="col-lg-9">
                                  <div class="fv-row mb-4 row">
                                      <div class="col-lg-3">
                                          <label class="fs-6 fw-bold form-label">
                                              Start Date
                                          </label>
                                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                      </div>
                                      <div class="col-lg-3">
                                          <label class="fs-6 fw-bold form-label">
                                              End Date
                                          </label>
                                          <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                      </div>
                                      <div class="col-lg-3">
                                          <label class="fs-6 fw-bold form-label">
                                              Document Type
                                          </label>
                                          <Select2 v-model="filterForm.document_type_id" :options="documentTypeCriteriaList" :settings="{ width: '100%' }"/>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-3">
                                  <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">   
                                      <Popper hover placement="top">
                                          <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                              <i class="bi bi-eraser-fill fs-4"> </i>
                                          </button>
                                          <template #content>
                                              <span class="text-gray-800"> Reset All Criterias </span>
                                          </template>
                                      </Popper>  
                                      <button type="button" class="btn btn-sm btn-primary" @click="getViewAllFolderDocuments()">
                                          <span class="svg-icon svg-icon-muted">
                                              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                  <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                  <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                              </svg>
                                          </span>
                                          <strong> List Documents </strong>
                                      </button>
                                  </div>
                              </div> 
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mt-4 p-3">
                  <!-- DOCUMENTS LIST -->
                  <div class="p-3">
                    <DxDataGrid :show-borders="true" 
                          key-expr="id" 
                          :data-source="documents" 
                          :allow-column-reordering="true"  
                          :allow-column-resizing="true" 
                          :column-auto-width="true" 
                          :row-alternatin-enabled="true"
                          :hover-state-enabled="true" 
                          :show-row-lines="true"
                          @row-prepared="onRowPrepared"> 

                      <DxFilterRow :visible="true"/>
                      <DxHeaderFilter :visible="true"/>
                      <DxGroupPanel :visible="true"/>
                      <DxSearchPanel :visible="true"/>  
                      <DxScrolling
                          mode="virtual"
                          row-rendering-mode="virtual"
                          
                      />
                      <DxColumn data-field="download" caption="Actions" cell-template="download-cell-template" alignment="left" width="125"/>  
                      <DxColumn data-field="document_name" caption="Document Name" cell-template="document_name-cell-template"/>
                      <DxColumn data-field="document_no" caption="Document No" cell-template="document_no-cell-template"/>
                      <DxColumn data-field="name" caption="File Name" cell-template="name-cell-template"/> 
                      <DxColumn data-field="document_type_name" caption="Document Type" cell-template="document_type_name-cell-template"/> 
                      <DxColumn data-field="importance" caption="Importance" cell-template="importance-cell-template" alignment="left"/> 
                      <DxColumn data-field="document_source" caption="In/Out" cell-template="document_source-cell-template" alignment="left"/> 
                      <DxColumn data-field="upload_source" caption="Source" cell-template="upload_source-cell-template" alignment="left"/> 
                      <DxColumn data-field="source_company" caption="Source Company" cell-template="source_company-cell-template"/> 
                      <DxColumn data-field="source_person" caption="Source Person" cell-template="source_person-cell-template"/> 
                      <DxColumn data-field="have_personal_data" caption="Personal Data" cell-template="have_personal_data-cell-template"/> 
                      <DxColumn data-field="have_gdpr_permission" caption="GDPR Permission" cell-template="have_gdpr_permission-cell-template"/> 
                      <DxColumn data-field="size" caption="Size" alignment="right" cell-template="size-cell-template"/>
                      <DxColumn data-field="created_by_name" caption="Uploaded By" alignment="left" cell-template="created_by_name-cell-template"/>
                      <DxColumn data-field="created_at" caption="Created At" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                      <DxColumn data-field="is_deleted" caption="Status" cell-template="is_deleted-cell-template" /> 
                      <DxColumn data-field="deleted_by_name" caption="Deleted By" alignment="left" cell-template="deleted_by_name-cell-template"/>

                      <template #is_deleted-cell-template="{ data: data }">
                          <span class="align-middle text-center"> 
                              <span v-if="data.data.is_deleted == 1"> 
                                  <i class="fas fa-times"></i> 
                                  <span class="ms-3">Deleted</span>
                              </span>
                              <span v-if="data.data.is_deleted == 0">  
                                  <span>Active</span>
                              </span>
                          </span>
                        </template> 
                        <template #deleted_by_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.deleted_by_name }}
                        </span>
                      </template>
                      <template #created_by_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.created_by_name }}
                        </span>
                      </template>
                      <template #name-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.name }}
                        </span>
                      </template>
                      <template #document_name-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.document_name }}
                        </span>
                      </template>
                      <template #document_no-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.document_no }}
                        </span>
                      </template>
                        <template #document_type_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.document_type_name }}
                        </span>
                      </template>
                      <template #source_company-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.source_company }}
                        </span>
                      </template>
                      <template #source_person-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.source_person }}
                        </span>
                      </template>
                      <template #document_source-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.document_source == 0"> Incoming</span>
                            <span v-if="data.data.document_source == 1"> Outgoing</span>
                        </span>
                      </template>
                      <template #upload_source-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.upload_source == 0"> Manual</span>
                            <span v-if="data.data.upload_source == 1"> Transaction</span>
                            <span v-if="data.data.upload_source == 2"> Liability</span>
                            <span v-if="data.data.upload_source == 3"> ChatBi</span>
                        </span>
                      </template>
                      <template #have_personal_data-cell-template="{ data: data }">
                        <span class="align-middle text-center">
                            <span v-if="data.data.have_personal_data == 0"> 
                                <i class="fas fa-times"></i>
                            </span>
                            <span v-if="data.data.have_personal_data == 1"> 
                                <i class="fas fa-check"></i>
                            </span>
                        </span>
                      </template>
                        <template #have_gdpr_permission-cell-template="{ data: data }">
                        <span class="align-middle text-center">
                            <span v-if="data.data.have_gdpr_permission == 0"> 
                                <i class="fas fa-times"></i>
                            </span>
                            <span v-if="data.data.have_gdpr_permission == 1"> 
                                <i class="fas fa-check"></i>
                            </span>
                        </span>
                      </template>
                        <template #importance-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.importance == 0"> Normal </span>
                            <span v-if="data.data.importance == 1"> Low </span>
                            <span v-if="data.data.importance == 2"> High </span>
                            <span v-if="data.data.importance == 3"> Very High </span>
                        </span>
                      </template>
                      <template #size-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ calculateSize(data.data.size) }}
                        </span>
                      </template>

                      <template #download-cell-template="{ data: data }">
                        <span>
                        <a href="#" target="_self" @click="onOpenDetail(null, data)" data-bs-toggle="modal" data-bs-target="#document_details_modal">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                <rect x="11" y="11" width="2" height="2" rx="1" fill="currentColor"/>
                                <rect x="11" y="15" width="2" height="2" rx="1" fill="currentColor"/>
                                <rect x="11" y="7" width="2" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                        </a> 
                        <!-- Transaction -->
                          <a href="#" target="_self" @click="onOpenAccordion(null, data)" data-bs-toggle="modal" data-bs-target="#transaction_details_modal">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-info">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </a>  
                        
                        <!-- Download -->
                          <a href="#" target="_self" data-bs-toggle="modal" data-bs-target="#file_download_modal" @click="onRequestFile(data.data)" v-if="data.data.is_deleted == 0">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-dark">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="currentColor"/>
                                <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="currentColor"/>
                                <path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </a> 
                        </span>
                      </template> 
                      
                    </DxDataGrid>
                  </div>
              </div>
         </div>
    </div> 

    <!-- Add Folder Modal -->
    <div class="modal fade" tabindex="-1" id="add_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Add Folder      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeAddModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">Size Limit</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">Upload size limits for files (Bytes)</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">Allowed Extensions</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">Blocked Extensions</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">Folder Description</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">Description for folder can be typed here.</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-success w-100 mt-4" @click="onCreateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        Create Folder
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> Icon Selection </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                Reset
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Edit Folder Modal -->
    <div class="modal fade" tabindex="-1" id="edit_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Update Folder      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">Size Limit</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">Upload size limits for files (Bytes)</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">Allowed Extensions</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">Blocked Extensions</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">Folder Description</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">Description for folder can be typed here.</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-primary w-100 mt-4" @click="onUpdateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        Update Folder
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> Icon Selection </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                Reset
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Set Document Type Modal -->
    <div class="modal fade" tabindex="-1" id="set_document_type_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Set Document Type
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeDocumentTypeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                    <div class="card">
                      <table class="table table-striped gy-7 gs-7"> 
                        <tbody>
                          <tr v-for="item in documentTypeslist" v-bind:key="item">
                            <td class="align-middle">
                              <button class="btn btn-sm btn-danger" @click="onDocumentTypeSave(item)" v-if="item.isAdded">
                                  Remove
                              </button>
                               <button class="btn btn-sm btn-success" @click="onDocumentTypeSave(item)" v-if="!item.isAdded">
                                  Add
                              </button>
                            </td>
                            <td class="align-middle text-gray-800">{{ item.code }}</td>
                            <td class="align-middle text-gray-900">{{ item.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Upload File Modal -->
    <div class="modal fade" tabindex="-1" id="upload_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content" style="min-height: 90% !important;">
                <div class="modal-header">
                    <div class="col-lg-6">
                    <h2 class="modal-title fw-bolder">
                        Upload File
                    </h2>
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUploadModal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg>
                    </span>
                    </div>
                </div>
                <div class="modal-body"> 
                  <div class="row">
                      <div class="col-lg-6">
                        <div class="card shadow-sm"> 
                            <div class="card-body">
                                <div class="row">
                                   <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Document Name
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.document_name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Document No
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.document_no" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Description
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <textarea class="form-control" rows="2" v-model="uploadForm.document_description"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Document Type
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.document_type_id" :options="documentTypesForUpload" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Importance
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.importance" :options="importanceList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="card shadow-sm"> 
                            <div class="card-body">
                                <div class="row">
                                   <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Document Source
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.document_source" :options="documentSourceList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Source Company
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.source_company" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Source Person
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.source_person" class="form-control" /> 
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Does it contain personal data? (GDPR Content)
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.have_personal_data" 
                                                      :options="havePersonelDataList" 
                                                      :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"
                                                      @select="onPersonalDataChange()"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" v-if="uploadForm.have_personal_data == 1">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Have GDPR permissions been obtained?
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.have_gdpr_permission" :options="gpdrObtainList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" v-if="uploadForm.have_personal_data == 1">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            GDPR Permissions Description
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <textarea class="form-control" rows="2" v-model="uploadForm.gdpr_description"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="card shadow-sm mt-4 bgi-no-repeat bgi-position-y-top bgi-position-x-end" style="background-image: url('assets/theme/media/svg/shapes/abstract-5.svg')">
                            <div class="card-body">
                                <div class="row"> 
                                    <div class="col-lg-12" v-if="this.activeFolder != null">  
                                      <!-- dialog-trigger="#btn_download" -->
                                        <DxFileUploader
                                            multiple="false"
                                            upload-mode="useButtons" 
                                            :visible="true"
                                            accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, image/*"
                                            :disabled="this.uploadForm.document_type_id == -1 || this.uploadForm.document_source == -1 || this.uploadForm.document_name == null || this.uploadForm.document_name == '' || this.uploadForm.document_no == null || this.uploadForm.document_no == ''"
                                            :upload-headers="getUploadHeaders()"
                                            :on-uploaded="onUploadCompleted"
                                            upload-url="/api/Folders/Upload"
                                            :ref="'fileUploader'"
                                        />
                                    </div>
                                </div> 
                            </div>
                    </div>
                </div>
                <!-- <div class="modal-footer"> 
                  <button id="btn_download" type="button" class="btn btn-success w-100" :disabled="this.uploadForm.document_type_id == -1 || this.uploadForm.document_source == -1">Upload</button>
                </div> -->
            </div>
        </div>
    </div>

    <!-- Add Transaction Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_add_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Add Transaction                
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                              <div class="card-body py-1">
                                  <div class="row">
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>Status</span>
                                          </label>
                                          <Select2 v-model="transactionForm.status" :options="statusList" :settings="{ width: '100%', dropdownParent: '#transaction_add_modal .modal-content' }"/>
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>Description</span>
                                          </label>
                                          <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="card card-flush mt-4">
                                              <div class="d-grid gap-2">
                                                  <button type="button" class="btn btn-success btn-lg" @click="this.saveTransactionData()" :disabled="this.transactionForm.status == -1">
                                                      <span class="indicator-label">Save</span> 
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Edit Transaction Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_edit_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Edit Transaction                    
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeEditTransactionModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                              <div class="card-body py-1">
                                  <div class="row">
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>Status</span>
                                          </label>
                                          <input class="form-control" v-model="transactionForm.status_text" disabled/>
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>Description</span>
                                          </label>
                                          <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="card card-flush mt-4">
                                              <div class="d-grid gap-2">
                                                  <button type="button" class="btn btn-primary btn-lg" @click="this.updateTransactionData()" :disabled="this.transactionForm.status == -1">
                                                      <span class="indicator-label">Update</span> 
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div> 

    <!-- Document Details Modal -->
    <div class="modal fade" tabindex="-1" id="document_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Document Details                   
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="documentDetailsModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                  <div class="row align-items-center mx-0 w-100"> 
                      <div class="col-lg-12 px-4 text-end">
                        <button type="button" v-if="!this.selectedDocument.is_deleted" class="btn btn-sm btn-light-danger me-3" style="font-size:11px !important;" @click="onDeleteDocument(this.selectedDocument)">
                            Delete File
                        </button>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                  <div class="row">
                                      <div class="col-lg-6">
                                        <div>
                                          <span>
                                            Importance: 
                                          </span>
                                          <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.importance == 0">Normal</span>
                                          <span class="badge badge-sm badge-secondary" v-if="this.selectedDocument.importance == 1">Low</span>
                                          <span class="badge badge-sm badge-warning" v-if="this.selectedDocument.importance == 2">High</span>
                                          <span class="badge badge-sm badge-danger" v-if="this.selectedDocument.importance == 3">Very High</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="text-end"> 
                                              <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.document_source == 0">Incoming Document</span>
                                              <span class="badge badge-sm badge-info" v-if="this.selectedDocument.document_source == 1">Outgoing Document</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                    <div class="row">
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>Document Name :</span>
                                        &nbsp; 
                                        <span class="badge badge-sm badge-secondary"> {{ this.selectedDocument.document_name }} </span>
                                      </div>
                                      <div class="col-lg-4">  
                                        <span class="bullet bg-secondary me-3 bullet-md"></span>
                                        <span>Document No :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.document_no }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>File Name :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.name }} </span>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.document_source == 0"> Incoming Document </span>
                                            <span v-if="this.selectedDocument.document_source == 1"> Outgoing Document </span>
                                          </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                          <span >Source Company :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_company }} </span>
                                        </div>
                                        <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source Person :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_person }} </span>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Size :</span>
                                          &nbsp; 
                                          <span> {{ calculateSize(this.selectedDocument.size) }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Created At :</span>
                                          &nbsp; 
                                          <span> {{ previewDate(this.selectedDocument.created_at) }} </span>
                                        </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md "></span>
                                          <span>Status :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.status == 0"> Active </span>
                                            <span class="text-danger" v-if="this.selectedDocument.status == 1"> Deleted File </span>
                                          </span>
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>  
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                <div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span>
                                        Does it contain personal data? (GDPR Content): 
                                      </span>
                                      &nbsp; 
                                      <span v-if="this.selectedDocument.have_personal_data == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_personal_data == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span> Have GDPR permissions been obtained? </span> &nbsp; 
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                      {{ this.selectedDocument.gdpr_description }}
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>
      </div>
    </div> 

     <!-- Transaction Details Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Document Transactions                    
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="transactionDetailsModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li class="nav-item">
                        <a class="nav-link fs-4 fw-bold text-gray-900 cursor-pointer" 
                          :class="{ 'active' : this.activeTransactionTab == 'Add' }" 
                          target="_self"
                          @click="this.activeTransactionTab = 'Add'">
                          Add Transaction
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link fs-4 fw-bold text-gray-900 cursor-pointer" 
                          :class="{ 'active' : this.activeTransactionTab == 'List' }" 
                          target="_self"
                          @click="this.activeTransactionTab = 'List'">
                          All Transactions
                        </a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane fade" id="add_transaction_tab" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'Add' }">
                        <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                            <div class="card-body py-1">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3">
                                            <span>Status</span>
                                        </label>
                                        <Select2 v-model="transactionForm.status" :options="statusList" :settings="{ width: '100%', dropdownParent: '#transaction_details_modal .modal-content' }"/>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3">
                                            <span>Description</span>
                                        </label>
                                        <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card card-flush mt-4">
                                            <div class="d-grid gap-2">
                                                <button type="button" class="btn btn-success btn-lg" @click="this.saveTransactionData()" :disabled="this.transactionForm.status == -1">
                                                    <span class="indicator-label">Save</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="all_transactions_tab" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'List' }">
                          <div> 
                              <!-- <div class="row">
                                <div class="col-lg-12">
                                  <div class="text-end"> 
                                      <button class="btn btn-secondary btn-sm mb-4" style="font-size:11px !important;" data-bs-toggle="modal" data-bs-target="#transaction_add_modal">
                                          Add Transaction
                                      </button>
                                    </div>
                                </div> 
                              </div>   -->
                              <div>
                                  <div class="table-responsive"> 
                                    <DxDataGrid :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="transactions" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true"> 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxSearchPanel :visible="true"/>  
                                       
                                        <DxColumn data-field="created_at" caption="Created At" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="created_at-cell-template"/> 
                                        <DxColumn data-field="created_by_name" caption="Created By" cell-template="created_by_name-cell-template"/> 
                                        <DxColumn data-field="last_transaction_date" caption="Last Transaction Date" alignment="right" format="dd.MM.yyyy" cell-template="last_transaction_date-cell-template"/> 
                                        <DxColumn data-field="last_transaction_by_name" caption="Last Transaction By" cell-template="last_transaction_by_name-cell-template"/>
                                        <DxColumn data-field="transaction_source" caption="Transaction Source" cell-template="transaction_source-cell-template"/>
                                        <DxColumn data-field="status" caption="Status" cell-template="status-cell-template" alignment="left"/>
                                        <DxColumn data-field="download_reason" caption="Download Reason"/> 
                                        <DxColumn data-field="actions" caption="..." cell-template="actions-cell-template" width="84" alignment="center"/>

                                        <template #created_at-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ previewDate(data.data.created_at) }}
                                          </span>
                                        </template>
                                        <template #created_by_name-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.created_by_name }}
                                          </span>
                                        </template>
                                        <template #last_transaction_date-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ previewDate(data.data.last_transaction_date) }}
                                          </span>
                                        </template>
                                        <template #last_transaction_by_name-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.last_transaction_by_name }}
                                          </span>
                                        </template>
                                        <template #transaction_source-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.transaction_source }}
                                          </span>
                                        </template>
                                        <template #status-cell-template="{ data: data }">
                                          <div class="align-middle">
                                                <span class="badge badge-sm badge-success" v-if="data.data.status == 1">File Uploaded</span>
                                                <span class="badge badge-sm badge-info" v-if="data.data.status == 2">File Downloaded</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 3">File Viewed</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 4">File Forwarded</span>
                                                <span class="badge badge-sm badge-info" v-if="data.data.status == 5">Submitted for Internal Confirmation</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 6">Submitted for External Confirmation</span>
                                                <span class="badge badge-sm badge-success" v-if="data.data.status == 7">Confirmed</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 8">Rejected</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 9">Revision Requested</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 10">Description Requested</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 11">Desription Added</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 12">Deletion Requested</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 13">File Deleted</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 14">Description Input</span>
                                          </div>
                                        </template>
                                        <template #actions-cell-template="{ data: data }">
                                          <div class="align-middle">
                                              <div class="d-flex justify-content-end flex-shrink-0">
                                                    <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" style="height: 30px; width: 30px;" @click="onEditTransactionData(data.data, selectedDocument)" data-bs-toggle="modal" data-bs-target="#transaction_edit_modal">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg>
                                                        </span>
                                                    </button>
                                                    <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" style="height: 30px; width: 30px;" @click="onDeleteTransactionData(data.data, selectedDocument)">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg>
                                                        </span>
                                                    </button>
                                                </div>
                                          </div>
                                        </template> 
                                        
                                      </DxDataGrid> 
                                    </div>
                              </div>
                      </div>
                    </div>
                </div> 
              </div>
          </div>
      </div>
    </div> 

    <!-- Download Modal -->
    <div class="modal fade" tabindex="-1" id="file_download_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10" v-if="this.requestedFile">
                      Download "{{ this.requestedFile.name }}"
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="downloadModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white"> 
                <div class="row"> 
                  <div class="col-lg-12">
                      <label class="fw-bold form-label mt-3">
                          <span>Download Reason</span>
                      </label>
                      <textarea rows="2" class="form-control " name="description" v-model="downloadForm.download_reason"></textarea>
                  </div> 
              </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary w-100" @click="onDownloadFile(this.requestedFile)"> Download </button>
              </div>
          </div>
      </div>
    </div> 
    
  </div> 
</template> 
<style setup>
.color-input .box {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}
#dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #66BB6A;
  padding: 10px;
}

.passive-dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #E57373;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dx-fileuploader-content > .dx-fileuploader-upload-button {
  width: 100% !important;
  background-color: #009ef7;
  color: white;
  padding: 4px;
  font-weight: 600;
}
.dx-fileuploader-content > .dx-fileuploader-upload-button:hover {
  width: 100% !important;
  background-color: #f1faff;
  color: #009ef7;
  padding: 4px;
  font-weight: 600;
}
</style>
<script>
// Component Alias: `DSHF001`  
import axios from 'axios';
import moment from 'moment';
import icons from '../../../assets/icons/icons.json';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
  


export default {
  name: "Documents",
  components:{
    DxFileUploader, DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return { 
      selectedCompany: this.$getSessionStorage('selected_company'),
      activeMainView: 'all_documents',
      activeTransactionTab: 'Add',
      collapsedFolder: null,
      downloadForm: {
        download_reason: null
      },
      requestedFile: null,
      isFirstLaunch: true,
      documentTypeCriteriaList: [],
      filterForm: {
          document_type_id: '-1',
          start_date: moment().subtract(10, 'days'),
          end_date: new Date(),
      },
      userData: this.$getUser(),
      lists: [],
      searchbar: '',
      folderSearch: '',
      isListProgressing: false, 
      icons: icons,
      form: {
        name: null,
        description: null,
        icon: 'fas fa-folder',
        color: '#009ef7',
        size_limit: 1048576,
        blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
        allowed_ext: null
      },
      uploadForm: {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      },
      isViewIconModal: false,
      parentFolders: [],
      activeFolder: null,
      isPreparementsReady: false,
      documentTypeslist: [],
      importanceList: [ 
        { id: 0, text: 'Normal' },
        { id: 1, text: 'Low' },
        { id: 2, text: 'High' },
        { id: 3, text: 'Very High' }
      ],
      documentSourceList: [
        { id: -1, text: 'Select an option' },
        { id: 0, text: 'Incoming Document' },
        { id: 1, text: 'Outgoing Document' }
      ], 
      havePersonelDataList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      gpdrObtainList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      documentTypesForUpload: [],
      documents: [],
      transactions: [],
      transactionForm: {
        status: -1,
        last_transaction_description: null
      },
      statusList: [
        { id: -1, text: 'Select an option'},
        { id: 14, text: 'Description Input'}
      ],
      selectedDocument: null
    };
  },
  computed: { 
    convertStringToISO(){
      return (value)=>{
       if (value) {
          value = value.replace(/ı/g, 'i');
          value = value.replace(/İ/g, 'I');
          value = value.replace(/ş/g, 's');
          value = value.replace(/Ş/g, 'S');
          value = value.replace(/ç/g, 'c');
          value = value.replace(/Ç/g, 'C');
          value = value.replace(/ö/g, 'o');
          value = value.replace(/Ö/g, 'O');
          value = value.replace(/ü/g, 'u');
          value = value.replace(/Ü/g, 'U');
          value = value.replace(/ğ/g, 'g');
          value = value.replace(/Ğ/g, 'G');
          return value;
        }
      }
    },
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    searchableFoldersList() {
        return this.lists.filter(p => { 
            return p.name.toLowerCase().indexOf(this.folderSearch.toLowerCase()) != -1;
        });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    calculateSize(){
      return (bytes)=>{
            if (bytes) 
            {
                const decimals = 2;
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
            }
        } 
    },
    getDocumentTypeID(){
      return (value)=>{
       if (value) {
          if(value == -1){
            return null;
          }
          else{
            var transactionID = null;
            this.documentTypesForUpload.forEach((item) => {
              if(item.id == value){
                transactionID = item.document_type_id;
              }
            });

            return transactionID;
          }
        }
      }  
    }
  },
  methods: {
    getData() { 
       this.isFirstLaunch = false;
       this.selectedDocument = null;
       this.isPreparementsReady = true;
       this.folderSearch = '';
       this.parentFolders = [];
       this.activeFolder = null;
       this.documents = [];
       this.requestedFile = null;
       const parameters = { 
         session_id: this.$getUser().session_id
       }; 
       axios.post('/api/Folders/GetFolders', parameters, {'Content-Type': 'application/json'})
         .then((response) => {
            response.data.forEach((item) => { 
              item.isOpenActions = false;
            });
            this.lists = response.data;
            this.form = {
              name: null,
              description: null,
              icon: 'fas fa-folder',
              color: '#009ef7',
              size_limit: 1048576,
              blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
              allowed_ext: null
            };
            this.isPreparementsReady = false;

            if(!this.lists.length > 0){
                this.isFirstLaunch = true;
            }

            this.collapsedFolder = null;
          }) 
         .catch(function (error) {
             console.log(error);
             this.Progress.finish();
         });          
    }, 
    onIconSelection(item){
      this.form.icon = item;  
    }, 
    onCreateFolder(){ 
      
      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        parent: parent == null ? null : parent.id,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/CreateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been created successfully..!", 'success');   
              this.$refs.closeAddModal.click(); 
              if(parent == null){
                this.getData();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    onUpdateFolder(){ 

      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        id: this.form.id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/UpdateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been saved successfully..!", 'success');  
              this.$refs.closeUpdateModal.click(); 
              if(parent == null){
                this.getData();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    openItemActionMenu(item){
      if(item.isOpenActions){
        item.isOpenActions = !item.isOpenActions;
      }
      else{
        this.lists.forEach((listItem) => { listItem.isOpenActions = false; });
        item.isOpenActions = !item.isOpenActions;
      }
      
    },
    openEditModal(item){
      const parameters = { 
        session_id: this.$getUser().session_id,
        id: item.id
      }; 
      axios.post('/api/Folders/GetFolderById', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
            this.form = {
              id: response.data.id,
              name: response.data.name,
              description: response.data.description,
              icon: response.data.icon,
              color: response.data.color,
              size_limit: response.data.size_limit,
              blocked_ext: response.data.blocked_ext,
              allowed_ext: response.data.allowed_ext
            };
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    deleteFolder(item) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this data?",
          confirmButtonColor: "#E53935",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          icon: 'warning'
        })
        .then((result) => {
          if (result.isConfirmed) {

                var parent = null;
                if(this.parentFolders.length > 0){
                  parent = this.parentFolders[this.parentFolders.length - 1];
                }
              
                const parameters = { 
                  session_id: this.$getUser().session_id,
                  id: item.id
                }; 
                axios.post('/api/Folders/DeleteFolder', parameters, {'Content-Type': 'application/json'})
                  .then((response) => {
                       if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            this.$swal("Deleted.", "Folder has been deleted..!", 'warning');  
                            if(parent == null){
                              this.getData();
                            }
                            else{
                              this.onOpenFolder(parent);
                            }
                       }
                  }) 
                  .catch(function (error) {
                      console.log(error);
                      this.Progress.finish();
                  });          
          }
        });
    },
    onOpenFolder(item, UseParentID){  
      if(item == null || item == '' || item == undefined){
        this.getData();
        return;
      }
      this.selectedDocument = null;
      this.collapsedFolder = null;
      this.requestedFile = null;
      this.isPreparementsReady = false;
      this.folderSearch = '';
      if(UseParentID){  
        if(item.parent == null || item.parent == ''){
            this.getData();
            return;
        }

        this.parentFolders.forEach((folderItem) => { 
          if(folderItem.id == item.parent){
             item = folderItem;
          }
        });
      }
      this.lists = [];
      const parameters = { 
        session_id: this.$getUser().session_id,
        parent: item.id
      }; 
      axios.post('/api/Folders/GetSubFolders', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              response.data.forEach((dataItem) => { 
                dataItem.isOpenActions = false;
              });
              this.lists = response.data;
              this.form = {
                name: null,
                description: null,
                icon: 'fas fa-folder',
                color: '#009ef7',
                size_limit: 1048576,
                blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
                allowed_ext: null
              };
 
              this.activeFolder = item;
              this.isPreparementsReady = true;
              this.onFeedBreadCrumbs(item);
              this.getFolderDocuments();
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onFeedBreadCrumbs(item){
      var isExists = false;
      this.parentFolders.forEach((parentItem, index) => { 
        if(parentItem.id == item.id){
          isExists = true;
          this.parentFolders.splice(index + 1, this.parentFolders.length);
        }
      });
      if(!isExists){
        this.parentFolders.push(item);
      }
    },
    getDocumentTypes(folder){
      this.collapsedFolder = folder;
      this.documentTypeslist = [];
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypeslist = response.data;
            //this.documentTypeslist.unshift({ id: null, code: '-', name: 'Select None'});
            this.documentTypeslist.forEach((item) => { 
              item['folder_id'] = folder.id;
            });

            const parameters = { 
              session_id: this.$getUser().session_id,
              folder_id: folder.id
            }; 
            axios.post('/api/Folders/GetDocumentTypesByFolderID', parameters, {'Content-Type': 'application/json'})
              .then((folderTypeResponse) => {
                this.documentTypeslist.forEach((typeItem) => { 
                    folderTypeResponse.data.forEach((folderTypeItem) => { 
                        if(typeItem.id == folderTypeItem.document_type_id){
                          typeItem['isAdded'] = true;
                        }
                    });
                }); 
               }) 
              .catch(function (error) {
                  console.log(error);
                  this.Progress.finish();
              });          
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onDocumentTypeSave(typeSelection){
        const parameters = { 
          session_id: this.$getUser().session_id,
          folder_id: typeSelection.folder_id,
          document_type_id: typeSelection.id
        }; 
        axios.post('/api/Folders/UpdateDocumentType', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.getDocumentTypes(this.collapsedFolder);
                //this.$refs.closeDocumentTypeModal.click(); 
            }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
        }); 
    },
    getDocumentTypesForUpload(){
      this.documentTypesForUpload = [];
      this.uploadForm =  {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      };
      
      const parameters = { 
        session_id: this.$getUser().session_id,
        folder_id: this.activeFolder.id
      }; 
      axios.post('/api/Folders/GetDocumentTypesByFolderID', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypesForUpload = response.data;
            this.documentTypesForUpload.unshift({ id: -1, text: 'Select an option'}); 

            this.$refs.fileUploader.instance.reset();
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onPersonalDataChange(){
        this.uploadForm.have_gdpr_permission = 0;
        this.uploadForm.gdpr_description = null;
    },
    getDocumentTypesList(){
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypeCriteriaList = response.data;
            this.documentTypeCriteriaList.unshift({ id: '-1', text: 'All'});
            this.filterForm['document_type_id'] = '-1';
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    getUploadHeaders(){
        return { 
            sid: this.$getUser().session_id,
            cid: this.selectedCompany.id,
            ctd: this.selectedCompany.controller_id,
            fid: this.activeFolder.id,
            dos: this.uploadForm.document_source == -1 ? null : this.uploadForm.document_source,
            ups: 0, /* Upload Source */
            dti: this.getDocumentTypeID(this.uploadForm.document_type_id),
            hgp: this.uploadForm.have_gdpr_permission,
            hpd: this.uploadForm.have_personal_data,
            imp: this.uploadForm.importance 
        };
    },
    getFolderDocuments(){ 
        this.selectedDocument = null;
        this.requestedFile = null;
        this.documents = [];
        if(this.activeFolder == null){
            this.getViewAllFolderDocuments();
            return;
        }
        const parameters = { 
          session_id: this.$getUser().session_id,
          folder_id: this.activeFolder.id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD')
        }; 
        axios.post('/api/Folders/GetDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    getViewAllFolderDocuments(){ 
        this.selectedDocument = null;
        this.requestedFile = null;
        this.documents = [];
        const parameters = { 
          session_id: this.$getUser().session_id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD'),
          document_type_id: this.filterForm.document_type_id == '-1' ? null : this.filterForm.document_type_id,
          company_id: this.selectedCompany.id,
          controller_id: this.selectedCompany.controller_id
        }; 
        axios.post('/api/Folders/ViewAllDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    getDocumentsByFilter(){ 
        this.selectedDocument = null;
        this.documents = [];
        const parameters = { 
          session_id: this.$getUser().session_id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD')
        }; 
        axios.post('/api/Folders/GetAllDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    }, 
    onUploadCompleted(e){
        const parameters = { 
          session_id: this.$getUser().session_id,
          fid: e.request.responseText,
          source_company: this.uploadForm.source_company,
          source_person: this.uploadForm.source_person,
          document_name: this.uploadForm.document_name,
          document_no: this.uploadForm.document_no,
          description: this.uploadForm.description,
          gdpr_description: this.uploadForm.gdpr_description
        }; 
        axios.post('/api/Folders/UpdateDocumentDetails', parameters, {'Content-Type': 'application/json'})
          .then(() => {
                this.getFolderDocuments();
                this.$swal("Success.", "File uploaded successfully..!", 'success');  
                this.$refs.closeUploadModal.click(); 
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });    
    },
    onDownloadFile(item){
      const config = { 
            'Content-type': 'application/json',
            responseType: 'blob',
            headers: {
                sid: this.$getUser().session_id,
                cid: this.selectedCompany.id,
                ctd: this.selectedCompany.controller_id,
                fid: item.folder_id
            }
      }; 
      const parameters = {
          name: item.name,
          fid: item.fid,
          id: item.id,
          rsn: this.downloadForm.download_reason
      };
      axios.post('/api/Folders/Download', parameters, config)
          .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', parameters.name);
                  document.body.appendChild(link);
                  link.click();
                  this.onOpenAccordion(item);
                  this.$refs.downloadModal.click();
                  this.downloadForm = {
                    download_reason: null
                  };
              }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          }); 
    },
    onDeleteDocument(item){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id,
                      fnm: item.name,
                      fid: item.fid,
                      cid: this.selectedCompany.id,
                      ctd: this.selectedCompany.controller_id
                  }; 
                  axios.post('/api/Folders/DeleteDocument', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                              //this.getFolderDocuments();
                              this.getViewAllFolderDocuments();
                              this.$refs.documentDetailsModal.click();
                          }
                      }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
             }
        });   
    },
    onOpenDetail(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.selectedDocument = item;

      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null,
        last_transaction_description: 'File details viewed.',
        status: 3,
        transaction_source: 'AUTO TRANSACTION',
        download_reason: null,
        company_id: this.selectedCompany.id,
        controller_id: this.selectedCompany.controller_id
      }; 
      axios.post('/api/Folders/SetCustomTransaction', parameters, {'Content-Type': 'application/json'})
        .then(() => {

         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onOpenAccordion(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.transactions = [];
      this.selectedDocument = item;
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null
      }; 
      axios.post('/api/TransactionsDocument/GetTransactionsDocumentBy', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.transactions = response.data;
            this.transactionForm = {
              status: -1,
              last_transaction_description: null
            };
            this.activeTransactionTab = 'Add';
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    saveTransactionData(){
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/CreateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been saved successfully..!", 'success');  
                  this.$refs.closeTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onDeleteTransactionData(item, documentItem){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id
                  }; 
                  axios.post('/api/Folders/DeleteTransaction', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                              this.onOpenAccordion(documentItem);
                          }
                      }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
             }
        });   
    },
    onEditTransactionData(item){ 
      this.transactionForm = {
          status: item.status == null || item.status == 0 ? -1 : item.status,
          last_transaction_description: item.last_transaction_description,
          id: item.id
      };
      if(item.status == 14) { this.transactionForm.status_text = 'Description Input' }
      if(item.status == 1) { this.transactionForm.status_text = 'Upload'; }
      if(item.status == 2) { this.transactionForm.status_text = 'Download'; }
      if(item.status == 3) { this.transactionForm.status_text = 'Viewed'; }
      if(item.status == 4) { this.transactionForm.status_text = 'Forwarded'; }
      if(item.status == 5) { this.transactionForm.status_text = 'Submitted for Internal Confirmation'; }
      if(item.status == 6) { this.transactionForm.status_text = 'Submitted for External Confirmation'; }
      if(item.status == 7) { this.transactionForm.status_text = 'Confirmed'; }
      if(item.status == 8) { this.transactionForm.status_text = 'Rejected'; }
      if(item.status == 9) { this.transactionForm.status_text = 'Revision Requested'; }
      if(item.status == 10) { this.transactionForm.status_text = 'Description Requested'; }
      if(item.status == 11) { this.transactionForm.status_text = 'Desription Added'; }
      if(item.status == 12) { this.transactionForm.status_text = 'Deletion Requested'; }
      if(item.status == 13) { this.transactionForm.status_text = 'Deleted'; }
    },
    updateTransactionData(){
      const parameters = { 
        id: this.transactionForm.id,
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/UpdateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been updated successfully..!", 'success');  
                  this.$refs.closeEditTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    }, 
    onChangeDateCriteria(type) {

      // Today 0
      // Yesterday 1
      // Past 3 
      // Past 10 
      // Past 30 

        this.filterForm.start_date = moment(); /* Today */
        this.filterForm.end_date = moment(); /* Today */

        if(type == 1) { 
          this.filterForm.start_date = moment().subtract(1, 'days');
          this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 3) {  
          this.filterForm.start_date = moment().subtract(3, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 10) { 
          this.filterForm.start_date = moment().subtract(10, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 30) { 
          this.filterForm.start_date = moment().subtract(30, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        } 

        this.getFolderDocuments();
    },
    resetFileManager() {
        this.isFirstLaunch = true;
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date()
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.parentFolders = [];
        this.activeFolder = null;
        this.documents = [];
        this.transactions = [];
        this.lists = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
    },
    setCriteriasToDefault() {
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date(),
            document_type_id: '-1'
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.transactions = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
        this.documents = [];
    },
    onRequestFile(item){
      this.requestedFile = item;
      this.downloadForm = {
        download_reason: null
      };
    },
    onRowPrepared(e){
        if(e.rowType === 'data') {
                if (e.data.is_deleted == '1') {
                    e.rowElement.classList.add('bg-file-deleted');  
                }
        } 
    }, 
    changeMainActiveView(value){
      this.activeMainView = value;
      this.getData();
      if(value == 'all_documents'){
        this.getDocumentTypesList();
      } 
    }
  },
  created() {
      this.activeMainView = 'all_documents';
      this.getData();
      this.resetFileManager();
      this.getDocumentTypesList();
  }
}; 
</script>