<template> 
  <div> 
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Profit & Loss Operations</h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
            </li>  
            <li class="breadcrumb-item text-primary">Accounting</li> 
            <li class="breadcrumb-item text-primary">Accounting Transaction</li>
            <li class="breadcrumb-item text-gray-600">Profit & Loss Operations</li>    
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">  
        <div class="row"> 
            <div class="col-lg-12">
                <div class="card mb-6">
                    <div class="card-header border-0 pt-6">
                        <div class="card-title">
                        </div>
                        <div class="card-toolbar">
                            <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> Reset All Criterias </span>
                                    </template>
                                </Popper>
                                <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <span v-if="!this.isListProgressing"> List Balances </span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                                </button>                        
                            </div>
                            <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                <div class="fw-bolder me-5">
                                <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                                <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row p-3">
                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2 bg-light">
                                <div class="fv-row mb-7 row">    
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>Accounting Period</span> 
                                        </label>   
                                        <Select2 v-model="form.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event)" :disabled="this.lists.length > 0"/>  
                                    </div> 
                                     <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>Balance Type</span>
                                        </label>
                                        <Select2 v-model="form.balance_type" :options="balanceTypeList" :settings="{width: '100%'}" :disabled="this.lists.length > 0"/>
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>Balance Date</span>
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.balance_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" @update:modelValue="onChangeBalanceDate()" :disabled="this.lists.length > 0"></Datepicker>
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            Transaction Type
                                        </label>
                                        <Select2 v-model="form.transaction_type" :options="transactionTypeList" @select="onChangeTransactionType()" :settings="{width: '100%'}" :disabled="this.lists.length > 0"/>
                                    </div> 
                                      <div class="col-lg">
                                        <!-- Accounting plansdaki Income vs seçili olan hesaplar gelecek -->
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            <span v-if="form.transaction_type == -1"> Account Plan</span> 
                                            <span v-if="form.transaction_type == 1"> Income Close Plan</span> 
                                            <span v-if="form.transaction_type == 2"> Expense Close Plan</span> 
                                            <span v-if="form.transaction_type == 3"> Cost Close Plan</span> 
                                            <span v-if="form.transaction_type == 4"> Other Income Close Plan</span>
                                            <span v-if="form.transaction_type == 5"> Other Expense Close Plan</span>
                                            <span v-if="form.transaction_type == 0"> All Plans</span>
                                        </label>
                                        <Select2 v-model="form.target_account_id" :options="targetAccountsList" :settings="{width: '100%'}" :disabled="this.lists.length > 0"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div>
                                <!-- Tercih edilen Balance Type'a göre hesap bakiyeleri gelecek -->
                                <!-- Comparative Balance General görüntüsü gelecek . Level 1'ler olmayacak. Background renkleri aynı olack (Accounting Transaction gibi) --> 
                                <!-- Kayıt Kuralı: Balance = 0 olacak -->

                                <!-- Opening için geçerli -->
                                <!-- Kapanıştan alıyorsa -> Önceki dönemde kapatılan kayıtları bul, onları aç. Hep ters kayıt yapılır. Balance debit ise, credit kaydı yapılacak. Sıfırlanması için -->
                                <!-- Accounting Balances -> gördüğü bakiyenin aynısını kaydedecek. Paralel kayıt  -->

                                <!-- Closing -->
                                <!-- Her zaman ters kayıt yapılır -->

                                <!-- Önceki dönemdeki muhasebe planı bu dönemde yoksa kırmızı ile işaretlenecek ve editlenebilecek (Yeni plan kodunu seçeceğiz listeden) -->
                            
                                <DxDataGrid  
                                        :show-borders="true" 
                                        :data-source="lists" 
                                        :allow-column-reordering="true"  
                                        :allow-column-resizing="true" 
                                        :column-auto-width="true" 
                                        :row-alternatin-enabled="true"
                                        :hover-state-enabled="true" 
                                        :show-row-lines="true" 
                                        @exporting="this.$onExporting($event , 'Profit-And-Loss-Operations')"> 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxGroupPanel :visible="true"/>      
                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="1000"/>
                                        <DxSearchPanel :visible="true"/>  
                                        <DxScrolling
                                            mode="virtual"
                                            row-rendering-mode="virtual"
                                            
                                        />
                                        <DxColumn data-field="l2_account_code" caption="Account Code" header-cell-template="lt-account-code-header" />
                                        <DxColumn data-field="l2_account_name" caption="Account Name" header-cell-template="lt-account-name-header" />
                                        <DxColumn data-field="account_code" caption="Account Code" header-cell-template="account-code-header" />
                                        <DxColumn data-field="account_name" caption="Account Name" header-cell-template="account-name-header" />
                                        <DxColumn data-field="balance" caption="Balance" alignment="right" />
                                        <DxColumn data-field="debit_credit" caption="Debit / Credit" header-cell-template="debit-credit-header"/>

                                        <template #lt-account-code-header>
                                            <div>Account Code</div><div>(Level 2)</div>
                                        </template>
                                        <template #lt-account-name-header>
                                            <div>Account Name</div><div>(Level 2)</div>
                                        </template>
                                        <template #account-code-header>
                                            <div>Account</div><div>Code</div>
                                        </template>
                                        <template #account-name-header>
                                            <div>Account</div><div>Name</div>
                                        </template>
                                        <template #new-account-code-header>
                                            <div>New</div><div>Account Code</div>
                                        </template>
                                        <template #debit-credit-header>
                                            <div>Debit</div><div>Credit</div>
                                        </template>

                                        <!-- Cell Templates --> 
                                        <!-- <template #lt-account-code-cell-template="{ data }">
                                             {{ data.data.l2_account_code }}
                                        </template> 
                                        <template #lt-account-name-cell-template="{ data }">
                                            {{ data.data.l2_account_name }}
                                        </template> 
                                        <template #account_code-cell-template="{ data }">
                                             {{ data.data.account_code }}
                                        </template> 
                                        <template #account_name-cell-template="{ data }">
                                             {{ data.data.account_name }}
                                        </template> 
                                        <template #balance-cell-template="{ data }">
                                             {{ data.data.balance }}
                                        </template> 
                                        <template #debit_credit-cell-template="{ data }">
                                            {{ data.data.debit_credit }}
                                        </template>  -->
                                </DxDataGrid>                   
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-6">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2">
                                <div class="fv-row mb-7 row">
                                    <div class="col-lg-2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>Voucher Date</span>
                                            <!-- Accounting Periyodun son günü setlenecek bu alana -->
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                        <!-- :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date" -->
                                    </div>
                                    <div class="col-lg-10">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>Description</span>
                                        </label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="discount" v-model="form.description">
                                            <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                <div class="dropdown dropup">
                                                    <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                        <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                    </button>
                                                    <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                        <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                            <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                    :class="{'text-success': this.form.description == item.description && item.description !== ''}">
                                                                    <i class="bi bi-dash fw-boldest" v-if="this.form.description !== item.description"> </i> 
                                                                    <i class="bi bi-check-lg fw-boldest text-success" v-if="this.form.description == item.description && item.description !== ''"> </i>
                                                                    {{item.text}}  
                                                                </a>
                                                                <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-6">
                    <div class="card-body">
                        <div class="d-grid gap-2"> 
                            <button class="btn btn-success btn-lg" type="button" @click="saveProfitAndLossVoucher()" data-kt-indicator="on" :disabled="!this.lists.length > 0"> 
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span>  
                                <!-- Kayıttan sonra ekranı temizleyip mesaj verip alanları sıfırlayacağız. (İşlem tamamlandı)-->
                                <!-- Bulduğu kayıtlara ters kayıt yapacak. Debit ise Credit -- > Credit ise Debit olacak -->
                                <strong v-if="!this.isDocketSaving"> Create Profit & Loss Voucher </strong> 
                                <span class="indicator-progress" v-if="this.isDocketSaving">
                                    Saving Transaction. Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
  </div>  
</template>

<script>
import axios from 'axios';
import moment from 'moment';
  

import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "ProfitAndLossOperationsList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel, DxScrolling
    },
	data() {
        return {
            searchbar: '',
            isOpenDescriptionDropdown: false,
            isListProgressing: false,
            isOpenActionsDropdown: false,      
            page: 1,
            page_size: 10,
            summary: {},
            lists: [],
            accountingYearList: [],
            accountingYearSelection: null,
            accountingYears: [],
            form:  {
                balance_date: moment(), 
                record_date: moment(),
                period_id: null,
                periodSelection: {},
                transaction_type: -1,
                balance_type: 1,
                target_account_id: '-1'
            },
            targetAccountsList: [{ id: '-1', text: 'Select an option'}],
            balanceTypeList: [
                { id: 1, text: 'Accounting Balances'}
            ],
            transactionTypeList: [
                { id: -1, text: 'Select an option'},
                { id: 1, text: 'Income Closing'},
                { id: 2, text: 'Expense Closing'},
                { id: 3, text: 'Cost Closing'},
                { id: 4, text: 'Other Income Closing'},
                { id: 5, text: 'Other Expense Closing'},
                { id: 0, text: 'ALL'},
            ],
            isAccountingYearLoading: false,
            descriptionTypeList: []
        };
  },
  computed: {  
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewOnlyDate(){
        return (value)=>{
        if (value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
            }
        }
    },
    getReportTypeValue(){
        return (value)=>{
            if (value) {
                return this.reportTypes.find(p => { 
                    return p.id == value;
                }).text; 
            }
        } 
    },
    getPeriodMonthValue(){
            return (value)=>{
            if (value) {
                var period = this.periodList.find(p => { 
                    return p.id == value && p.id != '-1';
                }); 
                if(period){
                    return period.text;
                }
                else{
                    return null;
                }
            }
        } 
    }
  },
  methods: { 
    getData() {
        if(this.form.target_account_id == '-1' || this.form.transaction_type == '-1'){
            this.$swal("Required Fields", "Transaction Type and Account Plan selection is required..!", 'warning');  
            return;
        }
        this.lists = []; 
        const params = {
            session_id: this.$getUser().session_id,
            balance_date: moment(this.form.balance_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            period_id: this.form.period_id,
            transaction_type: this.form.transaction_type
        };
        axios.post('/api/ProfitAndLoss/GetList', params, {'Content-Type': 'application/json'})
        .then((response) => {  
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.lists = response.data.data; 
            }
            else{
                this.lists = [];
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
        });
    },
    getAccountingYears(){
        this.isAccountingYearLoading = true;
        this.companyAccountingPeriods = [];
        const queryParameter = {
            id: this.$getSessionStorage("selected_company").id,
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
                this.accountingYears = response.data;
                this.accountingYears.forEach((period) => {
                    if(period.id == this.$getSessionStorage("selected_company").activeYear) {
                        this.form.period_id = period.id;
                        this.form['periodSelection'] = period; 
                        this.form.record_date = moment(this.form['periodSelection'].end_date); 
                        this.form.balance_date = moment(this.form['periodSelection'].end_date); 
                    } 
                }); 
                this.getDescriptions();

                // Seçili olan Period eğer 'Aktif' mali dönem ise Record Date bugüne setlenir.
                // Değilse; seçim yapılan Mali dönemin bitiş tarihine setlenir.
                // if((moment().isBetween(moment(this.form['periodSelection'].start_date), moment(this.form['periodSelection'].end_date))) == false){
                //     this.form.start_date = this.form['periodSelection'].start_date;
                //     this.form.end_date = this.form['periodSelection'].end_date;
                // }
                setTimeout(() => {
                    this.isAccountingYearLoading = false;
                }, 1000); 
        })
        .catch(function (error) {
            this.isAccountingYearLoading = false;
            console.log(error);
        }); 
    }, 
    onChangeTransactionType() {
        this.form.target_account_id = '-1';
        if (this.form.transaction_type == -1) {
            this.targetAccountsList = [{ id: '-1', text: 'Select an option'}]; 
            this.getDescriptions();
            return;
        }
        const queryParameter = {
            session_id: this.$getUser().session_id,
            accounting_period_id: this.form.period_id,
            list_type: 17,
            list_account_type: this.form.transaction_type
        };  
        axios.post('/api/VatOperations/GetAccountingPlanByParameter', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {
            this.targetAccountsList = response.data;
            this.targetAccountsList.forEach((item) => {
                item.text = item.final_code + ' - ' + item.name;
            });
            this.targetAccountsList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});
            this.getDescriptions();
        });

    },
    onChangeAccountYear(e){
        this.form['periodSelection'] = e;
        this.form.record_date = this.form['periodSelection'].end_date; 
        this.form.balance_date = this.form['periodSelection'].end_date; 
    },
    setCriteriasToDefault() {   
        this.form = {
            balance_date: moment(), 
            period_id: null,
            periodSelection: {},
            transaction_type: -1,
            balance_type: 1,
            target_account_id: '-1',
            record_date: moment(),
        };
        this.form.record_date = moment(this.form['periodSelection'].end_date); 
        this.form.balance_date = moment(this.form['periodSelection'].end_date); 
        this.lists = [];
        this.getAccountingYears();
    },
    getDescriptions() {
        this.isOpenDescriptionDropdown = false;
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 16, // Profit & Loss Operations
            location_detail: this.form.transaction_type,
            account_type: 0,
            sales_type: 0
        };
        axios.post('/api/Closing/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2) {
                    this.form.description = element.description;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
        });
    },
    onSelectDescription(item) {
        this.form.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    saveProfitAndLossVoucher(){ 
        this.$swal.fire({
            title: "You are about to save the Profit and Loss Voucher. Are you sure about this?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.isSaving = true;
                const params = {
                    session_id: this.$getUser().session_id,
                    balance_date: moment(this.form.balance_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    period_id: this.form.period_id,
                    transaction_type: this.form.transaction_type,
                    description: this.form.description,
                    record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    target_account_id: this.form.target_account_id
                };
                axios.post('/api/ProfitAndLoss/Create', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.$swal("Success", "Closing Voucher saved successfully..!", 'success');  
                        this.setCriteriasToDefault();
                    }
                    this.isSaving = false;
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    console.log(error); 
                    this.$Progress.fail();
                });
            }
        }); 
    }, 
    onChangeBalanceDate(){
        this.form.record_date = moment(this.form.balance_date);
    } 
  },
  created() {        
    this.getAccountingYears();
    this.$removeSessionStorage('record_id');
  },
  mounted(){ 
    try{ 
        this.form.start_date = moment().subtract((parseInt(this.$getSessionStorage("selected_company").list_date_range)), 'days');
    }
    catch(Err){ console.error(Err);}
  }
}; 
</script>