<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Mail Setting</h1>
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'MailSettingsList' }">Mail Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600">Create Mail Settings</li> 
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/MailSettingsList">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List </span>
            </button>
        </router-link>
      </div>  
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
      <RequiredField></RequiredField>
      <div class="row">
          <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-12 mt-0"> 
                          <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          Mail Subject
                                        </label>
                                        <Select2 v-model="form.mail_subject" :options="mailSubjectList" :settings="{ width: '100%' }" />  
                                      </div>
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          Sender Mail
                                        </label>
                                        <input type="text" class="form-control" name="name" v-model="form.sender" :class="{'border border-danger': isValidEmail == false}"> 
                                        <small class="text-danger" v-if="!isValidEmail && form.sender !== null && form.sender !== '' ">Email is not valid</small>
                                      </div>
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          Mail Server (Smtp)
                                        </label>
                                        <input type="text" class="form-control" name="Smtp" v-model="form.smtp"> 
                                      </div>
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          Port
                                        </label>
                                        <input type="number" class="form-control" name="port" v-model="form.port">
                                      </div>
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          SSL
                                        </label>
                                        <div class="form-check mt-3 mb-2">
                                            <input class="form-check-input" type="checkbox" value="" id="isSSL" v-model="form.ssl">
                                        </div> 
                                      </div>
                                    </div> 
                                  </div>
                                </div> 
                              </div>
                              <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                  <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                      <div class="card-body">
                                          <div class="row">
                                            <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                Sender Username
                                              </label>
                                              <input type="text" class="form-control" name="name" v-model="form.username"> 
                                            </div>
                                            <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                Sender  Password
                                              </label>
                                              <div class="position-relative">
                                              <input type="password" class="form-control" name="name" v-model="form.password"> 
                                              </div>
                                            </div>
                                          </div> 
                                      </div>
                                  </div> 
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-lg btn-success " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                              <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              </svg>
                              </span>
                              <strong v-if="!this.isProgressing"> Save Mail Settings </strong> 
                              <span class="indicator-progress" v-if="this.isProgressing">
                                  Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span> 
                            </button>
                          </div>               
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>    
  </div>  
</template>

<script>
import axios from 'axios' 


 
export default {
  name: "MailSettingsCreate", 
  data() {
    return {
      lists: [],
      mailSubjectList: [
        { id: 1, text: 'Creating a New Company'},
        { id: 2, text: 'User Account Operations'},
        { id: 3, text: 'Contact Us'},
      ],
      isProgressing: false,
      form: { 
        id: null,
        username: null,
        password: null,
        sender: null,
        smtp: null,
        port:0,
        ssl: false,
        mail_type: 0,
        mail_subject: 1,
      },
      isHide: true
    };
  },
  computed: {
		isValidEmail() {
			return (this.form.sender == null || this.form.sender == '' || this.form.sender == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.sender);
		}
	},
  methods: {
    getData() {      
      this.$setSessionStorage('refreshList', true);
    },
    saveData(){
      this.isProgressing = true;
      const obj = {
        "username": this.form.username,
        "password": this.form.password,
        "sender": this.form.sender,
        "smtp": this.form.smtp,
        "port": this.form.port,
        "ssl": this.form.ssl
      };
      const mailInfo = JSON.stringify(obj);
      const params = {
        session_id:  this.$getUser().session_id,
        data : mailInfo,
        mail_type: 1, /* System */
        mail_subject: this.form.mail_subject,
      };
      const that = this;
      axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){          
          this.$setSessionStorage("record_id", response.data);
          this.$swal("Success", "Data saved succesfully..!", 'success');    
          this.$setSessionStorage('refreshList', true);  
          this.$router.push({ name: 'MailSettingsUpdate' });
        }
      })
      .catch(function (error) {
          console.log(error);
          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function () { 
          setTimeout(() => {
              that.isProgressing = false;
          }, 500);
      });   
    }
  },
  mounted() { 
    this.getData();
  }
}; 
</script>