<template>
  <div>  
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
          Product Groups
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
            </li> 
            <li class="breadcrumb-item text-gray-600">Settings</li>  
            <li class="breadcrumb-item text-gray-600">Controller Settings</li>  
            <li class="breadcrumb-item text-gray-600">List Types</li>    
            <li class="breadcrumb-item text-gray-600">Product Groups</li> 
        </ul> 
    </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                          <span class="svg-icon svg-icon-1 position-absolute ms-6">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                              </svg>
                          </span>
                          <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15 form-control-sm" placeholder="Search Product Groups" v-model="searchbar">
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <router-link to="/ProductGroupCreate">
                                <button type="button" class="btn btn-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>Add</button>
                            </router-link>
                          <button type="button" class="btn btn-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>>
                                <strong v-if="!this.isListProgressing"> List </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-0">
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover">
                        <thead>
                            <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                                <th style="width: 1% !important;">#</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Created At</th>
                          </tr>
                        </thead>
                          <tbody class="fw-bold text-gray-600" v-if="lists"> 
                            <tr v-for="(item, index) in searchableList" v-bind:key="item"  @click="updateType(item.id)" class="cursor-pointer"> 
                                  <td class="text-gray-600">
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                        {{ index +1 }}
                                      </a>
                                  </td>
                                  <td class="text-gray-600 w-100px">
                                       <small> {{ item.code }} </small>
                                  </td>  
                                  <td>
                                      <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateType(item.id)">
                                        {{ item.name }} <br>
                                        <small class="text-italic text-gray-600"> {{ item.description }} </small>
                                      </a>
                                  </td>   
                                  <td class="text-gray-600">
                                    <small> {{ previewDate(item.created_at)}} </small>
                                  </td>  
                              </tr> 
                          </tbody>
                          <tbody v-if="!lists">
                                <tr>
                                    <td colspan="5">
                                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                          <div class="fs-6 text-gray-800 fw-bolder">No results yet.</div>
                                          <div class="fw-bold text-gray-400">Please use "List" button to get the latest data.</div>
                                      </div>
                                    </td>
                                </tr>
                          </tbody>
                      </table>
                    </div>
                  </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `PGL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';

 
export default {
  name: "ProductGroupList",
  data() {
    return {
      lists: [],
      searchbar: '',
      isListProgressing: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 1,
        list_type: 7 // Product Group: 7
      };
      axios.post('/api/Lists/GetLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.lists = response.data; 
          this.$setSessionStorage('data' + '_PGL001', response.data)
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
          console.log(error); 
          this.$setSessionStorage('data' + '_PGL001', null)
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    updateType(id){ 
        this.$setSessionStorage("record_id", id);
        this.$router.push({ name: 'ProductGroupUpdate' });
    }
  },
  created() {
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
        this.lists = [];
    }
  }
}; 
</script>