<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Description Type
              </h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>   
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DescriptionTypeList' }">Description Type</router-link></li> 
                  <li class="breadcrumb-item text-gray-600">Create Description Type</li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/DescriptionTypeList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-12 mt-0"> 
                            <div class="d-flex flex-row row">
                                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Code
                                                    </label>
                                                    <input type="text" class="form-control " name="code"
                                                        v-model="form.code">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Name
                                                    </label>
                                                    <input type="text" class="form-control " name="name"
                                                        v-model="form.name">
                                                </div>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">
                                            <div class="row">      
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Type
                                                    </label>
                                                    <Select2 v-model="form.type_id" :options="descriptionTypeList" :settings="{ width: '100%' }"/>
                                                </div>  
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Description Location
                                                    </label> 
                                                    <Select2 v-model="form.description_location" :options="locationList" @select="this.form.location_detail = 0" :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg-12" v-if="this.locationDetailListByType.length > 1">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Location Detail
                                                    </label> 
                                                    <Select2 v-model="form.location_detail" :options="locationDetailListByType" :settings="{ width: '100%' }"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6" v-if="this.form.description_location == 0 || this.form.description_location == 3 || this.form.description_location == 4 
                                                    || this.form.description_location == 7 || this.form.description_location == 8 || this.form.description_location == 9 || this.form.description_location == 11">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Account Type</span>
                                                    </label> 
                                                    <Select2 v-model="form.account_type" :options="accountTypeList" :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg-6" v-if="this.form.description_location == 1">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Sales Type</span>
                                                    </label> 
                                                    <Select2 v-model="form.sales_type" :options="salesTypeList" :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Reg. Code</span>
                                                    </label> 
                                                    <input type="text" class="form-control " name="name"
                                                        v-model="form.registration_code">
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Control Code</span>
                                                    </label> 
                                                    <input type="text" class="form-control " name="name"
                                                        v-model="form.control_code">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Description</span>
                                                    </label> 
                                                    <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> Save Description Type</strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>                   
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
// Component Alias: `EXTC001`
import axios from 'axios'  
 
// import Swal from 'sweetalert2'; 

 
export default {
  name: "DescriptionTypeCreate",
  data() {
    return {
        descriptionTypeList: [
            { id: 0, text: 'Select an option' },
            { id: 1, text: 'Manuel' },
            { id: 2, text: 'Automatic' }
        ],
        locationList: [
            { id: -1, text: 'Select an option' },
            { id: 0, text: 'ALL' },
            { id: 1, text: 'Invoice'},
            { id: 2, text: 'Fixed Asset'},
            { id: 3, text: 'Cash'},
            { id: 4, text: 'Bank' },
            { id: 5, text: 'Expense' },
            { id: 6, text: 'Cheque' },
            { id: 7, text: 'Offsetting'},
            { id: 8, text: 'Turnover' },
            { id: 9, text: 'Difference' },
            // { id: 10, text: 'Amortization' },
            { id: 11, text: 'VAT' },
            { id: 12, text: 'Cost Of Sales'},
            { id: 13, text: 'Cost Of Production'},
            { id: 14, text: 'Opening Operations'},
            { id: 15, text: 'Closing Operations'},
            { id: 16, text: 'Profit & Loss Operations'},
            { id: 17, text: 'VAT Operations'},
            { id: 18, text: 'Accounting Voucher Operations'},
        ],
        locationDetailList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Sales', type: 1},
            { id: 2, text: 'Purchase', type: 1},
            { id: 3, text: 'Sales Return', type: 1},
            { id: 4, text: 'Purchase Return', type: 1},
            { id: 1, text: 'Incoming', type: 2},
            { id: 2, text: 'Outgoing', type: 2},
            { id: 1, text: 'Debit', type: 3},
            { id: 2, text: 'Credit', type: 3},
            { id: 1, text: 'Income Closing', type: 4},
            { id: 2, text: 'Expense Closing', type: 4},
            { id: 3, text: 'Cost Closing', type: 4},
            { id: 4, text: 'Other Income Closing', type: 4},
            { id: 5, text: 'Other Expense Closing', type: 4},
        ],
        salesTypeList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Domestic'},
            { id: 2, text: 'Overseas'},           
        ],
        accountTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Customer' },
            { id: 2, text: 'Supplier' },
            { id: 3, text: 'Cash' },
            { id: 4, text: 'Bank' },
            { id: 5, text: 'Personnel' },
            { id: 6, text: 'Expense' },
            { id: 7, text: 'Stock' },
            { id: 8, text: 'Fixed Asset' },
            { id: 15, text: 'Other Account' }
        ], 
        isProgressing: false,
        form: { 
            name: null,
            code: null,
            description: null,
            session_id: null,
            type_id: 0,
            description_location: -1,
            location_detail: 0,
            sales_type: 0,
            account_type: 0,
            registration_code: null,
            control_code: null
        }
    };
  },
  computed: {
    locationDetailListByType(){
        if(this.form.description_location == 1){ 
            return this.locationDetailList.filter(p => { 
                return p.type == 1 || p.id == 0;
            });
        } 
        else if(this.form.description_location == 2 || this.form.description_location == 3 || this.form.description_location == 4 || this.form.description_location == 6 || this.form.description_location == 11){ 
            return this.locationDetailList.filter(p => { 
                return p.type == 2 || p.id == 0;
            });
        } 
        else if(this.form.description_location == 8 || this.form.description_location == 9 || this.form.description_location == 7 || this.form.description_location == 18){ 
            return this.locationDetailList.filter(p => { 
                return p.type == 3 || p.id == 0;
            });
        }
        else if(this.form.description_location == 16){ 
            return this.locationDetailList.filter(p => { 
                return p.type == 4 || p.id == 0;
            });
        }
        else {
            return this.locationDetailList.filter(p => { 
                return p.id == 0;
            });
        }
    }
  },
  methods: { 
    getData(){
        this.$setSessionStorage('refreshList', true);  
        const parameters = { 
            session_id: this.$getUser().session_id,
            tip: 19
        }; 
        axios.post('/api/Lists/GetConst', parameters, {'Content-Type': 'application/json'}).then((response) => {
            this.locationList = response.data;
            this.locationList.unshift({id: -1, text: 'Select an option'});
        });  
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;
      params.session_id = this.$getUser().session_id;
      axios.post('/api/Lists/CreateDescriptionType', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'DescriptionTypeUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
         .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>