<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Controller Account
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Controller Account</li> 
          </ul> 
      </div> 
      <div class="d-flex align-items-center py-1">
          <router-link to="/ControllerSettings">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> Return back to Settings</span>
              </button>
          </router-link> 
      </div>
    </div> 
    <div class="content flex-row-fluid mt-5" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <div class="d-flex align-items-center position-relative my-1" v-if="isSystemAdmin">
                        <router-link to="/ControllerAccountCreate">
                            <button type="button" class="btn btn-light-primary border border-primary me-3">
                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                                </span>
                                Add Controller Account
                            </button>
                        </router-link>                        
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                              <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                              <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                              <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                              </svg>
                              </span>
                            <strong v-if="!this.isListProgressing"> List Controller Accounts </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                            data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Controller-Account-List')">
                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="20"/>
                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/> 
                            <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                            />
                                <!-- <DxColumn data-field="id" caption="#" :allow-exporting="false"/>  -->
                                <DxColumn data-field="licence_id" alignment="left" caption="Licence ID"/> 
                                <DxColumn data-field="company_name" caption="Company Name" /> 
                                <DxColumn data-field="company_vkn" caption="Company Account ID" /> 
                                <DxColumn data-field="primary_person" caption="Primary Person" />
                                <DxColumn data-field="company_country_code" caption="Country"/>  
                                <DxColumn data-field="currency_code" caption="Currency"/>  
                                <DxColumn data-field="company_city" caption="City"/>
                                <DxColumn data-field="is_show_on_create_new_company" alignment="center" caption="Show on New Company?"/>
                                <DxColumn data-field="activation_date" caption="Activation Date"/>  

                                <template #dataRowTemplate="{ data: data }"> 
                                    <!-- <td class="text-gray-600"></td> -->
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.licence_id }}
                                        </a>
                                    </td> 
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.company_name }}
                                        </a>
                                    </td>  
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.company_vkn }}
                                        </a>
                                    </td>  
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.primary_person }}
                                        </a>
                                    </td>  
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.company_country_code }}
                                        </a>
                                    </td> 
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.currency_code }}
                                        </a>
                                    </td> 
                                    <td class="text-gray-800">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ data.data.company_city }}
                                        </a>
                                    </td>
                                    <td class="text-gray-800 text-center">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            <span v-if="data.data.is_show_on_create_new_company == 1">
                                                <i class="las la-check fs-2x text-success"></i>
                                            </span>  
                                            <span v-if="data.data.is_show_on_create_new_company == 0">
                                              <i class="las la-times fs-2x text-danger"></i>
                                          </span> 
                                        </a>
                                    </td>
                                    <td class="text-gray-800 text-end">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                            {{ previewDate(data.data.activation_date) }}
                                        </a>
                                    </td>
                                </template>
                        </DxDataGrid>     
                  </div>
                </div> 
            </div>
        </div> 
    </div>
  </div>
</template>


<script> 
// Component Alias: `COA001`
import axios from 'axios'
// import Swal from 'sweetalert2';
import moment from 'moment'; 

 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "ControllerAccountList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
  data() {
    return {    
      page: 1,
      page_size: 10,
      lists: {},     
      isListProgressing: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    isSystemAdmin() {
        return this.$getUser().permission_type == 1 && this.$getUser().account_type == 1;
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.isListProgressing = true;
      this.lists = [];
      const params = {
        session_id: this.$getUser().session_id
      }
      axios.post("/api/Accounts/ControllerAccount", params, {'Content-Type': 'application/json'}).then((response) => {
        this.lists = response.data;          
        this.lists.forEach(element => {
            element.company_name = this.$unlock(element.company_name);
            element.text = this.$unlock(element.text);
            element.company_vkn = this.$unlock(element.company_vkn);
            element.primary_person = this.$unlock(element.primary_person);
            element.company_gsm = this.$unlock(element.company_gsm);
            element.company_landphone = this.$unlock(element.company_landphone);
            element.company_email = this.$unlock(element.company_email);
            element.company_address = this.$unlock(element.company_address);
            element.company_city = this.$unlock(element.company_city);
            element.company_town = this.$unlock(element.company_town);
            element.company_state = this.$unlock(element.company_state);
            element.company_region = this.$unlock(element.company_region);
            element.company_postcode = this.$unlock(element.company_postcode);
            element.company_country_code = this.$unlock(element.company_country_code);
            element.currency_code = this.$unlock(element.currency_code);
            element.phone_country_code = this.$unlock(element.phone_country_code);
        });
        this.$Progress.finish();
        setTimeout(() => {
            this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
          setTimeout(() => {
              this.isListProgressing = false;
          }, 500);
      });
    },
    updateItem(id){ 
      this.$setSessionStorage("record_id", id);
      this.$router.push({ name: 'ControllerAccountUpdate' });
    }
  },
  created() {
    if(this.$getSessionStorage('refreshList')){
      setTimeout(this.getData(), 500);
      this.$removeSessionStorage('refreshList'); 
    }
    else{
            this.lists = [];
    } 
  }
};
</script>