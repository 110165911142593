<template>
    <div>  
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Data Import Templates
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Data Import Templates </li> 
          </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/SystemSettings">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> Return back to Settings </span>
                  </button>
              </router-link>
          </div> 
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <button type="button" class="btn btn-light-primary border border-primary me-3" 
                            @click="onOpenAddModal()" data-bs-toggle="modal" data-bs-target="#kt_modal_add_data_import_template" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg></span>
                            Add Template
                        </button>
                    </div>
                    <div class="card-toolbar">
                        <button type="button" class="btn btn-light-primary border border-primary me-3" @click="onGetTemplates()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List Templates </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">
                            <DxDataGrid :show-borders="true" key-expr="id" :data-source="templateList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                    data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Data-Import-Templates')"> 
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="20"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/> 
                
                                        <DxColumn caption="Actions" header-cell-template="actions-header" width="350" :allow-exporting="false"/>
                                        <DxColumn data-field="name" caption="Name" header-cell-template="name-header"/> 
                                        <DxColumn data-field="category_name" caption="Category" header-cell-template="category_name-header"/>
                                        <DxColumn data-field="file_type_name" caption="File Type" header-cell-template="file_type_id-header"/>
                                        <DxColumn data-field="description" caption="Description" header-cell-template="description-header"/>

                                        <template #actions-header>
                                                <div>Actions</div>
                                        </template>
                                        <template #category_name-header>
                                                <div>Category</div>
                                        </template>
                                        <template #name-header>
                                                <div>Name</div>
                                        </template>
                                        <template #file_type_id-header>
                                                <div>File Type</div>
                                        </template>
                                        <template #description-header>
                                                <div>Description</div>
                                        </template>
                                        

                                        <template #dataRowTemplate="{ data: data }">  
                                            <td class="align-middle text-center">
                                                <button class="btn btn-xs btn-primary me-1" @click="onOpenEdit(data.data.id)">
                                                    <span class="fs-8"><i class="fa fa-edit"></i> Edit</span>
                                                </button>
                                                <button class="btn btn-xs btn-info me-1" @click="onOpenParameters(data.data.id)" :disabled="data.data.file_type_id == 2">
                                                    <span class="fs-8"><i class="fa fa-pen"></i> Parameters</span>
                                                </button>
                                                <button class="btn btn-xs btn-success me-1" @click="onOpenDesigner(data.data.id)" :disabled="data.data.file_type_id !== 2">
                                                    <span class="fs-8"><i class="fa fa-brush"></i> Design</span>
                                                </button>
                                                <button class="btn btn-xs btn-danger me-1" @click="onDeleteDesign(data.data.id)">
                                                    <span class="fs-8"><i class="fa fa-brush"></i> Delete</span>
                                                </button>
                                            </td>
                                            <td class="text-gray-900 align-middle fw-bolder">
                                                {{ data.data.name }}
                                            </td> 
                                            <td class="text-gray-900 align-middle">
                                                <span> {{ data.data.category_name }} </span>
                                            </td> 
                                            <td class="text-gray-900 align-middle">
                                                <span> {{ data.data.file_type_name }} </span>
                                            </td>  
                                            <td class="text-gray-900 align-middle">
                                                {{ data.data.description }}
                                            </td>  
                                        </template> 
                            </DxDataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="kt_modal_add_data_import_template" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            Add Template                     
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form>
                                <div class="row">
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                Name
                                            </label>
                                            <input type="text" class="form-control" placeholder="" name="name"
                                                v-model="form.name">
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                Category
                                            </label>
                                            <Select2 v-model="form.category_id" :options="categoryList"
                                                :settings="{ width: '100%' }" />
                                        </div>  
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                File Type
                                            </label>
                                            <Select2 v-model="form.file_type_id" :options="fileTypeList" :settings="{ width: '100%' }" />
                                        </div>
                                         <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                Clone Template
                                            </label>
                                            <Select2 v-model="form.clone_template" :options="cloneTemplateList"
                                                :settings="{ width: '100%' }" />
                                        </div>
                                        <div class="fv-row">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                            Description
                                            </label>
                                            <textarea class="form-control" name="description" v-model="form.description"></textarea>
                                        </div> 
                                </div>
                                <div class="col-lg-12 mt-7">
                                    <div class="d-grid gap-2">
                                        <button type="button" class="btn btn-lg btn-success " @click="this.onSaveTemplate()" data-kt-indicator="on">
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                        <strong v-if="!this.isProgressing"> Save Template</strong>
                                        <span class="indicator-progress" v-if="this.isProgressing"> Saving Template... <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios';


import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
export default {
    name: "DataImportTemplates",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel
    },
    data() {
        return {
            templateList: [],
            categoryList: [
                { id: 0, text: 'Select an option'},
                { id: 1, text: 'Accounts'},
                { id: 2, text: 'Bank Statement'},
                { id: 5, text: 'Collection'},
                { id: 3, text: 'Insurance Policy'},
                { id: 4, text: 'Invoice'},
            ],
            sourceList: [],
            cloneTemplateList: [{ id: 0, text: 'Select an option' },],
            isHide: true,
            form: { 
                category_id: 0,
                source: 1,
                clone_template: 0,
                description: null,
                file_type_id: 0
            },
            fileTypeList: [
                { id: 0, text: 'Select an option' },
                { id: 1, text: 'Excel File (xlsx)' },
                { id: 4, text: 'Excel File (xls)' },
                { id: 2, text: 'XML File' },
                { id: 3, text: 'CSV File' }
            ],            
            isListProgressing: false,
        };
    },
    methods: {
        onOpenAddModal() {
            this.form = { 
                category_id: 0,
                source: 1,
                clone_template: 0,
                description: null,
                file_type_id: 0
            };
        },
        onSaveTemplate(){
            const params = {
                session_id: this.$getUser().session_id,
                name: this.form.name,
                category_id: this.form.category_id,
                description: this.form.description,
                file_type_id: this.form.file_type_id
            };
            axios.post('api/Office/AddDataImportTemplate', params, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                    this.$swal("Success", "Data Import Template saved succesfully..!", 'success');
                    this.$refs.closeModal.click();
                    this.onGetTemplates();
                }
            })
        },
        onGetTemplates(){
            this.isListProgressing = true;
            const params = {
                session_id: this.$getUser().session_id
            };
            const that = this;
            axios.post('api/Office/GetDataImportTemplates', params, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                   this.templateList = response.data;
                }
            })
            .finally(function() {
                setTimeout(() => {
                    that.isListProgressing = false;
                }, 500);
            });
        },
        onOpenParameters(id){
            this.$setSessionStorage("record_id", id);
            this.$router.push({ name: 'UpdateImportTemplate' });
        },
        onOpenEdit(id){
            this.$setSessionStorage("record_id", id);
            this.$router.push({ name: 'EditImportTemplate' });
        },
        onOpenDesigner(id){
            this.$setSessionStorage("record_id", id);
            this.$router.push({ name: 'DesignImportTemplate' });
        },
        onDeleteDesign(recordId) {
            this.$swal.fire({
                title: "Are you sure you want to delete this record?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        id: recordId
                    };  
                    axios.post('/api/Office/DeleteDataImportTemplate', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.onGetTemplates();
                        }
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
                }
            });
        }
    },
  created() {
    this.onGetTemplates();
  }
}
</script>