<template>
<div class="d-flex flex-column flex-root">
	<div class="d-flex flex-column flex-lg-row flex-column-fluid">
		<div class="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
			<div class="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style="background-image: url(/assets/theme/media/logos/auth-bg.png)">
				<div class="d-flex flex-center py-10 py-lg-20 mt-lg-20">
					<a href="#">
						<img alt="Logo" src="assets/theme/media/logos/logo.png" class="h-100px">
					</a>
				</div>
				<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7"> 
                Reset Password
            </h1>
        </div>
			</div>
		</div>
    
		<div class="d-flex flex-column flex-lg-row-fluid py-10">
			<div class="d-flex flex-center flex-column flex-column-fluid py-10 py-lg-20 mt-lg-20">
				<div class="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
          <form v-if="this.tokenStatus == 0 || (user_mail !== null && user_mail !== undefined && user_mail !== '')" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_new_password_form">
            <div class="text-center mb-10" v-if="user_mail == null || user_mail == undefined || user_mail == ''">
                <h1 class="text-dark fw-bolder mb-3">
                    Hi {{this.userName}}
                </h1>
                <div class="text-gray-500 fw-semibold fs-6"> Enter a new password below to change your password. </div>
            </div>
            <div class="text-center mb-5" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">
                <h1 class="text-dark fw-bolder mb-3">
                    Password expired!
                </h1>
                <div class="text-gray-500 fw-semibold fs-6">
                  Your password has expired and must be changed. You will not be able to access the system until your password is changed.
                  <br>
                  <router-link to="/Login">
                    <a href="#" class="link-primary fw-bold">
                        Back to Login
                    </a>
                  </router-link>
                  <router-link to="/ForgotPassword">
                    <a href="#" class="link-primary fw-bold ms-5">
                        Forgot Password?
                    </a>
                  </router-link>
                </div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">    
              <div class="position-relative"> 
              <input id="ps_password" type="password" placeholder="Old Password" name="old-password" v-model="form.old_password" maxlength="16" autocomplete="off" class="form-control">
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility('ps_password')">
                        <i v-if="old_isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!old_isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
              </span>
              </div>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container">
              <div class="mb-1">
                <div class="position-relative">    
                  <input id="pw_password"  class="form-control" type="password" v-model="form.password" maxlength="16" placeholder="Password" name="password" autocomplete="off"
                  :class="{'border border-success': isPasswordConfirm == true}">
                  <!--begin::Visibility toggle-->
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility('pw_password')">
                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    </span>
                    <!--end::Visibility toggle-->
                    <password-meter class="mb-3" @score="onScore" :password="form.password"/>
                </div>
              </div>              
              <div class="d-flex flex-stack mb-3 ms-1">
                <div class="text-gray-500 fw-semibold">
                  Including 3 of the following:
                </div>
                <div class="text-gray-500 fw-semibold">
                  Must Contain:
                </div>
              </div>
              <div class="d-flex flex-stack mb-3 ms-1">
                <div class="text-muted">
                  <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                  <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                  <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                  <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                </div>
                <div class="text-muted">
                  <span> <i class="fas" :class="form.password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                </div>
              </div>
            </div>
          
            <div class="fv-row mb-8 fv-plugins-icon-container">    
              <input type="password" placeholder="Repeat Password" name="confirm-password" v-model="form.password_confirm" maxlength="16" autocomplete="off" class="form-control" 
               @paste.prevent :class="{'border border-success': isPasswordConfirm == true, 'border border-danger': isPasswordConfirm == false}"> 
                <small class="text-danger" v-if="isPasswordConfirm == false">
                  The password and its confirm are not the same
                </small>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="d-grid mb-10" v-if="user_mail == null || user_mail == undefined || user_mail == ''">
              <button type="button" id="kt_new_password_submit" class="btn btn-primary" 
                @click="onBeforeSubmit('reset')" :disabled="passwordScore < 3 || isPasswordConfirm !== true">
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress">
                  Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
            <div class="d-grid mb-10" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">
              <button type="button" id="kt_new_password_submit" class="btn btn-primary" 
                @click="onBeforeSubmit('change')" :disabled="passwordScore < 3 || isPasswordConfirm !== true || (this.form.old_password == '' || this.form.old_password == null)">
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress">
                  Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
          <div class="text-center" v-if="this.tokenStatus > 0 && (user_mail == null || user_mail == undefined || user_mail == '')">
            <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
              <span v-if="tokenStatus == 1">URL is invalid!</span>  
              <span v-if="tokenStatus == 2">Token expired!</span>  
            </h1>
            <div class="fw-semibold fs-6 text-gray-500 mb-8">
              To send a new link, go to the
              <router-link to="/ForgotPassword">
                <a href="#" class="link-primary fw-bold">
                    Forgot Password
                </a>
              </router-link> page.
            </div>
            <div class="mb-10">
              <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18.png" class="mw-100 mh-200px theme-light-show" alt="">
              <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5.png" class="mw-100 mh-200px theme-light-show" alt="">
              
              <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
              <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
            </div>
            <div class="mb-10 fw-semibold fs-6 text-gray-500">
              <router-link to="/Login">
                <a href="#" class="btn btn-primary fw-bold">
                  Back to Login
                </a>
              </router-link>
            </div>
          </div>       
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from "axios";
import PasswordMeter from "../scripts/passwordMeter.js";
import { load } from 'recaptcha-v3';

export default {
  name: "ResetPassword",
  components: {
    PasswordMeter,
  },
  data() {
    return {
      form: {
        password: "",
        password_confirm: "",
        old_password: ""
      },
      tokenStatus: -1,
      passwordScore: 0,
      isHide: true,
      old_isHide: true,
      user_mail: this.$route.params.user_mail,
      isVerifyRecaptcha: false,
      rc_sk: '',
      userName: null
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.password !== null && this.form.password !== '') {
        if(this.form.password == this.form.password_confirm) return true;
        else return false;
      }
      else return null;
    },
    isHasNumber() {
      return /(.*[0-9].*)/g.test(this.form.password);
    },
    isHasLowercase() {
      return /(.*[a-z].*)/g.test(this.form.password);
    },
    isHasUppercase() {
      return /(.*[A-Z].*)/g.test(this.form.password);
    },
    isHasSpecial() {
      return /[^A-Za-z0-9]/g.test(this.form.password);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg8.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      if(this.$route.params.user_mail == null || this.$route.params.user_mail == undefined || this.$route.params.user_mail == '') {
        if(!this.$route.query.code) {return this.$router.push({ name: 'Login' });}

        const parameters = {
          code: this.$route.query.code,
          type: 1
        };  
        axios.post('/api/Auth/CheckTokenExpired', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.tokenStatus = response.data.tokenStatus;
            this.userName = response.data.name_surname;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      this.getUserParameters();
    },
    getUserParameters() {
      axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isVerifyRecaptcha = response.data.is_view_rc_rp == 1 ? true : false;
            this.rc_sk = this.$unlock(response.data.sk);
            this.loadReCaptcha();
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async loadReCaptcha() {
      if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
        const recaptcha = await load(this.rc_sk);
        if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
        else recaptcha.showBadge();
      }
    },
    async onBeforeSubmit(submitType) {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');
        const parameters = {
          token: token,
          sk: this.rc_sk
        };
        const that = this;
        axios.post("/api/Auth/CheckRC", parameters, { "Content-Type": "application/json" })
        .then((response) => {
          if (response.data.success == true && response.data.score > 0.5) {
            if(submitType == 'reset') { this.onResetPassword(); }
            else this.onChangePassword();
          } else {            
            that.$swal("Failed", "ReCaptcha error..!", 'error'); 
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        if(submitType == 'reset') { this.onResetPassword(); }
        else this.onChangePassword();
      }
    },
    onResetPassword() {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        const parameters = {
          code: this.$route.query.code,
          user_password: this.form.password,
          type: 1
        };  
        axios.post('/api/Auth/ResetPassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password changed!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
      }
    },
    onChangePassword() {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        const parameters = {
          user_mail: this.$route.params.user_mail,
          user_password: this.form.password,
          old_password: this.form.old_password
        };  
        axios.post('/api/Auth/ChangePassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password changed!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
      }
    },
    onScore(pwScore) {
      this.passwordScore = pwScore.score;
    },
    togglePasswordVisibility(id) {
      if(id == 'ps_password') {        
        this.oldIsHide = !this.oldIsHide;
      } else {
        this.isHide = !this.isHide;
      }
      var x = document.getElementById(id);
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    }
  },
  created() {
     this.getData();
  }
};
</script>
<style scoped>
body{
  background-image: url('/assets/theme/media/auth/bg8.jpg');
}

@media (min-width: 992px){
.flex-lg-start {
    justify-content: start;
    align-items: start;
}}
</style>