<template>
  <div>
      <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Vehicle Type  
              </h1>
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">Home</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PolicyListTypes' }">Policy List Types</router-link></li>  
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'VehicleTypeList' }">Vehicle Type</router-link></li> 
                  <li class="breadcrumb-item text-gray-600">Create Vehicle Type </li> 
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/VehicleTypeList">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> Return back to List</span>
                  </button>
              </router-link> 
          </div>
      </div>
      <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-6">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <form id="kt_ecommerce_settings_general_form"
                                            class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Code
                                                    </label>
                                                    <input type="text" class="form-control " name="code"
                                                        v-model="form.code">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Name
                                                    </label>
                                                    <input type="text" class="form-control " name="name"
                                                        v-model="form.name">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                            
                                        <div class="fv-row mb-7">
                                            <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                <span>Description</span>
                                            </label>
                                            <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                            </div>
                                            <div class="col-lg-12">
                                            <div class="form-check mt-3">
                                                <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                <label class="fs-6 fw-bold form-label" for="isDefault">
                                                    Default
                                                </label>
                                            </div> 
                                        </div>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        <div class="col-lg-12">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> Save Vehicle Type  </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
// Component Alias: `EGC001`
import axios from 'axios'  
 
// import Swal from 'sweetalert2'; 

 
export default {
  name: "VehicleTypeCreate",
  data() {
    return {
      isProgressing: false,
      form: { 
          name: null,
          code: null,
          description: null,
          session_id: null,
          is_default: false,
      }
    };
  },
  methods: { 
    getData(){
        this.$setSessionStorage('refreshList', true); 
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;   
      params.list_type = 10; // Vehicle Type: 10
      params.session_id = this.$getUser().session_id;
      axios.post('/api/PolicyLists/CreateListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'VehicleTypeUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>