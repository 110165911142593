<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Required Field Defaults
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Required Field Defaults</li> 
          </ul> 
      </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/SystemSettings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped table-rounded border gx-7 gy-5">
              <thead>
                <tr class="fw-bold text-gray-900">
                  <th>Field</th>
                  <th class="text-end">Is Required?</th>
                  <th class="text-end">Is Locked?</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="(item) in routes" v-bind:key="item">
                <tr class="text-gray-900 border-top" :class="{'fw-bolder bg-gray-400': item.isCollapsed}">
                  <td>{{ item.route }}</td>
                  <td></td>
                  <td></td>
                  <td class="text-end">
                    <a href="'#kt_accordion_field_'+ item.route" class="btn btn-xs btn-light-primary collapsible cursor-pointer rotate" data-bs-toggle="collapse" :class="{'collapsed': !item.isCollapsed, 'active': item.isCollapsed}"
                      :data-bs-target="'#kt_accordion_field_'+ item.route" aria-expanded="true" @click="getFormFields(item)">
                      <span class="svg-icon svg-icon-1 svg-icon-primary rotate-180 me-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path></svg>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr v-for="sub in lists" v-bind:key="sub" :id="'kt_accordion_field_' + item.route" class="collapse" :class="{'show': item.isCollapsed && item.route == sub.route}">
                    <td><span class="ms-10">{{  sub.field }}</span></td>
                    <td class="text-end">
                      <input class="form-check-input fs-6 my-0 form-label me-3" 
                        type="checkbox" 
                        v-model="sub.is_required" 
                        @change="onChangeFieldDefault(sub, true)">
                    </td>
                    <td class="text-end">
                      <input class="form-check-input fs-6 my-0 form-label me-3" 
                        type="checkbox" 
                        v-model="sub.is_locked" 
                        @change="onChangeFieldDefault(sub)">
                    </td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </div> 
  </div> 
</template>

<script>
import axios from 'axios' 


import Swal from 'sweetalert2';
 
export default {
  name: "RequiredFieldDefaults",
  data() {
    return {
      routes: [], 
      lists: []
    };
  },
  methods: {
    getData() { 
      this.routes = [];
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('api/RequiredFieldDefaults/GetRoutes', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data != null){  
          this.routes = response.data;
        }
      })
      .catch(function () { 
          new Swal( { icon: 'error', title: 'Error...', text: 'No fields found..!' });
      });
    },
    getFormFields(item) {
      item.isCollapsed = !item.isCollapsed;
      this.lists = [];

      if(item.isCollapsed) {
        const params = {
          session_id: this.$getUser().session_id,
          route: item.route
        };
        axios.post('api/RequiredFieldDefaults/GetFormFields', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(response.data != null){  
            this.lists = response.data;
          }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'No fields found..!' });
        });
      }
      
      this.routes.forEach(element => {
        if(element.route !== item.route) {
          element.isCollapsed = false;
        }
      });
    },
    onChangeFieldDefault(item, isRequiredSelected){      
      this.$swal.fire({
          title: 'Are you sure you want to change the default value for this field?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
      }).then((result) => { 
          if (result.isConfirmed) {
            const params = {
              session_id: this.$getUser().session_id,
              route: item.route,
              field: item.field,
              is_required: item.is_required ? 1 : 0,
              is_locked: item.is_locked ? 1 : 0,
            };
            axios.post('/api/RequiredFieldDefaults/SaveDefaultField', params, {'Content-Type': 'application/json'})
            .then(() => { 
              // this.$swal("Success", "Data saved succesfully..!", 'success');
            })
            .catch(function (error) {
              console.log(error);
            });
          } else{
            if(isRequiredSelected) item.is_required = !item.is_required;
            else item.is_locked = !item.is_locked;
          }
      })
    },
  },
  mounted() { 
    this.getData();
  }
}; 
</script>