<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Template
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DataImportTemplates' }">Data Import Templates</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Update Template</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/DataImportTemplates">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to List</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField> 

            <div class="card card-xxl-strectch p-10">
                <div class="col-lg-12 mb-4">
                    <h2 v-if="this.form"> {{ this.form.name }} Node Design </h2>
                    <div class="separator separator-dashed my-1"></div>
                    <label class="fs-6 fw-bold form-label mt-3 nb-2"> Main Node </label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control form-control-sm" v-model="mform.node_value" name="main_node" :disabled="this.mform.id !== '' &&  this.mform.id !== null"/> 
                        <button class="btn btn-success" @click="onSaveMainNode()"> 
                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                            </svg>
                            </span> 
                            SAVE MAIN NODE 
                        </button>
                    </div>
                </div> 
                <div v-if="this.mform.node_value !== null && this.mform.node_value !== '' && this.mform.id !== '' &&  this.mform.id !== null">
                    <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li class="nav-item fs-3" @click="onActivateNodeTab(1)">
                            <a class="nav-link text-dark active" data-bs-toggle="tab" href="#kt_tab_pane_import_1"><i class="bi bi-person-circle"></i> Insurer</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(2)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_2"><i class="bi bi-journals"></i> Policy</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(3)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_3"><i class="bi bi-car-front-fill"></i> Vehicle</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(4)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_4"><i class="bi bi-house"></i> Property</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(5)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_5"><i class="bi bi-person-video3"></i> Person</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(6)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_6"><i class="bi bi-truck"></i> Load</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(7)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_7"><i class="bi bi-stars"></i> Other Factor</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(8)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_8"><i class="bi bi-wallet"></i> Collection</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(9)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_9"><i class="bi bi-list-check"></i> Coverage</a>
                        </li>
                        <li class="nav-item fs-3" @click="onActivateNodeTab(10)">
                            <a class="nav-link text-dark" data-bs-toggle="tab" href="#kt_tab_pane_import_10"><i class="bi bi-bookmark-star"></i> Description</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="kt_tab_pane_import_1" role="tabpanel">
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="iform.insurer_sub_node" name="insurer_node" @change="onChangeInsurerSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-200 bg-light-primary">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th> 
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in iFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                               <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody> 
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in iFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSaveInsurerSubNode(1)">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE INSURER NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_2" role="tabpanel"> 
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="pform.policy_sub_node" name="policy_node" @change="onChangePolicySubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in pFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                               <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in pFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSavePolicySubNode(1)">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE POLICY NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_3" role="tabpanel">
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="vform.vehicle_sub_node" name="vehicle_node" @change="onChangeVehicleSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in vFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                                <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in vFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSaveVehicleSubNode(1)">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE VEHICLE NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_4" role="tabpanel"> 
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="prform.property_sub_node" name="property_node" @change="onChangePropertySubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in prFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                                <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in prFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSavePropertySubNode(1)">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE PROPERTY NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_5" role="tabpanel"> 
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="perform.person_sub_node" name="policy_node" @change="onChangePersonSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in perFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required" :disabled="item.field == 'person_registration_number'"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                                <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in perFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSavePersonSubNode()">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE PERSON NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_6" role="tabpanel">
                            ...
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_7" role="tabpanel">
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="ofform.otherfactor_sub_node" name="policy_node" @change="onChangeOtherFactorsSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in ofFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required" :disabled="item.field == 'other_factor_registration_number'"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                               <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in ofFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSaveOtherFactorsSubNode()">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE OTHER FACTORS NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_8" role="tabpanel">
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="clform.collection_sub_node" name="policy_node" @change="onChangeCollectionSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in clFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required" :disabled="item.field == 'collection_installment_no'"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                               <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in clFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSaveCollectionSubNode()">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE COLLECTION NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_9" role="tabpanel">
                            <div class="col-lg-12 bg-light-warning p-3 rounded mb-2">
                                <label class="fs-6 fw-bold form-label mt-3 nb-2"> Sub Node </label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm" v-model="this.mform.node_value" name="" disabled style="max-width: 240px;">
                                    <span class="input-group-text">
                                        <i class="bi bi-diagram-3 text-dark fs-2"></i>
                                    </span>
                                    <input type="text" class="form-control form-control-sm" v-model="cvform.coverage_sub_node" name="policy_node" @change="onChangeCoverageSubNode()"/>
                                </div>
                            </div> 

                            <div class="table-responsive">
                                <table class="table table-rounded table-row-bordered border gy-2 gs-2">
                                    <thead>
                                        <tr class="fw-semibold fs-7 border-bottom border-gray-400">
                                            <th class="w-60px">Select</th>
                                            <th class="w-60px">Required</th>
                                            <th class="w-100px">Data Preference</th>
                                            <th class="w-150px">Data Type</th>
                                            <th>Field</th>
                                            <th>XML Line</th> 
                                            <th>XML Node</th> 
                                            <th>Target Node</th> 
                                            <th>List Source</th> 
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr v-for="(item) in cvFormFields" v-bind:key="item" :class="{ 'bg-light-success' : item.is_selected }">
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isSelected" v-model="item.is_selected"></td>
                                            <td class="align-middle"><input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="item.is_required" :disabled="item.field == 'coverage_code'"></td>
                                            <td class="align-middle"> {{ item.data_preference }}</td>
                                            <td class="align-middle"> 
                                                {{ item.data_type }}
                                                <span v-if="item.data_type == 'Date'">
                                                    <br> 
                                                    <input type="text" class="form-control form-control-sm" v-model="item.format"/> 
                                                </span>
                                            </td>
                                            <td class="align-middle"><strong> {{ item.field_name }} </strong></td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2 text-gray-600">
                                                            {{ this.getActiveNodes(item.xml_line) }}
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_line" style="min-width: 240px;" />
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <div class="input-group">
                                                    <span class="input-group-text p-2">
                                                        <i class="bi bi-asterisk" v-if="this.getNodeProcessType(item.xml_node) == 'Operator'"> </i>
                                                        <i class="bi bi-braces" v-if="this.getNodeProcessType(item.xml_node) == 'SingleField'"> </i>
                                                        <i class="bi bi-plus-slash-minus" v-if="this.getNodeProcessType(item.xml_node) == 'MultipleFields'"> </i>
                                                        <i class="bi bi-list" v-if="this.getNodeProcessType(item.xml_node) == 'List'"> </i>
                                                    </span>
                                                    <input type="text" class="form-control form-control-sm" v-model="item.xml_node"  /> 
                                                    <span class="input-group-text p-2">
                                                        <button ref="openXmlNodeModal" class="btn p-0 ps-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_add_xml_node" data-kt-menu-trigger="click" ata-kt-menu-placement="bottom-end" @click="this.getXMLNodeFromRow(item)"> 
                                                            <i class="bi bi-plus " style="font-size: 1.5rem"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="align-middle"><input type="text" class="form-control form-control-sm" v-model="item.xml_target" /> </td>
                                            <td class="align-middle">  {{ item.list_source }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead @click="this.openSummary = !this.openSummary;">
                                        <tr>
                                            <th> 
                                                <button type="button" class="btn btn-xs btn-warning"> 
                                                    <span v-if="!this.openSummary"> <i class="fa fa-plus"></i> Show </span>
                                                    <span v-if="this.openSummary"> <i class="fa fa-minus"></i> Hide </span>
                                                    Summary 
                                                </button> 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="this.openSummary">
                                        <tr v-for="(item) in cvFormFields" v-bind:key="item">
                                            <td v-if="item.is_selected" class="pt-3">
                                                <span v-if="this.getNodeProcessType(item.xml_node) == 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. 
                                                    Route in XML File is: <code>{{ item.xml_line }}</code> and data will be searched at: <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''">  If such data exists with this condition then <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span>
                                                <span v-if="this.getNodeProcessType(item.xml_node) !== 'Operator'">
                                                    <code>{{ item.field_name }}</code> will be saved. Route in XML File is: <code>{{ item.xml_line }}</code> 
                                                    and field's data is at <code>{{ item.xml_node }}</code>. 
                                                    <span v-if="item.xml_target != null && item.xml_target != ''"> <code>{{ item.xml_target }}</code>'s value will be saved. </span>
                                                    <span v-if="item.list_source == 'Data Import Settings'"> If Data Import Parameter value is equal to this final data, parameter value will be used.</span>
                                                </span> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <button class="btn btn-lg btn-success w-100" @click="onSaveCoverageSubNode()">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    SAVE COVERAGE NODE SETTINGS
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_import_10" role="tabpanel">
                            ...
                        </div> 
                    </div>
                </div>
            </div> 

    <!-- XML NODE MODAL -->
    <div class="modal fade" id="kt_modal_add_xml_node" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        <span> XML NODE </span>
                    </h2>                        
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeXmlModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y">
                    <div class="preloader vh-100" v-if="this.isDataTransferring">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_company_account'">
                                        XML Node Type
                                    </label>
                                    <Select2 v-model="this.xmlNodeType" :options="this.xmlNodeTypeList" name="producer_company_account"  v-custom  
                                        :settings="{width: '100%', dropdownParent: '#kt_modal_add_xml_node .modal-content'}" @select="onChangeXmlNode($event)" />
                                </div>
                                <div class="col-lg-12" v-if="this.xmlNodeType == '0'">
                                    <span>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            XML Node
                                        </label>
                                        <input type="text" class="form-control form-control-sm" v-model="this.xml_node_value" style="min-width: 240px;" />
                                    </span>
                                </div>
                                <div class="col-lg-12" v-if="this.xmlNodeType == '1'">
                                    <span>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            XML Node
                                        </label>
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-sm" v-model="this.xml_node_value" style="min-width: 240px;"/>
                                                <span class="input-group-text p-2 bg-success ">
                                                    <button class="btn p-0 ps-1"  @click="addInput()"> 
                                                        <i class="bi bi-plus " style="font-size: 1.5rem; color: white"></i>
                                                    </button>
                                                </span>
                                        </div>
                                    </span>
                                        <div v-for="(input, index) in inputList" :key="index">
                                            <span class="svg-icon svg-icon-muted svg-icon-2hx d-flex justify-content-center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-align-center">
                                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"/>
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            <div class="input-group">
                                                <input type="text" class="form-control form-control-sm" v-model="inputList[index]" style="min-width: 240px;" />
                                                <span class="input-group-text p-2 bg-danger ">
                                                    <button class="btn p-0 ps-1"  @click="removeInput(index)"> 
                                                        <i class="bi bi-dash " style="font-size: 1.5rem; color: white"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    <span>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Target Node
                                        </label>
                                        <input type="text" class="form-control form-control-sm" v-model="this.activeNode.xml_target" style="min-width: 240px;" />
                                    </span>
                                </div>
                                <div class="col-lg-12" v-if="this.xmlNodeType == '2'">
                                    <span>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            XML Node
                                        </label>
                                        <input type="text" class="form-control form-control-sm" v-model="this.xml_node_value" style="min-width: 240px;" />
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Target Node
                                        </label>
                                        <input type="text" class="form-control form-control-sm" v-model="this.activeNode.xml_target" style="min-width: 240px;" />
                                    </span>
                                </div>
                                <div class="col-lg-12" v-if="this.xmlNodeType == '3'">
                                    <span>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            XML Node
                                        </label>
                                        <div class="input-group">
                                            <input type="text" class="form-control d-inline-block" v-model="this.xml_node_value"  />
                                            <span class="input-group-text">
                                                <i class="fa fa-equals pe-1"></i>
                                                <i class="fa fa-equals"></i>
                                            </span>
                                            <input type="text" class="form-control d-inline-block" v-model="this.xml_node_key"  />
                                        </div>
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            Target Node
                                        </label>
                                        <input type="text" class="form-control form-control-sm" v-model="this.activeNode.xml_target" style="min-width: 240px;" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-if="this.xmlNodeType != '-1'">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-success btn-lg"
                                @click="this.saveXmlNodeType()" >
                                <strong>Save</strong> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</template>
<script>
import axios from 'axios' 


 
export default {
  data() {
    return {
        iFormFields: [ 
            { field_name: 'Member Type', field: 'member_type', data_preference: 'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Personal ID', field: 'personal_id', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Registration ID (Company)', field: 'registration_number', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Name/Company', field: 'name_company', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Contact Name', field: 'contact_name', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Email', field: 'email', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Country Phone Code', field: 'country_phone_code', data_preference: 'Default Data', data_type: 'List', format: '', list_source: 'Default', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Mobile Phone', field: 'mobile_phone', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Phone', field: 'phone', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Customer Type', field: 'customer_type', data_preference: 'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sales Channel', field: 'sales_channel', data_preference: 'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Postcode', field: 'postcode', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Address Code', field: 'address_code', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Address', field: 'address', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'City', field: 'city', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'District', field: 'district', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Country', field: 'country', data_preference: 'Default Data', data_type: 'List', format: '', list_source: 'Default', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Birthday', field: 'birthday', data_preference: 'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        pFormFields:[
            { field_name: 'Regulation Date', field: 'regulation_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Start Date', field: 'start_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'End Date', field: 'end_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Policy No', field: 'policy_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Renewal No', field: 'renewal_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Endorsement No', field: 'endorsement_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Payment Method', field: 'payment_method', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Shipping Type', field: 'shipping_type', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Branch', field: 'branch', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'General Description', field: 'general_description', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'New/Renewal', field: 'new_renewal', data_preference: 'List Data', data_type: 'List', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Producer Policy Type', field: 'producer_policy_type', data_preference:'List Data', data_type: 'List', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Producer Policy Type Name', field: 'producer_policy_type_name', data_preference:'List Data', data_type: 'List', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Policy Record Type', field: 'policy_record_type', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Endorsement Type', field: 'endorsement_type', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Endorsement Type Detail', field: 'endorsement_type_detail', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Policy Sales Cahannel', field: 'policy_sales_channel', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sales Role/Campaign', field: 'sales_role_campaign', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Source Code 1', field: 'source_code_1', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Source Code 2', field: 'source_code_2', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Currency', field: 'currency', data_preference:'List Data', data_type:'List', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Change Currency', field: 'change_currency', data_preference:'List Data', data_type: 'List', format: '', list_source:'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Rate', field: 'rate', data_preference:'List Data', data_type:'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Policy Net', field: 'policy_net', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Tax', field: 'tax', data_preference:'Fixed Data', data_type:'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 1', field: 'sub_tax_1', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 2', field: 'sub_tax_2', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 3', field: 'sub_tax_3', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Total Amount', field: 'total_amount', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Commission', field: 'commission', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Policy Net Local', field: 'policy_net_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Tax Local', field: 'tax_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 1 Local', field: 'sub_tax_1_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 2 Local', field: 'sub_tax_2_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Sub Tax 3 Local', field: 'sub_tax_3_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Total Amount Local', field: 'total_amount_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Commission Local', field: 'commission_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
        ],
        vFormFields:[
            { field_name: 'Registration Number', field: 'vehicle_registration_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Group', field: 'vehicle_group_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Detail', field: 'vehicle_usage_type_detail_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Name Description', field: 'vehicle_name_description', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Type', field: 'vehicle_type_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Fuel Type', field: 'vehicle_fuel_type_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Registration Status', field: 'vehicle_registration_status', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Type', field: 'vehicle_usage_type_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Licence Number', field: 'vehicle_licence_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Brand Code', field: 'vehicle_brand_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Model Code', field: 'vehicle_model_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Brand Name', field: 'vehicle_brand_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Model Name', field: 'vehicle_model_name', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Code', field: 'vehicle_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Engine Number', field: 'vehicle_engine_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Color', field: 'vehicle_color', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Load Capacity', field: 'vehicle_load_capacity', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Last Reg. Date', field: 'vehicle_last_registration_date', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Chassis Number', field: 'vehicle_chassis_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Max. Passenger', field: 'vehicle_max_passenger', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Engine Power', field: 'vehicle_engine_power', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Price', field: 'vehicle_vehicle_price', data_preference:'Fixed Data', data_type:'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Model Year', field: 'vehicle_model_year', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Empty Weight', field: 'vehicle_empty_weight', data_preference:'Fixed Data', data_type:'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'First Reg. Date', field: 'vehicle_first_registration_date', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Usage Type Code', field: 'vehicle_usage_type_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Usage Type Detail Code', field: 'vehicle_usage_type_detail_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Usage Type Value', field: 'vehicle_usage_type_value', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Usage Type Detail Value', field: 'vehicle_usage_type_detail_value', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Group Code', field: 'vehicle_group_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Group Name', field: 'vehicle_group_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Group Value', field: 'vehicle_group_value', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Type Code', field: 'vehicle_type_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Type Name', field: 'vehicle_type_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Vehicle Type Value', field: 'vehicle_type_value', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        prFormFields:[
            { field_name: 'Post Code', field: 'property_postcode', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Address Code', field: 'property_address_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Select Address', field: 'property_address_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'City', field: 'property_city', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'District', field: 'property_district', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Country', field: 'property_country', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Apartment No / Name', field: 'property_apartment_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Building No', field: 'property_building_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Block No', field: 'property_block_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Floor No', field: 'property_floor_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Street', field: 'property_street_no', data_preference: 'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Residence Period (Year)', field: 'property_residence_year', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Residence Period (Month)', field: 'property_residence_month', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Type', field: 'property_usage_type_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Type Detail', field: 'property_usage_type_detail_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Type', field: 'property_type_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Construction Type', field: 'property_construction_type_id', data_preference:'Fixed Data', data_type: 'Text', format: 'None', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Construction Year', field: 'property_construction_year_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Area', field: 'property_property_area', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Residence Type', field: 'property_residence_type_id', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Owner Name', field: 'property_owner_name', data_preference:'Fixed Data', data_type:'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Registration Number', field: 'property_registration_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Name Description', field: 'property_name_description', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Address', field: 'property_address', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Type Code', field: 'property_usage_type_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Usage Type Name', field: 'property_usage_type_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Type Code', field: 'property_property_type_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Property Type Name', field: 'property_property_type_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Construction Type Code', field: 'property_construction_type_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Construction Type Name', field: 'property_construction_type_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Construction Year', field: 'property_construction_year', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
        ],
        perFormFields:[
            { field_name: 'Registration No / ID', field: 'person_registration_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: true },
            { field_name: 'Full Name', field: 'person_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Birth Date', field: 'person_birth_date', data_preference: 'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Gender', field: 'person_gender', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Position Type', field: 'person_position_type_id', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Position Date', field: 'person_position_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Licence Number', field: 'person_licence_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source:'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Licence Date', field: 'person_licence_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Person Type', field: 'person_type', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        ofFormFields:[
            { field_name:'Registration No /', field: 'other_factor_registration_number', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: true },
            { field_name: 'Name / Description', field: 'other_factor_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Brand', field: 'other_factor_brand', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Model', field: 'other_factor_model', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Serial No', field: 'other_factor_serial_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Invoice Date', field: 'other_factor_invoice_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Invoice No', field: 'other_factor_invoice_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Producer', field: 'other_factor_producer', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Price', field: 'other_factor_price', data_preference:'Fixed Data', data_type: 'Text', format: '',  list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        cvFormFields:[
            { field_name: 'Code', field: 'coverage_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: true },
            { field_name: 'Name', field: 'coverage_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Currency', field: 'coverage_currency', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Amount', field: 'coverage_amount', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Coverage Main', field: 'coverage_main_name', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Coverage Detail', field: 'coverage_detail_name', data_preference:'List Data', data_type: 'List', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Coverage Group', field: 'coverage_group_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Insurance Comission', field: 'coverage_insurance_commission', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Insurance Premium', field: 'coverage_insurance_premium', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Producer Coverage Code', field: 'coverage_producer_coverage_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Producer Coverage Name', field: 'coverage_producer_coverage_name', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Coverage Main Code', field: 'coverage_main_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Coverage Detail Code', field: 'coverage_detail_code', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'Data Import Settings', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        clFormFields:[
            { field_name: 'Installment No', field: 'collection_installment_no', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: true },
            { field_name: 'Due Date', field: 'collection_due_date', data_preference:'Fixed Data', data_type: 'Date', format: 'DD.MM.YYYY', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Installment Amount', field: 'collection_installment_amount', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Installment Amount Local', field: 'collection_installment_amount_local', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Rate', field: 'collection_rate', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false },
            { field_name: 'Currency', field: 'collection_currency', data_preference:'Fixed Data', data_type: 'Text', format: '', list_source: 'None', id: null, main_node: null, sub_node: null, node_type: 1, xml_line: null, xml_node: null, xml_target: null, is_selected: false, is_required: false }
        ],
        iform: {},
        pform: {},
        vform: {},
        prform: {},
        perform: {},
        ofform: {},
        cvform: {},
        clform:{},
        mform: {
            main_node: null,
            is_record_exist: false
        },
        isSaveFinished: null,
        activeNodeTab: 1,
        xmlNodeTypeList:[
            {id: '-1', text: 'Select an option'},
            {id: '0' , text: 'Plain'},
            {id: '1' , text: 'SUM'},
            {id: '2' , text: 'Array'},
            {id: '3' , text: 'Condition'}
        ],
        activeNode: null,
        inputList: [],
        xml_node_key: null,
        xml_node_value: null,
        xmlNodeType: null,
        xml_node_condition_list:[],
        openSummary: false
    };
  }, 
  computed: {
    getActiveNodes() {
        return (value)=>{
            if (value) 
            {
                return value.split('.[').length;
            }
        } 
    },
    getNodeProcessType() {
        return (value)=>{
            if (value) 
            {
                if(value.includes('==') || value.includes('<>')){
                    return 'Operator';
                }
                if(value.includes('+')){
                    return 'MultipleFields';
                }
                if(value.includes('*[')){
                    return 'List';
                }
                return 'SingleField';
            }
        } 
    }
  },
  methods: {
    addInput() {
      this.inputList.push('');
    },
    removeInput(index) {
      this.inputList.splice(index, 1);
    },
    saveXmlNodeType(){
        if(this.xmlNodeType === "0"){
            this.activeNode.xml_node = this.xml_node_value == "" ? "" : `[${this.xml_node_value}]`;
            this.activeNode.xml_target = "";
        }else if (this.xmlNodeType === "1") {
            this.activeNode.xml_node = "";
            this.activeNode.xml_node = `[${this.xml_node_value}]`;
            this.activeNode.xml_target = this.activeNode.xml_target == "" ? "" : `[${this.activeNode.xml_target}]`;
            this.inputList.forEach((element) => {
                if(element != "") this.activeNode.xml_node += `+[${element}]`;
            });
        }else if(this.xmlNodeType === "2"){
            this.activeNode.xml_node = this.xml_node_value == "" ? "" : `*[${this.xml_node_value}]`;
            this.activeNode.xml_target = this.activeNode.xml_target == "" ? "" : `[${this.activeNode.xml_target}]`;
        }else if(this.xmlNodeType === "3"){
            this.activeNode.xml_node = this.xml_node_value == "" ? "" : `[${this.xml_node_value}]=="${this.xml_node_key}"`
            this.activeNode.xml_target = this.activeNode.xml_target == "" ? "" : `[${this.activeNode.xml_target}]`;
        }
        this.$refs.closeXmlModal.click();
    },
    getXMLNodeFromRow(item){
        this.inputList= [];
        this.xml_node_value = null;
        this.xml_node_key = null;
        item.xml_target = item.xml_target == "" ? "" : item.xml_target.replaceAll("[","").replaceAll("]","");
        if(item.xml_node.includes("+")){
            this.xmlNodeType= "1"
            this.xml_node_condition_list = item.xml_node.split("+");
            this.xml_node_value = this.xml_node_condition_list[0].replaceAll("*","").replace("[","").replace("]","");
            let inputListReplace = this.xml_node_condition_list.slice(1);
            inputListReplace.forEach((item) => {
                item = item.replace("[","").replace("]","");
                this.inputList.push(item);
            })
        }else if(item.xml_node.includes("*")){
            this.xmlNodeType= "2"
            this.xml_node_value = item.xml_node.replaceAll("[","").replaceAll("]","").replaceAll("*","")
        }else if(item.xml_node.includes("==")){
            this.xmlNodeType= "3";
            let xml_node_split = item.xml_node.split("==");
            this.xml_node_value = xml_node_split[0].replaceAll("[","").replaceAll("]",""); 
            this.xml_node_key = xml_node_split[1].replaceAll("\"", ""); 
        }else if(item.xml_node.includes("[")){
            this.xmlNodeType= "0";
            this.xml_node_value = item.xml_node.replaceAll("[","").replaceAll("]","")
        }else{
            this.xmlNodeType= "-1"
        }
        this.activeNode = item;
    },
    onChangeXmlNode(e){
        this.xmlNodeType = e.id;
    },
    onGetTemplate(){
            const params = {
                session_id: this.$getUser().session_id,
                id: this.$getSessionStorage('record_id')
            };
            axios.post('api/Office/GetDataImportTemplateById', params, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                   this.form = response.data;
                   this.getXMLNodes();
                }
            })
    },
    updateTemplate() {
      this.$setSessionStorage('refreshList', true);
      this.$swal("Success", "Template update succesfully..!", 'success');
      this.$router.replace('/UpdateImportTemplate');
    },   
    getXMLNodes(){
         const parameters = {
            session_id: this.$getUser().session_id,
            template_id: this.$getSessionStorage('record_id')
         };
        axios.post('/api/DLLTemplates/GetXMLMainNode', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data != null){
                    this.mform = response.data; 

                    const parameters = {
                        session_id: this.$getUser().session_id,
                        main_node: this.mform.id,
                        node_type: 1
                    };
                    axios.post('/api/DLLTemplates/GetDataImportXmlNodesByTemplateId', parameters, {'Content-Type': 'application/json'})
                        .then((response) => {
                            if(response.data.length > 0){
                                response.data.forEach(responseItem => {
                                   this.iFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                        }
                                    });
                                    this.pFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.pform.policy_sub_node = responseItem.sub_node;
                                        }
                                    });
                                    this.vFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.vform.vehicle_sub_node = responseItem.sub_node;
                                        }
                                    });
                                    this.prFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.prform.property_sub_node = responseItem.sub_node;
                                        }
                                    });
                                    this.perFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.perform.person_sub_node = responseItem.sub_node;
                                        }
                                        if(elementItem.field === 'person_registration_number'){
                                            elementItem['is_required'] = true ;
                                        }
                                    });
                                    this.ofFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.ofform.otherfactor_sub_node = responseItem.sub_node;
                                        }
                                        if(elementItem.field === 'other_factor_registration_number'){
                                            elementItem['is_required'] = true ;
                                        }
                                    });
                                    this.cvFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.cvform.coverage_sub_node = responseItem.sub_node;
                                        }
                                        if(elementItem.field === 'coverage_code'){
                                            elementItem['is_required'] = true ;
                                        }
                                    });
                                    this.clFormFields.forEach(elementItem => {
                                        if(elementItem.field == responseItem.field){
                                            elementItem['is_selected'] = responseItem.is_selected == 1 ? true : false;
                                            elementItem['is_required'] = responseItem.is_required == 1 ? true : false;
                                            elementItem['id'] = responseItem.id;
                                            elementItem['xml_line'] = responseItem.xml_line;
                                            elementItem['xml_node'] = responseItem.xml_node;
                                            elementItem['xml_target'] = responseItem.xml_target;
                                            this.clform.collection_sub_node = responseItem.sub_node;
                                        }
                                        if(elementItem.field === 'collection_installment_no'){
                                            elementItem['is_required'] = true ;
                                        }
                                    });
                                });
                                
                                this.iform.insurer_sub_node = response.data[0].sub_node;
                            } 
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } 
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    onSaveMainNode(){
        const parameters = {
            session_id: this.$getUser().session_id,
            template_id: this.$getSessionStorage('record_id'),
            node_value: this.mform.node_value
         };
        axios.post('/api/DLLTemplates/SaveXMLMainNode', parameters, {'Content-Type': 'application/json'})
            .then(() => {
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.getXMLNodes();
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    onSaveInsurerSubNode(typeValue){
        // Insurer Save
        if(typeValue == 1){
           this.isSaveFinished = false;
           var fieldCount = this.iFormFields.length;
           var fieldSaved = 0;
            this.iFormFields.forEach(element => {
                const parameters = {
                    session_id : this.$getUser().session_id,
                    id : element.id,
                    main_node : this.mform.id,
                    sub_node : this.iform.insurer_sub_node,
                    node_type : element.node_type,
                    xml_line : element.xml_line,
                    xml_node : element.xml_node,
                    xml_target : element.xml_target,
                    is_selected : element.is_selected == true ? 1 : 0,
                    is_required : element.is_required == true ? 1 : 0,
                    field: element.field,
                    data_format: element.format
                };
                axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
                    .then(() => {
                        fieldSaved++;
                        if(fieldSaved == fieldCount){
                            this.$swal("Success", "Data saved succesfully..!", 'success');
                            this.isSaveFinished = true;
                            this.getXMLNodes();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });  
        } 
    },
    onSavePolicySubNode(){
        // Policy Save
        this.isSaveFinished = false;
        var fieldCount = this.pFormFields.length;
        var fieldSaved = 0;
        this.pFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
            .then(() => {
                fieldSaved++;
                if(fieldSaved == fieldCount){
                    this.$swal("Success", "Data saved succesfully..!", 'success');
                    this.isSaveFinished = true;
                    this.getXMLNodes();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });  
    },
    onSaveVehicleSubNode(){
        // Vehicle Save
        this.isSaveFinished = false;
        var fieldCount = this.vFormFields.length;
        var fieldSaved = 0;
        this.vFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
                .then(() => {
                    fieldSaved++;
                    if(fieldSaved == fieldCount){
                        this.$swal("Success", "Data saved succesfully..!", 'success');
                        this.isSaveFinished = true;
                        this.getXMLNodes();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });  
    },
    onSavePropertySubNode(){
        // Property Save
        this.isSaveFinished = false;
        var fieldCount = this.prFormFields.length;
        var fieldSaved = 0;
        this.prFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
                .then(() => {
                    fieldSaved++;
                    if(fieldSaved == fieldCount){
                        this.$swal("Success", "Data saved succesfully..!", 'success');
                        this.isSaveFinished = true;
                        this.getXMLNodes();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });  
    },
    onSavePersonSubNode(){
        //Person Save
        this.isSaveFinished = false;
        var fieldCount = this.perFormFields.length;
        var fieldSaved = 0;
        this.perFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
                .then(() => {
                    fieldSaved++;
                    if(fieldSaved == fieldCount){
                        this.$swal("Success", "Data saved succesfully..!", 'success');
                        this.isSaveFinished = true;
                        this.getXMLNodes();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });  
    },
    onSaveOtherFactorsSubNode(){
        //Other Factors Save
        this.isSaveFinished = false;
        var fieldCount = this.ofFormFields.length;
        var fieldSaved = 0;
        this.ofFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };  
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
            .then(() => {
                fieldSaved++;
                if(fieldSaved == fieldCount){
                    this.$swal("Success", "Data saved succesfully..!", 'success');
                    this.isSaveFinished = true;
                    this.getXMLNodes();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });  
    },
    onSaveCollectionSubNode(){
        //Collection Save
        this.isSaveFinished = false;
        var fieldCount = this.clFormFields.length;
        var fieldSaved = 0;
        this.clFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
            .then(() => {
                fieldSaved++;
                if(fieldSaved == fieldCount){
                    this.$swal("Success", "Data saved succesfully..!", 'success');
                    this.isSaveFinished = true;
                    this.getXMLNodes();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });  
    },
    onSaveCoverageSubNode(){
        //Coverage Save
        this.isSaveFinished = false;
        var fieldCount = this.cvFormFields.length;
        var fieldSaved = 0;
        this.cvFormFields.forEach(element => {
            const parameters = {
                session_id : this.$getUser().session_id,
                id : element.id,
                main_node : this.mform.id,
                sub_node : this.iform.insurer_sub_node,
                node_type : element.node_type,
                xml_line : element.xml_line,
                xml_node : element.xml_node,
                xml_target : element.xml_target,
                is_selected : element.is_selected == true ? 1 : 0,
                is_required : element.is_required == true ? 1 : 0,
                field: element.field,
                data_format: element.format
            };
            axios.post('/api/DLLTemplates/UpdateDataImportXmlNodes', parameters, {'Content-Type': 'application/json'})
            .then(() => {
                fieldSaved++;
                if(fieldSaved == fieldCount){
                    this.$swal("Success", "Data saved succesfully..!", 'success');
                    this.isSaveFinished = true;
                    this.getXMLNodes();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        });  
    },
    onActivateNodeTab(value){
        this.activeNodeTab = value;
    },
    onChangeInsurerSubNode(){
        this.iFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.iform.insurer_sub_node + ']';
        }); 
    },
    onChangePolicySubNode(){
        this.pFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.pform.policy_sub_node + ']';
        }); 
    },
    onChangeVehicleSubNode(){
        this.vFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.vform.vehicle_sub_node + ']';
        }); 
    },
    onChangePropertySubNode(){
        this.prFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.prform.property_sub_node + ']';
        }); 
    },
    onChangePersonSubNode(){
        this.perFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.perform.person_sub_node + ']';
        });         
    },
    onChangeOtherFactorsSubNode(){
        this.ofFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.ofform.otherfactor_sub_node + ']';
        });         
    },
    onChangeCollectionSubNode(){
        this.clFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.clform.collection_sub_node + ']';
        });   
    },
    onChangeCoverageSubNode(){
        this.cvFormFields.forEach(element => {
            element.xml_line = '[' + this.mform.node_value + '].[' + this.cvform.coverage_sub_node + ']';
        });   
    },
  },
  created(){
    this.onGetTemplate();
  }
}; 
</script>