<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Help Pages
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600">Help Pages</li> 
          </ul> 
      </div>  
        <div class="d-flex align-items-center py-1">
        <router-link to="/GeneralSettings">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to Settings </span>
              </button>
            </router-link>
        </div> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <router-link to="/PagesCreate">
                                <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>Add Help Page</button>
                            </router-link>
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Help Pages </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Pages-List')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="20"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                                <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                                <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/>  
                                <DxColumn data-field="location_name" caption="Location" alignment="left" cell-template="location-cell-template"/> 
                                <DxColumn data-field="menu_selection" caption="Menu" alignment="left" cell-template="menu_selection-cell-template"/>
                                <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/>
                                <DxColumn data-field="country" caption="Country" cell-template="country-cell-template"/>
                                <DxColumn data-field="is_view" caption="Is View?" header-cell-template="is_view-header" alignment="center" cell-template="is_view-cell-template"/> 
                                <DxColumn data-field="release_date" caption="Release Date" alignment="right" cell-template="release_date-cell-template"/>
                                <DxColumn data-field="created_at" caption="Created At" header-cell-template="created_at-header" alignment="right"  cell-template="created_at-cell-template"/> 

                                <template #is_view-header>
                                    <div> Is View ? </div>
                                </template>
                                <template #created_at-header>
                                    <div> Created At </div>
                                </template> 

                                <template #code-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{data.data.code}}
                                    </a>
                                </template>
                                 <template #name-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{data.data.name}}
                                    </a>
                                </template> 
                                <template #location-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        <span> {{ data.data.location_name }}</span>
                                    </a>
                                </template>
                                <template #description-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{data.data.description}}
                                    </a>
                                </template>
                                <template #country-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        <span v-if="data.data.country !== '-1'"> {{data.data.country}} </span>
                                    </a>
                                </template>
                                <template #menu_selection-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{data.data.menu_selection}}
                                    </a>
                                </template>
                                <template #release_date-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{ previewDate(data.data.release_date) }} 
                                    </a>
                                </template> 
                                 <template #is_view-cell-template="{ data }">
                                    <a href="#" class="text-gray-800  text-center text-hover-primary mb-1" @click="updateType(data.data)">                                      
                                        <span v-if="data.data.is_view == true">
                                            <i class="las la-check fs-2 text-success"></i>
                                        </span>  
                                        <span v-if="data.data.is_view == false">
                                            <i class="las la-times fs-2 text-danger"></i>
                                        </span> 
                                    </a>
                                </template>
                                <template #created_at-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateType(data.data)">
                                        {{ previewDate(data.data.created_at) }} 
                                    </a>
                                </template> 
                        </DxDataGrid> 
                    </div>
                  </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `PL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
  name: "HelpPageList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists: [],
      isListProgressing: false
    };
  },
  computed: { 
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() { 
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Pages/PagesList', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    updateType(item){ 
        this.$setSessionStorage("record_id", item.id);
        this.$router.push({ name: 'PagesUpdate' });
    }
  },
  created() {
         if(this.$getSessionStorage('refreshList')){
             this.getData();
             this.$removeSessionStorage('refreshList'); 
         }
     else{
         this.lists = [];
     }
  }
}; 
</script>