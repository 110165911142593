<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Policy Branch 
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PolicyListTypes' }">Policy List Types</router-link></li>   
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PolicyBranchList' }">Policy Branch</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Update Policy Branch</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/PolicyBranchList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link> 
            &nbsp;
            <div class="dropdown">
                <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    Actions
                </button>
                <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem(form.id)"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>Delete </a>
                    </li>
                </ul>
            </div>  
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mb-2 required">                                                            
                                                            Main Branch
                                                        </label>
                                                        <select class="form-control" v-model="form.mainBranchSelection">
                                                            <option v-for="item in mainBranchList" :value="item" :key="item.id">
                                                            {{ item.code }} <span v-if="item.code != null">-</span> {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Code
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">                                                
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-check mt-3">
                                                            <input class="form-check-input fs-6 fw-bold form-label mt-3" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                            <label class="fs-6 fw-bold form-label mt-3" for="isDefault">
                                                                Default
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="card-header">
                        <div class="card-title">
                           Add Factor Detail
                        </div>
                    </div>
                    <div class="card-body"> 
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                            <div class="p-5">
                                <div class="d-flex flex-stack flex-wrap mb-5">
                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                            data-bs-target="#kt_modal_add_factor_detail" @click="this.onGetFactorTypes()"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            Add
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                            data-bs-target="#kt_modal_add_factor_detail" @click="this.openEditFactorModal()"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :disabled="isFactorListButtonDisabled == null || isFactorListButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteSubListItem(2)" 
                                            :disabled="isFactorListButtonDisabled == null || isFactorListButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove
                                        </button>
                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllSubLists(2)" :disabled="this.recordedFactorList.length == 0">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove All (!)
                                        </button>
                                    </div>
                                </div>
                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive">
                                        <DxDataGrid :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="recordedFactorList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" > 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="false"/>
                                                <DxPaging :page-size="1000"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                    
                                                />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                <DxColumn data-field="factor_type_name" caption="Factor Type" alignment="left" cell-template="factor_type_name-cell-template"/> 
                                                <!-- <DxColumn data-field="code" caption="Factor Detail Code" cell-template="code-cell-template"/>
                                                <DxColumn data-field="name" caption="Factor Detail Name" alignment="left" cell-template="name-cell-template"/>  -->
                                                <DxColumn data-field="is_required" caption="Is Required?" alignment="left" cell-template="is_required-cell-template"/> 
                                                
                                                <!-- Cell Templates -->
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(recordedFactorList, data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <!-- <template #code-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.code }}
                                                    </span>
                                                </template>
                                                <template #name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.name }}
                                                    </span>
                                                </template> -->
                                                <template #factor_type_name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.factor_type_name }}
                                                    </span>
                                                </template>
                                                <template #is_required-cell-template="{ data }"> 
                                                    <span v-if="data.data.is_required == 0">Optional</span>
                                                    <span v-if="data.data.is_required == 1">Required</span>
                                                </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="card-header">
                        <div class="card-title">
                           Add Coverage Detail
                        </div>
                    </div>
                    <div class="card-body"> 
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                            <div class="p-5">
                                <div class="d-flex flex-stack flex-wrap mb-5">
                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                            data-bs-target="#kt_modal_add_coverage_detail" @click="this.openAddCoverageDetailModal()"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                        fill="currentColor">
                                                    </rect>
                                                </svg>
                                            </span>
                                            Add
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteSubListItem(1)" 
                                            :disabled="isSubListButtonDisabled == null || isSubListButtonDisabled == undefined">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove
                                        </button>
                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllSubLists(1)" :disabled="this.policyBranchSubLists.length == 0">
                                            <span class="svg-icon svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            Remove All (!)
                                        </button>
                                    </div>
                                </div>
                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive">
                                        <DxDataGrid :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="policyBranchSubLists" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" > 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="false"/>
                                                <DxPaging :page-size="1000"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                    
                                                />
                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                                                <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/>
                                                <DxColumn data-field="name" caption="Name" alignment="left" cell-template="name-cell-template"/> 
                                                <DxColumn data-field="parent_name" caption="Parent Name" alignment="left" cell-template="parent_name-cell-template"/> 
                                                
                                                <!-- Cell Templates -->
                                                <template #is-selected-cell-template="{ data }"> 
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(policyBranchSubLists, data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #code-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.code }}
                                                    </span>
                                                </template>
                                                <template #name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.name }}
                                                    </span>
                                                </template>
                                                <template #parent_name-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.parent_name }}
                                                    </span>
                                                </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="p-5"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Update Policy Branch</strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    
    <!-- Add Coverage Detail -->
    <div class="modal fade" id="kt_modal_add_coverage_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Add Coverage Detail                    
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="coverageDetailList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Policy-Branch')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>
                               
                                <DxColumn data-field="isSelected" :width="80" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :allow-filtering="false" alignment="center"/>
                                <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                                <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/> 
                                <DxColumn data-field="parent_name" caption="Parent Name" cell-template="parent_name-cell-template"/>

                                 <!-- Header Cell Templates -->
                                <template #is-selected-header>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input" type="checkbox" v-model="modalForm.isSelectedAll" @change="onSelectAll()"> 
                                    </div>
                                </template>

                                <!-- Cell Templates -->
                                <template #is_selected-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                        </div>
                                    </span>
                                </template>
                                <template #code-cell-template="{ data }">
                                    <span>
                                        {{ data.data.code }}
                                    </span>
                                </template>
                                <template #name-cell-template="{ data }">
                                    <span>
                                        {{ data.data.name }}
                                    </span>
                                </template>
                                <template #parent_name-cell-template="{ data }">
                                    <span>
                                        {{ data.data.parent_name }}
                                    </span>
                                </template> 
                            </DxDataGrid> 
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-flush mt-4" id="kt_add_user_button">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-light-success border border-success btn-lg"
                                        @click="addSubList()">
                                        <span class="indicator-label">Add Item to List</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Add Factor Detail -->
    <div class="modal fade" id="kt_modal_add_factor_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header">
                    <h2 class="modal-title fw-bolder col-md-10" v-if="!isEditModal"> Add Factor Detail</h2>
                    <h2 class="modal-title fw-bolder col-md-10" v-if="isEditModal"> Edit Factor Detail</h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeFactorModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6" v-if="!isEditModal">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>Factor Type</span>
                                    </label>
                                    <Select2 name="detail_list_type" v-model="factorForm.detail_list_type" :options="factorTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_factor_detail .modal-content' }" />
                                </div>
                                <div class="col-lg-6" :class="{'col-lg-12': isEditModal}">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>Is Required</span>
                                    </label>
                                    <Select2 name="is_required" v-model="factorForm.is_required" :options="optionList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_factor_detail .modal-content' }" />
                                </div>
                            </div>
                        </div>
                        <div class="card-footer py-3">
                            <div class="col-lg-12">
                                <div class="card card-flush" id="kt_add_user_button">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-light-success border border-success btn-lg" v-if="!isEditModal"
                                            @click="addFactorItem()">
                                            <span class="indicator-label">Add Item to List</span> 
                                        </button>
                                        <button type="button" class="btn btn-light-primary border border-primary btn-lg" v-if="isEditModal"
                                            @click="editFactorItem()">
                                            <span class="indicator-label">Update</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `PBC001`
import axios from 'axios' 

import Swal from 'sweetalert2';

import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling, DxExport } from 'devextreme-vue/data-grid';
 
export default {
  name: "PolicyBranchUpdate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling, DxExport
  },
  data() {
    return {
        isOpenActionsDropdown: false, 
        isProgressing: false,
        form: { 
            id: null,
            name: null,
            code: null,
            description: null,
            session_id: null,
            parent_id: null,
            mainBranchSelection: null,
            is_default: false,
            tmp_session_id: null
        },
        modalForm: {
            sub_list_id: null
        },
        coverageDetailList: [],
        policyBranchSubLists: [],
        factorTypeList: [],
        factorForm: {
            id: null,
            detail_list_type: 1,
            sub_list_id: '-1',
            is_required: 1
        },
        recordedFactorList: [],
        optionList: [
            {id: 0, text: 'Optional'},
            {id: 1, text: 'Required'}
        ],
        isEditModal: false,
        isItemSaving: false
    };
  },
  computed: {
    isSubListButtonDisabled() {
       return this.policyBranchSubLists.find(item => item.isSelected == true );
    },
    isFactorListButtonDisabled() {
       return this.recordedFactorList.find(item => item.isSelected == true );
    }
  },
  methods: {
    getData() { 
        this.mainBranchList = [];   
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);   

        const parameters = {
            session_id: this.$getUser().session_id,
            get_access_status: 1,
            list_type: 1, // Main Branch: 1
            id: this.$getSessionStorage("record_id"),
        };  
        
        const requestOne =  axios.post('/api/PolicyLists/GetLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/PolicyLists/GetListWithSubById', parameters, {'Content-Type': 'application/json'}); 
        const that = this;
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {          
            this.mainBranchList = responses[0].data; 
            this.mainBranchList.unshift({ code: null, name: 'Select an option'});
          
            this.form = { 
                id: responses[1].data.header.id,
                name: responses[1].data.header.name,
                code: responses[1].data.header.code,
                description: responses[1].data.header.description,
                parent_id: responses[1].data.header.parent_id,
                mainBranchSelection: null,
                is_default: responses[1].data.header.is_default
            };   

            this.mainBranchList.forEach((value) => {
                if(this.form.parent_id == value.id){
                this.form.mainBranchSelection = value;
                }
            });
            this.policyBranchSubLists = [];
            this.recordedFactorList = [];

            responses[1].data.sub_list.forEach(element => {
                if(element.sub_type == 1) { 
                    this.policyBranchSubLists.push(element); 
                }
                if(element.sub_type == 2) { 
                    this.recordedFactorList.push(element);
                }
            });
            if(this.policyBranchSubLists.length > 0){ this.$setSessionStorage('tmp_session_id', this.policyBranchSubLists[0].tmp_session_id); }            
            if(this.recordedFactorList.length > 0){ this.$setSessionStorage('tmp_session_id', this.recordedFactorList[0].tmp_session_id); }            
            this.$Progress.finish();
        }))
        .catch(errors => { 
          console.error(errors);          
          that.$Progress.finish();
        });
    },
    saveData(){        
        this.isProgressing = true;
        const params = this.form; 
        const that = this;   
        params.session_id = this.$getUser().session_id;
        params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
        params.parent_id = params.mainBranchSelection.id;

        if(params.parent_id == null || params.parent_id == '' || params.parent_id == undefined){ 
            this.isProgressing = false;
            this.$swal("Warning", "Main Branch selection is required..!", 'warning'); 
            return; 
        }
        axios.post('/api/PolicyLists/UpdateListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){   
                this.$swal("Success", "Data saved succesfully..!", 'success');    
                this.$setSessionStorage('refreshList', true);
                this.getData();
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) 
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteItem(id) { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
        }).then((result) => { 
            if (result.isConfirmed) {
            const queryParameter = {
                id: id,
                session_id: this.$getUser().session_id,
            }; 
            axios.post('/api/PolicyLists/DeleteListItem', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => {   
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                    new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                    this.$setSessionStorage('refreshList', true);
                    this.$router.push({ name: 'PolicyBranchList' }); 
                }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        })  
    },
    openAddCoverageDetailModal(){
        this.isItemSaving = false;
        this.modalForm.isSelectedAll = false;
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 4,
            tmp_session_id: this.$getSessionStorage('tmp_session_id')
        }
        axios.post("/api/PolicyLists/GetListsForSelection", params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.coverageDetailList = response.data;
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    addSubList(){
        this.selectedLists = [];
        this.coverageDetailList.forEach((value) => {
            if(value.isSelected){
                this.selectedLists.push(value.id);
            }
        }); 
        if (this.selectedLists.length == 0) {            
            this.$swal("Failed", " At least one option must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            SubLists: this.selectedLists,
            sub_type: 1,
            is_required: 0
        };
        const that = this;
        axios.post('/api/PolicyLists/AddMultiSubListItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.policyBranchSubLists = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeModal.click();
            }            
            this.isItemSaving = false;
        }) 
        .catch(function (error) {
            that.isItemSaving = false;
            console.log(error);
            this.Progress.finish();
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteSubListItem(subType){
        if(subType == 1) {
            this.policyBranchSubLists.forEach((item) => {
                if (item.isSelected) { this.modalForm.sub_list_id = item.id; }
            });
        } else {            
            this.recordedFactorList.forEach((item) => {
                if (item.isSelected) { this.modalForm.sub_list_id = item.id; }
            });
        }

        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    sub_list_id: this.modalForm.sub_list_id,
                    is_delete_all: 0,
                    sub_type: subType
                }; 
                axios.post('/api/PolicyLists/DeleteSubListItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        if(subType == 1) this.policyBranchSubLists = response.data;
                        if(subType == 2) this.recordedFactorList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllSubLists(subType){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    sub_list_id: null,
                    sub_type: subType
                }; 
                axios.post('/api/PolicyLists/DeleteSubListItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        if(subType == 1) this.policyBranchSubLists = [];
                        if(subType == 2) this.recordedFactorList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    addFactorItem(){
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            sub_list_id: null,
            detail_list_type: this.factorForm.detail_list_type,
            sub_type: 2,
            is_required: this.factorForm.is_required
        };
        const that = this;
        axios.post('/api/PolicyLists/AddSubListItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.recordedFactorList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeFactorModal.click();
            }            
            this.isItemSaving = false;
        }) 
        .catch(function (error) {
            that.isItemSaving = false;
            console.log(error);
            this.Progress.finish();
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    editFactorItem(){
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            id: this.factorForm.id,
            sub_type: 2,
            is_required: this.factorForm.is_required
        };
        const that = this;
        axios.post('/api/PolicyLists/EditSubListItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.recordedFactorList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeFactorModal.click();
            }            
            this.isItemSaving = false;
        }) 
        .catch(function (error) {
            that.isItemSaving = false;
            console.log(error);
            this.Progress.finish();
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    onSelectItem(array, item) { 
        array.forEach((value) => {
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },
    onSelectAll() {
        this.coverageDetailList.forEach((value) => {
            value.isSelected = this.modalForm.isSelectedAll;
        });
    },
    onGetFactorTypes() {
        this.isEditModal = false;
        this.factorForm = {
            id: null,
            detail_list_type: 1,
            sub_list_id: '-1',
            is_required: 1
        };
        const parameters = {
            session_id: this.$getUser().session_id,
            tip: 28, //Factor Type
        };   
        const requestOne =  axios.post('/api/Lists/GetConst', parameters, {'Content-Type': 'application/json'}); 
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.factorTypeList = responses[0].data;
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    openEditFactorModal() {
        this.isEditModal = true;
        this.recordedFactorList.forEach((item) => {
            if (item.isSelected) {
                this.factorForm.is_required = item.is_required;
                this.factorForm.id = item.id;
            }
        });
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true);
    this.$setSessionStorage('tmp_session_id', null);
    this.getData();
  }
}; 
</script>