<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Controller Operation Groups
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Controller Operation Groups</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
        <router-link :to="{ name: 'ControllerSettings' }">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
              <span class="ms-2 fs-7"> Return back to Settings </span>
           </button>
            </router-link>
        </div> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <router-link to="/ControllerOperationGroupsCreate">
                                <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>Add Controller Operation Groups</button>
                            </router-link>
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Controller Operation Groups </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-0">
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Contoller-Operation-Groups-List')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="20"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                                <DxColumn data-field="auth_type" caption="Authorized User" header-cell-template="authorized-user-header" cell-template="authorized_user-cell-template"/> 
                                <DxColumn data-field="code" caption="Code" cell-template="code-cell-template"/> 
                                <DxColumn data-field="name" caption="List Name" cell-template="list_name-cell-template"/>
                                <DxColumn data-field="list_type" caption="Type" alignment="left" cell-template="list_type-cell-template" :allow-filtering="false"/>
                                <DxColumn data-field="is_default" caption="Is Default?" alignment="left" :allow-filtering="false" cell-template="is_default-cell-template"/>
                                <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/>
                                <DxColumn data-field="hide" caption="Is Hide?" alignment="center" cell-template="hide-cell-template" :allow-filtering="false"/> 

                                <!-- Header Cell Templates -->
                                <template #authorized-user-header>
                                    <div> Authorized </div> <div> User </div>
                                </template> 

                                <!-- Cell Templates -->
                                <template #authorized_user-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}" 
                                      class="text-gray-900 mb-1" @click="updateItem(data.data)">
                                        {{data.data.auth_type}}
                                    </a>
                                </template>
                                <template #code-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}" 
                                      class="text-gray-900 mb-1" @click="updateItem(data.data)">
                                        <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                            <i class="bi bi-pencil text-gray-900"></i> 
                                        </span> 
                                        {{ data.data.code }}
                                    </a>
                                </template>
                                <template #list_name-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}" 
                                      class="text-gray-900 fw-bold mb-1" @click="updateItem(data.data)">
                                        {{ data.data.name }}
                                    </a>
                                </template> 
                                <template #list_type-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}" 
                                      class="text-gray-900 fw-bold mb-1" @click="updateItem(data.data)">
                                        <span v-if="data.data.list_type"> Liability </span>
                                    </a>
                                </template>
                                <template #is_default-cell-template="{ data }">
                                    <span class="svg-icon svg-icon-1 svg-icon-success cursor-pointer" v-if="data.data.is_default" @click="updateItem(data.data)">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </template>
                                <template #description-cell-template="{ data }">
                                    <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}" 
                                      class="text-gray-900 fw-bold mb-1" @click="updateItem(data.data)">
                                        {{ data.data.description }}
                                    </a>
                                </template> 
                                <template #hide-cell-template="{ data }">
                                     <input class="form-check-input" type="checkbox" value="" id="hide" v-model="data.data.hide" @change="setListItemHide(data.data)">
                                </template>
                        </DxDataGrid>  
                    </div>
                  </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `LOG001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
  name: "ControllerOperationGroupsList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists: [],
      searchbar: '',
      isListProgressing: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/OperationGroups/GetOperationGroups', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.lists = response.data;
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.finish();
          setTimeout(() => {
            this.isListProgressing = false;
          }, 500);
        });
    },
    updateItem(item){ 
        if (item.full_access == 0) return;
        this.$setSessionStorage("record_id", item.id);
        this.$router.push({ name: 'ControllerOperationGroupsUpdate' });
    },
    setListItemHide(data) {
        this.$swal.fire({
            title: data.hide == true ? 'Are you sure to hide this selected data from the lists?' : 'Are you sure to show this selected data from the lists?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
            if (result.isConfirmed) {
                  const params = {
                    session_id: this.$getUser().session_id,
                    list_type: 21,
                    hide: data.hide,
                    id: data.id
                  };      
                  params.hide = params.hide == true ? 1: 0;
                  axios.post('/api/Lists/SetListItemHide', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                              this.getData();
                          } 
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                    });
            }
            else{
                data.hide = !data.hide;
            }
        });  
    }
  },
  created() { 
    if(this.$getSessionStorage('refreshList')){
        this.getData();
        this.$removeSessionStorage('refreshList'); 
    }
    else{
    this.lists = [];
    }
  }
}; 
</script>