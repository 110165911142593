<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3">
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
          System Account
        </h1>
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
          <li class="breadcrumb-item text-gray-600">
            <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
          </li>
          <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
          <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>
          <li class="breadcrumb-item text-gray-600">System Account</li>
        </ul>
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link to="/SystemSettings">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> Return back to Settings </span>
              </button>
          </router-link>
      </div> 
      </div> 
    <div class="content flex-row-fluid mt-5" id="kt_content">
      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img src="assets/theme/media/avatars/300-1.jpg" alt="image" />
                <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <a href="#" class=" text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                      {{ form.company_name }}
                    </a>
                    <a href="#">
                      <span class="svg-icon svg-icon-1 svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                          <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                          <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                        </svg>
                      </span>
                    </a> 
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a href="#" class=" d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                      <span class="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="currentColor"></path>
                        <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="currentColor"></path>
                        </svg>
                      </span>  
                      System Account
                    </a>
                    <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"> 
                      <span class="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor"></path>
                          <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor"></path>
                        </svg>
                      </span>   
                      {{ form.country_name }}
                    </a>
                    <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"> 
                      <span class="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                          <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                        </svg>
                      </span>
                      {{ form.company_email}}
                    </a>
                  </div> 
                </div> 
              </div>  
              <div class="d-flex flex-wrap flex-stack"> 
                <div class="d-flex flex-column flex-grow-1 pe-8"> 
                  <div class="d-flex flex-wrap"> 
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"></path>
                          </svg>
                        </span> 
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">
                          $4,500
                        </div>
                      </div> 
                      <div class="fw-bold fs-6 text-gray-400">Companies</div> 
                    </div> 
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
                          </svg>
                        </span> 
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">
                          75
                        </div>
                      </div> 
                      <div class="fw-bold fs-6 text-gray-400">Users</div> 
                    </div> 
                  </div> 
                </div> 
              </div> 
            </div> 
          </div>
        </div>
      </div> 
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div class="card-header cursor-pointer">
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">System Account</h3>
          </div>
          <a href="#" class="btn btn-light-warning border border-warning align-self-center btn-sm">
            <i class="fa fa-key"></i> Register your Licence Key
          </a> 
        </div> 
        <div class="card-body p-9">  
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">Licence ID</label>
              <div class="col-lg-6">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="" name="name" v-model="form.licence_id" disabled> 
                    <span class="input-group-text badge badge-success ml-3"> Verified</span>
                  </div>
              </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Country</label> 
            <div class="col-lg-6">
              <Select2 v-model="form.country_code" :options="countryList" :settings="{width: '100%'}" />
            </div> 
          </div>
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">Currency</label> 
            <div class="col-lg-6">
              <Select2 v-model="form.currency_code" :options="this.currenyList" :settings="{width: '100%'}" />
            </div> 
          </div>
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">Server Name</label> 
            <div class="col-lg-6">
              <input type="text" class="form-control" name="name" v-model="form.company_name"> 

            </div>
          </div>
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">
              Mobile Phone
            </label> 
            <div class="col-lg-6 d-flex align-items-center">
              <div class="input-group">
                <input type="text" class="form-control" name="name" v-model="form.company_gsm"> 
              </div>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Phone</label>
            <div class="col-lg-6">
              <div class="input-group">
                <input type="text" class="form-control" name="name" v-model="form.company_landphone"> 
              </div>
            </div> 
          </div> 
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">
              Email
            </label> 
            <div class="col-lg-6">
              <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_email"> 
            </div> 
          </div> 
          <div class="row mb-7"> 
            <label class="col-lg-4 fw-bold text-muted">Activation Date</label> 
            <div class="col-lg-6">
              <!-- <span class="fw-bolder fs-6 text-gray-800">{{ previewDate(form.activation_date) }}</span> -->
              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.activation_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>  
            </div> 
          </div> 
        </div> 
        <div class="card-footer p-3">  
          <div class="col-lg-12">
              <div class="d-grid gap-2">
                  <button type="button" class="btn btn-primary" @click="this.saveData()" form-kt-indicator="on" >
                      <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      </svg>
                      </span> 
                      <strong v-if="!this.isProgressing"> Update System Account </strong> 
                          <span class="indicator-progress" v-if="this.isProgressing">
                              Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span> 
                  </button>
              </div>                 
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
// Component Alias: `SAC001`
import axios from 'axios';
import Swal from 'sweetalert2';
  
import moment from 'moment';
 
export default {
  name: "SystemAccount",
  data() {
    return {
      form: {
        licence_id: null,
        country_code: null,
        currency_code: null,
        company_name: null,
        company_gsm: null,
        company_landphone: null,
        company_email: null,
        activation_date: moment()
      },
      isProgressing: false,
      currenyList:[],
      countryList:[]
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() {
      const params = {
        session_id: this.$getUser().session_id
      };
      const parameters = {
        session_id: this.$getUser().session_id,
        get_access_status: 0
      };  
      const requestOne =  axios.post('/api/Accounts/SystemAccount', params, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/Lists/Currencies', parameters, {'Content-Type': 'application/json'});
      const requestThree =  axios.post('/api/Lists/GetCountryList', parameters, {'Content-Type': 'application/json'});

      axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...response) => {        
        if(response[0].data.error == null || response[0].data.error == 'undefined' || response[0].data.error == ''){
          response[0].data.country_code = this.$unlock(response[0].data.country_code);
          response[0].data.currency_code = this.$unlock(response[0].data.currency_code);
          response[0].data.company_name = this.$unlock(response[0].data.company_name);
          response[0].data.company_gsm = this.$unlock(response[0].data.company_gsm);
          response[0].data.company_landphone = this.$unlock(response[0].data.company_landphone);
          response[0].data.company_email = this.$unlock(response[0].data.company_email);

          this.form = response[0].data;
        }
        this.currenyList = response[1].data;
        this.countryList = response[2].data;
      }))
        .catch(function (error) {
          console.log(error);
        });
        
    },
    getSystemAccount() {      
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/Accounts/SystemAccount', params, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){     
          response.data.country_code = this.$unlock(response.data.country_code);
          response.data.currency_code = this.$unlock(response.data.currency_code);
          response.data.company_name = this.$unlock(response.data.company_name);
          response.data.company_gsm = this.$unlock(response.data.company_gsm);
          response.data.company_landphone = this.$unlock(response.data.company_landphone);
          response.data.company_email = this.$unlock(response.data.company_email);

          this.form = response.data;
        }
      })
      .catch(function (error) {
         console.log(error);
      });
    },
    saveData(){
      const params= this.form;
      params.activation_date = moment(params.activation_date).format('YYYY-MM-DD[T]HH:mm:ss');
      params.session_id = this.$getUser().session_id;
      const that = this;
      axios.post('/api/Accounts/UpdateSystemAccount', params, {'Content-Type': 'application/json'})
      .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              that.$swal("Success", "Data saved succesfully..!", 'success');
              that.getSystemAccount();
            }
        })
        .catch(function (error) {
            console.log(error);
            new Swal( { icon: 'error', title: 'Error...', text: 'Do not leave the Licence ID blank..!' });   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    }
  },
  created() {
    setTimeout(this.getData(), 500);
  }
};
</script>