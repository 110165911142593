<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Controller Admin Settings</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Controller Admin Settings</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/Settings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div>
    </div>

    <div class="content flex-row-fluid mt-4">
        <div class="row g-6 g-xl-9 text-center p-6 pt-2 mt-6 pb-12 bg-white bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow rounded min-h-250px" 
              style="background-image: url('../assets/theme/media/svg/shapes/abstract-2.svg')"> 
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                <router-link to="/ControllerAccountList">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-warning svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="currentColor"/>
                            <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Controller Account
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                 <router-link to="/ControllerOperationGroupsList"> 
                    <a href="#" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-warning svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="currentColor"/>
                            <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Controller Operation Groups
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                <router-link to="/ControllerUserList">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-warning svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="currentColor"/>
                            <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Controller Users
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                <router-link to="/FixedPaymentValueList">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-warning svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="currentColor"/>
                            <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Fixed Payment Values
                        </h5>
                    </a>
                </router-link>
            </div>
        </div>
    </div>  
  </div>  
</template>

<script>
// import axios from 'axios' 
// import Swal from 'sweetalert2';
  

 
export default {
    name: "ControllerSettings", 
    data() {
        return {
        lists:[],
        searchbar: '',
        activeMenu: 'Home',      
        userData: this.$getUser()
        };
    },
    methods: {
            getData() {
                this.activeMenu = this.$route.params.type;
            },
            onClickMenu(param) {
                this.activeMenu = param;
            }
        }, 
        created() {
        if(this.$getSessionStorage('refreshList')){
            this.getData();
        this.$removeSessionStorage('refreshList'); 
        }
        else{
            this.lists = [];
        }
    }
}; 
</script>