<template> 
  <div> 
    <div class="ms-4">
        <div class="d-flex">
            <div class="p-2 flex-grow-1">
                <div class="page-title"> 
                    <h1 class="text-dark fw-bolder my-1 fs-3">Comparative Balance-Sheet</h1>  
                    <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                        <li class="breadcrumb-item text-gray-600">
                            <a href="#" class="text-gray-600 text-hover-primary">
                                <router-link :to="{ name: 'Home' }">Home</router-link>
                            </a>
                        </li> 
                        <li class="breadcrumb-item text-primary">Accounting</li>  
                        <li class="breadcrumb-item text-primary">Balance-Sheet</li>    
                        <li class="breadcrumb-item text-gray-600">Comparative Balance-Sheet</li> 
                    </ul>  
                </div>  
            </div> 
        </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="row p-9 pb-4">
                <div class="col-lg-9">
                       <div class="fv-row mb-4 row">
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Accounting Period</span> 
                            </label>   
                            <Select2 v-model="filterForm.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event)"/>  
                        </div>
                         <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>View Type</span>
                            </label> 
                            <Select2 v-model="filterForm.view_type" :options="viewTypesList" :settings="{ width: '100%' }"/> 
                        </div> 
                         <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Report Type</span>
                            </label>
                            <Select2 v-model="filterForm.report_type" :options="reportTypes"  :settings="{ width: '100%' }" @select="onReportTypeChange()"/> 
                        </div>  
                        <div class="col-lg" v-if="filterForm.report_type != 2 && filterForm.report_type != 3">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Year Count</span>
                            </label>
                            <Select2 v-model="filterForm.year_count" :options="yearCountList" :settings="{ width: '100%' }"/>  
                        </div>  
                        <div class="col-lg" v-if="this.filterForm.report_type == 1">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Exclude Closing Entries</span> 
                            </label>   
                            <Select2 v-model="filterForm.except_closing_items" :options="exceptClosingItemsList" :settings="{ width: '100%' }"/>  
                        </div> 
                        <div class="col-lg" v-if="this.filterForm.report_type == 1"  style="margin-top: -18px;">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Get Previous Period <br>By Opening Entries</span> 
                            </label>   
                            <Select2 v-model="filterForm.load_prev_period_from_opening_items" :options="loadPrevList" :settings="{ width: '100%' }"/>  
                        </div> 
                        <div class="col-lg" v-if="this.filterForm.report_type == 2"  style="margin-top: -18px;">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Calculate by carrying over<br>From Previous Month</span> 
                            </label>   
                            <Select2 v-model="filterForm.turnover_prev_month" :options="loadPrevList" :settings="{ width: '100%' }"/>  
                        </div>  
                    </div> 
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">  
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> Reset All Criterias </span>
                            </template>
                        </Popper> 
                         <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on"> 
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span> 
                            <span v-if="!this.isListProgressing"> List Transactions </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                </div>
            </div> 
            <div class="card-body pt-3">
                 <div v-if="this.lists && this.lists.length > 0">
                    <div class="bg-light p-3 text-center" v-if="filterForm.report_type == 1">
                        <h3 class="text-center"> 
                            {{ filterForm.periodSelection.period_year_month_short }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            Comparative Balance-Sheet 
                        </h3>
                        <span class="text-center mb-0 fw-bold"> ({{ previewOnlyDate(filterForm.periodSelection.start_date) }} - {{ previewOnlyDate(filterForm.periodSelection.end_date) }}) </span>
                    </div>
                    <div class="bg-light p-3 text-center" v-if="filterForm.report_type == 2">
                        <h3 class="text-center mb-0"> 
                            {{ this.getPeriodMonthValue(this.filterForm.period_month) }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            Monthly Comparative Balance-Sheet 
                        </h3>
                        <span class="text-center mb-0 fw-bold"> ({{ previewOnlyDate(filterForm.periodSelection.start_date) }} - {{ previewOnlyDate(filterForm.periodSelection.end_date) }}) </span>
                    </div>
                    <div class="bg-light p-3 text-center" v-if="filterForm.report_type == 3">
                        <h3 class="text-center mb-0">  
                            {{ filterForm.periodSelection.period_year_month_short }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            Periodic Comparative Balance-Sheet 
                        </h3>
                        <span class="text-center mb-0 fw-bold"> ({{ previewOnlyDate(filterForm.periodSelection.start_date) }} - {{ previewOnlyDate(filterForm.periodSelection.end_date) }}) </span>
                    </div>
                </div>
                <div class="separator my-3 mt-0 border-gray-300"></div>
                <div>
                    <DxDataGrid 
                            :show-borders="true"
                            :data-source="lists"
                            :column-auto-width="true"
                            :hover-state-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="onCustomizeColumn"
                            @row-prepared="onRowPrepared"   
                            @cell-prepared="onCellPrepared"
                            @exporting="this.$onExporting($event , 'Comparative-Balance-Sheet-List')"> 
                              
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager
                                :visible="false"
                                :allowed-page-sizes="pageSizes"
                                :show-page-size-selector="false"
                                :show-info="false"
                                :show-navigation-buttons="false"
                            />
                            <DxFilterRow :visible="true"/>
                            <DxSearchPanel :visible="true"/>
                            <DxSorting mode="none"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                                
                            />
                            <template #headerTemplate="{ data: data }">
                                <div v-html="setColumnTemplate(data)"></div> 
                            </template>
                            
                    </DxDataGrid>
                </div> 
            </div>
        </div> 
    </div>   
  </div>  
</template>
<style> 
.fs-10px {
    font-size: 10px !important;
}
</style>
<script>
import axios from 'axios';
  

import moment from 'moment';
import { DxDataGrid, DxExport, DxPaging, DxPager, DxSorting, DxSearchPanel, DxFilterRow, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "BalanceSheetList",
    components:{
        DxDataGrid, DxExport, DxPaging, DxPager, DxSorting, DxSearchPanel, DxFilterRow, DxScrolling
    },
    data() {
        return {
            accountingYears: [], 
            pageSizes: [50, 100, 200, 400, 1000, 'all'],
            pageDetailSizes: [10, 20, 50, 100, 'all'],
            lists: [],
            summary: {},
            accountingYearList: [],
            reportTypes: [{ id: '1', text: 'General'}, { id: '2', text: 'Monthly'}, { id: '3', text: 'Period Years'}],
            viewTypesList: [ { id: '1', text: 'With Details'}, { id: '2', text: 'Main Plans Only'} ],
            yearCountList: [],
            periodList: [],
            exceptClosingItemsList: [ { id: 0, text: 'No'}, { id: 1, text: 'Yes'} ],
            loadPrevList: [ { id: 0, text: 'No'}, { id: 1, text: 'Yes'} ],
            searchbar: '',
            detailList: [],
            detailSummary: {},
            detailTurnover: null,
            latestParameterCollection: null,
            filterForm: {
                start_date: moment(),
                end_date: moment(),
                report_type: 1,
                period_month: null,
                with_empty: 0,
                year_count: 2,
                view_type: 1,
                period_id: null,
                periodSelection: {},
                except_closing_items: 0,
                load_prev_period_from_opening_items: 0,
                turnover_prev_month: 0
            },
            userData: this.$getUser(),
            isListProgressing: false,
            isAccountingYearLoading: false
        };
    },
    computed: { 
        setColumnTemplate(){
            return (value)=>{
              return value.column.caption;             
            }
        },
        searchableList() {
            return this.lists.filter(p => { 
                return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            });
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        previewOnlyDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        getReportTypeValue(){
          return (value)=>{
                if (value) {
                   this.reportTypes.forEach((item) => { 
                        if(item.id == value){
                            return item.text;
                        }
                   }); 
              }
          } 
        },
        getPeriodMonthValue(){
             return (value)=>{
                if (value) {
                    var period = this.periodList.find(p => { 
                        return p.id == value && p.id != '-1';
                    }); 
                    if(period){
                        return period.text;
                    }
                    else{
                        return null;
                    }
              }
          } 
        }
    },
    methods: {
        getData() {
            const that = this;
            this.lists = [];
            this.latestParameterCollection = null;
            this.$Progress.start();
            this.isListProgressing = true;
            const queryParameter = {
                session_id: this.$getUser().session_id,
                start_date: null,
                end_date: null,
                report_type: this.filterForm.report_type,
                month_number: this.filterForm.period_month, 
                view_type: this.filterForm.view_type,
                year_count: this.filterForm.year_count,
                period_id: this.filterForm.period_id,
                except_closing_items: this.filterForm.except_closing_items,
                load_prev_period_from_opening_items: this.filterForm.load_prev_period_from_opening_items,
                turnover_prev_month: this.filterForm.turnover_prev_month
            };   
            axios.post('/api/BalanceSheet/GetComparativeBalanceSheet', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {
                    this.lists = response.data;
                    this.latestParameterCollection = queryParameter;
                    this.isListProgressing = false;  
                    this.$Progress.finish();
                })
                .catch(function () {
                    that.isListProgressing = false;
                    that.$Progress.finish();
                });
        },
        onReportTypeChange(){
            this.periodList = [];
            if(this.filterForm.report_type == 2){
                this.$Progress.start(); 
                const queryParameter = {
                    session_id: this.$getUser().session_id,
                    period_id: this.filterForm.period_id
                };   
                axios.post('/api/BalanceSheet/GetPeriodMonths', queryParameter, {'Content-Type': 'application/json'}) 
                    .then((response) => { 
                        this.periodList = response.data;
                        this.periodList.unshift({ id: -1, text: 'Select an option'});
                        this.filterForm.period_month = -1;
                        this.$Progress.finish();
                    })
                    .catch(function () {
                        this.$Progress.finish();
                    });
            } 
        },
        viewDetail(id){
            this.detailList = [];
            this.$Progress.start(); 
            const queryParameter = {
                session_id: this.$getUser().session_id,
                accounting_plan_id: id,
                report_type: this.latestParameterCollection.report_type,
                period_month: this.latestParameterCollection.period_month,
                start_date: this.latestParameterCollection.start_date,
                end_date: this.latestParameterCollection.end_date,
                period_id: this.latestParameterCollection.period_id
            }; 
            axios.post('/api/CompanyManagement/GetPlanDetailById', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {  
                    this.detailList = response.data.plans;
                    this.detailSummary = response.data.summary;  
                    this.detailTurnover = response.data.turnover;
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });
        },
        setSummaries(options){
            if(options.name == "total_debit") { options.totalValue = this.summary.grand_total_debit }
            if(options.name == "total_credit") { options.totalValue = this.summary.grand_total_credit }
            if(options.name == "balance_debit") { options.totalValue = this.summary.grand_balance_debit }
            if(options.name == "balance_credit") { options.totalValue = this.summary.grand_balance_credit } 
        },
        setDetailSummaries(options){
            if(options.name == "total_detail_debit") { options.totalValue = this.detailSummary.total_debit }
            if(options.name == "total_detail_credit") { options.totalValue = this.detailSummary.total_credit }
            if(options.name == "total_detail_balance") { options.totalValue = this.detailSummary.total_balance }
        }, 
        getAccountingYears(){
        this.companyAccountingPeriods = [];
        this.yearCountList = [];
        const queryParameter = {
            id: this.$getSessionStorage("selected_company").id,
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {
            this.accountingYears = response.data; 
            this.accountingYears.forEach((period) => {
                if(period.id == this.$getSessionStorage("selected_company").activeYear){
                    this.filterForm.period_id = period.id; 
                    this.filterForm['periodSelection'] = period;
                } 
            }); 
            var count = 0;
            this.accountingYears.forEach(() => {
                if(count < 3){
                    this.yearCountList.push({
                        id: count + 1,
                        text : (count + 1) + ' Years'
                    });
                } 
                count++;
            });  
                
            if(count == 1){
                this.filterForm.year_count = 1;
            }

            setTimeout(() => {
                if(this.filterForm.period_id == null){ 
                    this.accountingYears.forEach((period) => {
                        if(period.id == this.userData.period_id){
                            this.filterForm.period_id = period.id;
                        } 
                    }); 
                }
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },  
        onChangeAccountYear(e){
            this.filterForm['periodSelection'] = e;
        },
        onCellPrepared: function (e) {
            if (e.rowType == 'data') {
                if (e.column.dataField !== 'total_balance' && e.column.dataField !== 'opening_balance' && e.column.dataField.includes('_balance')) {
                    e.cellElement.bgColor = '#F9FBE7';
                }
            }
        },
        onRowPrepared(e){  
             
             // Plan Level = 1 ve 2 olan satırlar için stil değişiklikleri uygulanır. 
             if(e.rowType === 'data') {
                if (e.data.plan_level == '1' && (e.rowElement.classList.value.includes('fw-boldest') == false)) {
                      e.rowElement.classList.add('fw-boldest');  
                      e.rowElement.classList.add('bg-secondary');
                }
                if (e.data.plan_level == '2'&& (e.rowElement.classList.value.includes('fw-bolder') == false)) {
                      e.rowElement.classList.add('fw-bolder');  
                } 
                e.rowElement.classList.add('fs-10px');

                //Plan Level 0 ise bu Totals olduğu anlamına gelir ve stiller güncellenir.
                if (e.data.plan_level == '0'&& (e.rowElement.classList.value.includes('fw-boldest') == false)) {
                      e.rowElement.classList.add('fw-boldest'); 
                      e.rowElement.classList.add('p-3'); 
                      e.rowElement.classList.add('bg-light-success'); 
                      e.rowElement.classList.remove('fs-10px'); 
                      e.rowElement.classList.add('fs-12px');
                }  
            } 
        }, 
        onCustomizeColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => { 
                        column.dataType = 'string';
                        if(column.dataField !== 'plan_level' && column.dataField !== 'account_name' && column.dataField !== 'account_code'){ 
                            if(column.caption.includes('Ended')){
                                column.caption = (column.caption).replaceAll('$', '/');
                                var newCaption = column.caption.split('Ended');
                                column.caption =  newCaption[0] + ' Ended <br>' + newCaption[1];
                            }  
                            column.alignment = 'right';
                            column.headerCellTemplate = 'headerTemplate';
                        } 
                        if(column.dataField == 'plan_level'){
                            column.visible= false; 
                        }
                        if(column.dataField == 'trn_count'){
                            column.visible= false;
                        }
                        if(column.dataField == 'account_name'){ 
                            column.caption =  'Account' + ' <br>' + 'Name';
                            column.headerCellTemplate = 'headerTemplate';
                        }
                        if(column.dataField == 'account_code'){ 
                            column.caption =  'Account' + ' <br>' + 'Code';
                            column.headerCellTemplate = 'headerTemplate';
                        }
                        if(column.dataField == 'opening_balance'){ 
                            column.caption =  'Opening' + ' <br>' + 'Balance';
                            column.headerCellTemplate = 'headerTemplate';
                        }
                        if(column.dataField == 'total_balance'){ 
                            column.caption =  'Total' + ' <br>' + 'Balance';
                            column.headerCellTemplate = 'headerTemplate';
                        } 
                    });  
                } 
            } 
        },
        setCriteriasToDefault() {
            this.filterForm = {
                start_date: moment(),
                end_date: moment(),
                report_type: 1,
                period_month: null,
                with_empty: 0,
                year_count: 2,
                view_type: 1,
                period_id: null,
                periodSelection: {},
                except_closing_items: 0,
                load_prev_period_from_opening_items: 0,
                turnover_prev_month: 0
            },
            this.getAccountingYears();
            this.lists = [];
        }
    },
    created() {
       this.$removeSessionStorage('record_id');
       this.getAccountingYears();
    }
}; 
</script>
