<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Security Settings Create</h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600">
                        <router-link :to="{ name: 'Settings' }" >Settings</router-link>
                    </li>
                    <li class="breadcrumb-item text-gray-600">
                        <router-link :to="{ name: 'SystemSettings' }">System Settings</router-link>
                    </li>
                    <li class="breadcrumb-item text-gray-600">
                        <router-link to="/SecuritySettings">Security Settings</router-link>
                    </li>
                    <li class="breadcrumb-item text-gray-600">Security Settings Create</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/SecuritySettings">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to List </span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-6" id="kt_content">
            <RequiredField></RequiredField>
            <div class="row">
                <div class="tab-content" id="myTabContent">
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                        <div class="p-10">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex flex-row row">
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-secondary">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Setting Type
                                                            </label>
                                                            <Select2 v-model="form.rate_type" :options="this.rateTypeList" :settings="{ width: '100%' }"/>
                                                        </div>
                                                        <div class="col-lg-12" v-if="form.rate_type == 2">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                IP
                                                            </label>
                                                            <div class="input-group">
                                                                <input ref="ip1" type="number" max="255" min="0" class="form-control" v-model="form.ip1" placeholder="000">
                                                                <span class="input-group-text" >.</span>
                                                                <input ref="ip2" type="number" max="255" min="0" class="form-control" v-model="form.ip2" placeholder="000" >
                                                                <span class="input-group-text" >.</span>
                                                                <input ref="ip3" type="number" max="255" min="0" class="form-control" v-model="form.ip3" placeholder="000" >
                                                                <span class="input-group-text" >.</span>
                                                                <input ref="ip4" type="number" max="255" min="0" class="form-control" v-model="form.ip4" placeholder="000" >
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 ">
                                                                Endpoint
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.endpoint_type" :options="endpointTypeList" :settings="{ width: '100%' }"/>
                                                                <input ref="rate_endpoint" type="text" class="form-control" v-model="form.rate_endpoint" placeholder="/api/action">
                                                            </div>
                                                            <div class="d-flex mt-2">
                                                                <i class="bi bi-info-circle text-primary fs-3 ms-3 me-3" ></i>
                                                                <small v-if="form.endpoint_type != 3">if you want to apply rate limiting for all operations. Type '/*' in the Endpoint section</small>
                                                                <small v-if="form.endpoint_type == 3">If you want to impose rate limitation for all its operations. Leave the Endpoint section blank</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-secondary">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Period
                                                            </label>
                                                            <div class="input-group">
                                                                <input ref="rate_period" type="number" class="form-control" v-model="form.rate_period">
                                                                <Select2 v-model="form.period_id" :options="periodTypeList" :settings="{ width: '100%' }" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Limit
                                                            </label>
                                                            <div class="position-relative">
                                                                <input ref="rate_limit" type="number" class="form-control" v-model="form.rate_limit">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" for="isActive">
                                                                Active
                                                            </label>
                                                            <div class="form-check mt-3 mb-3">
                                                                <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.isActive">
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-5">
                                    <div class="d-grid gap-2">
                                        <button type="button" class="btn btn-lg btn-success" @click="this.saveData()" data-kt-indicator="on">
                                            <span class="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                            <strong v-if="!this.isProgressing"> Save Rate Limit Role </strong>
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default{
    name:'SecuritySettingsCreate',
    data(){
        return {
            form: {
                rate_type: 1,
                rate_ip: '',
                rate_endpoint: '',
                rate_period: '',
                rate_period_time: '',
                rate_limit: 1,
                period_id: 1,
                ip1: '',
                ip2: '',
                ip3: '',
                ip4: '',
                endpoint_type: 1,
                is_active: 1,
                isActive: true
            },
            isProgressing: false,
            endpointTypeList: [
                { id: 1, text: 'GET' },
                { id: 2, text: 'POST' },
                { id: 3, text: '*' }
            ],
            periodTypeList: [
                { id: 1, text: 's' },
                { id: 2, text: 'm' },
                { id: 3, text: 'h' }
            ],
            rateTypeList: [{ id: 1, text: 'General Rate Limiting' }, { id: 2, text: 'Ip Rate Limiting' }],
        };
    },
    methods:{
        saveData(){
            this.form.rate_endpoint = this.form.rate_endpoint.replaceAll(' ','') == "" ? this.endpointTypeList.find(x => x.id == this.form.endpoint_type).text : `${this.endpointTypeList.find(x => x.id == this.form.endpoint_type).text}:${this.form.rate_endpoint.replaceAll(" ","")}`;
            this.form.rate_period = `${this.form.rate_period == 0 ? 1 : this.form.rate_period}${this.periodTypeList.find(x => x.id == this.form.period_id).text}`;
            const params = this.form;
            params.session_id = this.$getUser().session_id;
            if(params.rate_type == 2) {
                params.rate_ip = (!params.ip1 ? 0 : params.ip1) + '.' + (!params.ip2 ? 0 : params.ip2) + '.' + (!params.ip3 ? 0 : params.ip3) + '.' + (!params.ip4 ? 0 : params.ip4);
            }
            params.is_active = params.isActive ? 1 : 0;
            const that = this;
            axios.post('/api/SecuritySettings/CreateSecuritySettingsDetails', params, {'Content-Type': 'application/json'})
            .then((response) => {
                if (response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
                    that.$swal("Success", "Data saved succesfully..!", 'success');
                    that.$setSessionStorage("record_id", response.data);
                    that.$router.push({ name: 'SecuritySettingsUpdate' });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                that.form.rate_period = that.form.rate_period.replace(/[smh]/, "");
                that.form.rate_endpoint = that.form.rate_endpoint.split(":")[1] == undefined ? '' : that.form.rate_endpoint.split(":")[1];
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            });
        }
    }
}
</script>