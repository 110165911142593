<template>
    <div>
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Search Result
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeCompanySearchModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                            </svg>
                        </span>
                    <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15 form-control-sm" placeholder="Search in Results" v-model="searchBar" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0"/>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%" v-if="this.isCompanySearchInProgress">
                            Getting Company List. Please wait...
                        </div>
                    </div>
                    <h5 v-if="this.companySearchResult.length == 0 && !this.isCompanySearchInProgress">
                        No result found.
                    </h5>
                    <table class="table table-row-dashed table-row-gray-300 table-hover" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Registration Number</th>
                                <th>Date of Creation</th>
                                <th>Address</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of searchableList" v-bind:key="item">
                                <td>
                                    <strong>{{ item.title }}</strong> <br>
                                    <small>{{ item.description }}</small>
                                </td>
                                <td>{{ item.companyNumber }}</td> 
                                <td>{{ previewDate(item.dateOfCreation) }}</td>
                                <td> 
                                    <small>
                                        {{ item.address.addressLine1 }} {{ item.address.postalCode }}<br>
                                        {{ item.address.locality }} / {{ item.address.country }}
                                    </small>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-primary" @click="onSelectSearchResult(item.companyNumber)">Select</button>
                                </td>
                            </tr> 
                        </tbody>
                    </table> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
  

export default {
    name: 'SearchCompanyByName',
    props: {
        accountName: String
    },
    data() {
        return {
            companySearchResult: [],
            searchBar: '',
            isCompanySearchInProgress: false
        }
    },
    computed: {
        searchableList() {
            return this.companySearchResult.filter(p => { 
                return p.title.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1 || 
                p.companyNumber.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1
            });
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
    },
    methods: {
        getData() {
            this.isCompanySearchInProgress = true;
            this.companySearchResult = [];
            this.searchBar = '';
            const parameters = {
                CompanyName: this.accountName,
                SessionId: this.$getUser().session_id
            }
            axios.post('api/Accounts/SearchByName', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.companySearchResult = response.data;
                    this.isCompanySearchInProgress = false;
                } else {
                    setTimeout(() => {
                        this.$refs.closeCompanySearchModal.click(); 
                    }, 500);
                }
            }).catch(errors => {
                console.error(errors); 
                this.isCompanySearchInProgress = false;
            });
        },
        onSelectSearchResult(selectedCompanyNumber){
            this.$parent.onSearchCompanyByNo(selectedCompanyNumber);
            this.$refs.closeCompanySearchModal.click(); 
        }
    }
}
</script>