<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            General Company Settings
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CompanySettings' }">Company Settings</router-link></li>
          </ul> 
      </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/CompanySettings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="card card-xxl-stretch">
        <div class="card-header">
          <div class="card-title">
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
              <li class="nav-item">
                <a class="nav-link" href="#company_ganeral" @click="this.activeView = 'General'" :class="{'text-active-primary active' :activeView == 'General'}" data-bs-toggle="tab" target="_self">
                  General
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#company_translate" @click="getTranslateList()" :class="{'text-active-primary active' :activeView == 'Translate'}" data-bs-toggle="tab" target="_self" >
                  Translate
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#company_translate_values" @click="getTranslateValues()" :class="{'text-active-primary active' :activeView == 'TranslateValues'}" data-bs-toggle="tab" target="_self" >
                  Translate Values
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="this.activeView == 'General'" class="tab-pane fade show active" id="company_general" role="tabpanel">
          <div class="row">
              <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                  <div class="p-10">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <div class="form-check">
                                                    <input class="form-check-input fs-6 fw-bold form-label mt-3" type="checkbox" id="isAllowRegistrationNumberDuplication" v-model="form.is_allow_registration_number_duplication">
                                                    <label class="fs-6 fw-bold form-label mt-3" for="isDefault">
                                                      Is Allow Registration Number Duplication
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> 
                      <div class="d-grid gap-2 "> 
                          <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on">
                              <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              </svg>
                              </span>
                                <strong v-if="!this.isProgressing"> Save Company General Settings </strong> 
                                  <span class="indicator-progress" v-if="this.isProgressing">
                                      Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span> 
                          </button>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div v-if="this.activeView == 'Translate'" class="tab-pane fade show active" id="company_translate" role="tabpanel">
          <div class="d-flex flex-column flex-row-auto col-lg-12 mt-3 p-4"> 
              <div class="d-flex flex-column-fluid col-xl-12 card justify-content-center card-xxl-stretch bg-lighten border border-gray-300">
                  <div class="card-body">
                      <form id="kt_ecommerce_settings_general_form"
                          class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                          <div class="fv-row row">
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    Select Language
                                </label>
                                <div class="row">
                                  <div class="col-xl-6">
                                    <Select2 v-model="this.isSelectedLanguage.id"  :options="this.languages" @select="onSelectLanguage($event)" :settings="{ width: '100%' }"/>
                                  </div>
                                    <div class="col-xl-6 mt-3 mt-xl-0 d-flex align-items-center">
                                      <button class="btn ps-4 p-3 me-3 bg-success" type="button" @click="addLanguage(this.isSelectedLanguage.title)" :disabled="this.isSelectedLanguage.id == '-1'"> 
                                        <i class="bi bi-plus "  style="font-size: 1.5rem; color: white"></i>
                                      </button>
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" name="is_translate" id="inlineRadio2" value="api" v-model="this.isTranslate" v-custom>
                                          <label class="form-check-label" for="inlineRadio2">Use Translate API</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" name="is_local" id="inlineRadio1" value="local" v-model="this.isTranslate" v-custom>
                                          <label class="form-check-label" for="inlineRadio1">Use Local</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div> 
              <table v-if="this.isSelectedLanguages.length > 0 " class="table table-rounded table-striped border gy-7 gs-7 mt-4">
                <thead>
                  <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                    <td>Language</td>
                    <td>Translate</td>
                    <td>Remove</td>
                  </tr>
                </thead>
                <tbody class="fw-semibold fs-6 text-gray-700">
                  <tr v-for="(language, index) in isSelectedLanguages" :key="index">
                    <td>{{ language.title }}</td>
                    <td>{{ language.use_local == 1 ? "Use Local" : "Use Translate API" }}</td>
                    <td>
                        <button class="btn btn-danger p-2 pe-1"  @click="removeLanguage(language.id)"> 
                            <i class="bi bi-dash " style="font-size: 1.5rem; color: white"></i>
                        </button>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div v-if="this.activeView == 'TranslateValues'" class="tab-pane fade show active" id="company_translate_values" role="tabpanel">
          <div class="d-flex flex-column flex-row-auto col-lg-12 mt-3 p-4"> 
              <div class="d-flex flex-column-fluid col-xl-12 card justify-content-center card-xxl-stretch bg-lighten border border-gray-300">
                  <div class="card-body">
                      <form id="kt_ecommerce_settings_general_form"
                          class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                          <div class="fv-row row">
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    Select Language
                                </label>
                                <div class="row">
                                  <div class="col-xl-6">
                                    <Select2 v-model="this.selectedLocalLanguage"  :options="this.languages" @select="onChangeTranslateSelection($event)" />
                                  </div> 
                                </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
              <div class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-3 mt-3" v-if="this.isLanguageValuesEmpty == true">
                  <i class="ki-duotone ki-notification-bing fs-2hx text-primary me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                  <div class="d-flex flex-column pe-0 pe-sm-10">
                      <h4 class="fw-semibold">No Language Values Found...</h4>
                      <span>Selected language has no values saved yet. You can import default language values to continue progress.</span>
                  </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary" v-if="this.isLanguageValuesEmpty == true" @click="this.onImportDefaultValues()">
                Import Default Values
              </button>
              <table v-if="this.selectedLocalLanguageList.length > 0 " class="table table-rounded table-striped border gy-7 gs-7 mt-4">
                <thead>
                  <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Code</th>
                    <th>Original Text</th>
                    <th>Translate Value</th>
                  </tr>
                </thead>
                <tbody class="fw-semibold fs-7 text-gray-700">
                  <tr v-for="(language, index) in selectedLocalLanguageList" :key="index">
                    <td>
                      <span class="badge badge-primary">
                        {{ language.language_code }}
                      </span> 
                    </td>
                    <td class="w-200px">{{ language.original_text}}</td>
                    <td>
                        <div class="input-group">
                          <textarea class="form-control form-control-sm" rows="3" v-model="language.translate_value" />
                          <span class="input-group-text p-0">
                            <button class="btn btn-secondary btn-sm p-2" @click="onSaveLanguageValue(language)">
                              Save
                            </button>
                          </span>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</template>

<script>
import axios from 'axios' 

export default {
  name: "GeneralCompanySettings",
  data() {
    return {
      isSelectedLanguage:{
        id: '-1',
        code: null,
        title: null,
        use_local: null,
        use_api: null
      },
      isSelectedLanguages: [],
      selectedLocalLanguageList: [],
      isTranslate: 'api',
      isProgressing: false,
      isLanguageValuesEmpty: false,
      form: {
        list_date_range: 10,
        is_allow_registration_number_duplication: false,
        consolidation_rate_type: 0,
        consolidation_currency: null
      },
      activeView: "General",
      languages: [
        {
          id: '-1',
          text: 'Select on option ',
        },
        {
          id: 'en|af',
          text: 'Afrikaans',
        },
        {
          id: 'en|sq',
          text: 'Albanian',
        },
        {
          id: 'en|ar',
          text: 'Arabic',
        },
        {
          id: 'en|hy',
          text: 'Armenian',
        },
        {
          id: 'en|az',
          text: 'Azerbaijani',
        },
        {
          id: 'en|eu',
          text: 'Basque',
        },
        {
          id: 'en|be',
          text: 'Belarusian',
        },
        {
          id: 'en|bg',
          text: 'Bulgarian',
        },
        {
          id: 'en|ca',
          text: 'Catalan',
        },
        {
          id: 'en|zh-CN',
          text: 'Chinese (Simplified)',
        },
        {
          id: 'en|zh-TW',
          text: 'Chinese (Traditional)',
        },
        {
          id: 'en|hr',
          text: 'Croatian',
        },
        {
          id: 'en|cs',
          text: 'Czech',
        },

        {
          id: 'en|da',
          text: 'Danish',
        },
        {
          id: 'en|nl',
          text: 'Dutch',
        },
        {
          id: 'en|en',
          text: 'English',
        },
        {
          id: 'en|et',
          text: 'Estonian',
        },
        {
          id: 'en|tl',
          text: 'Filipino',
        },
        {
          id: 'en|fi',
          text: 'Finnish',
        },
        {
          id: 'en|fr',
          text: 'French',
        },

        {
          id: 'en|de',
          text: 'German',
        },
        {
          id: 'en|el',
          text: 'Greek',
        },
        {
          id: 'en|hu',
          text: 'Hungarian',
        },
        {
          id: 'en|id',
          text: 'Indonesian',
        },
        {
          id: 'en|ga',
          text: 'Irish',
        },
        {
          id: 'en|it',
          text: 'Italian',
        },
        {
          id: 'en|ja',
          text: 'Japanese',
        },
        {
          id: 'en|ko',
          text: 'Korean',
        },
        {
          id: 'en|lt',
          text: 'Lithuanian',
        },
        {
          id: 'en|ms',
          text: 'Malay',
        },
        {
          id: 'en|no',
          text: 'Norwegian',
        },
        {
          id: 'en|pl',
          text: 'Polish',
        },

        {
          id: 'en|pt',
          text: 'Portuguese',
        },
        {
          id: '"en|ro',
          text: 'Romanian',
        },
        {
          id: 'en|ru',
          text: 'Russian',
        },
        {
          id: 'en|es',
          text: 'Spanish',
        },
        {
          id: 'en|sv',
          text: 'Swedish',
        },
        {
          id: 'en|th',
          text: 'Thai',
        },
        {
          id: 'en|tr',
          text: 'Turkish',
        },
        {
          id: 'en|uk',
          text: 'Ukrainian',
        },

      ]
    };
  },
  methods: {
    getData() {
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/SystemParameters/GetGeneralSystemParameters', params, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.form = { 
              list_date_range: response.data.list_date_range,
              is_allow_registration_number_duplication: response.data.is_allow_registration_number_duplication == 1 ? true : false,
              consolidation_rate_type: response.data.consolidation_rate_type,
              consolidation_currency: response.data.consolidation_currency
            };   
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form;
      params.session_id = this.$getUser().session_id;
      params.is_allow_registration_number_duplication = params.is_allow_registration_number_duplication == true ? 1 : 0;
      const that = this;     
      axios.post('/api/SystemParameters/UpdateGeneralSystemParameters', params, {'Content-Type': 'application/json'})
        .then(() => { 
          this.$swal("Success", "Data saved succesfully..!", 'success');
          this.getData();
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    } ,
    onSelectLanguage(event){
      this.isSelectedLanguage.code = event.id;
      this.isSelectedLanguage.title = event.text;
    },
    addLanguage(title){
      var isThere = this.isSelectedLanguages.find((language) => language.title == title) ? true : false;
      if(!isThere && title != null){
        this.isSelectedLanguage.use_local = this.isTranslate == 'local' ? 1 : 0;
        this.isSelectedLanguage.use_api = this.isTranslate == 'api' ? 1 : 0;
        const params = this.isSelectedLanguage;
        params.session_id = this.$getUser().session_id;
        axios.post('/api/SystemParameters/CreateCompanyLanguageTranslate', params, {'Content-Type': 'application/json'})
        .then((response) =>{
          this.isSelectedLanguages = response.data;
          this.isSelectedLanguage = {
            id: '-1',
            code: null,
            title: null,
            use_local: null,
            use_api: null
          } 
        })
      }
      else{
        this.$swal("Failed", "List is also attached!", 'warning'); 
      }
    },
    removeLanguage(id){
      const params = {
        session_id:this.$getUser().session_id,
        id: id,
      };
      axios.post('/api/SystemParameters/DeleteCompanyLanguageTranslate', params, {'Content-Type': 'application/json'})
      .then((response) =>{
        this.isSelectedLanguages = response.data
      })
    },
    getTranslateList(){
      this.activeView = 'Translate';
      const params ={
        session_id : this.$getUser().session_id,
      };
      axios.post('/api/SystemParameters/GetCompanyLanguageTranslate', params, {'Content-Type': 'application/json'})
      .then((response) =>{
        this.isSelectedLanguages = response.data;
        this.isSelectedLanguage = {
            id: '-1',
            code: null,
            title: null,
            use_local: null,
            use_api: null
          } 
      });
    },
    onSaveLanguageValue(e){
      const params ={
        language_code : e.language_code,
        original_text : e.original_text,
        translate_value : e.translate_value
      };
      axios.post('/api/SystemParameters/SaveCompanyLanguageTranslateValues', params, {'Content-Type': 'application/json'})
      .then(() =>{
        
      });
    },
    getTranslateValues(){
      this.activeView = 'TranslateValues';
      this.selectedLocalLanguage = 'en|tr';
      this.onChangeTranslateSelection({ id: this.selectedLocalLanguage });
    },
    onChangeTranslateSelection(e){
       const params ={
          language_code : e.id
        };
        axios.post('/api/SystemParameters/GetCompanyLanguageTranslateValues', params, {'Content-Type': 'application/json'})
        .then((response) =>{
          this.selectedLocalLanguageList = response.data;
          if(response.data.length == 0){
            this.isLanguageValuesEmpty = true;
          }
        });
    },
    onImportDefaultValues(){
      const params = {
        session_id: this.$getUser().session_id,
        language_code: this.selectedLocalLanguage
      };
      axios.post('/api/SystemParameters/SaveCompanyLanguageTranslateDefaultValues', params, {'Content-Type': 'application/json'})
      .then(() =>{
           this.onChangeTranslateSelection({ id: this.selectedLocalLanguage });
      });
    }
  },
  mounted() { 
    this.getData();
  }
}; 
</script>