<template>
  <div> 
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 p-0">
                <div class="card-title">
                  <button type="button" class="btn btn-light-primary border border-primary me-3" data-bs-toggle="modal" data-bs-target="#addCompanyUser"
                    @click="this.createUser();">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                      </svg>
                    </span>
                    Add User
                  </button>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List Company Users </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div> 
                </div>
            </div>
            <div class="card-body p-0">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid :show-borders="true" key-expr="id" :data-source="userList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true" data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Company-Users-List')">> 
                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="20"/>
                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                            />
                            <DxColumn data-field="name_surname" caption="Name Surname" header-cell-template="name-surname-header" /> 
                            <DxColumn data-field="user_mail" caption="Email" alignment="left" /> 
                            <DxColumn data-field="user_assignment_name" caption="User Assignment" /> 
                            <DxColumn data-field="login_method_name" caption="Login Method" alignment="center" header-cell-template="login-method-header" :allow-filtering="false" /> 
                            <DxColumn data-field="is_owner" caption="Owner?" :allow-filtering="false" :width="75" />
                            <DxColumn data-field="is_chatbi_user" caption="Is ChatBi User?" alignment="center" :allow-filtering="false"/> 
                            <DxColumn data-field="is_active" caption="Active?" :allow-filtering="false" :width="75"/> 
                            <DxColumn data-field="created_at" caption="Created Date" header-cell-template="created-date-header" alignment="right" :allow-filtering="false" /> 

                            <template #name-surname-header>
                                <div>Name</div><div>Surname</div>
                            </template>  
                            <template #login-method-header>
                                <div>Login</div><div>Method</div>
                            </template>
                            <template #created-date-header>
                                <div>Created</div><div>Date</div>
                            </template>

                            <template #dataRowTemplate="{ data: data }"> 
                                <td class="text-gray-900 fw-bolder">
                                    <a href="#" class="text-gray-900 fw-bold text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        <span class="badge badge-circle badge-light me-3">
                                        <i class="bi bi-pencil text-gray-900"></i> 
                                        </span> 
                                        <span class="me-3"> {{ data.data.name_surname }} </span>
                                        <span v-if="data.data.permission_type == 0">
                                            <span class="badge badge-light-warning">Not Defined</span> 
                                        </span> 
                                        <span v-if="data.data.permission_type == 1">
                                            <span class="badge badge-light-primary">Admin</span> 
                                        </span> 
                                        <span v-if="data.data.permission_type == 2">
                                            <span class="badge badge-light-primary">Client</span> 
                                        </span> 
                                    </a>
                                </td>
                                <td class="text-gray-900">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        {{ data.data.user_mail }}                                   
                                    </a>
                                </td>
                                <td class="text-gray-800">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        {{ data.data.user_assignment_name }}
                                    </a>
                                </td>
                                <td class="text-gray-900 text-center">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">                                      
                                    <span :class="data.data.login_method == 0 ? 'badge badge-warning' : 'badge badge-primary'">
                                        {{ data.data.login_method_name }}
                                    </span>
                                    </a>
                                </td>
                                <td class="text-gray-800 text-center">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        <span v-if="data.data.is_owner">
                                            <i class="las la-check fs-2 text-success"></i>
                                        </span>
                                        <span v-if="!data.data.is_owner">
                                            <i class="las la-times fs-2 text-danger"></i>
                                        </span> 
                                    </a>
                                </td>
                                <td class="text-gray-800 text-center">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        <span v-if="data.data.is_chatbi_user">
                                            <i class="las la-check fs-2 text-success"></i>
                                        </span>
                                        <span v-if="!data.data.is_chatbi_user">
                                            <i class="las la-times fs-2 text-danger"></i>
                                        </span> 
                                    </a>
                                </td>
                                <td class="text-gray-800 text-center">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">                                      
                                        <span v-if="data.data.is_active">                                            
                                            <i class="las la-check fs-2 text-success"></i>
                                        </span>  
                                        <span v-if="!data.data.is_active">
                                            <i class="las la-times fs-2 text-danger"></i>
                                        </span> 
                                    </a>
                                </td>  
                                <td class="text-gray-800 text-end">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateCompanyUser">
                                        {{ previewDate(data.data.created_at) }} 
                                    </a>
                                </td>
                            </template>
                        </DxDataGrid>   
                    </div>
                </div> 
            </div>
        </div> 
        <!-- Modal: Add User To Company -->        
        <div class="modal fade" tabindex="-1" id="addCompanyUser" style="display: none" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create User</h5> 
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>  
                    <div class="modal-body">
                        <CompanyUserCreate ref="addCompanyUser" :controller-id="this.controllerId"
                            :phone-country-code="this.phoneCountryCode" :company-country-code="this.companyCountryCode"/>
                    </div>  
                </div>
            </div>
        </div>

        <!-- Modal: Update User To Company -->
        <div class="modal fade" tabindex="-1" id="updateCompanyUser" style="display: none" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update User</h5> 
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>  
                    <div class="modal-body">
                        <CompanyUserUpdate ref="updateCompanyUser" :phone-country-code="this.phoneCountryCode" :company-country-code="this.companyCountryCode"/>
                    </div>  
                </div>
            </div>
        </div>
    </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `CML001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
import CompanyUserCreate from '../Users/CompanyUserCreate.vue';
import CompanyUserUpdate from '../Users/CompanyUserUpdate.vue';
 
export default {
    name: "CompanyUserList",
    props: {
        controllerId: Object,
        phoneCountryCode: Object,
        companyCountryCode: Object
    },
    components:{
        CompanyUserCreate,
        CompanyUserUpdate,
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
    data() {
        return {
            userList: [],
            isListProgressing: false,
        };
    },
    computed: {
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        }
    },
    methods: {
        getData() {
            this.$removeSessionStorage("user_record_id");
            this.$Progress.start();
            this.isListProgressing = true;
            const params = {
                company_account_id: this.$getSessionStorage("cp_record_id"),
                session_id: this.$getUser().session_id
            }
            axios.post("/api/Users/CompanyUsers", params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.userList = response.data;
                this.userList.forEach(element => {
                    element.company_account_id = this.$unlock(element.company_account_id);
                    element.controller_account_id = this.$unlock(element.controller_account_id);
                    element.system_account_id = this.$unlock(element.system_account_id);
                    element.user_mail = this.$unlock(element.user_mail);
                    element.name_surname = this.$unlock(element.name_surname);
                    element.phone = this.$unlock(element.phone);
                    element.system_account_name = this.$unlock(element.system_account_name);
                    element.controller_account_name = this.$unlock(element.controller_account_name);
                    element.company_account_name = this.$unlock(element.company_account_name);
                    element.user_assignment_name = this.$unlock(element.user_assignment_name);
                });
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.fail();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        createUser(){  
            this.$refs.addCompanyUser.getData();        
        }, 
        updateUser(id){ 
            this.$setSessionStorage("user_record_id", id);
            this.$refs.updateCompanyUser.getData();   
        }
    },
  created() {
  }
}; 
</script>