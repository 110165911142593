<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Document Type
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DocumentTypeList' }">Document Types</router-link></li>
                <li class="breadcrumb-item text-gray-600">Create Document Type</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/DocumentTypeList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link> 
            &nbsp;
            <div class="dropdown">
                <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    Actions
                </button>
                <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteType()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>Delete </a>
                    </li>
                </ul>
            </div>  
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row"> 
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Code
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">                                                
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label">Icon</label>
                                                        <br>
                                                        <div class="btn-group" role="group">
                                                        <button class="btn btn-secondary btn-sm p-2 mt-1" data-bs-toggle="modal" data-bs-target="#iconmodal">
                                                            <span v-if="this.form.icon == null || this.form.icon == ''">Select an Icon</span>
                                                            <span v-if="this.form.icon != null && this.form.icon != ''">
                                                                <i :class="this.form.icon + ' fs-2x'"></i>
                                                            </span>
                                                        </button>
                                                            <span class="position-absolute top-100 start-100 translate-middle badge badge-circle badge-secondary cursor-pointer z-index-1"
                                                                v-if="this.form.icon != null && this.form.icon != ''" @click="onIconSelection(null)">
                                                                <i class="bi bi-x fw-bold fs-1"> </i>
                                                            </span>
                                                        </div>
                                                    </div> 
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label">Color</label>
                                                        <br>
                                                        <div class="btn-group" role="group">
                                                            <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string"/>
                                                            <span class="position-absolute top-100 start-100 translate-middle badge badge-circle badge-secondary cursor-pointer"
                                                                v-if="this.form.color != null && this.form.color != '' && this.form.color != '#009ef7'" @click="this.form.color = '#009ef7'">
                                                                <i class="bi bi-x fw-bold fs-1"> </i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label">Manual Upload</label>
                                                        <br>
                                                        <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="form.is_manual_upload">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div> 
                                                </div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Create Document Type </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
    
    <!-- Icon modal -->
    <div class="modal fade" tabindex="-1" id="iconmodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <form class="d-flex align-items-center position-relative m-0" autocomplete="off">
                            <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search icons" v-model="searchbar">
                        </form>
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                    <div class="row" v-if="icons">
                        <div class="card mb-1 col-lg-2" v-for="item of searchableList" v-bind:key="item">
                            <div class="card-body align-middle text-center "> 
                                <a class="btn btn-light p-4 text-center cursor-pointer" @click="onIconSelection(item)" data-bs-dismiss="modal" aria-label="Close">
                                <i :class="item + ' fs-2x'"></i> <br>
                                </a> <br>
                                <small class="mt-2">{{ item }}</small>
                            </div> 
                        </div> 
                    </div>  
                </div> 
                <div class="modal-footer"> 
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `EXTU001`
import axios from 'axios';
  

import icons from '../../../assets/icons/icons.json';
 
export default {
  name: "DocumentTypeUpdate",
  data() {
    return {
      searchbar: '',
      lists: [],
      isOpenActionsDropdown: false, 
      isProgressing: false,
      form: { 
        id: null,
        name: null,
        code: null,
        description: null,
        session_id: null,
        color: '#009ef7',
        icon: null,
        is_manual_upload: false
      },
      icons: icons,
    };
  },
  computed: {
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    }
  },
  methods: { 
    getData(){
        this.$setSessionStorage('refreshList', true);   
    },
    saveData(){
        const parameters = { 
          session_id: this.$getUser().session_id,
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          icon: this.form.icon,
          color: this.form.color,
          is_manual_upload: this.form.is_manual_upload == true ? 1 : 0
        }; 
        axios.post('/api/Documents/CreateDocumentType', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('record_id', response.data);
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'DocumentTypeUpdate' });
             }
           }) 
          .catch(function (error) {
              console.log(error);
          });          
    },
    onIconSelection(item){
      this.form.icon = item;
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
    
  },
  mounted() { 
  }
}; 
</script>