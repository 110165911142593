<template>
<div class="d-flex flex-column flex-root">
	<div class="d-flex flex-column flex-lg-row flex-column-fluid">
		<div class="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
			<div class="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style="background-image: url(/assets/theme/media/logos/auth-bg.png)">
				<div class="d-flex flex-center py-10 py-lg-20 mt-lg-20">
					<a href="#">
						<img alt="Logo" src="assets/theme/media/logos/logo.png" class="h-100px">
					</a>
				</div>
				<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7"> 
                Set a password to verify your account.
            </h1>
        </div>
			</div>
		</div>
    
		<div class="d-flex flex-column flex-lg-row-fluid py-10">
			<div class="d-flex flex-center flex-column flex-column-fluid py-10 py-lg-20 mt-lg-20">
				<div class="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
          <form v-if="this.tokenStatus == 0" class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_new_password_form">
            <div class="text-center mb-10">
                <h1 class="text-dark fw-bolder mb-3">                  
                  Hi {{this.userName}}
                </h1>
                <div class="text-gray-500 fw-semibold fs-6 mb-7">  Set a password to verify your account and log in to the system with the e-mail address that you entered. </div>
                <div class="text-gray-500 fw-semibold fs-7">  Create a password 8-16 characters long, including uppercase, lowercase and special characters. </div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container">
              <div class="mb-1">
                <div class="position-relative">    
                  <input id="pw_password"  class="form-control" type="password" v-model="form.password" placeholder="Password" name="password" autocomplete="off"
                  :class="{'border border-success': isPasswordConfirm == true}">
                  <!--begin::Visibility toggle-->
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    </span>
                    <!--end::Visibility toggle-->
                    <password-meter class="mb-3" @score="onScore" :password="form.password"/>
                </div>
              </div>
              <div class="d-flex flex-stack mb-3 ms-1">
                <div class="text-gray-500 fw-semibold">
                  Including 3 of the following:
                </div>
                <div class="text-gray-500 fw-semibold">
                  Must Contain:
                </div>
              </div>
              <div class="d-flex flex-stack mb-3 ms-1">
                <div class="text-muted">
                  <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                  <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                  <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                  <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                </div>
                <div class="text-muted">
                  <span> <i class="fas" :class="form.password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                </div>
              </div>
            </div>
          
            <div class="fv-row mb-8 fv-plugins-icon-container">    
              <input type="password" placeholder="Repeat Password" name="confirm-password" v-model="form.password_confirm" autocomplete="off" class="form-control" 
                @paste.prevent :class="{'border border-success': isPasswordConfirm == true, 'border border-danger': isPasswordConfirm == false}"> 
                <small class="text-danger" v-if="isPasswordConfirm == false">
                  The password and its confirm are not the same
                </small>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="d-grid mb-10">
              <button type="button" id="kt_new_password_submit" data-kt-indicator="on" class="btn btn-primary" 
                @click="onResetPassword()" :disabled="passwordScore < 3 || isPasswordConfirm !== true">
                <span v-if="!this.isProggressing">Submit</span>
                <span class="indicator-progress" v-if="this.isProggressing">
                  Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
            
            <div class="text-gray-500 fw-semibold fs-6">
                Have you already verify your account ?
                
              <router-link to="/Login">
                <a href="#" class="link-primary fw-bold">
                    Sign in
                </a>
              </router-link>
            </div>
          </form>
          <div class="text-center" v-if="this.tokenStatus > 0">
            <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
              <span v-if="tokenStatus == 1">URL is invalid!</span>  
              <span v-if="tokenStatus == 2">Token expired!</span>  
            </h1>
            <div class="fw-semibold fs-6 text-gray-500 mb-8">
              To receive a new link, contact to your manager.
            </div>
            <div class="mb-10">
              <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18.png" class="mw-100 mh-200px theme-light-show" alt="">
              <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5.png" class="mw-100 mh-200px theme-light-show" alt="">
              
              <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
              <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
            </div>
            <div class="mb-10 fw-semibold fs-6 text-gray-500">
              <router-link to="/Login">
                <a href="#" class="btn btn-primary fw-bold">
                  Back to Login
                </a>
              </router-link>
            </div>
          </div>       
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from "axios";
import PasswordMeter from "../scripts/passwordMeter.js";
export default {
  name: "VerifyAccount",
  components: {
    PasswordMeter,
  },
  data() {
    return {
      form: {
        password: "",
        password_confirm: "",
        old_password: ""
      },
      tokenStatus: -1,
      passwordScore: 0,
      isHide: true,
      userName: null,
      isProggressing: false
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.password !== null && this.form.password !== '') {
        if(this.form.password == this.form.password_confirm) return true;
        else return false;
      }
      else return null;
    },
    isHasNumber() {
      return /(.*[0-9].*)/g.test(this.form.password);
    },
    isHasLowercase() {
      return /(.*[a-z].*)/g.test(this.form.password);
    },
    isHasUppercase() {
      return /(.*[A-Z].*)/g.test(this.form.password);
    },
    isHasSpecial() {
      return /[^A-Za-z0-9]/g.test(this.form.password);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg8.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      if(!this.$route.query.code) {return this.$router.push({ name: 'Login' });}

      const parameters = {
        code: this.$route.query.code,
        type: 2
      };  
      axios.post('/api/Auth/CheckTokenExpired', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.tokenStatus = response.data.tokenStatus;
          this.userName = response.data.name_surname;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    onResetPassword() {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        this.isProggressing = true;
        const that = this;
        const parameters = {
          code: this.$route.query.code,
          user_password: this.form.password,
          type: 2
        };  
        axios.post('/api/Auth/ResetPassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password changed!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProggressing = false; 
          }, 500);
        });
      }
    },
    onScore(pwScore) {
      this.passwordScore = pwScore.score;
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("pw_password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    }
  },
  created() {
     this.getData();
  }
};
</script>
<style scoped>
body{
  background-image: url('/assets/theme/media/auth/bg8.jpg');
}

@media (min-width: 992px){
.flex-lg-start {
    justify-content: start;
    align-items: start;
}}
</style>