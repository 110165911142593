<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
                    Accounting Plans
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'AccountingPlans' }">Account Plans</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Accounting Plans</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link :to="{ name: 'AccountingPlans', params: { type: 'AccountingPlans' } }">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> Return back to Settings </span>
                    </button>
                </router-link>
            </div> 
        </div> 
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="row col-lg-6">
                        <div class="fv-row mb-7 row">
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3 text-gray-600">
                                    <span>Authorized Account Name</span>
                                </label>
                                <Select2 v-model="filterForm.auth_controller_account_id" :options="authControllerAccountList" 
                                    :disabled="isDisabledAuthController" :settings="{width: '100%'}" @select="getTemplates()" />
                            </div>
                            <div class="col-lg">  
                                <label class="fs-6 fw-bold form-label mt-3 text-gray-600">
                                    <span>Templates</span>
                                </label>
                                <Select2 v-model="filterForm.plan_template_id" :options="planTemplatesList" :settings="{width: '100%'}" />
                            </div> 
                        </div>
                        <!-- <div class="d-flex mt-5">
                            <label class="fw-bold fs-6 mt-3">
                                Templates:
                            </label>
                            &nbsp; &nbsp;
                            <div class="input-group mb-5">
                                <select class="form-select mr-5 w-250px"
                                    v-model="filterForm.planTemplateSelection" @change="onChangePlan()">
                                    <option v-for="item in planTemplatesList" :value="item" :key="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                            <button type="button" class="btn btn-light-primary border border-primary" @click="this.getAccountingPlans()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Accounting Plan </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                        <div class="d-flex justify-content-end align-items-center d-none"
                            data-kt-customer-table-toolbar="selected">
                            <div class="fw-bolder me-5">
                                <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected
                            </div>
                            <button type="button" class="btn btn-danger"
                                data-kt-customer-table-select="delete_selected">Delete Selected</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <router-link to="/PlanCreate">
                            <button type="button" class="btn btn-light-primary border border-primary me-3"><span
                                    class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                            fill="currentColor" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                            transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                            fill="currentColor" />
                                    </svg>
                                </span>Add Accounting Plan</button>
                        </router-link>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer"> 
                        <div class="table-responsive min-h-300px">
                            <DxDataGrid
                                    :show-borders="true"
                                    key-expr="id"
                                    :data-source="lists"
                                    :allow-column-reordering="true" 
                                    :allow-column-resizing="true"
                                    :column-auto-width="true"
                                    :row-alternatin-enabled="true"
                                    data-row-template="dataRowTemplate"
                                    :hover-state-enabled="true"
                                    :show-row-lines="true"
                                    @exporting="this.$onExporting($event , 'Accounting-plans')">
                                    
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="10000"/>
                                    <DxPager
                                        :visible="false"
                                        :allowed-page-sizes="pageSizes"
                                        :show-page-size-selector="false"
                                        :show-info="false"
                                        :show-navigation-buttons="false"
                                    /> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                        
                                    />
                                    <DxColumn data-field="plan_level" caption="Level"/> 
                                    <DxColumn data-field="final_code" caption="Code"/> 
                                    <DxColumn data-field="name" caption="Name" /> 
                                    <DxColumn data-field="account_type_name" caption="Account Type"/>
                                    <DxColumn data-field="" caption="Transfer Parameters" header-cell-template="account_transfer_parameters"/>

                                    <template #account_transfer_parameters>
                                        <div>Transfer</div><div>Parameters</div>
                                    </template>

                                    <template #dataRowTemplate="{ data: data }"> 
                                        <tr class="align-middle border border-gray-200" :class="{ 'p-3 bg-material-gray-200': data.data.plan_level == 1}">
                                            <td class="border border-end border-gray-200 p-3"> 
                                                <a href="#" class="text-hover-primary mb-1" @click="updatePlan(data.data.id)">
                                                    <span class="badge badge-dark" v-if="data.data.plan_level == 1">
                                                        Level: {{ data.data.plan_level }}
                                                    </span>
                                                    <span class="badge badge-secondary" v-if="data.data.plan_level == 2">
                                                        Level: {{ data.data.plan_level }} <span
                                                            v-if="data.data.level_2_id !== null && data.data.level_2_id !== ''">.2</span>
                                                    </span>
                                                    <span class="badge badge-light" v-if="data.data.plan_level == 3">
                                                        Level: {{ data.data.plan_level }}
                                                    </span>
                                                </a>
                                            </td>
                                            <td class="border border-end border-gray-200 p-3">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)">                                          
                                                    <h4 v-if="data.data.plan_level == 1"> {{ data.data.final_code }} </h4>
                                                    <h6 v-if="data.data.plan_level > 1"> {{ data.data.final_code }} </h6>
                                                </a>
                                            </td>
                                            <td class="border border-end border-gray-200 p-3">                                                
                                                <a href="#" class="text-gray-900 text-hover-primary fw-bolder" @click="updatePlan(data.data.id)"
                                                    v-if="data.data.plan_level == 1">
                                                    <h5 class="mb-0 text-hover-primary"> {{ data.data.name }} </h5>
                                                </a>
                                                <a href="#" class="text-gray-700 text-hover-info fw-bolder" @click="updatePlan(data.data.id)"
                                                    v-if="data.data.plan_level == 2">
                                                    &nbsp;&nbsp; {{ data.data.name }}
                                                </a>
                                                <a href="#" class="text-gray-600 text-hover-success" @click="updatePlan(data.data.id)"
                                                    v-if="data.data.plan_level == 3">
                                                    &nbsp;&nbsp;&nbsp;&nbsp; {{ data.data.name }}
                                                </a>
                                            </td>
                                            <td class="border border-end border-gray-200 p-3">                                                
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)">
                                                    <span v-if="data.data.account_type_name !== 'Undefined'"> 
                                                        {{data.data.account_type_name }} <br>
                                                    </span>
                                                    <small class="me-2"> {{ data.data.detail_type_name }} </small>
                                                    <small class="me-2"> {{ data.data.currency }}</small>
                                                    <small class="me-2"> {{ data.data.sales_type_name}}</small>
                                                    <small class="me-2"> {{ data.data.vat_type_name }}</small>
                                                    <small class="me-2"> {{ data.data.cheque_type_name }}</small>
                                                    <small class="me-2"> {{ data.data.vat_calculated_type_name }}</small>
                                                </a>
                                            </td>
                                            <td class="border border-end border-gray-200 p-3">                                                
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updatePlan(data.data.id)" data-bs-toggle="modal" data-bs-target="#updateAccountingPlan">
                                                    <small class="me-2" v-if="data.data.is_opening_account == 2"> <i class="bi bi-check"></i> Opening </small>
                                                    <small class="me-2" v-if="data.data.is_closing_account == 2"> <i class="bi bi-check"></i> Closing </small>
                                                    <small class="me-2" v-if="data.data.is_profit_loss_account == 2">
                                                        <br> <i class="bi bi-check"></i> Profit & Loss
                                                    </small>
                                                    <small v-if="data.data.profit_loss_transaction_type_name !== ''"> ({{data.data.profit_loss_transaction_type_name}}) </small>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                            </DxDataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `PLL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling} from 'devextreme-vue/data-grid';
 
export default {
    name: "PlanList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
  data() {
    return {
      lists: [],
      planTemplatesList: [],
      authControllerAccountList: [], 
      isListProgressing: false,
      searchbar: '',
      isDisabledAuthController: false,
      filterForm: {
        planTemplateSelection: null,
        authorizedAccountName: null,
        plan_template_id: '-1',
        auth_controller_account_id: '-1'
      },
      templateForm: {
        name: null
      },
      copyForm: {
        source_template_id: null,
        target_template_id: null,
        sourceTemplateSelection: null,
        targetTemplateSelection: null
      },
      pageSizes: [50, 100, 200, 'all'],
      page_size: 10,
      page: 1
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
        this.planTemplatesList = [];
        this.lists = [];
        this.isDisabledAuthController = false  
        const params = {
            session_id: this.$getUser().session_id
        };
        axios.post("/api/AccountingPlans/GetAuthControllerAccounts", params, {'Content-Type': 'application/json'}).then((response) => {
            this.authControllerAccountList = response.data;
            if (this.$getUser().account_type == 2 || (this.$getUser().account_type == 1 && this.$getUser().permission_type == 2)) {
                this.isDisabledAuthController = true;
                this.filterForm.auth_controller_account_id = this.authControllerAccountList[0].id;
            }
            this.getTemplates();
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    getTemplates() {
        this.planTemplatesList = []; 
        this.$Progress.start();        
        const templateParams = {
            session_id: this.$getUser().session_id,
            controller_id: this.filterForm.auth_controller_account_id
        };
        if (templateParams.controller_id == '-1') { templateParams.controller_id = null; }
        axios.post("/api/AccountingPlans/GetPlanTemplatesForController", templateParams, {'Content-Type': 'application/json'}).then((templateResponse) => {
            this.planTemplatesList = templateResponse.data;
            this.$setSessionStorage('data', templateResponse.data); 
            this.$Progress.finish();  
            if(this.$getSessionStorage('active_template_id') === null || this.$getSessionStorage('active_template_id') === ''){
                this.filterForm.plan_template_id = this.planTemplatesList[0].id; 
                this.$setSessionStorage('active_template_id', this.planTemplatesList[0]); 
            }
            else{ 
                var active_template = this.planTemplatesList.find(template =>template.id == this.$getSessionStorage('active_template_id').id);
                if (active_template !== null && active_template !== '' && active_template !== undefined) {
                    this.filterForm.plan_template_id = this.$getSessionStorage('active_template_id').id;
                } else {
                    this.filterForm.plan_template_id = this.planTemplatesList[0].id; 
                }
            }
            if(this.$getSessionStorage('refreshList')){
                this.getAccountingPlans();
                this.$removeSessionStorage('refreshList'); 
            }
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    getAccountingPlans(){ 
        const planTemplate = this.planTemplatesList.find(i => i.id == this.filterForm.plan_template_id);
        this.$setSessionStorage('active_template_id', planTemplate);
        this.lists = [];
        this.isListProgressing = true;
        this.$Progress.start(); 
        const queryParameter = {
          plan_template_id: this.filterForm.plan_template_id,
          session_id: this.$getUser().session_id
        };  
        const that = this;
        axios.post('/api/AccountingPlans/AccountingPlans', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => { 
            this.lists = response.data;
            this.$setSessionStorage('data'+ '_PLL001', response.data);
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.finish();
        })
        .finally(function() {
            setTimeout(() => {
                that.isListProgressing = false;
            }, 500);
        });
    },
    updatePlan(id){ 
        this.$setSessionStorage("record_id", id);
        this.$router.push({ name: 'PlanUpdate' });
    }
  },
  created() {
    this.getData(); 
  }

}; 
</script>
