<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Your Companies</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600">Settings</li> 
                <li class="breadcrumb-item text-gray-600">Controller Functions</li> 
                <li class="breadcrumb-item text-gray-600">Your Companies</li> 
            </ul> 
        </div>
    </div>

    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="row g-6 g-xl-9 text-center"> 
             <div class="col-xl-12 mb-xl-10">
                <div class="card shadow">
                    <div class="card-header">
                        <div class="card-title">
                            Your Companies
                        </div>          
                    </div> 
                    <div class="card-body pt-0">
                        <div class="table-responsive mh-550px ">
                            <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed table-hover gy-4">
                            <thead class="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                <th class="text-gray-600">#</th>               
                                <th class="text-start">Company Name</th>     
                                <th>Company Type</th>
                                <th>Country </th>
                                <th>Primary Person/Director </th>
                                <th>Controller Name</th>
                                <th class="text-end">Selection</th>
                                <th class="text-end">Actions</th>
                            </tr>
                            </thead>
                            <tbody class="fs-6">
                                <tr class="odd" v-for="(item, index) in lists" v-bind:key="item">
                                <td class="fs-7"> {{index + 1}} </td>
                                <td>
                                    <div class="d-flex align-items-start">
                                    <div class="me-5 position-relative">
                                        <div class="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src="assets/theme/media/avatars/blank.png">
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column text-start">
                                        <a href="" class="fs-6 text-gray-800 text-hover-primary">{{item.text}}</a>
                                        <div class="fw-semibold text-gray-400">{{item.company_vkn}}</div>
                                    </div>
                                    </div>
                                </td>
                                <td class="fs-7">{{item.company_type_name}}</td>
                                <td class="fs-7">{{item.company_country_code}}</td>
                                <td class="fs-7">
                                    {{item.primary_person}}
                                </td>
                                <td class="fs-7"> {{item.controller_account_name }} </td>
                                <td>
                                    <button type="button" class="btn btn-success border border-success btn-sm w-100px p-1 py-2">
                                        <i class="bi bi-box-arrow-in-right"></i>
                                        <span>Select</span>
                                    </button>
                                </td>
                                <td>
                                    <button v-if="item.instant_access == 1 && item.is_active == 1" type="button" class="btn btn-primary border border-primary btn-sm w-150px p-1 py-2" @click="onInstantLogin(item)">
                                        <i class="bi bi-box-arrow-right"></i>
                                        <span>Instant Access</span>
                                    </button>
                                </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>  
  </div>  
</template>

<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
   

 
export default {
  name: "YourCompanies", 
  data() {
    return {
      lists:[],
      userData: this.$getUser()
    };
  }, 
  computed: {
    
  },
  methods: {
    getData(){
         const companyParams = {
            session_id: this.$getUser().session_id,
            controller_id: this.$getUser().controller_account_id
        }
        axios.post("/api/Accounts/CompanyAccounts", companyParams, {'Content-Type': 'application/json'})
        .then((companyResponse) => { 
            this.lists = companyResponse.data;
            this.lists.forEach(element => {
                element.company_name = this.$unlock(element.company_name);
                element.text = this.$unlock(element.text);
                element.company_vkn = this.$unlock(element.company_vkn);
                element.primary_person = this.$unlock(element.primary_person);
                element.company_gsm = this.$unlock(element.company_gsm);
                element.company_landphone = this.$unlock(element.company_landphone);
                element.company_email = this.$unlock(element.company_email);
                element.company_address = this.$unlock(element.company_address);
                element.company_city = this.$unlock(element.company_city);
                element.company_town = this.$unlock(element.company_town);
                element.company_state = this.$unlock(element.company_state);
                element.company_region = this.$unlock(element.company_region);
                element.company_postcode = this.$unlock(element.company_postcode);
                element.company_country_code = this.$unlock(element.company_country_code);
                element.controller_id = this.$unlock(element.controller_id);
                element.controller_account_name = this.$unlock(element.controller_account_name);
                element.system_access_name = this.$unlock(element.system_access_name);
                element.controller_access_name = this.$unlock(element.controller_access_name);
            });
        })
        .catch(function (error) {
            console.log(error);
        });
    }
  }, 
  created() { 
    this.getData();
  }
}; 
</script>