<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Update Accounting Plan
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                  </a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'AccountingPlans' }">Account Plans</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PlanList' }">Accounting Plans</router-link></li>  
              <li class="breadcrumb-item text-gray-600">Update Accounting Plan</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link to="/PlanList">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> Return back to List</span>
              </button>
          </router-link> 
          &nbsp;
          <div class="dropdown">
              <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
              <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
              <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
              </svg></span>
                  Actions
              </button>
              <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                  <li>
                      <a class="dropdown-item fs-4 text-danger" href="#" @click="deletePlan()">
                          <span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                              </svg>
                          </span>
                          Delete
                      </a>
                  </li>
              </ul>
          </div>  
      </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="card card-xxl-stretch">
        <div class="p-10">
          <div class="card">
            <form @submit.prevent="submit">
              <div class="card card-body p-12 mb-6 bg-lighten border border-gray-300">
                <div class="fv-row mb-7 fv-plugins-icon-container">
                      <label class="fs-6 fw-bold form-label mb-2 required">ID
                      </label>
                      <input type="text" class="form-control" v-model="form.id" disabled>
                </div>   
                <div class="fv-row mb-7 fv-plugins-icon-container">
                      <label class="fs-6 fw-bold form-label mb-2 required">Level
                      </label>
                      <div class="input-group mb-5">
                          <select class="form-control form-control-lg" v-model="form.levelSelection" @change="onChangeLevel(true)">
                            <option v-for="item in levelList" :value="item" :key="item.code">
                              <strong> {{ item.name }} </strong>
                            </option>
                          </select>
                          <span class="input-group-text" v-if="this.isLoadingPlans && form.levelSelection.code != 1">
                            Getting Plans... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                    </div>
                  </div>    

                  <!-- LEVEL 2 TO LEVEL 1 --> 
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 2">
                      <label class="fs-6 fw-bold form-label mb-2">Level 1 Plan Selection</label>
                      
                      <div class="input-group mb-5">
                      <select class="form-control" v-model="form.level1Selection" @change="onChangeSelectablePlan()">
                        <option v-for="item in levelPlansList" :value="item" :key="item.code">
                          {{ item.final_code }} - {{ item.name }}
                        </option>
                      </select>  
                        <span class="input-group-text">
                          <small> Level 1 Plans Only </small>
                        </span>
                      </div>
                  </div>
                  <!-- LEVEL 2 TO LEVEL 2 -->
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 22">
                      <label class="fs-6 fw-bold form-label mb-2">Level 2 Plan Selection</label>
                      <div class="input-group mb-5">
                      <select class="form-control" v-model="form.level2Selection" @change="onChangeSelectablePlan()">
                        <option v-for="item in onlyLevel2PlansList" :value="item" :key="item.code">
                          {{ item.final_code }} - {{ item.name }}
                        </option>
                        </select>
                        <span class="input-group-text">
                          <small> Level 2 Plans Only </small>
                        </span>
                      </div>
                  </div>    

                  <!-- LEVEL 3 TO LEVEL 2 --> 
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 3">
                      <label class="fs-6 fw-bold form-label mb-2">Level 2 Plan Selection</label>
                      <div class="input-group mb-5">
                      <select class="form-control" v-model="form.level2Selection" @change="onChangeSelectablePlan()">
                        <option v-for="item in levelPlansList" :value="item" :key="item.code">
                          {{ item.final_code }} - {{ item.name }}
                        </option>
                        </select>
                        <span class="input-group-text">
                          <small> Level 2 and 2.2 Plans </small>
                        </span>
                      </div>
                  </div>     
  
                  <!-- CODE INPUT --> 
                  <!-- LEVEL 1 CODE -->
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 1 && this.isReady">
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Code
                    </label>
                    <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_1">
                  </div>   
                  <!-- LEVEL 2 CODE -->
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 2 && this.isReady"> 
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Code
                      </label>
                      <div class="input-group mb-5"> 
                          <span class="input-group-text" v-for="finalCodePart in this.form.level1Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                          <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_2">
                      </div> 
                  </div>  
                  <!-- LEVEL 2.2 CODE -->
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 22 && this.isReady"> 
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Code
                      </label>
                      <div class="input-group mb-5"> 
                          <span class="input-group-text" v-for="finalCodePart in this.form.level2Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                          <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_2">
                      </div>
                  </div>   
                  <!-- LEVEL 3 CODE -->
                  <div class="fv-row mb-7 fv-plugins-icon-container" v-if="form.levelSelection.code == 3 && this.isReady">
                    <label class="fs-6 fw-bold form-label mb-2 required">
                      Code                      
                      </label>
                    <div class="input-group mb-5" v-if="form.level2Selection !== null">
                      <span class="input-group-text" v-for="finalCodePart in form.level2Selection.final_code_array" :key="finalCodePart">{{ finalCodePart }}</span>
                      <input type="text" class="form-control" v-model="form.code" :maxlength="planSettingsData.level_3"> 
                    </div>
                  </div>    

                  <div class="fv-row mb-7 fv-plugins-icon-container">
                      <label class="fs-6 fw-bold form-label mb-2 required">
                        Name
                      </label>
                      <input type="text" class="form-control" placeholder="" name="name" v-model="form.name"> 
                  </div>   

                  
                  <div class="separator separator-dashed my-6"></div>


                  <!-- <div class="row fv-row mb-7 fv-plugins-icon-container">
                    <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mb-2">Record Type <small>Kayıt Şekli</small>
                      </label>
                      <select class="form-control" v-model="form.recordTypeSelection">
                        <option v-for="item in recordTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                        </select>
                    </div>
                  </div> -->
                  <div class="row fv-row mb-7 fv-plugins-icon-container"> 
                    <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mb-2">Balance Amount Type</label>
                      <!-- <small>Bakiye Şekli</small> -->
                      <select class="form-control" v-model="form.balanceTypeSelection">
                        <option v-for="item in balanceTypeList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mb-2">Profit and Loss Account </label>
                      <select class="form-control" v-model="form.incomeItemSelection">
                          <option v-for="item in incomeItemList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                    </div> -->
                    <!-- <div class="col-lg-6">
                      <label class="fs-6 fw-bold form-label mb-2">Balance Item <small>Bilanço Kalemi</small>
                      </label>
                      <select class="form-control" v-model="form.balanceItemSelection">
                        <option v-for="item in balanceItemList" :value="item" :key="item.code">
                          {{ item.name }}
                        </option>
                      </select>
                    </div> -->
                  </div> 
                
                <div v-if="form.levelSelection.code == 3">
                  <div class="separator separator-dashed my-6"></div>

                  <div class="fv-row mb-7 fv-plugins-icon-container">
                    <h5> Accounting Defaults</h5>
                  </div>
                  
                  <div class="fv-row mb-7 fv-plugins-icon-container row">
                    <div class="col-lg-4">
                        <label class="fs-6 fw-bold form-label mb-2 required">
                          Plan Type
                      </label>
                        <select class="form-control" v-model="form.planTypeSelection">
                          <option v-for="item in planTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>  
                    <div class="col-lg-4">
                        <label class="fs-6 fw-bold form-label mb-2 required">
                          Account Type
                      </label>
                        <select class="form-control" v-model="form.accountTypeSelection" @change="getListData()">
                          <option v-for="item in accountTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 3">
                        <label class="fs-6 fw-bold form-label mb-2">Cash Type</label>
                        <select class="form-control" v-model="form.cashTypeSelection">
                          <option v-for="item in cashTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>                   
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 4">
                        <label class="fs-6 fw-bold form-label mb-2">Bank Account Type</label>
                        <select class="form-control" v-model="form.bankAccountTypeSelection">
                          <option v-for="item in bankAccountTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4" v-if="form.accountTypeSelection.code == 3 || form.accountTypeSelection.code == 4">
                        <label class="fs-6 fw-bold form-label mb-2">Currency</label>
                        <select class="form-control" v-model="form.currencySelection">
                          <option v-for="item in currencyList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4" v-if="form.accountTypeSelection.code == 1">
                        <label class="fs-6 fw-bold form-label mb-2">Customer Type</label>
                        <select class="form-control" v-model="form.customerTypeSelection">
                          <option v-for="item in customerTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4" v-if="form.accountTypeSelection.code == 2">
                        <label class="fs-6 fw-bold form-label mb-2">Supplier Type</label>
                        <select class="form-control" v-model="form.supplierTypeSelection">
                          <option v-for="item in supplierTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 5">
                        <label class="fs-6 fw-bold form-label mb-2">Personnel Position Type</label>
                        <select class="form-control" v-model="form.personnelPositionTypeSelection">
                          <option v-for="item in personnelPositionTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 6">
                        <label class="fs-6 fw-bold form-label mb-2">Expense Group</label>
                        <select class="form-control" v-model="form.expenseGroupSelection">
                          <option v-for="item in expenseGroupList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 7 || form.accountTypeSelection.code == 9 || form.accountTypeSelection.code == 16">
                        <label class="fs-6 fw-bold form-label mb-2">Stock Group</label>
                        <select class="form-control" v-model="form.stockGroupSelection">
                          <option v-for="item in stockGroupList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 9 || form.accountTypeSelection.code == 16">
                        <label class="fs-6 fw-bold form-label mb-2">Sales Type</label>
                        <select class="form-control" v-model="form.salesTypeSelection">
                          <option v-for="item in salesTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 8 || form.accountTypeSelection.code == 10">
                        <label class="fs-6 fw-bold form-label mb-2">Asset Type</label>
                        <select class="form-control" v-model="form.assetTypeSelection">
                          <option v-for="item in assetTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 11">
                        <label class="fs-6 fw-bold form-label mb-2">VAT Type</label>
                        <select class="form-control" v-model="form.vatTypeSelection">
                          <option v-for="item in vatTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 12">
                        <label class="fs-6 fw-bold form-label mb-2">Cheque Type</label>
                        <select class="form-control" v-model="form.chequeTypeSelection">
                          <option v-for="item in chequeTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 12 && this.form.chequeTypeSelection.code == 1">
                        <label class="fs-6 fw-bold form-label mb-2">Cheque Operation Type</label>
                        <select class="form-control" v-model="form.chequeOperationTypeSelection">
                          <option v-for="item in chequeOperationTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 11">
                        <label class="fs-6 fw-bold form-label mb-2">VAT Rate</label>
                        <CurrencyInput type="text" step="any" class="form-control text-end" name="vat_rate" v-model="form.vat_rate" :precision="5" @focus="$event.target.select()"/>
                    </div> 
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 15">
                        <label class="fs-6 fw-bold form-label mb-2">Other Account Group</label>
                        <select class="form-control" v-model="form.otherAccountGroupSelection">
                          <option v-for="item in otherAccountGroupList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 15">
                        <label class="fs-6 fw-bold form-label mb-2">Policy Account Type</label>
                        <select class="form-control" v-model="form.policyAccountTypeSelection">
                          <option v-for="item in policyAccountTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div> 
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 17">
                        <label class="fs-6 fw-bold form-label mb-2">Transfer Account Type</label>
                        <select class="form-control" v-model="form.transferAccountTypeSelection">
                          <option v-for="item in transferAccountTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-7" v-if="form.accountTypeSelection.code == 18">
                        <label class="fs-6 fw-bold form-label mb-2">VAT Calculated Type</label>
                        <select class="form-control" v-model="form.vatCalculatedTypeSelection">
                          <option v-for="item in vatCalculatedTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                  </div>
                </div>

                <div v-if="form.levelSelection.code !== null">
                  <div class="separator separator-dashed my-6"></div>

                  <div class="fv-row mb-7 fv-plugins-icon-container">
                    <h5> Account Transfer Parameters</h5>
                  </div>
                  <div class="fv-row mb-7 fv-plugins-icon-container row">
                    <div class="col-lg-6 mb-7" v-if="form.levelSelection.code == 1 || form.levelSelection.code == 3">
                        <label class="fs-6 fw-bold form-label mb-3 required">
                          Is Opening Account?
                        </label>
                        <select class="form-control" v-model="form.isOpeningAccountSelection">
                          <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-7" v-if="form.levelSelection.code == 1 || form.levelSelection.code == 3">
                        <label class="fs-6 fw-bold form-label mb-3 required">
                          Is Closing Account?
                        </label>
                        <select class="form-control" v-model="form.isClosingAccountSelection">
                          <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-6">
                        <label class="fs-6 fw-bold form-label mb-2 required">
                          Is Profit & Loss Account?
                        </label>
                        <select class="form-control" v-model="form.isProfitLossSelection">
                          <option v-for="item in accountTransferParameterList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                    <div class="col-lg-6" v-if="(form.levelSelection.code == 1 || form.levelSelection.code == 3) && form.isProfitLossSelection.code == 2">
                        <label class="fs-6 fw-bold form-label mb-2 required">
                          Profit & Loss Transaction Type
                        </label>
                        <select class="form-control" v-model="form.profitLossTransactionTypeSelection">
                          <option v-for="item in profitLossTransactionTypeList" :value="item" :key="item.code">
                            {{ item.name }}
                          </option>
                        </select>
                    </div>
                  </div>
                </div>   
              </div>
              <div class=" d-grid gap-2">
                <button type="submit" href="#" class="btn btn-primary" id="kt_invoice_submit_button" @click="saveData()" data-kt-indicator="on">
                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                    </svg>
                    </span>
                      <strong v-if="!this.isProgressing"> Update Accounting Plan </strong> 
                        <span class="indicator-progress" v-if="this.isProgressing">
                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</template>


<script>
// Component Alias: `PLU001`
import axios from 'axios';

// import Swal from 'sweetalert2';

 
export default {
  name: "PlanCreate",
  data() {
    return {
      isOpenActionsDropdown: false,
      isReady: false,
      isProgressing: false,
      lists: [],  
      levelList: [{ code: null, name: 'Select an option' }, { code: 1, name: 'Level 1' }, { code: 2, name: 'Level 2' }, { code: 22, name: 'Level 2.2'} ,{ code: 3, name: 'Level 3' } ],
      recordTypeList: [{ code: 1, name: 'Serbest'}, { code: 2, name: 'Sadece Borç'}, { code: 3, name: 'Sadece Alacak'}],
      balanceTypeList: [{ code: 1, name: 'No Control'}, { code: 2, name: 'No Debit'}, { code: 3, name: 'No Credit'}],
      incomeItemList: [{ code: 1, name: 'No'}, { code: 2, name: 'Yes'}],
      balanceItemList: [{ code: 1, name: 'Item 1'}, { code: 2, name: 'Item 2'}, { code: 3, name: 'Item 3'}],
      levelPlansList: [],
      planTypeList: [{ code: 1, name: 'Standard'}],
      accountTypeList: [
        { code: 0, list_type: 0, name: 'Select an option'},
        { code: 10, list_type: 8, name: 'Amortization'},
        { code: 4, list_type: 1, name: 'Bank'},
        { code: 3, list_type: 0, name: 'Cash'},
        { code: 12, list_type: 0, name: 'Cheques'},
        { code: 16, list_type: 6, name: 'Cost Of Sales'},
        { code: 1, list_type: 14, name: 'Customer'},
        { code: 14, list_type: 0, name: 'Difference'},
        { code: 6, list_type: 4, name: 'Expense'},
        { code: 8, list_type: 8, name: 'Fixed Asset'},
        { code: 15, list_type: 17, name: 'Other Account'},
        { code: 5, list_type: 3, name: 'Personnel'},
        { code: 17, list_type: 6, name: 'Profit & Loss Transfer'},
        { code: 9, list_type: 6, name: 'Sales'},
        { code: 7, list_type: 6, name: 'Stock'},
        { code: 2, list_type: 14, name: 'Supplier'},
        { code: 13, list_type: 0, name: 'Turnover'},
        { code: 11, list_type: 0, name: 'VAT Account'},
        { code: 18, list_type: 0, name: 'VAT Calculated'},
      ],
      salesTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'Domestic'},
        { code: 2, name: 'Overseas'},
      ],
      vatTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'Sales'},
        { code: 2, name: 'Purchase'},
        { code: 3, name: 'Sales Return'},
        { code: 4, name: 'Purchase Return'},
        { code: 5, name: 'VAT Type Expense'}
      ],
      chequeTypeList: [
        { code: 1, name: 'Incoming Cheque'},
        { code: 2, name: 'Outgoing Cheque'},
      ],
      vatCalculatedTypeList: [
        { code: 0, name: 'ALL'},
        { code: 1, name: 'To be Paid'},
        { code: 2, name: 'To be Refund'},
      ],
      currencyList: [],
      customerTypeList: [{ code: 1, name: 'ALL'}],
      supplierTypeList: [{ code: 1, name: 'ALL'}],
      cashTypeList: [{ code: 1, name: 'ALL'}],
      bankAccountTypeList: [
        { id: null, text: 'ALL', code: null, name: 'ALL'}, 
        { id: '1', text: 'Bank Account', code: '1', name: 'Bank Account'}, 
        { id: '2', text: 'Credit Card Account', code: '2', name: 'Credit Card Account' },
        { id: '3', text: 'Loan Account', code: '3', name: 'Loan Account'},
        { id: '4', text: 'Lease Account', code: '4', name: 'Lease Account'},
        { id: '5', text: 'Cheque Account', code: '5', name: 'Cheque Account'},
        { id: '6', text: 'Other Bank Account',  code: '6', name: 'Other Bank Account'}
      ],
      chequeOperationTypeList: [
        { code: 0, name: 'Waiting'},
        { code: 1, name: 'Deposit'},
      ],
      expenseGroupList: [],
      stockGroupList: [],
      assetTypeList: [],
      personnelPositionTypeList: [],
      otherAccountGroupList: [],
      policyAccountTypeList: [
        { code: 0, name: 'Select an option'}, 
        { code: 1, name: 'Insurance Commission'}, 
        { code: 2, name: 'Vendor Commission'}, 
        { code: 3, name: 'Policy Taxes'}
      ],
      form: { 
          levelSelection: { code: null, name: 'Select an option'},
          level1Selection: { final_code_array: [] },
          level2Selection: { final_code_array: [] },
          level_1_id: null,
          level_2_id: null,
          code: null,
          name: null,  
          plan_level: null, 
          balance_type: null,
          income_item: null,
          balance_item: null,
          recordTypeSelection: null,
          balanceTypeSelection: null,
          incomeItemSelection: null,
          balanceItemSelection: null,
          plan_type_id: null,
          account_type_id: null,
          list_type_id: null,
          currency: null,
          sales_type: 0,
          vat_rate: 0.00,
          vat_type: null,
          cheque_type: null,
          cheque_operation_type: 0,
          transfer_account_type: 0,
          is_opening_account: 0,
          is_closing_account: 0,
          is_profit_loss_account: 0,
          profit_loss_transaction_type: 0,
          planTypeSelection: null,
          accountTypeSelection: null,
          cashTypeSelection: null, 
          bankAccountTypeSelection: null,
          personnelPositionTypeSelection: null,
          expenseGroupSelection: null,
          stockGroupSelection: null,
          assetTypeSelection: null,
          customerTypeSelection: null,
          currencySelection: null,
          salesTypeSelection: null,
          vatTypeSelection: null,
          chequeTypeSelection: null,
          chequeOperationTypeSelection: null,
          otherAccountGroupSelection:null,
          policyAccountTypeSelection:null,
          accountSelection: null,
          isOpeningAccountSelection: null,
          isClosingAccountSelection: null,
          isProfitLossSelection: { code: 1, name: 'No'},
          profitLossTransactionTypeSelection: null,
          transferAccountTypeSelection: null,
          vatCalculatedTypeSelection: null,
      },
      accountTransferParameterList: [
        { code: 1, name: 'No'},
        { code: 2, name: 'Yes'},
      ],
      profitLossTransactionTypeList: [
          { code: 0, name: 'Select an option'},
          { code: 1, name: 'Income Closing'},
          { code: 2, name: 'Expense Closing'},
          { code: 3, name: 'Cost Closing'},
          { code: 4, name: 'Other Income Closing'},
          { code: 5, name: 'Other Expense Closing'},
          // { code: 6, name: 'All'},
      ],
      transferAccountTypeList: [
          { code: -1, name: 'Select an option'},
          { code: 1, name: 'Income Transfer'},
          { code: 2, name: 'Expense Transfer'},
          { code: 3, name: 'Cost Transfer'},
          { code: 4, name: 'Other Income Transfer'},
          { code: 5, name: 'Other Expense Transfer'},
          { code: 0, name: 'All Transfers'},
      ],
      accountsList: [],
      planSettingsData: {
        id: null,
        level_1: null,
        level_2: null,
        level_3: null,
        brace_character: null,
        level_1_code: null,
        level_2_code: null
      },
      isLoadingPlans: false
    };
  },
  computed: {
    onlyLevel2PlansList() {
      return this.levelPlansList.filter(p => { 
        // Yalnızca Level 2 olanları getirmek için kullanılır. Level2.2'de diğer Level 2.2'ler kullanılmazlar.
        return p.id == null || p.is_level_2_to_level_2 == 0;
      });
    }
  },
  methods: {
    getData() { 
      this.$Progress.start(); 
      this.$setSessionStorage('refreshList', true);
      this.getCurrencies();
      const params = {
        id: this.$getUser().session_id
      }
      axios.get('/api/AccountingPlans/AccountingPlanSettings/' + params.id)
        .then((response) => {      
            this.planSettingsData = { 
                id: response.data[0].id, 
                level_1: response.data[0].level_1,
                level_2: response.data[0].level_2,
                level_3: response.data[0].level_3,
                brace_character: response.data[0].brace_character
            };   
            const queryParameter = {
              id: this.$getSessionStorage("record_id"),
              session_id: this.$getUser().session_id
            };
            axios.post('/api/AccountingPlans/GetAccountingPlan', queryParameter, {'Content-Type': 'application/json'})
              .then((response) => {
                  this.form= { 
                      id: response.data[0].id,
                      levelSelection: null,
                      level1Selection: { final_code_array: [] },
                      level2Selection: { final_code_array: [] },
                      level_1_id: response.data[0].level_1_id,
                      level_2_id: response.data[0].level_2_id,
                      code: response.data[0].code,
                      name: response.data[0].name,
                      plan_level: response.data[0].plan_level, 
                      balance_type: response.data[0].balance_type,
                      income_item: response.data[0].income_item,
                      balance_item: response.data[0].balance_item,
                      recordTypeSelection: null,
                      balanceTypeSelection: null,
                      incomeItemSelection: null,
                      balanceItemSelection: null,
                      plan_type_id: response.data[0].plan_type_id,
                      account_type_id: response.data[0].account_type_id,
                      list_type_id: response.data[0].list_type_id,
                      currency: response.data[0].currency,
                      sales_type: response.data[0].sales_type,                      
                      transfer_account_type: response.data[0].transfer_account_type,
                      is_opening_account: response.data[0].is_opening_account,
                      is_closing_account: response.data[0].is_closing_account,
                      is_profit_loss_account: response.data[0].is_profit_loss_account,
                      profit_loss_transaction_type: response.data[0].profit_loss_transaction_type,
                      vat_calculated_type: response.data[0].vat_calculated_type,
                      planTypeSelection: null,
                      accountTypeSelection: null,
                      cashTypeSelection: null, 
                      bankAccountTypeSelection: null,
                      personnelPositionTypeSelection: null,
                      expenseGroupSelection: null,
                      stockGroupSelection: null,
                      assetTypeSelection: null,
                      customerTypeSelection: null,
                      currencySelection: this.currencyList[0],
                      salesTypeSelection: this.salesTypeList[0],
                      vatTypeSelection: this.vatTypeList[0],
                      chequeTypeSelection: this.chequeTypeList[0],
                      chequeOperationTypeSelection: this.chequeOperationTypeList[0],
                      vat_type: response.data[0].vat_type,
                      cheque_type: response.data[0].cheque_type,
                      cheque_operation_type: response.data[0].cheque_operation_type,
                      vat_rate: parseFloat(response.data[0].vat_rate.replace(',', '.')),
                      is_level_2_to_level_2: response.data[0].is_level_2_to_level_2,
                      is_selectable_for_level_3: response.data[0].is_selectable_for_level_3,
                      otherAccountGroupSelection: null,
                      policyAccountTypeSelection: null,
                      policy_account_type: response.data[0].policy_account_type,
                      account_id: response.data[0].account_id,
                      isOpeningAccountSelection: this.accountTransferParameterList[0],
                      isClosingAccountSelection: this.accountTransferParameterList[0],
                      isProfitLossSelection: this.accountTransferParameterList[0],
                      profitLossTransactionTypeSelection: this.profitLossTransactionTypeList[0],
                      transferAccountTypeSelection: this.transferAccountTypeList[0],
                      vatCalculatedTypeSelection: this.vatCalculatedTypeList[0],
                  }; 

                  this.accountTypeList.forEach((value) => {
                    if(this.form.account_type_id == value.code) {
                      this.form.accountTypeSelection = value;
                    }
                  });
                  
                  if(this.form.plan_level == 2 && this.form.is_level_2_to_level_2 == '1'){
                      this.form.plan_level = 22; 
                  } 
                  this.levelList.forEach((value) => {
                    if(this.form.plan_level == value.code){
                      this.form.levelSelection = value;
                    }
                  }); 
                  this.balanceTypeList.forEach((value) => {
                    if(this.form.balance_type == value.code){
                      this.form.balanceTypeSelection = value;
                    }
                  });
                  this.incomeItemList.forEach((value) => {
                    if(this.form.income_item == value.code){
                      this.form.incomeItemSelection = value;
                    }
                  });
                  this.balanceItemList.forEach((value) => {
                    if(this.form.balance_item == value.code){
                      this.form.balanceItemSelection = value;
                    }
                  }); 
                  if(this.form.accountTypeSelection.code == 4 || this.form.accountTypeSelection.code == 3){
                    this.currencyList.forEach((value) => {
                      if(this.form.currency == value.code){
                        this.form.currencySelection = value;
                      }
                    });
                  } 
                  if(this.form.accountTypeSelection.code == 9 || this.form.accountTypeSelection.code == 16) {
                    this.salesTypeList.forEach((value) => {
                      if(this.form.sales_type == value.code){
                        this.form.salesTypeSelection = value;
                      }
                    });
                  }
                  if(this.form.accountTypeSelection.code == 11) {
                    this.vatTypeList.forEach((value) => {
                      if(this.form.vat_type == value.code){
                        this.form.vatTypeSelection = value;
                      }
                    });
                  } 
                  if(this.form.accountTypeSelection.code == 12) {
                    this.chequeTypeList.forEach((value) => {
                      if(this.form.cheque_type == value.code){
                        this.form.chequeTypeSelection = value;
                      }
                    });
                    this.chequeOperationTypeList.forEach((value) => {
                      if(this.form.cheque_operation_type == value.code){
                        this.form.chequeOperationTypeSelection = value;
                      }
                    });
                  }

                  this.transferAccountTypeList.forEach((value) => {
                    if(this.form.transfer_account_type == value.code){
                      this.form.transferAccountTypeSelection = value;
                    }
                  }); 
                  this.accountTransferParameterList.forEach((value) => {
                    if(this.form.is_opening_account == value.code){
                      this.form.isOpeningAccountSelection = value;
                    }
                  }); 
                  this.accountTransferParameterList.forEach((value) => {
                    if(this.form.is_closing_account == value.code){
                      this.form.isClosingAccountSelection = value;
                    }
                  }); 
                  this.accountTransferParameterList.forEach((value) => {
                    if(this.form.is_profit_loss_account == value.code){
                      this.form.isProfitLossSelection = value;
                    }
                  }); 
                  this.profitLossTransactionTypeList.forEach((value) => {
                    if(this.form.profit_loss_transaction_type == value.code){
                      this.form.profitLossTransactionTypeSelection = value;
                    }
                  }); 
                  this.vatCalculatedTypeList.forEach((value) => {
                    if(this.form.vat_calculated_type == value.code){
                      this.form.vatCalculatedTypeSelection = value;
                    }
                  }); 
                  this.policyAccountTypeList.forEach((value) => {
                    if(this.form.policy_account_type == value.code){
                      this.form.policyAccountTypeSelection = value;
                    }
                  }); 
                  this.form.planTypeSelection = this.planTypeList[0];
                  this.form.customerTypeSelection = this.customerTypeList[0];
                  this.form.supplierTypeSelection = this.supplierTypeList[0];
                  this.form.cashTypeSelection = this.cashTypeList[0];   
                  this.onChangeLevel();                 
                  this.getListData();
                  setTimeout(() => {
                      this.isReady = true;
                  }, 200);
              })
              .catch(function (error) {
                  console.log(error);
                  this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
              });  
        })
        .catch(function () {  
        }); 
    },
    saveData(){
      if(this.form.levelSelection.code == null || this.form.name == null || this.form.code == null) {
        this.$swal("Failed", " Required fields are missing..!", 'warning' );
        return;
      }
      this.isProgressing = true;
      const params = this.form; 
      const that = this;  
        params.id = this.$getSessionStorage("record_id");
        params.session_id = this.$getUser().session_id;
        params.plan_level = params.levelSelection.code;

        // Level 1
        if(params.plan_level == 1){
          params.level_1_id = null;
          params.level_2_id = null;
        }

        // Level 2 to Level 1
        if(params.plan_level == 2){
          params.level_1_id = params.level1Selection.id; 
          params.level_2_id = null;

          if(params.level1Selection.id == null) {
            this.isProgressing = false;
            this.$swal("Failed", " Level-1 must be selected..!", 'warning' );
            return;
          }
        }
        
        // Level 2 to Level 1
        if(params.plan_level == 22){
          params.level_1_id = null; 
          params.level_2_id = params.level2Selection.id;
          params.plan_level = 2;

          if(params.level2Selection.id == null) {
            this.isProgressing = false;
            this.$swal("Failed", " Level-2 must be selected..!", 'warning' );
            return;
          }
        }
        
        // Level 3 to Level 2-Main or Sub
        if(params.plan_level == 3){
          params.level_2_id = params.level2Selection.id;
          params.level_1_id = null;

          if(params.level2Selection.id == null) {
            this.isProgressing = false;
            this.$swal("Failed", " Level-2 must be selected..!", 'warning' );
            return;
          }
        }  
           
          params.balance_type = params.balanceTypeSelection.code;
          params.income_item = params.incomeItemSelection.code;
          params.balance_item = params.balanceItemSelection.code;
          params.plan_type_id = params.planTypeSelection.code;
          params.account_type_id = params.accountTypeSelection.code;
          params.plan_type_id = params.planTypeSelection.code;
          params.account_type_id = params.accountTypeSelection.code;
          params.is_opening_account = params.isOpeningAccountSelection.code;
          params.is_closing_account = params.isClosingAccountSelection.code;
          params.is_profit_loss_account = params.isProfitLossSelection.code;
          params.profit_loss_transaction_type = params.profitLossTransactionTypeSelection.code;
          params.currency = null;
          params.sales_type = 0;
          params.vat_type = 0;
          params.cheque_type = 0;
          params.cheque_operation_type = 0;
          params.policy_account_type = 0;

          params.list_type_id = null;
          if (params.account_type_id == 4) {
            params.list_type_id = params.bankAccountTypeSelection.id;
          } 
          if (params.account_type_id == 5) {
            params.list_type_id = params.personnelPositionTypeSelection.id;
          }
          if (params.account_type_id == 6) {
            params.list_type_id = params.expenseGroupSelection.id;
          } 
          if (params.account_type_id == 7 || params.account_type_id == 9 || params.account_type_id == 16) {
            params.list_type_id = params.stockGroupSelection.id;
          } 
          if (params.account_type_id == 8 || params.account_type_id == 10) {
            params.list_type_id = params.assetTypeSelection.id;
          }
          if (params.account_type_id == 15) {
            params.list_type_id = params.otherAccountGroupSelection.id;
            params.policy_account_type = params.policyAccountTypeSelection.code;
          }
          if (params.account_type_id == 1) {
            params.list_type_id = params.customerTypeSelection.id;
          }
          if (params.account_type_id == 2) {
            params.list_type_id = params.supplierTypeSelection.id;
          }
          if (params.account_type_id == 11) {
            params.vat_type = params.vatTypeSelection.code;
          } else {
            params.vat_rate = 0;
          }

          if (params.account_type_id == 12) {
            params.cheque_type = params.chequeTypeSelection.code;
            params.cheque_operation_type = params.cheque_type == 1 ? params.chequeOperationTypeSelection.code : 0;
          }
          if (params.account_type_id == 3 || params.account_type_id == 4 ) {
            params.currency = params.currencySelection.code;
          }
          if (params.account_type_id == 9 || params.account_type_id == 16 ) {
            params.sales_type = params.salesTypeSelection.code;
          }
          if (params.account_type_id == 17) {
            params.transfer_account_type = params.transferAccountTypeSelection.code;
          }
          if (params.account_type_id == 18) {
            params.vat_calculated_type = params.vatCalculatedTypeSelection.code;
          }
          params.account_id = null;
          axios.post('/api/AccountingPlans/UpdateAccountingPlan', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data != null && response.data.length == 36){  
                    this.$setSessionStorage("record_id", response.data);
                    this.$swal("Success", "Data saved succesfully..!", 'success');     
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            }) .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeSelectablePlan(){
      if (this.form.levelSelection.code == 3) {
        this.accountTransferParameterList.forEach(element => {
          if (element.code == this.form.level2Selection.is_opening_account) {
            this.form.isOpeningAccountSelection = element;
          }
          if (element.code == this.form.level2Selection.is_closing_account) {
            this.form.isClosingAccountSelection = element;
          }
          if (element.code == this.form.level2Selection.is_profit_loss_account) {
            this.form.isProfitLossSelection = element;
          }
        });
        if (this.form.isProfitLossSelection.code == 2) {
          this.profitLossTransactionTypeList.forEach(element => {
            if (element.code == this.form.level2Selection.profit_loss_transaction_type) {
              this.form.profitLossTransactionTypeSelection = element;
            }
          });
        }
      }
      if (this.form.levelSelection.code == 2) {
        this.accountTransferParameterList.forEach(element => {
          if (element.code == this.form.level1Selection.is_profit_loss_account) {
            this.form.isProfitLossSelection = element;
          }
        });    
      }
      setTimeout(() => {
          this.isReady = true;
      }, 200);
    },
    onChangeLevel(isChanged){
      this.isLoadingPlans = true;
      const that = this;

      setTimeout(() => {  
          try{   
              if(isChanged){
                this.levelPlansList = [];
                this.form.level1Selection = { final_code_array: [] };
                this.form.level2Selection = { final_code_array: [] };
                this.form.code = null;
              }
              /**
               * Level 1 seçimi için --
               * Level 2 seçimi için Level 1'ler çağrılmalıdır.
               * Level 2.2 seçimi için Level 2'ler çağrılmalıdır.
               * Level 3 seçimi için Level 2 ve Level 2.2'ler çağrılmalıdır.
               */
              this.levelPlansList = [];

              var requestedLevel = 0;
              if(parseInt(this.form.levelSelection.code) == 2){
                requestedLevel = 1;
              }
              if(parseInt(this.form.levelSelection.code) == 22){
                requestedLevel = 2;
              }
              if(parseInt(this.form.levelSelection.code) == 3){
                requestedLevel = 2;
              }  
              const queryParameter = {
                plan_level: parseInt(requestedLevel),
                template_id: this.$getSessionStorage('active_template_id').id,
                session_id: this.$getUser().session_id
              };
              axios.post('/api/AccountingPlans/GetAccountingPlanByLevel', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {      
                    this.levelPlansList = response.data;   
                    this.levelPlansList.forEach((value) => {
                      var splits = []; 
                      splits = value.final_code.split(/([this.planSettingsData.brace_character])/); 
                      splits.push(this.planSettingsData.brace_character);
                      value.final_code_array = splits; 
                    }); 
                    this.levelPlansList.unshift(
                      {
                        balance_item: null,
                        balance_type: null,
                        code: "0",
                        final_code: "0",
                        id: null,
                        income_item: null,
                        is_level_2_to_level_2: null,
                        is_selectable_for_level_3: null,
                        level_1_code: null,
                        level_1_id: null,
                        level_2_code: null,
                        level_2_id: null,
                        name: "Select an Option",
                        plan_level: null,
                        final_code_array: ['0', '.']
                      }
                    ); 
                    if(this.form.levelSelection.code == 2){
                      this.form.level1Selection = this.levelPlansList[0];
                    }
                    if(this.form.levelSelection.code == 22){
                      this.form.level2Selection = this.levelPlansList[0];
                    }
                    if(this.form.levelSelection.code == 3){
                      this.form.level2Selection = this.levelPlansList[0];
                    }

                    if(!isChanged){
                      this.levelPlansList.forEach((value) => {
                        // Level 2
                        if(this.form.plan_level == 2 && this.form.level_1_id == value.id && this.form.levelSelection.code == 2){
                            this.form.level1Selection = value;
                        }
                        // Level 2.2
                        if(this.form.plan_level == 22 && this.form.level_2_id == value.id && this.form.levelSelection.code == 22){ 
                            this.form.level2Selection = value;
                        } 
                        // Level 3
                        if(this.form.plan_level == 3 && this.form.level_2_id == value.id){
                          this.form.level2Selection = value;
                        } 
                      }); 
                    }
                    this.$Progress.finish();
                })
                .catch(function () { 
                  this.levelPlansList = [];
                  this.form.level1Selection = { final_code_array: [] };
                  this.form.level2Selection = { final_code_array: [] };
                  this.$Progress.finish();
                });  

          }catch(Err){
            console.log(Err);
          }
          finally{
            setTimeout(() => {
                that.isLoadingPlans = false;
            }, 500);
          }
      
      }, 200);
    },
    getListData() {
      if (this.form.accountTypeSelection.list_type == 0) return;
      this.$Progress.start();
      this.lists = [];
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: this.form.accountTypeSelection.list_type,
        type_id: this.form.accountTypeSelection.code
      };
      axios.post('/api/Lists/GetLists', params, {'Content-Type': 'application/json'})
        .then((response) => {
          if(this.form.accountTypeSelection.code == 4){ 
            this.form.bankAccountTypeSelection = this.bankAccountTypeList[0];
            this.bankAccountTypeList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.bankAccountTypeSelection = value;
              }
            });
          } 
          if(this.form.accountTypeSelection.code == 5){
            this.personnelPositionTypeList = response.data; 
            this.personnelPositionTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.personnelPositionTypeSelection = this.personnelPositionTypeList[0];
            this.personnelPositionTypeList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.personnelPositionTypeSelection = value;
              }
            });
          }
          if(this.form.accountTypeSelection.code == 6){
            this.expenseGroupList = response.data;
            this.expenseGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.expenseGroupSelection = this.expenseGroupList[0];
            this.expenseGroupList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.expenseGroupSelection = value;
              }
            });
          } 
          if(this.form.accountTypeSelection.code == 7 || this.form.accountTypeSelection.code == 9 || this.form.accountTypeSelection.code == 16){ 
            this.stockGroupList = response.data;
            this.stockGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.stockGroupSelection = this.stockGroupList[0];
            this.stockGroupList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.stockGroupSelection = value;
              }
            });
          } 
          if(this.form.accountTypeSelection.code == 8 || this.form.accountTypeSelection.code == 10){
            this.assetTypeList = response.data; 
            this.assetTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.assetTypeSelection = this.assetTypeList[0];
            this.assetTypeList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.assetTypeSelection = value;
              }
            });
          }
          if(this.form.accountTypeSelection.code == 15){ 
            this.otherAccountGroupList = response.data;
            this.otherAccountGroupList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.otherAccountGroupSelection = this.otherAccountGroupList[0];
            this.otherAccountGroupList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.otherAccountGroupSelection = value;
              }
            }); 
          } 
          if(this.form.accountTypeSelection.code == 1){ 
            this.customerTypeList = response.data;
            this.customerTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.customerTypeSelection = this.customerTypeList[0];
            this.customerTypeList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.customerTypeSelection = value;
              }
            });
          } 
          if(this.form.accountTypeSelection.code == 2){ 
            this.supplierTypeList = response.data;
            this.supplierTypeList.unshift({ code: 'ALL', name: 'ALL'});
            this.form.supplierTypeSelection = this.supplierTypeList[0];
            this.supplierTypeList.forEach((value) => {
              if(this.form.list_type_id == value.id){
                this.form.supplierTypeSelection = value;
              }
            });
          } 
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error);  
          this.$Progress.finish();
        });
    },
    deletePlan(){
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
                id: this.$getSessionStorage("record_id"),
                session_id: this.$getUser().session_id,
            };
            axios.post('/api/AccountingPlans/DeleteUpdateAccountPlan', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => {  
                    if(!response.data.error){
                        this.$swal("Deleted", "Selected data has been deleted..!", 'error');  
                        this.$setSessionStorage('refreshList', true);
                        this.$router.push({ name: 'PlanList' });
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        });
    },
    getCurrencies(){
        const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };    
        axios.post('/api/Lists/Currencies', params, {'Content-Type': 'application/json'})
             .then((response) => { 
                this.currencyList = response.data;
                this.currencyList.unshift({ code: null, name: 'ALL'});
                this.$Progress.finish();
             })
             .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
             });
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>