<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Document Locations
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>
              <li class="breadcrumb-item text-gray-600">Document Locations</li> 
          </ul> 
      </div>  
        <div class="d-flex align-items-center py-1">
        <router-link to="/GeneralSettings">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to Settings </span>
              </button>
            </router-link>
        </div> 
      </div>  
      <div class="content flex-row-fluid mt-4" id="kt_content">
          <div class="card">
              <div class="card-header border-0 pt-6">
                  <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <router-link to="/DocumentLocationCreate">
                                <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                  </span>Add Document Location</button>
                            </router-link>
                      </div>
                  </div>
                  <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Document Locations </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                          <div class="fw-bolder me-5">
                          <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                      </div>
                  </div>
              </div>
              <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Document-Location-List')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="50"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/> 
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                                <DxColumn data-field="document_type_name" caption="Document Type" cell-template="document_type_name-cell-template"/> 
                                <DxColumn data-field="location_name" caption="Location" cell-template="location-cell-template" :group-index="0"/> 
                                <DxColumn data-field="location_type_name" caption="Type" cell-template="type-cell-template"/> 
                                <DxColumn data-field="location_subtype_name" caption="Sub Type" cell-template="subtype-cell-template"/> 
                                <DxColumn data-field="file_size" caption="Size Limit" cell-template="file_size-cell-template"/> 
                                <DxColumn data-field="is_view_accounts" caption="View Accounts" width="100" align="center" cell-template="is_view_accounts-cell-template"/>  
                                <DxColumn data-field="is_liability" caption="Default Liability" width="120" align="center" cell-template="is_liability-cell-template"/>  
                                <DxColumn data-field="is_chatbi" caption="Default ChatBi" width="120" align="center" cell-template="is_chatbi-cell-template"/>  
  
                                <template #document_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.document_type_name}}
                                    </a>
                                </template>
                                 <template #location-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.location_name}}
                                    </a>
                                </template>
                                <template #type-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.location_type_name}}
                                    </a>
                                </template> 
                                <template #subtype-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.location_subtype_name}}
                                    </a>
                                </template> 
                                <template #file_size-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 mb-1" @click="updateType(data.data)">
                                        {{data.data.file_size}} bytes
                                    </a>
                                </template>  
                                <template #is_view_accounts-cell-template="{ data }"> 
                                    <input class="form-check-input" type="checkbox" v-model="data.data.is_view_accounts" disabled/> 
                                </template> 
                                 <template #is_liability-cell-template="{ data }"> 
                                    <input class="form-check-input" type="checkbox" v-model="data.data.is_liability" disabled/> 
                                </template> 
                                 <template #is_chatbi-cell-template="{ data }"> 
                                    <input class="form-check-input" type="checkbox" v-model="data.data.is_chatbi" disabled/> 
                                </template> 
                        </DxDataGrid> 
                    </div>
                  </div> 
              </div>
          </div> 
      </div> 
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `EXTL001`  
import axios from 'axios'  
import moment from 'moment';
// import Swal from 'sweetalert2';
  
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
  name: "DocumentLocationList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists: [],
      searchbar: '',
      isListProgressing: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    getData() {
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Documents/GetDocumentLocation', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;
            this.lists.forEach((item) => { 
              item.is_view_accounts = item.is_view_accounts == 1 ? true : false;
              item.is_liability = item.is_liability == 1 ? true : false;
              item.is_chatbi = item.is_chatbi == 1 ? true : false;
            });
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    updateType(item){ 
        this.$setSessionStorage("record_id", item.id);
        this.$router.push({ name: 'DocumentLocationUpdate' });
    }
  },
  created() {
         if(this.$getSessionStorage('refreshList')){
             this.getData();
             this.$removeSessionStorage('refreshList'); 
         }
     else{
         this.lists = [];
     }
  }
}; 
</script>