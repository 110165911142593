<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Stock Tracking Group 
            </h1>
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">List Types</router-link></li>    
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'StockTrackingGroupList' }">Stock Tracking Group</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Create Stock Tracking Group</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/StockTrackingGroupList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link> 
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10"> 
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Tracking Code
                                                            </label>
                                                            <input type="text" class="form-control " name="code" v-model="form.code">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Tracking Name
                                                            </label>
                                                            <input type="text" class="form-control " name="name" v-model="form.name">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Description</span>
                                                            </label>
                                                            <textarea rows="2" class="form-control " name="description" v-model="form.description"></textarea>
                                                        </div>
                                                        <div class="col-lg-12 mt-5">
                                                            <input class="form-check-input" type="checkbox" value="" id="isReadOnly" v-model="form.read_only">
                                                            <label class="fs-6 fw-bold form-label ms-3" for="isReadOnly">
                                                                Read Only (Values)
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </form>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                            
                                                    <div class="fv-row mb-7">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Input Type
                                                            </label>
                                                            <Select2 v-model="form.input_type" :options="inputTypeList" :settings="{width: '100%'}" :disabled="this.itemList.length > 0"/>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Data Type
                                                            </label>
                                                            <Select2 v-model="form.data_type" :options="dataTypeList" :settings="{width: '100%'}" :disabled="this.itemList.length > 0"/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel" v-if="form.input_type == '2'">
                    <div class="p-10"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="p-5">
                                        <div class="d-flex flex-stack flex-wrap mb-5">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                    data-bs-target="#kt_modal_add_list_item" @click="openItemModal('addItem')"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                    :disabled="form.data_type == 0">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span>
                                                    Add Item
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                                    data-bs-target="#kt_modal_add_list_item" @click="openItemModal('editItem')"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :disabled="form.data_type == 0 ||  isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                            </svg>
                                                    </span>
                                                    Edit
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteItemData()" :disabled="form.data_type == 0 ||  isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    Remove
                                                </button>
                                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllItemData()" :disabled="form.data_type == 0">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    Remove All (!)
                                                </button>
                                            </div>
                                        </div>
                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive min-h-300px">
                                                <DxDataGrid :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="itemList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" > 

                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="false"/>
                                                        <DxPaging :page-size="1000"/>
                                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                
                                                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                        <DxColumn data-field="currency" caption="List Code"  cell-template="code-cell-template"/>
                                                        <DxColumn data-field="name" caption="List Name" cell-template="name-cell-template"/> 
                                                        <DxColumn data-field="value" caption="Value" alignment="right" cell-template="value-cell-template"/> 
                                                        <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/> 

                                                        <template #sales-channel-header>
                                                            <div>Sales</div><div>Channel</div>
                                                        </template>
                                                        
                                                            <!-- Cell Templates -->
                                                        <template #is-selected-cell-template="{ data }"> 
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template #code-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.code }}
                                                            </span>
                                                        </template>
                                                        <template #name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.name }}
                                                            </span>
                                                        </template>
                                                        <template #value-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.value }}
                                                            </span>
                                                        </template>
                                                        <template #description-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.description }}
                                                            </span>
                                                        </template>
                                                </DxDataGrid>
                                            </div>
                                            <div class="row" v-if="itemList && itemList.length > 0">
                                                <div
                                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                    <div class="dataTables_info" role="status" aria-live="polite">
                                                        {{ itemList.length }} Records
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10"> 
                        <div class="row">
                            <div class="col-lg-12"> 
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> Save Stock Tracking Group </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>                   
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
      
    <div class="modal fade" id="kt_modal_add_list_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        <span class="fw-bolder" v-if="this.modalType == 'addItem'"> Add Item </span>
                        <span class="fw-bolder" v-if="this.modalType == 'editItem'"> Edit Item </span>                     
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <form class="form">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required">List Code</label>
                                            <div class="col-lg-8">
                                                <input type="text" class="form-control " name="code"
                                                    v-model="itemForm.code">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required">List Name</label>
                                            <div class="col-lg-8">
                                                <input type="text" class="form-control " name="name"
                                                    v-model="itemForm.name">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required">Value</label>
                                            <div class="col-lg-8">
                                                <!-- TEXT -->
                                                <input type="text" class="form-control " name="value" v-model="itemForm.value" v-if="this.form.data_type == 1">
                                                <!-- NUMBER -->
                                                <CurrencyInput type="text" class="form-control" name="total_length" v-model="itemForm.value" :precision="0" @focus="$event.target.select()" v-if="this.form.data_type == 2"/>
                                                <!-- NUMBER DECIMAL -->
                                                <CurrencyInput type="text" class="form-control" name="total_length" v-model="itemForm.value" :precision="2" @focus="$event.target.select()" v-if="this.form.data_type == 3"/>
                                                <!-- DATE -->
                                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" 
                                                            v-maska="'##.##.####'" 
                                                            v-model="itemForm.value" 
                                                            inputClassName="form-control" 
                                                            format="dd.MM.yyyy" autoApply 
                                                            :clearable="false" 
                                                            weekNumbers :monthChangeOnScroll="'inverse'" 
                                                            v-if="this.form.data_type == 4"></Datepicker>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label fw-bold fs-6">Description</label>
                                            <div class="col-lg-8">
                                                <input type="text" class="form-control " name="description"
                                                    v-model="itemForm.description">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-flush mt-4" id="kt_contacts_list">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-light-success border border-success btn-lg"
                                        @click="this.saveItemData()" v-if="this.modalType == 'addItem'">
                                        <span class="indicator-label">Add to List</span> 
                                    </button>
                                    <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                        @click="this.updateItemData()">
                                        <span class="indicator-label">Update</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>



<script>
// Component Alias: `STGC001`
import axios from 'axios'  
// import Swal from 'sweetalert2'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn } from 'devextreme-vue/data-grid';
 

 
export default {
  name: "StockTrackingGroupCreate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn
  },
  data() {
    return {
        isProgressing: false,
        isItemSaving: false,
        form: { 
            code: null,
            name: null,
            description: null,
            session_id: this.$getUser().session_id,
            data_type: 0,
            input_type: 0,
            sales_channel: null,
            tmp_session_id: null,
            read_only: false
        },
        itemForm: {
            name: null,
            code: null,
            description: null,
            value: null
        },
        dataTypeList: [
            { id: 0, text: 'Select an option' },
            { id: 1, text: 'Text' },
            { id: 2, text: 'Number' },
            { id: 3, text: 'Number (Float)' },
            { id: 4, text: 'Date' },
        ],
        inputTypeList: [
            { id: 0, text: 'Select an option' },
            { id: 1, text: 'Free Data' },
            { id: 2, text: 'List' }
        ],
        salesChannelList: [
            { id: '-1', text: 'Select an option' }
        ],
        itemList: [],
        modalType: 'addItem'
    };
  },
  computed: { 
   isItemButtonDisabled() {
       return this.itemList.find(item =>item.isSelected == true );
    }
  },
  methods: { 
    getData(){
        this.$setSessionStorage('refreshList', true);
            
    },
    saveData(){
        const parameters = this.form; 
        const that = this;
        parameters['tmp_session_id'] = this.$getSessionStorage('tmp_session_id');
        parameters.read_only = parameters.read_only == true ? 1 : 0;
        axios.post('/api/StockTrackingGroups/CreateStockTrackingGroup', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data != null && response.data.length == 36){  
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.$setSessionStorage('record_id', response.data);
                    this.$setSessionStorage('refreshList', true); 
                    this.$router.push({ name: 'StockTrackingGroupUpdate' });
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          })
          .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            }); 
    },
    onSelectItem(item) {
        this.itemList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });     
    },
    openItemModal(value) {
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.modalType = value;
        this.isItemSaving = false;

        this.itemForm = {
            id: null,
            name: null,
            code: null,
            description: null,
            value: null
        };

        if (this.modalType == 'editItem') {                        
            this.itemList.forEach((item) => {
                if (item.isSelected) {
                    this.itemForm.id = item.id;
                    this.itemForm.name = item.name;
                    this.itemForm.code = item.code;
                    this.itemForm.description = item.description;
                    this.itemForm.value = item.value;
                }
            });
        }
    },
    saveItemData(){
        if(this.itemForm.code == null || this.itemForm.code == '' || this.itemForm.name == null || this.itemForm.name == '' || this.itemForm.value == null || this.itemForm.value == ''){ 
            this.$swal("Warning", "Required field can not be empty..!", 'info');
            return; 
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            code: this.itemForm.code,
            name: this.itemForm.name,
            value: this.itemForm.value,
            description: this.itemForm.description
        }; 
        parameters.value = String(parameters.value);
        axios.post('/api/StockTrackingGroups/AddStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.itemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } 
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    updateItemData(){
        if(this.itemForm.code == null || this.itemForm.code == '' || this.itemForm.name == null || this.itemForm.name == '' || this.itemForm.value == null || this.itemForm.value == ''){ 
            this.$swal("Warning", "Required field can not be empty..!", 'info');
            return; 
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            code: this.itemForm.code,
            name: this.itemForm.name,
            value: this.itemForm.value,
            description: this.itemForm.description,
            id: this.itemForm.id
        }; 
        parameters.value = String(parameters.value);
        axios.post('/api/StockTrackingGroups/UpdateStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.itemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } 
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    }, 
    deleteItemData(){
        this.itemList.forEach((item) => {
            if (item.isSelected) {
                this.itemForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    id: this.itemForm.id
                }; 
                axios.post('/api/StockTrackingGroups/DeleteStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.itemList = response.data;
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllItemData(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('/api/StockTrackingGroups/DeleteStockTrackingListItemAll', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.itemList = [];
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  }
}; 
</script>