import axios from "axios"; 
import SecureLS from "secure-ls"; 

const ls = new SecureLS({ isCompression: true, encodingType: 'aes' });
// import Swal from 'sweetalert2'

// axios.interceptors.response.use((response) => {
//   if (response.data.error) { 
//     new Swal({
//       icon: 'error',
//       title: 'Error...',
//       text: response.data.error
//     });
//   }
//   return response;
// }, (error) => {
//   return Promise.reject(error.message);
// });

const state = {
  user: null
};

const getters = {
  isAuthenticated: (state) => !!state.user, 
  StateUser: (state) => state.user
};

const actions = {  
  async LogIn({commit}, user) {
    sessionStorage.clear();
    const params = {
      username: user.get("username"),
      password: user.get("password")
    };
    await axios.post('/api/Auth/AuthUser', params, {'Content-Type': 'application/json'})
    .then((response) => {
      if(response.data.id != null && response.data.id.length == 36){
        response.data.company_account_id = this.$unlock(response.data.company_account_id);
        response.data.controller_account_id = this.$unlock(response.data.controller_account_id);
        response.data.system_account_id = this.$unlock(response.data.system_account_id);
        response.data.user_mail = this.$unlock(response.data.user_mail);
        response.data.name_surname = this.$unlock(response.data.name_surname);
        response.data.phone = this.$unlock(response.data.phone);
        response.data.controller_account_name = this.$unlock(response.data.controller_account_name);
        response.data.company_url = this.$unlock(response.data.company_url);
        response.data.currency_code = this.$unlock(response.data.currency_code);
        response.data.system_account_country_code = this.$unlock(response.data.system_account_country_code);

        ls.set('user_' + response.data.session_id, response.data); 
        sessionStorage.setItem('sid', response.data.session_id);
      }
      else{ 
        commit("removeUser");
      }
    })
    .catch(function (error) { 
        console.log(error);
    }); 
  },  
};

const mutations = {  
  removeUser() {
    sessionStorage.clear();
  },
};  
export default {
  state,
  getters,
  actions,
  mutations
};
