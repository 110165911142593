<template>
  <div>  
    <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
          Create Product Group
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
              <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
            </li> 
            <li class="breadcrumb-item text-gray-600">Settings</li>  
            <li class="breadcrumb-item text-gray-600">Controller Settings</li>  
            <li class="breadcrumb-item text-gray-600">List Types</li>    
            <li class="breadcrumb-item text-gray-600">Product Groups</li> 
            <li class="breadcrumb-item text-gray-600">Create Product Group</li> 
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
          <form @submit.prevent="submit">
            <div class="card-header border-0 pt-6">
              <div class="card-title">
                  Create Product Group
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                      <router-link to="/ProductGroupList">
                          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                           <span class="ms-2 fs-7"> Return back to List</span></button>
                      </router-link> 
                  </div> 
              </div>   
            </div>
            <div class="card-body p-12">
               <div class="fv-row mb-7 fv-plugins-icon-container">
                    <div class="row mb-7">
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mb-2 required">
                            Code
                          </label>
                          <input type="text" class="form-control form-control-solid" placeholder="" name="name" v-model="form.code"> 
                        </div>
                        <div class="col-lg-6">
                          <label class="fs-6 fw-bold form-label mb-2 required">
                            Name
                          </label>
                          <input type="text" class="form-control form-control-solid" placeholder="" name="name" v-model="form.name"> 
                        </div>
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mb-2 mt-5">Description</label>
                          <textarea type="text" rows="2" class="form-control form-control-solid" placeholder="" name="name" v-model="form.description" /> 
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="form-check mt-3">
                              <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                              <label class="form-check-label" for="isDefault">
                                  Default
                              </label>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>
            <div class="card-footer d-flex justify-content-end">
              <button type="submit" href="#" class="btn btn-success" id="kt_invoice_submit_button" @click="saveData()" data-kt-indicator="on">
                  <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                  <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                  </svg>
                  </span>
                    <strong v-if="!this.isProgressing"> Save </strong> 
                      <span class="indicator-progress" v-if="this.isProgressing">
                          Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span> 
              </button>
            </div>
          </form>
        </div>
    </div> 
  </div> 
</template>


<script>
// Component Alias: `PGC001`
import axios from 'axios'  
 
// import Swal from 'sweetalert2'; 

 
export default {
  name: "ProductGroupCreate",
  data() {
    return {
      stockGroups: [],
      isProgressing: false,
      form: { 
          name: null,
          code: null,
          description: null,
          session_id: null,
          parent_id: null,
          is_default: false
      }
    };
  },
  methods: { 
    getData(){
      
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;     
      params.list_type = 7; // Product Group: 7
      params.session_id = this.$getUser().session_id;
      params.parent_id = null;
      axios.post('/api/Lists/CreateListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'ProductGroupUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      }
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>