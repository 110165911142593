<template> 
  <div>
    <div class="content flex-row-fluid" id="kt_content">
        <div class="card">
            <div class="card-header border-0">
                <div class="card-title">
                    <button type="button" class="btn btn-light-primary border border-primary me-3" @click="openModal('addItem')" data-bs-toggle="modal" data-bs-target="#kt_modal_mail_settings" >
                        <span class="svg-icon svg-icon-muted">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        Add Mail Setting
                    </button>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div> 
                </div>
            </div>
            <div class="card-body">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">                  
                        <DxDataGrid :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Mail-Settings-List')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                    
                                />
                                <DxColumn data-field="mail_type_name" caption="Mail Type" cell-template="mail_type_name-cell-template"/> 
                                <DxColumn data-field="mail_subject" caption="Mail Subject" cell-template="mail_subject-cell-template" alignment="left"/> 
                                <DxColumn data-field="created_at" caption="Date" alignment="center" cell-template="created_at-cell-template" :allow-filtering="false"/> 

                                <!-- Header Cell Templates -->

                                <!-- Cell Templates -->
                                <template #mail_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="openModal('editItem', data.data.id)" data-bs-toggle="modal" data-bs-target="#kt_modal_mail_settings">
                                        {{ data.data.mail_type_name }}
                                    </a>
                                </template>
                                <template #mail_subject-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary mb-1" @click="openModal('editItem', data.data.id)" data-bs-toggle="modal" data-bs-target="#kt_modal_mail_settings">
                                        {{ getMailSubjectName(data.data.mail_subject) }}
                                    </a>
                                </template>
                                <template #created_at-cell-template="{ data }">
                                    <a href="#" class="text-gray-900 text-hover-primary fw-bold mb-1" @click="openModal('editItem', data.data.id)" data-bs-toggle="modal" data-bs-target="#kt_modal_mail_settings">
                                        {{ previewDate(data.data.created_at) }}
                                    </a>
                                </template>
                        </DxDataGrid>
                    </div>
                </div> 
            </div>
        </div> 
    </div>

    <!-- ADD ACCOUNTING YEAR MODAL -->
    <div class="modal fade" id="kt_modal_mail_settings" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-11">
                        <span v-if="this.modalType == 'addItem'"> Add </span>
                        <span v-if="this.modalType == 'editItem'"> Edit </span> Mail Settings
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>
                    </div> 
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isSaving == true}">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row">
                            <div class="col-lg-12"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Mail Subject
                                                        </label>
                                                        <Select2 v-model="form.mail_subject" :options="mailSubjectList" :settings="{ width: '100%' }" />  
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Sender Mail
                                                        </label>
                                                        <input type="text" class="form-control" name="name" v-model="form.sender" :class="{'border border-danger': isValidEmail == false}"> 
                                                        <small class="text-danger" v-if="!isValidEmail && form.sender !== null && form.sender !== '' ">Email is not valid</small>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Mail Server (Smtp)
                                                        </label>
                                                        <input type="text" class="form-control" name="Smtp" v-model="form.smtp"> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Port
                                                        </label>
                                                        <input type="number" class="form-control" name="port" v-model="form.port">
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                        SSL
                                                        </label>
                                                        <div class="form-check mt-3 mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="isSSL" v-model="form.ssl">
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Sender Username
                                                    </label>
                                                    <input type="text" class="form-control" name="name" v-model="form.username"> 
                                                    </div>
                                                    <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                        Sender  Password
                                                    </label>
                                                    <div class="position-relative">
                                                    <input type="password" class="form-control" name="name" v-model="form.password"> 
                                                    </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-grid gap-2"> 
                                    <button v-if="modalType == 'addItem'" type="button" class="btn btn-lg btn-success " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> Save Mail Settings </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                    </button>
                                    <button v-if="modalType == 'editItem'" type="button" class="btn btn-lg btn-primary " @click="this.updateData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> Update Mail Settings </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                    </button>
                                    <button v-if="modalType == 'editItem'"  type="button" class="btn btn-danger float-start btn-lg" @click="deleteItem()">
                                        <strong>Delete</strong> 
                                    </button>
                                </div>               
                            </div>
                        </div>
                    </form>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios' 

import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

import moment from 'moment'; 
 
export default {
    name: "MailSettingsList", 
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
    props: {
        mailType: Number,
        accountId: String
    },
    data() {
        return {
            lists:[],
            mailSubjectList: [
                { id: 1, text: 'Sending Document'},
            ],
            isListProgressing: false,
            isSaving: false,
            modalType: 'addItem',
            form: { 
                id: null,
                username: null,
                password: null,
                sender: null,
                smtp: null,
                port:0,
                ssl: false,
                mail_type: 0,
                mail_subject: 1,
            },
        };
    },
    computed: {
        previewDate(){
            return (value)=>{
                if (value) {
                    return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        getMailSubjectName(){
            return (value)=>{
                let mailSubject = this.mailSubjectList.find(p => { return p.id == value;});
                if(mailSubject) {
                    return mailSubject.text
                }
                return null;
            }
        },
		isValidEmail() {
			return (this.form.sender == null || this.form.sender == '' || this.form.sender == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.sender);
		}
    },
    methods: {
        getData() {  
            this.$Progress.start();
            this.lists = [];
            this.isListProgressing = true;
            const params = {
                session_id: this.$getUser().session_id,
                mail_type: this.mailType,
                company_id: this.mailType == 3 ? this.accountId : null,
                controller_id: this.mailType == 2 ? this.accountId : null
            };    
            axios.post('/api/MailSettings/GetAll', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                this.lists = response.data;
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        openModal(modalType, recordId){
            this.modalType = modalType;
            this.form = { 
                id: null,
                username: null,
                password: null,
                sender: null,
                smtp: null,
                port:0,
                ssl: false,
                mail_type: 0,
                mail_subject: 1,
            };

            if(this.modalType == 'editItem') {
                const params = {
                    id: recordId,
                    session_id: this.$getUser().session_id,
                }; 
                axios.post('/api/MailSettings/GetById', params, {'Content-Type': 'application/json'}).then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        const mailData = JSON.parse(response.data.data);
                        if(mailData !== null && mailData !== '' && mailData !== undefined) {
                            this.form = { 
                                id: response.data.id,
                                mail_type: response.data.mail_type,
                                mail_subject: response.data.mail_subject,
                                username: mailData.username,
                                password: mailData.password,
                                sender: mailData.sender,
                                smtp: mailData.smtp,
                                port: mailData.port,
                                ssl: mailData.ssl,
                            }; 
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error); 
                });
            }
        },
        saveData(){
            if(!this.form.sender || !this.form.smtp || !this.form.username || !this.form.password) {                
                this.$swal("Failed", "Required fields must be filled!", 'error');
                return;
            }
            this.isSaving = true;
            const obj = {
                "username": this.form.username,
                "password": this.form.password,
                "sender": this.form.sender,
                "smtp": this.form.smtp,
                "port": this.form.port,
                "ssl": this.form.ssl
            };
            const mailInfo = JSON.stringify(obj);
            const params = {
                session_id:  this.$getUser().session_id,
                data : mailInfo,
                mail_type: this.mailType,
                mail_subject: this.form.mail_subject,
                company_id: this.mailType == 3 ? this.accountId : null,
                controller_id: this.mailType == 2 ? this.accountId : null
            };
            const that = this;
            axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$refs.closeModal.click();
                    this.getData();
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isSaving = false;
                }, 500);
            });   
        },
        updateData(){
            if(!this.form.sender || !this.form.smtp || !this.form.username || !this.form.password) {                
                this.$swal("Failed", "Required fields must be filled!", 'error');
                return;
            }
            this.isProgressing = true;
            const obj = {
                "username": this.form.username,
                "password": this.form.password,
                "sender": this.form.sender,
                "smtp": this.form.smtp,
                "port": this.form.port,
                "ssl": this.form.ssl
            };
            const mailInfo = JSON.stringify(obj);
            const params = {
                id: this.form.id,
                mail_subject: this.form.mail_subject,
                session_id:  this.$getUser().session_id,
                data : mailInfo,
            };
            const that = this;
            axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$refs.closeModal.click();
                    this.getData();
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            });   
        },
        deleteItem(){
            this.$swal.fire({
                title: 'Are you sure you want to delete this data?',
                confirmButtonColor: '#f1416c',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            }).then((result) => { 
                if (result.isConfirmed) {
                    const params = {
                        session_id: this.$getUser().session_id,
                        id: this.form.id
                    }; 
                    axios.post('api/MailSettings/Delete', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.getData();
                            this.$refs.closeModal.click();
                        }
                    })
                    .catch(function () {
                        this.$swal("Error", "Data could not be deleted..Please refresh the page and try again..!", 'error');
                    });  
                }
            })   
        },
    }, 
    created() {
        this.getData();
    }
}; 
</script>