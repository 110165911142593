<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Document Location
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'DocumentLocationList' }">Document Location</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Update Document Location </li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/DocumentLocationList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link> 
            &nbsp;
            <div class="dropdown">
                <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    Actions
                </button>
                <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdown }"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteData()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>Delete </a>
                    </li>
                </ul>
            </div>  
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row"> 
                                                        <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Document Location
                                                        </label>
                                                        
                                                        <Select2 v-model="form.location" :options="locationList" @select="this.getMainLocations()" :settings="{ width: '100%' }"/>
                                                    </div>

                                                    <!-- Accounts -->
                                                        <div class="col-lg-12" v-if="form.location == 1">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Accounts
                                                        </label>
                                                        <Select2 v-model="form.location_type" :options="accountsList" @select="this.onTypeChange()" :settings="{ width: '100%' }"/> 
                                                    </div>

                                                    <!-- Transactions -->
                                                    <div class="col-lg-12" v-if="form.location == 2">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Transactions
                                                        </label>
                                                        <Select2 v-model="form.location_type" :options="transactionsList" @select="this.onTypeChange()" :settings="{ width: '100%' }"/> 
                                                    </div> 
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 1">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Invoice Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="invoiceTypeList" :settings="{ width: '100%' }"/>  
                                                            </div>
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 2">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Cash Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="cashTypeList" :settings="{ width: '100%' }"/>
                                                            </div>
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 3">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Bank Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="bankTypeList" :settings="{ width: '100%' }"/> 
                                                            </div>
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 4">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Expense Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="expenseTypeList" :settings="{ width: '100%' }"/>  
                                                            </div>
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 5">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Cheque Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="chequeTypeList" :settings="{ width: '100%' }"/>   
                                                            </div>
                                                            <div class="col-lg-12" v-if="form.location == 2 && form.location_type == 6">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Offsetting Types
                                                                </label>
                                                                <Select2 v-model="form.location_subtype" :options="offsettingTypeList" :settings="{ width: '100%' }"/>  
                                                            </div>


                                                    <!-- Accounting -->
                                                    <div class="col-lg-12" v-if="form.location == 3">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Accounting Types
                                                        </label>
                                                        <Select2 v-model="form.location_type" :options="accountingList" @select="this.onTypeChange()" :settings="{ width: '100%' }"/> 
                                                    </div>

                                                    <!-- Reports -->
                                                    <div class="col-lg-12" v-if="form.location == 4">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Report Types
                                                        </label>
                                                        <Select2 v-model="form.location_type" :options="reportList" @select="this.onTypeChange()" :settings="{ width: '100%' }"/>  
                                                    </div> 

                                                    <!-- General -->
                                                    <div class="col-lg-12" v-if="form.location == 5">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            General Types
                                                        </label>
                                                        <Select2 v-model="form.location_type" :options="generalList" @select="this.onTypeChange()" :settings="{ width: '100%' }"/>   
                                                    </div> 


                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Document Types
                                                        </label>
                                                        <Select2 v-model="form.document_type_id" :options="documentTypeList" :settings="{ width: '100%' }"/> 
                                                    </div>  

                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Main Location
                                                        </label>
                                                        <Select2 v-model="form.main_location" :options="documentLocationList" :settings="{ width: '100%' }"/> 
                                                    </div>  
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">      
                                                    <!-- exe, bin -->
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Block Extensions
                                                        </label>
                                                        <input type="text" class="form-control " v-model="form.blocked_extensions">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            File Size Limit (MB)
                                                        </label>
                                                        <input type="text" class="form-control " v-model="form.file_size">
                                                    </div>                                          
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                    <div class="col-lg-12 mt-4">
                                                        <input class="form-check-input" type="checkbox" value="" id="is_view_accounts" 
                                                                v-model="form.is_view_accounts" 
                                                                :disabled="form.location == 1">
                                                        <label class="fs-6 fw-bold form-label ms-4" for="is_view_accounts">
                                                            View in Accounts
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-12 mt-4">
                                                        <input class="form-check-input" type="checkbox" value="" id="is_operable_in_accounts" 
                                                                v-model="form.is_operable_in_accounts"  
                                                                :disabled="form.is_view_accounts == false || form.location == 1">
                                                        <label class="fs-6 fw-bold form-label ms-4" for="is_operable_in_accounts">
                                                            Let Actions in Accounts
                                                        </label>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-4">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Update Document Location </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `EXTU001`
import axios from 'axios';
import Swal from 'sweetalert2';
  

 
export default {
  name: "DocumentLocationUpdate",
  data() {
    return {
      lists: [],
      isOpenActionsDropdown: false, 
      isProgressing: false,
      form: { 
          id: null,
          name: null,
          code: null,
          session_id: null,
          location: 1,
          location_type: 0,
          document_type_id: '-1',
          blocked_extensions: null,
          file_size: 10485760,
          description: null,
          location_subtype: 0,
          is_view_accounts: true,
          is_operable_in_accounts: true,
          main_location: 0
      },
      locationList: [
            { id: 1, text: 'Accounts' },
            { id: 2, text: 'Transactions' },
            { id: 3, text: 'Accounting' },
            { id: 4, text: 'Reports' },
            { id: 5, text: 'General' }
      ],
      accountsList: [
            { id: 0, text: 'Select and option' },
            { id: 1, text: 'Customers' },
            { id: 2, text: 'Suppliers' },
            { id: 3, text: 'Cash' },
            { id: 4, text: 'Bank' },
            { id: 5, text: 'Personnel' },
            { id: 6, text: 'Expense' },
            { id: 7, text: 'Stock' },
            { id: 8, text: 'Fixed Assets' },
            { id: 9, text: 'Other Accounts' }
      ],
      transactionsList: [
            { id: 0, text: 'Select and option' },
            { id: 1, text: 'Invoice' },
            { id: 2, text: 'Cash' },
            { id: 3, text: 'Bank' },
            { id: 4, text: 'Expense' },
            { id: 5, text: 'Cheque' },
            { id: 6, text: 'Offsetting' }
      ],
      accountingList:[
            { id: 0, text: 'Select and option' },
            { id: 1, text: 'Vouchers' },
            { id: 2, text: 'Balance-sheets' },
            { id: 3, text: 'Profit and Loss Statements' }
      ],
      reportList: [
            { id: 0, text: 'Select and option' },
            { id: 1, text: 'Accounting Reports' },
            { id: 2, text: 'Stock Reports' },
            { id: 3, text: 'Financial Reports' },
      ],
      generalList: [
            { id: 0, text: 'Select and option' },
            { id: 1, text: 'Liabilities' },
            { id: 2, text: 'ChatBi' }
      ],
      documentTypeList: [],
      invoiceTypeList:[
        { id: 0, text: 'All Invoices' },
        { id: 1, text: 'Sales Invoice' },
        { id: 2, text: 'Purchase Invoice' },
        { id: 3, text: 'Sales Return Invoice' },
        { id: 4, text: 'Purchase Return Invoice' }
      ], 
      cashTypeList:[
        { id: 0, text: 'All Cash' },
        { id: 1, text: 'Incoming Cash' },
        { id: 2, text: 'Outgoing Cash' }
      ],
      bankTypeList:[
        { id: 0, text: 'All Bank' },
        { id: 1, text: 'Incoming Bank' },
        { id: 2, text: 'Outgoing Bank' }
      ],
      expenseTypeList:[
        { id: 0, text: 'All Expenses' }
      ],
      chequeTypeList:[
        { id: 0, text: 'All Cheques' },
        { id: 1, text: 'Incoming Cheque' },
        { id: 2, text: 'Outgoing Cheque' }
      ],
      offsettingTypeList:[
        { id: 0, text: 'All Offsetting' }
      ],
      documentLocationList: []
    };
  },
  methods: {
    getData() { 
        this.$setSessionStorage('refreshList', true);
        const parameters = { 
            session_id: this.$getUser().session_id
        }; 
        axios.post('/api/Documents/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.documentTypeList = response.data;

                const parameters = { 
                    session_id: this.$getUser().session_id,
                    id: this.$getSessionStorage('record_id'),
                }; 
                axios.post('/api/Documents/GetDocumentLocationById', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        this.form.id = response.data.id;
                        this.form.location = response.data.location;
                        this.form.location_type = response.data.location_type;
                        this.form.document_type_id = response.data.document_type_id;
                        this.form.blocked_extensions = response.data.blocked_extensions;
                        this.form.file_size = response.data.file_size;
                        this.form.description = response.data.description;
                        this.form.location_subtype = response.data.location_subtype;
                        this.form.is_view_accounts = response.data.is_view_accounts == 1 ? true : false;
                        this.form.is_operable_in_accounts = response.data.is_operable_in_accounts == 1 ? true : false;
                        this.form.main_location = response.data.main_location == null || response.data.main_location == '' ? '-1' : response.data.main_location;

                        this.getMainLocations();
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });          
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });          
        
    },
    saveData(){
        const parameters = { 
          session_id: this.$getUser().session_id,
          id: this.$getSessionStorage('record_id'),
          document_type_id: this.form.document_type_id,
          location: this.form.location,
          location_type: this.form.location_type,
          location_subtype: this.form.location_subtype,
          blocked_extensions: this.form.blocked_extensions,
          file_size: this.form.file_size,
          description: this.form.description,
          is_view_accounts: this.form.is_view_accounts,
          is_operable_in_accounts: this.form.is_operable_in_accounts,
          main_location: this.form.main_location
        }; 
        parameters.document_type_id = parameters.document_type_id == '-1' ? null : parameters.document_type_id;
        parameters.is_view_accounts = parameters.is_view_accounts == true ? 1 : 0;
        parameters.is_operable_in_accounts = parameters.is_operable_in_accounts == true ? 1 : 0;
        parameters.is_operable_in_accounts = parameters.is_view_accounts == 1 ? parameters.is_operable_in_accounts : 0;
        parameters.main_location = parameters.main_location == '-1' ? null : parameters.main_location;
        parameters.is_view_accounts = parameters.location == 1 ? 0 : parameters.is_view_accounts;
        parameters.is_operable_in_accounts = parameters.location == 1 ? 0 : parameters.is_operable_in_accounts;
        axios.post('/api/Documents/UpdateDocumentLocation', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.$setSessionStorage('refreshList', true); 
                this.getData(); 
             }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    }, 
    onTypeChange(){
        this.form.location_subtype = 0;
    },
    deleteData(){ 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          confirmButtonColor: '#f1416c',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const parameters = { 
                session_id: this.$getUser().session_id,
                id: this.$getSessionStorage('record_id'),
            }; 
            axios.post('/api/Documents/DeleteDocumentLocation', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                        this.$setSessionStorage('refreshList', true);
                        this.$router.push({ name: 'DocumentLocationList' });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        })    
    },
     getMainLocations() {
      this.documentLocationList = []; 
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Documents/GetDocumentLocation', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            response.data.forEach((item) => { 
                if(item.location == this.form.location){
                    this.documentLocationList.push(item);
                }
            });
            this.documentLocationList.unshift({
                id: '-1',
                text: 'Select an option'
            });
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
     }
  },
  mounted() { 
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>