<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Plan Templates
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'AccountingPlans' }">Account Plans</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Plan Templates</li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link :to="{ name: 'AccountingPlans', params: { type: 'AccountingPlans' } }">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                         <span class="ms-2 fs-7"> Return back to Settings </span>
                    </button>
                </router-link>
            </div> 
        </div> 
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                            <button type="button" class="btn btn-light-info border border-info me-3" data-bs-toggle="modal"
                                data-bs-target="#PlanTemplateCopyModal" @click="openPlanTemplateCopyModal()">
                                <span class="svg-icon svg-icon-muted">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                Copy
                            </button>
                            <router-link to="/AccountingTemplatesCreate">
                                <button type="button" class="btn btn-light-success border border-success me-3">
                                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                fill="currentColor" />
                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    Add Accounting Template
                                </button>
                            </router-link>
                            <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> List Accounting Templates </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                        <div class="d-flex justify-content-end align-items-center d-none"
                            data-kt-customer-table-toolbar="selected">
                            <div class="fw-bolder me-5">
                                <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected
                            </div>
                            <button type="button" class="btn btn-danger"
                                data-kt-customer-table-select="delete_selected">Delete Selected</button>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">                            
                            <DxDataGrid :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="lists" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    @exporting="this.$onExporting($event , 'Accounting-Templates-List')">

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="20"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                        
                                    />
                                    <DxColumn data-field="auth_type" caption="Authorized User" header-cell-template="authorized-user-header" cell-template="authorized_user-cell-template"/> 
                                    <DxColumn data-field="name" caption="Template Name" header-cell-template="template-name-header" cell-template="name-cell-template"/>
                                     
                                    <DxColumn data-field="hide" caption="Is Hide?" alignment="center" cell-template="hide-cell-template" :allow-filtering="false"/> 

                                    <!-- Header Cell Templates -->
                                    <template #authorized-user-header>
                                        <div> Authorized </div> <div> User </div>
                                    </template> 
                                    <template #template-name-header>
                                        <div> Template </div> <div> Name </div>
                                    </template>

                                    <!-- Cell Templates -->
                                    <template #authorized_user-cell-template="{ data }">
                                        <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                            class="text-gray-900 mb-1" @click="updateItem(data.data)">
                                            {{data.data.auth_type}}
                                        </a>
                                    </template>
                                    <template #name-cell-template="{ data }">
                                        <a href="#" :class="{'text-hover-primary': data.data.full_access == 1, 'pe-none': data.data.full_access == 0}"
                                            class="text-gray-900 fw-bold mb-1" @click="updateItem(data.data)">
                                            <span class="badge badge-circle badge-light me-3" v-if="data.data.full_access == 1">
                                                <i class="bi bi-pencil text-gray-900"></i> 
                                            </span> 
                                            {{ data.data.name }}
                                        </a>
                                    </template> 
                                    <template #hide-cell-template="{ data }">
                                            <input class="form-check-input" type="checkbox" value="" id="hide" v-model="data.data.hide" @change="setListItemHide(data.data)">
                                    </template>
                            </DxDataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" id="PlanTemplateCopyModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Copy Plan Template</h5>
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                            aria-label="Close">
                            <span class="svg-icon svg-icon-2x"></span>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="fv-row mb-7">
                            <div class="col-lg-12">
                                <label>Source Template</label>
                                <select class="form-select form-select-solid mt-2 bg-light-info"
                                    v-model="copyForm.sourceTemplateSelection">
                                    <option v-for="(item, index) in lists" :value="item" :key="item.id">
                                        {{ index + 1 }}. {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="text-center align-items-center">
                            <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.5"
                                        d="M13 14.4V3.00003C13 2.40003 12.6 2.00003 12 2.00003C11.4 2.00003 11 2.40003 11 3.00003V14.4H13Z"
                                        fill="currentColor" />
                                    <path
                                        d="M5.7071 16.1071C5.07714 15.4771 5.52331 14.4 6.41421 14.4H17.5858C18.4767 14.4 18.9229 15.4771 18.2929 16.1071L12.7 21.7C12.3 22.1 11.7 22.1 11.3 21.7L5.7071 16.1071Z"
                                        fill="currentColor" />
                                </svg></span>
                        </div>
                        <div class="fv-row mb-7">
                            <div class="col-lg-12">
                                <label>Target Template</label>
                                <select class="form-select form-select-solid mt-2 bg-light-success"
                                    v-model="copyForm.targetTemplateSelection">
                                    <option v-for="(item, index) in lists" :value="item" :key="item.id">
                                        {{ index + 1 }}. {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success" @click="copyTemplate()" data-bs-dismiss="modal" :disabled="!copyForm.sourceTemplateSelection || !copyForm.targetTemplateSelection">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `ACTL001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

 
export default {
  name: "AccountingTemplatesList", 
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists:[],
      searchbar: '',
      logs: [],
      isListProgressing: false,
      copyForm: {
        source_template_id: null,
        target_template_id: null,
        sourceTemplateSelection: null,
        targetTemplateSelection: null
      }
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMMM Do, YYYY')
        }
      }
    }
  },
  methods: {
        getData() {
            this.lists = [];
            this.isListProgressing = true;
            this.$Progress.start();
            const params = {
                session_id: this.$getUser().session_id,
                get_access_status: 1
            };
            axios.post("/api/AccountingPlans/GetPlanTemplates", params, {'Content-Type': 'application/json'}).then((templateResponse) => {
                this.lists = templateResponse.data;
                this.$setSessionStorage('data'+ '_ACTL001', templateResponse.data);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        updateItem(item){ 
            if (item.full_access == 0) return;
            this.$setSessionStorage("record_id", item.id);
            this.$router.push({ name: 'AccountingTemplatesUpdate' });
        },
        copyTemplate(){
            const params = this.copyForm; 
            params.session_id = this.$getUser().session_id;
            params.source_template_id = this.copyForm.sourceTemplateSelection.id;
            params.target_template_id = this.copyForm.targetTemplateSelection.id;
            axios.post('/api/AccountingPlans/CopyTemplate', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){   
                    this.$swal("Success", "Data saved succesfully..!", 'success');   
                    this.copyForm= {
                        source_template_id: null,
                        target_template_id: null,
                        sourceTemplateSelection: null,
                        targetTemplateSelection: null
                    };
                    this.getData();
                }
            })
            .catch(function (error) {
                console.log(error);
                this.copyForm= {
                        source_template_id: null,
                        target_template_id: null,
                        sourceTemplateSelection: null,
                        targetTemplateSelection: null
                };
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            }); 
        },
        setListItemHide(data) {
            this.$swal.fire({
              title: data.hide == true ? 'Are you sure to hide this selected data from the lists?' : 'Are you sure to show this selected data from the lists?',
              confirmButtonColor: '#f1416c',
              showDenyButton: false,
              showCancelButton: true,
              icon: 'warning',
              confirmButtonText: 'Yes'
            }).then((result) => { 
                if (result.isConfirmed) {
                    const params = {
                        session_id: this.$getUser().session_id,
                        list_type: 7,
                        hide: data.hide,
                        id: data.id
                    };      
                    params.hide = params.hide == true ? 1: 0;
                    axios.post('/api/Lists/SetListItemHide', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            this.getData();
                        } 
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                    });
                }
                else{
                    data.hide = !data.hide;
                }
            });
        },
        openPlanTemplateCopyModal() {
            this.copyForm.sourceTemplateSelection = null;
            this.copyForm.targetTemplateSelection = null;
        }
    }, 
    created() {
        this.getData();
    }
}; 
</script>