<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Access Authorization</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600">Access Authorization</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/Settings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div>
    </div>

    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="card">
            <div class="card-body pt-0">
                <div class="row">
                    <div class="p-10 pb-0 d-flex flex-column">
                        <div class="alert alert-dismissible bg-light-success shadow d-flex flex-column flex-sm-row w-100 p-5 mb-5">
                            <input class="form-check-input fs-6 fw-bold form-label mt-3 me-5" type="checkbox" v-model="form.system_access" @change="onChangeSharingMode(1)"/>
                            <div class="d-flex flex-column pe-0 pe-sm-10">
                                <h4 class="fw-semibold">Allow your systems to be accessed by the Polisoft CreatorCUBE users.</h4>
                                <span>Depending on this option, there are two more options below. These options define the type of authorization granted. One of the two options below must be selected.</span>
                            </div>
                        </div>
                        <div class="alert alert-dismissible bg-light-warning d-flex flex-column flex-sm-row w-100 py-2 mb-5">                            
                            <input class="form-check-input fs-6 fw-bold form-label mt-3 me-5" type="checkbox" v-model="form.read_only" @change="onChangeSharingMode(2)"/>
                            <div class="d-flex flex-column">
                                <h4 class="fw-semibold mt-3"> Allow your systems to read only. </h4>
                                <!-- <span>The alert component can be used to highlight certain parts of your page for higher content visibility.</span> -->
                            </div>
                        </div>
                        <div class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row w-100 py-2 mb-5">                            
                            <input class="form-check-input fs-6 fw-bold form-label mt-3 me-5" type="checkbox" v-model="form.full_access" @change="onChangeSharingMode(3)"/>
                            <div class="d-flex flex-column">
                                <h4 class="fw-semibold mt-3">Allow to make changes to your systems</h4>
                                <!-- <span>The alert component can be used to highlight certain parts of your page for higher content visibility.</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-0">
                        <div class="d-grid gap-2 ps-7 pe-7"> 
                            <button type="button" class="btn btn-lg btn-primary mb-0" data-kt-indicator="on" @click="onSaveSharingMode()">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> Save </strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <div class="row mt-5">
            
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Users with Access permissions and Access Requests
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <DxDataGrid 
                                :show-borders="true" 
                                key-expr="id" 
                                :data-source="lists" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" >
                                    
                                    <!-- <DxColumn data-field="controller_check" caption="" cell-template="controller_check-cell-template"/>  -->
                                    <DxColumn data-field="name_surname" caption="Name, Surname" cell-template="name_surname-cell-template" alignment="left"/> 
                                    <DxColumn data-field="user_mail" caption="Mail" cell-template="user_mail-cell-template" alignment="left"/> 
                                    <DxColumn data-field="user_description" caption="Description" cell-template="user_description-cell-template" alignment="left"/> 
                                    <DxColumn data-field="access_created_at" caption="Access Requested" cell-template="access_created_at-cell-template" alignment="right"/> 
                                    <DxColumn data-field="controller_check_date" caption="Checked At" cell-template="controller_check_date-cell-template" alignment="right"/> 
 
                                    <!-- Cell Templates -->
                                    <!-- <template #controller_check-cell-template="{ data }">
                                        <span>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                <input class="form-check-input" type="checkbox" v-model="data.data.controller_check" @change="onSaveAuthorization(data.data)"/>
                                            </div>
                                        </span>
                                    </template>  -->
                                    <template #name_surname-cell-template="{ data }">
                                        <a href="#" class="text-gray-900 text-hover-primary mb-1">
                                            {{ data.data.name_surname }}
                                        </a>
                                    </template> 
                                    <template #user_mail-cell-template="{ data }">
                                        <a href="#" class="text-gray-900 text-hover-primary mb-1">
                                            {{ data.data.user_mail }}
                                        </a>
                                    </template> 
                                     <template #user_description-cell-template="{ data }">
                                        <a href="#" class="text-gray-900 text-hover-primary mb-1">
                                            {{ data.data.user_description }}
                                        </a>
                                    </template> 
                                     <template #access_created_at-cell-template="{ data }">
                                        <a href="#" class="text-gray-900 text-hover-primary mb-1">
                                            {{ data.data.access_created_at }}
                                        </a>
                                    </template> 
                                    <template #controller_check_date-cell-template="{ data }">
                                        <a href="#" class="text-gray-900 text-hover-primary mb-1">
                                            {{ data.data.controller_check_date }}
                                        </a>
                                    </template> 
                            </DxDataGrid>     
                        </div>
                    </div>
                </div>                
            </div>   
            <div class="col-lg-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Authorization History
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row">
                            <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover">
                                <thead>
                                    <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Date</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">User Name</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Permission</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Read Only</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table" rowspan="1" colspan="1">Change</th>
                                    </tr>
                                </thead>
                                <tbody class="fw-bold text-gray-600"> 
                                    <tr> 
                                        <td>
                                            03.11.2022 16:18
                                        </td> 
                                        <td>
                                            Aysen Ozturk 
                                        </td> 
                                        <td>
                                            Admin
                                        </td> 
                                        <td>
                                            Yes
                                        </td> 
                                        <td>
                                            Yes
                                        </td> 
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>         
        </div>
    </div>  
  </div>  
</template>

<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';

 
  
export default {
  name: "AccessAuthorization", 
  components:{
    DxDataGrid, DxColumn
  },
  data() {
    return {
      lists:[],
      searchbar: '',
      form: {
        system_access: false,
        read_only: false,
        full_access: false
      } 
    };
  }, 
  computed: {
  },
  methods: {
        getData() { 
            this.$Progress.start();
            this.isListProgressing = true;
            this.lists = [];
            const params = {
                session_id: this.$getUser().session_id,
                controller_id: this.$getUser().controller_account_id 
            }
            axios.post("/api/Access/GetControllerAccess", params, {'Content-Type': 'application/json'}).then((response) => {
                this.lists = response.data.access_list;
                if(response.data.sharing_mode == 0){ 
                    this.form.system_access =  false;
                    this.form.read_only =  false;
                    this.form.full_access =  false;
                }
                if(response.data.sharing_mode == 1){
                    this.form.system_access =  true;
                    this.form.read_only =  true;
                    this.form.full_access =  false;
                }
                if(response.data.sharing_mode == 2){
                    this.form.system_access =  true;
                    this.form.read_only =  false;
                    this.form.full_access =  true;
                }
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            }); 
        },
        onSaveAuthorization(item){ 
            const params = {
                session_id: this.$getUser().session_id,
                controller_id: this.$getUser().controller_account_id,
                user_id: item.id,
                is_checked: (item.controller_check == true ? 1 : 0)
            } 
            axios.post("/api/Access/UpdateAuthorization", params, {'Content-Type': 'application/json'}).then((response) => {
               if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.getData();
               }
            })
            .catch(function (error) {
                console.log(error); 
            });
        },
        onChangeSharingMode(value){
            if(value == 1){
                if(this.form.system_access){
                    this.form.read_only = true;
                }
                else{
                    this.form.read_only = false;
                    this.form.full_access = false;
                }
            }
            if(value == 2){
                if(this.form.read_only){ 
                    this.form.system_access = true;
                    this.form.full_access = false;
                }
                else{
                    this.form.system_access = false; 
                }
            }
            if(value == 3){
                if(this.form.full_access){ 
                    this.form.read_only = false;
                    this.form.system_access = true;
                }
                else{
                    this.form.system_access = false; 
                }
            }
        },
        onSaveSharingMode(){
            var sharingMode = 0;
            if(this.form.read_only){
                sharingMode = 1;
            }
            if(this.form.full_access){
                sharingMode = 2;
            } 
            const params = {
                session_id: this.$getUser().session_id,
                sharing_mode: sharingMode
            } 
            axios.post("/api/Access/SaveSharingMode", params, {'Content-Type': 'application/json'}).then((response) => {
               if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.getData();
               }
            })
            .catch(function (error) {
                console.log(error); 
            });
        }
    }, 
    created() {
        this.getData();
    }
}; 
</script>