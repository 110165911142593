<template>
  <div>
    <div>  
      <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
                SEO Settings
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                  </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600">Update SEO Settings</li> 
            </ul> 
        </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/SystemSettings">
                  <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                      <span class="ms-2 fs-7"> Return back to Settings </span>
                  </button>
              </router-link>
          </div> 
      </div>
    </div> 
    <div class="content flex-row-fluid mt-4" id="kt_content">
    <RequiredField></RequiredField>
    <div class="row">
      <div class="tab-content" id="myTabContent">
        <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
          <div class="p-10">
            <div class="row">
              <div class="menu-content fs-6 text-gray-900 fw-bold px-2 py-2 mb-3">
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.activeMode">
                      <label class="form-check-label fw-bold" for="flexSwitchCheckDefault">Is Active?</label>
                  </div>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="custom_head_code" class="form-label">Custom Head Code</label>
                <textarea v-model="this.form.custom_head_code" rows="10" class="form-control" placeholder="Enter SEO <head> Code Here."></textarea>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="custom_body_code" class="form-label">Custom Body Code</label>
                <textarea v-model="this.form.custom_body_code" rows="10" class="form-control" placeholder="Enter SEO <body> Code Here."></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="d-grid gap-2">
                  <button type="button" class="btn btn-lg btn-primary" @click="this.saveSEO" data-kt-indicator="on">
                    <span class="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      </svg>
                    </span>
                    <strong v-if="!this.isProgressing">Save SEO Settings</strong>
                    <span class="indicator-progress" v-if="this.isProgressing">
                      Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios' 


export default {
  name: "SEOSettings",
  data() {
    return {
    form:{
      custom_head_code: '',
      custom_body_code: '',
      activeMode: false
    },
    isProgressing: false,
    };
  },
  methods: {
    saveSEO(){
      this.isProgressing = true;

      const params = this.form;

      params.session_id = this.$getUser().session_id;
      params.active_mode = this.form.activeMode ? 1 : 0;

      axios.post('/api/SystemParameters/UpdateGeneralSystemSEOParameters', params, {'Content-Type': 'application/json'})
      .then(() => {
          this.$swal("Success", "SEO code saved successfully!", "success");
        })
        .catch((error) => {
          this.$swal("Error", "An error occurred while saving SEO Code.", "error");
          console.error(error);
        })
        .finally(() => {
          this.isProgressing = false;
        });
    },
    getSEO() {
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/SystemParameters/GetGeneralSystemSEOParameters', params, {'Content-Type': 'application/json'})
        .then((response) => {
          this.form = response.data;
          this.form.activeMode = response.data.active_mode == 1 ? true : false
        })
        .catch((error) => {
          console.error(error);
          this.$swal("Error", "An error occurred while fetching SEO Code.", "error");
        });
    },
  },
  mounted() { 
    this.getSEO();
  }
};
</script>
