<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-xxl-stretch mb-6">
                <div class="card-header border-0" style="min-height: 44px;">
                    <div class="card-title">
                    </div>
                    <div class="card-toolbar">
                       <label class="required">Required field </label>                        
                        <i class="bi bi-gear fs-2 ms-3"></i>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "RequiredField"
};  
</script>