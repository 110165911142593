<template>  
  <div>
    <div class="page-title d-flex flex-column me-3 mb-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Liabilities</h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">Home</router-link>
                </a>
            </li>  
        </ul> 
    </div>
    <div class="card mb-5 mb-xl-10 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm" style="background-image: url('assets/theme/media/svg/shapes/abstract-2.svg')" >
        <div class="card-body pt-5 pb-0">
            <div class="d-flex flex-stack">
                <div class="flex-grow-1">
                    <div class="row my-3">
                        <div class="col-lg-9">
                            <div class="fv-row mb-4 row">
                                <div class="col-lg">
                                    <label class="fs-3 fw-bold form-label">
                                        Company Accounts
                                    </label>
                                    <Select2 v-model="form.company_id" :options="companyAccounts" @select="getAccountingYears($event)" :settings="{width: '100%'}"/>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label">
                                        Liability Group
                                    </label>
                                    <Select2 v-model="form.liability_group_id" :options="liabilityGroupList" @select="getLiabilities()" :settings="{width: '100%'}"/>
                                </div>
                                <div class="col-lg">
                                    <label class="fw-bold form-label ">
                                        <span> Assignment Status </span>
                                    </label>
                                    <Select2 v-model="form.assignment_status" :options="filteredAssignmentList" @select="getLiabilities()"
                                        :settings="{ width: '100%' }"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> Reset All Criterias </span>
                                    </template>
                                </Popper> 
                                <button type="button" class="btn btn-sm btn-primary" @click="getLiabilities()">
                                    <span class="svg-icon svg-icon-muted">
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    <strong> List Liabilities </strong>
                                </button>
                            </div>
                        </div> 
                    </div>
                    <div class="separator my-3 border-gray-300"></div>
                    
                    <div class="row my-3">
                        <div class="col-lg-10">
                            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-0 py-5 mb-3" role="tablist">
                                <a class="fw-bold px-4 py-0 collapsible cursor-pointer rotate justify-content-end align-items-end shadow-sm rounded-1 collapsed border bg-light-primary"
                                    data-bs-toggle="collapse" data-bs-target="#kt_criterias" @click="onChangeCriteriaView()">
                                    <span class="svg-icon svg-icon-3x rotate-180">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </a>
                                <li v-if="!isViewCtierias" class="nav-item" role="presentation">
                                    <a id="nav_tab_1" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4 me-1"
                                        data-bs-toggle="tab" href="nav_tab_1" target="_self" @click="onChangeDateCriteria(1)">Past 1 Month ({{this.totals.prev_month_total}}) </a>
                                </li>
                                <li v-if="!isViewCtierias" class="nav-item" role="presentation">
                                    <a id="nav_tab_2" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4 active"
                                        data-bs-toggle="tab" href="nav_tab_2" target="_self" @click="onChangeDateCriteria(2)">On This Month ({{this.totals.this_month_total}}) </a>
                                </li>
                                <li v-if="!isViewCtierias" class="nav-item" role="presentation">
                                    <a id="nav_tab_3" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4"
                                        data-bs-toggle="tab" href="nav_tab_3" target="_self" @click="onChangeDateCriteria(3)">Next Month ({{this.totals.next_month_total}})</a>
                                </li>
                                <li v-if="!isViewCtierias" class="nav-item" role="presentation">
                                    <a id="nav_tab_4" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4"
                                        data-bs-toggle="tab" href="nav_tab_4" target="_self" @click="onChangeDateCriteria(4)">Next 3 Month  ({{this.totals.next_three_total}})</a>
                                </li>
                                <li v-if="!isViewCtierias && form.company_id !== '-1'" class="nav-item" role="presentation">
                                    <a id="nav_tab_5" class="nav-link shadow-sm rounded-1 py-3 text-gray-600 text-active-primary fw-bold px-4"
                                        data-bs-toggle="tab" href="nav_tab_5" target="_self" @click="onChangeDateCriteria(5)">Next All  ({{this.totals.next_all_total}})</a>
                                </li>
                                <li v-if="!isViewCtierias" class="nav-item" role="presentation">
                                    <Popper hover placement="top">
                                        <div class="form-check form-check-custom my-3 px-4">
                                            <input class="form-check-input h-25px w-25px" type="checkbox" value="" v-model="this.form.is_show_completed" @click="onChangeHideCriteria()" />
                                        </div>
                                        <template #content>
                                            <span class="text-gray-800 fs-7" v-if="!this.form.is_show_completed"> Show Hidden Liabilities </span>
                                            <span class="text-gray-800 fs-7" v-if="this.form.is_show_completed"> Hide Completed Liabilities </span>
                                        </template>
                                    </Popper>
                                </li>
                            </ul>
                        </div>                    
                        <div class="col-lg-2" v-if="this.filteredAccountingPeriodList.length > 0 && this.form.company_id !== '-1' && this.form.company_id !== null && this.form.company_id !== undefined">
                            <button type="button" class="btn btn-sm btn-secondary my-3 py-3 justify-content-end align-items-end float-end" @click="openAddLiabilityModal()"
                                data-bs-toggle="modal" data-bs-target="#add_cc_liability_modal">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                    </svg>
                                </span>
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card no-border collapse" id="kt_criterias">                
                <div class="row py-3">
                    <div class="col-lg-12">
                        <div class="fv-row mb-4 row">
                            <div class="row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label">
                                        Accounting Period
                                    </label>
                                    <Select2 v-model="form.period_id" :options="accountingPeriodList" :settings="{width: '100%'}" @select="onChangeAccountingYear($event)"/>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label">
                                        Start Date
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label">
                                        End Date
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Status
                                    </label>
                                    <Select2 v-model="form.status" :options="statusList" :settings="{width: '100%'}"/>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Amount Type
                                    </label> 
                                    <Select2 v-model="form.amount_type" :options="amountTypeList" :settings="{ width: '100%' }"/>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Source
                                    </label> 
                                    <Select2 v-model="form.source" :options="sourceList" :settings="{ width: '100%' }"/>
                                </div>
                                <div class="col-lg" v-if="this.form.source == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Transaction Type
                                    </label>
                                    <Select2 v-model="form.transaction_type" :options="transactionSelectionList" :settings="{width: '100%'}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-xl-6 shadow-sm border border-primary border-dashed rounded bg-light-primary py-3 px-4"> 
        <div class="col-lg-12">
            <div class="d-flex flex-row">
                <li class="d-flex align-items-center py-2 pe-6">
                    <span class="bullet bullet-md me-2 bg-secondary"></span>
                    <span class="me-3"> Liabilities: </span> <b> {{summary.total_liability}} </b>
                </li>
                <li class="d-flex align-items-center py-2 pe-6">
                    <span class="bullet bullet-md me-2 bg-danger"></span>
                    <span class="me-3"> Amount: </span> <b> {{summary.total_amount}} </b>
                </li>
                <li class="d-flex align-items-center py-2 pe-6">
                    <span class="bullet bullet-md me-2 bg-success"></span>
                    <span class="me-3"> Payment: </span> <b> {{summary.total_payment}} </b>
                </li>
                <li class="d-flex align-items-center py-2 pe-6">
                    <span class="bullet bullet-md me-2 bg-primary"></span> 
                    <span class="me-3"> Collection: </span> <b> {{summary.total_collection}} </b>
                </li>
            </div>
        </div>
    </div>

    <div v-if="this.activeView == 'list'">
        <div class="py-3" v-for="(liabilityItem, index) in liabilities" v-bind:key="liabilityItem">
            <div class="card shadow-sm ribbon ribbon-top card-rounded" :class="{'border': liabilityItem.border_color !== '' && liabilityItem.border_color !== null}" :style="'border-color:' + liabilityItem.border_color + '!important'">
                <div class="ribbon-label py-1 ribbon-label-right-8 fs-8 bg-light-danger text-danger border border-danger" v-if="liabilityItem.amount_type == 0">No Amount</div>
                <div class="ribbon-label py-1 ribbon-label-right-8 fs-8 bg-light-success text-success border border-success" v-if="liabilityItem.amount_type == 1">Payment</div>
                <div class="ribbon-label py-1 ribbon-label-right-8 fs-8 bg-light-primary text-primary border border-primary" v-if="liabilityItem.amount_type == 2">Collection</div>

                <span class="ribbon-label py-1 fs-8 bg-primary border border-primary" v-if="liabilityItem.source == 1">Standard</span>
                <span class="ribbon-label py-1 fs-8 bg-warning border border-warning" v-if="liabilityItem.source == 2">Transaction</span>
                <span class="ribbon-label py-1 fs-8 bg-info border border-info" v-if="liabilityItem.source == 3">User Manuel</span>
                
                <div v-if="liabilityItem.location == 2" class="ribbon ribbon-triangle ribbon-top-start border-primary cursor-pointer" style="border-top-left-radius: 12px">                    
                    <Popper hover placement="top">
                        <div class="ribbon-icon mt-n5 ms-n6">
                            <i class="bi bi-check2 fs-2 text-white"></i>
                        </div>
                        <template #content>
                            <span class="text-gray-800 fw-bold fs-7"> Only Controller </span>
                        </template>
                    </Popper>
                </div>
                <div class="card-header">
                    <div class="card-title">
                       <h6> {{liabilityItem.company_name}} </h6>
                    </div>
                </div>
                <div class="card-header py-3 px-10">
                    <h3 class="card-title">
                        <div class="d-flex flex-center rounded-3 bg-opacity-90 collapsible cursor-pointer rotate collapsed me-10 border bg-light-primary" data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_liability_'+ index" @click="liabilityItem.activeDetailTab = 'general'">
                            <span class="svg-icon svg-icon-3x rotate-180">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center flex-grow-1">
                                <div class="symbol symbol-45px me-5">
                                    <i class="fs-3x" :class="!liabilityItem.icon ? 'fa fa-bell' : liabilityItem.icon" :style="{ 'color': liabilityItem.color }"></i>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7">
                                    <div class="fs-6 text-gray-800 fw-bold text-center">{{previewOnlyDate(liabilityItem.value_date)}}</div>
                                    <div class="fs-7 fw-semibold text-gray-400 text-center">Value Date</div>
                                    <div class="fs-7 fw-semibold text-center text-primary" :class="{'text-danger': liabilityItem.value_remain_day < 0}">
                                        {{liabilityItem.value_remain_day}} days
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bold">{{liabilityItem.liability_type_name}}</a>
                                    <span class="fs-7 text-gray-400 fw-bold">{{liabilityItem.liability_group_name}}</span>
                                    <span class="fs-7 text-gray-400 fw-bold">{{ previewOnlyDate(liabilityItem.period_start_date) }} - {{ previewOnlyDate(liabilityItem.period_end_date) }}</span>
                                </div>
                            </div>
                        </div>
                    </h3>
                    <div class="card-toolbar">
                        <div class="d-flex flex-column me-10">
                            <div class="border border-gray-300 border-dashed rounded min-w-150px py-3 px-4">
                                <div class="fs-6 text-gray-800 fw-bold text-center" v-if="liabilityItem.amount_type != 0">
                                    <span class="fs-3 badge badge-light-danger fs-base mt-n3"> {{liabilityItem.amount}} </span>
                                    <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> {{this.localCurrency}} </span>
                                </div>
                                <div class="fw-semibold text-gray-400 fs-7 text-center" v-if="liabilityItem.amount_type != 0">Amount</div>
                                <div class="fw-semibold text-gray-400 fs-5 text-center" v-if="liabilityItem.amount_type == 0">No Amount</div>
                            </div>
                            <div class="fs-8 text-gray-400 mt-1 px-3">
                                Starting Date:  <b> {{previewOnlyDate(liabilityItem.at)}} </b>
                            </div>
                        </div>
                        <div class="d-flex flex-column me-10">
                            <button type="button" class="btn btn-sm btn-light-primary" 
                                data-bs-toggle="modal" data-bs-target="#liability_edit_modal" @click="openLiabilityTransactionModal(liabilityItem)">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                Update
                            </button>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="dropdown mx-auto">
                                <button class="btn btn-sm btn-icon btn-circle btn-bg-light border btn-active-color-primary border-gray-300" type="button" data-kt-menu-placement="bottom-end" @click="onClickDropdown(liabilityItem)">
                                    <i class="bi bi-chevron-down fw-bold fs-5"></i>
                                </button>
                                <ul class="dropdown-menu" :class="{ 'show': liabilityItem.isOpenActionsDropdown }"> 
                                    <li v-for="item in assignmentStatusList" v-bind:key="item">
                                        <a class="dropdown-item fs-6" @click="onChangeAssignmentStatus(liabilityItem, item.id)" href="#" v-if="item.id !== -1 && item.id !== 0">
                                            <span :class="'text-' + item.color"> {{item.text}} </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mx-auto">
                                <span class="fw-bold" :class="'text-' + assignmentStatusColor(liabilityItem.assignment_status)"> {{assignmentStatusName(liabilityItem.assignment_status)}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-10 py-2">
                    <div class="row"> 
                        <div class="col-lg-6">
                            <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(liabilityItem.status)">{{liabilityStatusName(liabilityItem.status)}}</span>
                        </div>
                        <div class="col-lg-5">
                            <div class="text-end text-muted"> 
                                {{previewDate(liabilityItem.last_transaction_date)}} <span class="fs-8"> by {{liabilityItem.last_transaction_by}} </span>                           
                            </div>
                        </div>
                        <div class="col-lg-1" v-if="userData.is_chatbi_user == 1">
                            <div class="text-end text-muted">
                                <span>
                                    <div class="cursor-pointer position-relative" @click="openChatBi(liabilityItem)" data-bs-toggle="modal" data-bs-target="#chatbi_modal">
                                        <span class="svg-icon svg-icon-success svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z" fill="currentColor"/>
                                            <path d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <span class="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger badge-sm m-1 fs-8 ms-3" v-if="liabilityItem.message_count > 0">
                                            {{ liabilityItem.message_count }}
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 border border-gray-300 border-dashed rounded bg-light mx-2 my-3 p-3" v-if="liabilityItem.last_transaction_description !== ''">
                            <a href="#kt_liability_tab_2" @click="getLiabilityDescriptions(liabilityItem), liabilityItem.activeDetailTab = 'descriptions'"
                                data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_liability_'+ index">
                                {{liabilityItem.last_transaction_description}}
                            </a>
                        </div>
                    </div>
                </div>
                <div :id="'kt_accordion_liability_'+ index" class="collapse">
                    <ul class="nav nav-tabs nav-line-tabs fs-6 p-10 py-2">
                        <li class="nav-item">
                            <a class="nav-link text-gray-600" @click="liabilityItem.activeDetailTab = 'general'" :class="{'active': liabilityItem.activeDetailTab == 'general'}"
                                data-bs-toggle="tab" href="#kt_liability_tab_1">General</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="getLiabilityDocuments(liabilityItem), liabilityItem.activeDetailTab = 'documents'" :class="{'active': liabilityItem.activeDetailTab == 'documents'}"
                                data-bs-toggle="tab" href="#kt_liability_tab_2">Documents</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-gray-600" @click="getOperationGroupUsers(liabilityItem), liabilityItem.activeDetailTab = 'users'" :class="{'active': liabilityItem.activeDetailTab == 'users'}"
                                data-bs-toggle="tab" href="#kt_liability_tab_3">Users</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="getLiabilityDescriptions(liabilityItem), liabilityItem.activeDetailTab = 'descriptions'" :class="{'active': liabilityItem.activeDetailTab == 'descriptions'}"
                                data-bs-toggle="tab" href="#kt_liability_tab_4">Descriptions</a>
                        </li>
                    </ul>
                    <div class="card-body py-3 scroll mh-350px">
                        <div class="tab-content" id="myTabContent">
                            <div v-if="liabilityItem.activeDetailTab === 'general'" class="tab-pane fade show active" id="kt_liability_tab_1" role="tabpanel">                                
                                <div class="row align-items-center mx-0 w-100">
                                    <div class="col-lg-12 border border-gray-300 border-dashed rounded bg-light-primary py-3 px-4 me-7 mb-3">
                                        <div class="d-flex flex-row">
                                            <li class="d-flex align-items-center py-2 pe-6">
                                                <span class="bullet bullet-md me-2 bg-primary"></span>
                                                <span class="me-3"> To be calculate on: </span> {{previewOnlyDate(liabilityItem.at)}}
                                            </li>
                                            <li class="d-flex align-items-center py-2 pe-6">
                                                <span class="bullet bullet-md me-2 bg-warning"></span>
                                                <span class="me-3"> Company Number: </span> {{liabilityItem.company_vkn}} 
                                            </li>
                                            <li class="d-flex align-items-center py-2 pe-6" v-if="liabilityItem.company_vat_number !== ''">
                                                <span class="bullet bullet-md me-2 bg-success"></span> 
                                                <span class="me-3"> VAT Number: </span> {{liabilityItem.company_vat_number}}
                                            </li>
                                            <li class="d-flex align-items-center py-2 pe-6" v-if="liabilityItem.account_office_reference !== ''">
                                                <span class="bullet bullet-md me-2 bg-info"></span> 
                                                <span class="me-3"> Account Office Reference: </span> {{liabilityItem.account_office_reference}}
                                            </li>
                                            <li class="d-flex align-items-center py-2 pe-6">
                                                <span class="bullet bullet-md me-2 bg-danger"></span> 
                                                <span class="me-3"> User: </span> Test User
                                            </li>
                                            <li class="d-flex align-items-center py-2 pe-6">
                                                <span class="bullet bullet-md me-2 bg-secondary"></span> 
                                                <span class="me-3"> Location: </span> Controller
                                            </li>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 px-0">
                                        <div class="d-flex flex-column content-justify-center">
                                            <div class="border border-gray-300 border-dashed rounded bg-lighten scroll py-3 px-4 me-7 mb-3">
                                                <div class="fw-semibold text-gray-600">Description Title</div>
                                                <div class="fw-semibold text-gray-600 min-h-50px mh-lg-50px">{{liabilityItem.description_title}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 px-0">
                                        <div class="d-flex flex-column content-justify-center">
                                            <div class="border border-gray-300 border-dashed rounded bg-lighten scroll py-3 px-4 me-7 mb-3">
                                                <div class="fw-semibold text-gray-600">Payment Title</div>
                                                <div class="fw-semibold text-gray-600  min-h-50px mh-lg-50px">{{liabilityItem.payment_title}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 px-0">
                                        <div class="d-flex flex-column content-justify-center">
                                            <div class="border border-gray-300 border-dashed rounded bg-lighten py-3 px-4 me-7 mb-3">
                                                <div class="fw-semibold text-gray-600">Description URL</div>
                                                <a class="min-h-25px mh-lg-25px fw-semibold" :href="liabilityItem.description_url" target="_blank">{{liabilityItem.description_url}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 px-0">
                                        <div class="d-flex flex-column content-justify-center">
                                            <div class="border border-gray-300 border-dashed rounded bg-lighten py-3 px-4 me-7 mb-3">
                                                <div class="fw-semibold text-gray-600">Payment URL</div>
                                                <a class="min-h-25px mh-lg-25px fw-semibold" :href="liabilityItem.payment_url" target="_blank">{{liabilityItem.payment_url}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="liabilityItem.activeDetailTab === 'documents'" class="tab-pane fade show active" id="kt_liability_tab_2" role="tabpanel">
                                <div class="card-body scroll p-0 mh-300px rounded" v-if="liabilityItem.documents !== null && liabilityItem.documents !== '' && liabilityItem.documents !== undefined">
                                    <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-10" v-if="liabilityItem.documents.length == 0"> 
                                        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                                            <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                                            <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                                            <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column pe-0 pe-sm-10">
                                            <h4 class="fw-semibold">No document added yet.</h4>
                                            <span>Please use Update button to add documents..!</span>
                                        </div> 
                                    </div>
                                    <div v-if="liabilityItem.documents.length > 0">
                                        <DxDataGrid :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="liabilityItem.documents" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true"
                                                @row-prepared="onRowPrepared"> 

                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>
                                            <DxSearchPanel :visible="true"/>  
                                            
                                            <DxColumn data-field="download" caption="Actions" cell-template="download-cell-template" alignment="left" width="100"/> 
                                            <DxColumn data-field="document_name" caption="Document Name" cell-template="document_name-cell-template"/>
                                            <DxColumn data-field="document_no" caption="Document No" cell-template="document_no-cell-template"/>
                                            <DxColumn data-field="name" caption="File Name" cell-template="name-cell-template"/> 
                                            <DxColumn data-field="document_type_name" caption="Document Type" cell-template="document_type_name-cell-template"/> 
                                            <DxColumn data-field="importance" caption="Importance" cell-template="importance-cell-template" alignment="left"/> 
                                            <DxColumn data-field="document_source" caption="Source" cell-template="document_source-cell-template" alignment="left"/> 
                                            <DxColumn data-field="source_company" caption="Source Company" cell-template="source_company-cell-template"/> 
                                            <DxColumn data-field="source_person" caption="Source Person" cell-template="source_person-cell-template"/> 
                                            <DxColumn data-field="have_personal_data" caption="Personal Data" cell-template="have_personal_data-cell-template"/> 
                                            <DxColumn data-field="have_gdpr_permission" caption="GDPR Permission" cell-template="have_gdpr_permission-cell-template"/> 
                                            <DxColumn data-field="size" caption="Size" alignment="right" cell-template="size-cell-template"/>
                                            <DxColumn data-field="created_by_name" caption="Uploaded By" alignment="left" cell-template="created_by_name-cell-template"/>
                                            <DxColumn data-field="created_at" caption="Created At" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                                            <DxColumn data-field="is_deleted" caption="Status" cell-template="is_deleted-cell-template" /> 
                                            <DxColumn data-field="deleted_by_name" caption="Deleted By" alignment="left" cell-template="deleted_by_name-cell-template"/>

                                            <template #is_deleted-cell-template="{ data: data }">
                                            <span class="align-middle text-center"> 
                                                <span v-if="data.data.is_deleted == 1"> 
                                                    <i class="fas fa-times"></i> 
                                                    <span class="ms-3">Deleted</span>
                                                </span>
                                                <span v-if="data.data.is_deleted == 0">  
                                                    <span>Active</span>
                                                </span>
                                            </span>
                                            </template>
                                            <template #deleted_by_name-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ data.data.deleted_by_name }}
                                            </span>
                                            </template>
                                            <template #created_by_name-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ data.data.created_by_name }}
                                            </span>
                                            </template>
                                            <template #name-cell-template="{ data: data }">
                                            <span class="align-middle fw-bold">
                                                {{ data.data.name }}
                                            </span>
                                            </template>
                                            <template #document_name-cell-template="{ data: data }">
                                            <span class="align-middle fw-bold">
                                                {{ data.data.document_name }}
                                            </span>
                                            </template>
                                            <template #document_no-cell-template="{ data: data }">
                                            <span class="align-middle fw-bold">
                                                {{ data.data.document_no }}
                                            </span>
                                            </template>
                                            <template #document_type_name-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ data.data.document_type_name }}
                                            </span>
                                            </template>
                                            <template #source_company-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ data.data.source_company }}
                                            </span>
                                            </template>
                                            <template #source_person-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ data.data.source_person }}
                                            </span>
                                            </template>
                                            <template #document_source-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                <span v-if="data.data.document_source == 0"> Incoming</span>
                                                <span v-if="data.data.document_source == 1"> Outgoing</span>
                                            </span>
                                            </template>
                                            <template #have_personal_data-cell-template="{ data: data }">
                                            <span class="align-middle text-center">
                                                <span v-if="data.data.have_personal_data == 0"> 
                                                    <i class="fas fa-times"></i>
                                                </span>
                                                <span v-if="data.data.have_personal_data == 1"> 
                                                    <i class="fas fa-check"></i>
                                                </span>
                                            </span>
                                            </template>
                                            <template #have_gdpr_permission-cell-template="{ data: data }">
                                            <span class="align-middle text-center">
                                                <span v-if="data.data.have_gdpr_permission == 0"> 
                                                    <i class="fas fa-times"></i>
                                                </span>
                                                <span v-if="data.data.have_gdpr_permission == 1"> 
                                                    <i class="fas fa-check"></i>
                                                </span>
                                            </span>
                                            </template>
                                            <template #importance-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                <span v-if="data.data.importance == 0"> Normal </span>
                                                <span v-if="data.data.importance == 1"> Low </span>
                                                <span v-if="data.data.importance == 2"> High </span>
                                                <span v-if="data.data.importance == 3"> Very High </span>
                                            </span>
                                            </template>
                                            <template #size-cell-template="{ data: data }">
                                            <span class="align-middle">
                                                {{ calculateSize(data.data.size) }}
                                            </span>
                                            </template>
                                            
                                            <template #download-cell-template="{ data: data }">
                                            <span>
                                                <a href="#" target="_self" @click="onOpenDetail(null, data, liabilityItem)" data-bs-toggle="modal" data-bs-target="#document_details_modal">
                                                    <span class="svg-icon svg-icon-1 mx-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                        <rect x="11" y="11" width="2" height="2" rx="1" fill="currentColor"/>
                                                        <rect x="11" y="15" width="2" height="2" rx="1" fill="currentColor"/>
                                                        <rect x="11" y="7" width="2" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a> 
                                                <!-- Download -->
                                                <a href="#" target="_self" data-bs-toggle="modal" data-bs-target="#file_download_modal" @click="onRequestFile(data.data)" v-if="data.data.is_deleted == 0">
                                                    <span class="svg-icon svg-icon-1 mx-2 svg-icon-dark">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="currentColor"/>
                                                        <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="currentColor"/>
                                                        <path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                </a> 
                                            </span>
                                            </template> 
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                            <div v-if="liabilityItem.activeDetailTab === 'users'" class="tab-pane fade show active" id="kt_liability_tab_3" role="tabpanel">
                                <div class="card-body scroll p-0 mh-300px rounded" v-if="liabilityItem.users !== null && liabilityItem.users !== '' && liabilityItem.users !== undefined">
                                    <DxDataGrid :show-borders="true" 
                                            key-expr="user_id" 
                                            :data-source="liabilityItem.users" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true" > 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="false"/>
                                        <DxGroupPanel :visible="false"/>
                                        <DxPaging :page-size="1000"/>
                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                
                                        <DxColumn data-field="user_name" caption="User Name" cell-template="user_name-cell-template"/>
                                        <DxColumn data-field="user_mail" caption="Email" alignment="left" cell-template="user_mail-cell-template"/> 
                                        <DxColumn data-field="is_owner" caption="Is Owner?" cell-template="is_owner-cell-template" :allow-filtering="false" alignment="left"/>
                                        
                                        <!-- Cell Templates -->
                                        <template #user_name-cell-template="{ data }"> 
                                            <span>
                                                {{ data.data.user_name }}
                                            </span>
                                        </template>
                                        <template #user_mail-cell-template="{ data }"> 
                                            <span>
                                                {{ data.data.user_mail }}
                                            </span>
                                        </template>
                                        <template #is_owner-cell-template="{ data }"> 
                                            <span v-if="data.data.is_owner == 1">
                                                <i class="las la-check fs-2 text-success"></i>
                                            </span>  
                                            <span v-if="data.data.is_owner == 0">
                                                <i class="las la-times fs-2 text-danger"></i>
                                            </span> 
                                        </template>
                                    </DxDataGrid>
                                </div>
                            </div>
                            <div v-if="liabilityItem.activeDetailTab === 'descriptions'" class="tab-pane fade show active" id="kt_liability_tab_4" role="tabpanel">                                
                                <div class="card-body scroll p-0 mh-300px rounded" v-if="liabilityItem.descriptions !== null && liabilityItem.descriptions !== '' && liabilityItem.descriptions !== undefined">
                                    <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-10" v-if="liabilityItem.descriptions.length == 0"> 
                                        <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                                            <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                                            <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                                            <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column pe-0 pe-sm-10">
                                            <h4 class="fw-semibold">No description added yet.</h4>
                                            <span>Please use Update button to add descriptions..!</span>
                                        </div> 
                                    </div>
                                    <div v-if="liabilityItem.descriptions.length > 0">
                                        <DxDataGrid :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="liabilityItem.descriptions" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true"
                                                @row-prepared="onRowPrepared"> 

                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>
                                            <DxSearchPanel :visible="true"/>  
                                            
                                            <DxColumn data-field="last_transaction_date" :width="80" caption="Transaction Date" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                                            <DxColumn data-field="description" caption="Description" /> 
                                            <DxColumn data-field="status" caption="Status" cell-template="status-cell-template" /> 
                                            <DxColumn data-field="last_transaction_by" caption="User"/>

                                            <template #status-cell-template="{ data: data }">
                                                <span class="align-middle text-center">                                                    
                                                    <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(data.data.status)">{{liabilityStatusName(data.data.status)}}</span>
                                                </span>
                                            </template>
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-cloak class="py-3" v-if="this.liabilities.length == 0">
            <div class="card shadow-sm">
                <div class="card-body d-flex align-items-end">
                   <span class="fs-6"> No liabilities found... </span>
                </div>
            </div>
        </div>
    </div>

    <div v-if="this.activeView == 'calendar'">
        <DxScheduler
            :data-source="liabilities"
            text-expr="liability_type_name"
            :current-date="form.start_date"
            :views="views"
            :height="600"
            :start-day-hour="9"
            current-view="week"
        />
    </div>

    <!-- Transaction Edit Modal -->
    <div class="modal fade" id="liability_edit_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-xxl">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Add Status
                        <span class="fs-6 text-gray-700">
                            (<i class="mx-2" :class="!this.selectedLiabilityItem.icon ? 'fa fa-bell' : this.selectedLiabilityItem.icon" :style="{ 'color': this.selectedLiabilityItem.color }"></i>
                            <span> {{this.selectedLiabilityItem.liability_type_name}} - ({{ previewOnlyDate(selectedLiabilityItem.period_start_date) }} - {{ previewOnlyDate(selectedLiabilityItem.period_end_date) }}) </span>
                            )
                        </span>
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>  
                    </div>                    
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y bg-white">
                    <div class="d-grid">
                        <ul class="nav nav-tabs flex-nowrap text-nowrap">
                            <li class="nav-item">
                                <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" @click="this.activeTransactionTab = 'add'"
                                    :class="{ 'active border border-bottom-0 border-gray-300' : this.activeTransactionTab == 'add' }" data-bs-toggle="tab" href="#kt_transaction_tab_1">Add Transaction</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" @click="getLiabilityTransactions(this.selectedLiabilityItem.id), this.activeTransactionTab = 'list'"
                                :class="{ 'active border border-bottom-0 border-gray-300' : this.activeTransactionTab == 'list' }" data-bs-toggle="tab" href="#kt_transaction_tab_2">All Transactions</a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" id="kt_transaction_tab_1" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'add' }">
                            <div class="fv-row row">
                                <div class="col-lg-12 mt-0"> 
                                    <div class="d-flex flex-row row">
                                        <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                            <div class="d-flex flex-column-fluid">
                                                <div class="card-body pt-0 pb-0">
                                                    <div class="row">
                                                        <form id="kt_ecommerce_settings_general_form"
                                                            class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                            <div class="fv-row row">
                                                                <div class="col-lg-3">
                                                                    <label class="fw-bold form-label mt-3">
                                                                        <span>Status</span>
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <span class="col-lg-11" :class="{'col-lg-12': this.liabilityTransactionModalType == 'edit'}">
                                                                            <Select2 v-model="editTransactionForm.status" :options="filteredLiabilityStatusList"
                                                                            :disabled="this.liabilityTransactionModalType == 'edit' || this.isStatusDisabled"
                                                                            :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"/>
                                                                        </span>
                                                                        <span class="col-lg-1" v-if="this.liabilityTransactionModalType !== 'edit'">
                                                                            <div class="input-group-append">
                                                                                <button class="btn btn-icon btn-active-color-primary" type="button" @click="this.isStatusDisabled = !this.isStatusDisabled" >		
                                                                                    <span class="svg-icon svg-icon-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <label class="fw-bold form-label mt-3">
                                                                        <span>Value Date</span>
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <span class="col-lg-11">
                                                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="editTransactionForm.value_date" inputClassName="form-control"
                                                                            format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :disabled="this.isValueDateDisabled"></Datepicker>                                                
                                                                        </span>
                                                                        <span class="col-lg-1">
                                                                            <button class="btn btn-icon btn-active-color-primary" type="button" @click="this.isValueDateDisabled =  !this.isValueDateDisabled">		
                                                                                <span class="svg-icon svg-icon-2">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <label class="fw-bold form-label mt-3">
                                                                        <span>Amount Type</span>
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <span class="col-lg-11" :class="{'col-lg-12': this.liabilityTransactionModalType == 'edit'}">
                                                                            <Select2 v-model="editTransactionForm.amount_type" :options="filteredAmountTypeList"
                                                                                :disabled="this.liabilityTransactionModalType == 'edit' || this.isAmountTypeDisabled"
                                                                                :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"/>
                                                                        </span>
                                                                        <span class="col-lg-1" v-if="this.liabilityTransactionModalType !== 'edit'">
                                                                            <div class="input-group-append">
                                                                                <button class="btn btn-icon btn-active-color-primary" type="button" @click="this.isAmountTypeDisabled = !this.isAmountTypeDisabled" >		
                                                                                    <span class="svg-icon svg-icon-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <label class="fw-bold form-label mt-3">
                                                                        <span>Amount</span>
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <span class="col-lg-11">
                                                                            <CurrencyInput type="text" class="form-control text-end" name="amount_local" v-model="editTransactionForm.amount" @focus="$event.target.select()"
                                                                               :disabled="this.isAmountDisabled"/>
                                                                        </span>
                                                                        <span class="col-lg-1">
                                                                            <button class="btn btn-icon btn-active-color-primary" type="button" @click="this.isAmountDisabled = !this.isAmountDisabled">		
                                                                                <span class="svg-icon svg-icon-2">
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fw-bold form-label mt-3">
                                                                        <span>Description</span>
                                                                    </label>
                                                                    <textarea rows="2" class="form-control " name="description" v-model="editTransactionForm.description"></textarea>
                                                                </div>
                                                                
                                                                <div class="col-lg-12 mt-5" v-if="this.liabilityTransactionModalType !== 'edit'">                                                                    
                                                                    <div class="card shadow-sm">
                                                                        <div class="card-header">
                                                                            <h3 class="card-title">
                                                                                <div class="d-flex flex-center rounded-3 bg-opacity-90 collapsible cursor-pointer rotate me-3 border bg-light-primary"
                                                                                    data-bs-toggle="collapse" data-bs-target="#kt_add_document_transaction" @click="onChangeDocumentView()" type="button"
                                                                                    :class="{'collapsed': !this.isDocumentViewOpen}">
                                                                                    <span class="svg-icon svg-icon-2x rotate-180">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="d-flex align-items-center">                                                                                    
                                                                                    <span class="fs-5 text-gray-600 fw-bold"> Upload Document</span>
                                                                                </div>
                                                                            </h3>
                                                                        </div>
                                                                        <div id="kt_add_document_transaction" class="collapse"  :class="{'show': this.isDocumentViewOpen}">
                                                                            <div class="card-body">
                                                                                <!-- Show a warning message if no Document Locations defined -->
                                                                                <div class="col-lg-12" v-if="this.documentLocation == null && isFileManagerReady">
                                                                                    <div class="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row w-100 p-5">
                                                                                        <span class="svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0">
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                                                                                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                                                                                            </svg>
                                                                                        </span>
                                                                                        <div class="d-flex flex-column pe-0 pe-sm-10">
                                                                                            <h4 class="fw-semibold">Document Location not found.</h4>
                                                                                            <span>No Document Location has been defined yet.</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex flex-row row" v-if="this.documentLocation !== null && isFileManagerReady">
                                                                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                                                        <div class="d-flex flex-column-fluid">
                                                                                            <div class="card shadow-sm">
                                                                                                <div class="card-body">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                                                                Document Name
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <input type="text" v-model="uploadForm.document_name" class="form-control" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                                                                Document No
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <input type="text" v-model="uploadForm.document_no" class="form-control" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                                                                Importance
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <Select2 v-model="uploadForm.importance" :options="importanceList" :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                                                                Document Source
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <Select2 v-model="uploadForm.document_source" :options="documentSourceList" :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                                                                Description
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <textarea class="form-control" rows="2" v-model="uploadForm.document_description"></textarea>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                                                        <div class="d-flex flex-column-fluid">
                                                                                            <div class="card shadow-sm">
                                                                                                <div class="card-body">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                                                                Source Company
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <input type="text" v-model="uploadForm.source_company" class="form-control" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                                                                Source Person
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <input type="text" v-model="uploadForm.source_person" class="form-control" /> 
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                                                                Does it contain personal data? (GDPR)
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <Select2 v-model="uploadForm.have_personal_data" 
                                                                                                                        :options="havePersonelDataList" 
                                                                                                                        :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"
                                                                                                                        @select="onPersonalDataChange()"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-6" v-if="uploadForm.have_personal_data == 1">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                                                                Have GDPR permissions been obtained?
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <Select2 v-model="uploadForm.have_gdpr_permission" :options="gpdrObtainList" :settings="{ width: '100%', dropdownParent: '#liability_edit_modal .modal-content' }"/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12" v-if="uploadForm.have_personal_data == 1">
                                                                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                                                                GDPR Permissions Description
                                                                                                            </label>
                                                                                                            <div class="fv-row fv-plugins-icon-container">
                                                                                                                <textarea class="form-control" rows="2" v-model="uploadForm.gdpr_description"></textarea>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                                                                        <div class="d-flex flex-column-fluid">
                                                                                            <div class="card shadow-sm col-lg-12 mt-4 bgi-no-repeat bgi-position-y-top bgi-position-x-end" style="background-image: url('assets/theme/media/svg/shapes/abstract-5.svg')">
                                                                                                <div class="card-body">
                                                                                                    <div class="row"> 
                                                                                                        <div class="col-lg-12">
                                                                                                            <DxFileUploader
                                                                                                                multiple="false"
                                                                                                                upload-mode="useButtons" 
                                                                                                                :visible="true"
                                                                                                                accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, image/*"
                                                                                                                :disabled="this.uploadForm.document_source == -1 || this.uploadForm.document_name == null || this.uploadForm.document_name == ''"
                                                                                                                :upload-headers="getUploadHeaders()"
                                                                                                                :on-uploaded="onUploadCompleted"
                                                                                                                upload-url="/api/Folders/UploadDocumentByLocation"
                                                                                                                :ref="'fileUploader'"
                                                                                                                uploadButtonText="Upload and Start Transaction"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div> 
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 mt-5">
                                                                    <div class="d-grid gap-2" v-if="!isDocumentViewOpen">
                                                                        <button type="button" class="btn btn-success btn-sm" @click="this.createLiabilityTransaction()" v-if="this.liabilityTransactionModalType == 'add'">
                                                                            <span class="indicator-label">Save</span> 
                                                                        </button>
                                                                    </div>
                                                                    <div v-if="this.liabilityTransactionModalType == 'edit'">
                                                                        <button type="button" class="btn btn-danger btn-sm" @click="this.onClearEditTransactionForm()">
                                                                            <span class="indicator-label">Cancel</span> 
                                                                        </button>
                                                                        <button type="button" class="btn btn-primary btn-sm float-end" @click="this.updateLiabilityTransaction()">
                                                                            <span class="indicator-label">Update Transaction</span> 
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div> 
                                                </div>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="kt_transaction_tab_2" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'list' }">
                            <div class="table-responsive p-2"> 
                                <DxDataGrid :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="liabilityTransactionList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true"
                                    :ref="'detailDataGrid'"> 

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxSearchPanel :visible="true"/>  

                                    <DxColumn data-field="actions" caption="..." cell-template="actions-cell-template" width="80" alignment="center"/>
                                    <DxColumn data-field="assignment_status" width="130" caption="Assignment Status" header-cell-template="assignment_status-header" cell-template="assignment_status-cell-template" alignment="center"/>
                                    <DxColumn data-field="status" caption="Status" width="100" cell-template="status-cell-template" alignment="center"/>
                                    <DxColumn data-field="last_transaction_date" caption="Last Transaction Date" alignment="right" format="dd.MM.yyyy" header-cell-template="last_transaction_date-header" cell-template="last_transaction_date-cell-template"/>
                                    <DxColumn data-field="value_date" caption="Value Date" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="value_date-header" cell-template="value_date-cell-template"/> 
                                    <DxColumn data-field="amount" caption="Amount" alignment="right" cell-template="amount-cell-template"/>  
                                    <DxColumn data-field="amount_type" caption="Amount Type" cell-template="amount_type-cell-template" header-cell-template="amount_type-header"/>
                                    <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/> 
                                    <DxColumn data-field="last_transaction_by_name" caption="Last Transaction By" header-cell-template="last_transaction_by_name-header" cell-template="last_transaction_by_name-cell-template"/>

                                    <!-- Header Cell Templates -->
                                    <template #assignment_status-header>
                                        <div>Assignment</div><div>Status</div>
                                    </template>
                                    <template #last_transaction_date-header>
                                        <div>Last</div><div>Transaction Date</div>
                                    </template>
                                    <template #value_date-header>
                                        <div>Value</div><div>Date</div>
                                    </template>
                                    <template #amount_type-header>
                                        <div>Amount</div><div>Type</div>
                                    </template>
                                    <template #last_transaction_by_name-header>
                                        <div>Last</div><div>Transaction By</div>
                                    </template>

                                    <!-- Cell Templates -->
                                    <template #actions-cell-template="{ data: data }">
                                        <div class="align-middle">
                                            <div class="d-flex justify-content-end flex-shrink-0" v-if="liabilityTransactionList.length > 1 && data.rowIndex == (this.liabilityTransactionList.length - 1)">
                                                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" style="height: 30px; width: 30px;" @click="onEditTransactionData(data.data)">
                                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg>
                                                    </span>
                                                </button>
                                                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" style="height: 30px; width: 30px;" @click="onDeleteTransactionData(data.data.id)">
                                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </template> 
                                    <template #assignment_status-cell-template="{ data: data }">
                                        <div class="align-middle">
                                            <span class="badge badge-sm" :class="'badge-light-' + assignmentStatusColor(data.data.assignment_status)"> 
                                                {{assignmentStatusName(data.data.assignment_status)}}
                                            </span>
                                        </div>
                                    </template>
                                    <template #status-cell-template="{ data: data }">
                                        <div class="align-middle">
                                            <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(data.data.status)">{{liabilityStatusName(data.data.status)}}</span>
                                        </div>
                                    </template>
                                    <template #last_transaction_date-cell-template="{ data: data }">
                                        <span class="align-middle">
                                        {{ previewDate(data.data.last_transaction_date) }}
                                        </span>
                                    </template>
                                    <template #value_date-cell-template="{ data: data }">
                                        <span class="align-middle">
                                        {{ previewOnlyDate(data.data.value_date) }}
                                        </span>
                                    </template>
                                    <template #amount-cell-template="{ data: data }">
                                        <span class="align-middle">
                                        {{ data.data.amount }}
                                        </span>
                                    </template>
                                    <template #amount_type-cell-template="{ data: data }">
                                        <span class="align-middle">                                                    
                                            <span v-if="data.data.amount_type == 0"> No Amount </span>
                                            <span v-if="data.data.amount_type == 1"> Payment </span>
                                            <span v-if="data.data.amount_type == 2"> Collection </span>
                                        </span>
                                    </template>
                                    <template #description-cell-template="{ data: data }">
                                        <span class="align-middle">
                                        {{ data.data.description }}
                                        </span>
                                    </template>
                                    <template #last_transaction_by_name-cell-template="{ data: data }">
                                        <span class="align-middle">
                                        {{ data.data.last_transaction_by }}
                                        </span>
                                    </template>
                                </DxDataGrid> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Download Modal -->
    <div class="modal fade" tabindex="-1" id="file_download_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10" v-if="this.requestedFile">
                      Download "{{ this.requestedFile.name }}"
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="downloadModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white"> 
                <div class="row"> 
                  <div class="col-lg-12">
                      <label class="fw-bold form-label mt-3">
                          <span>Download Reason</span>
                      </label>
                      <textarea rows="2" class="form-control " name="description" v-model="downloadForm.download_reason"></textarea>
                  </div> 
              </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary w-100" @click="onDownloadFile(this.requestedFile)"> Download </button>
              </div>
          </div>
      </div>
    </div> 
    
    <!-- Add Manuel Liability Modal -->
    <div class="modal fade" id="add_cc_liability_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        Add Liability            
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>   
                    </div>              
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeAddModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y pt-0">                    
                    <div class="card card-xxl-stretch h-sm-100">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label class="fs-6 fw-bold form-label">
                                        Accounting Period
                                    </label>
                                    <Select2 v-model="manuelLiabilityForm.period_id" :options="filteredAccountingPeriodList" :settings="{ width: '100%', dropdownParent: '#add_cc_liability_modal .modal-content' }"/>
                                </div>
                                <div class="col-lg-4">
                                    <label class="fs-6 fw-bold form-label">Liability Type</label> 
                                    <Select2 v-model="manuelLiabilityForm.liability_id" :options="liabilityTypeList"
                                        @select="onChangeLiabilityType($event)" :settings="{ width: '100%', dropdownParent: '#add_cc_liability_modal .modal-content' }"/>
                                </div>
                                <div class="col-lg-4">
                                    <label class="fs-6 fw-bold form-label">
                                        Period Month 
                                    </label>
                                    <CurrencyInput type="text" class="form-control" name="period_month" :precision="0" @focus="$event.target.select()"  v-model="manuelLiabilityForm.period_month"/>

                                </div>
                                <div class="col-lg-4">
                                    <label class="fs-6 fw-bold form-label mt-3">Starting Date</label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="manuelLiabilityForm.created_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                </div>
                                
                                <div class="col-lg-4">
                                    <label class="fs-6 fw-bold form-label mt-3">Value Date</label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="manuelLiabilityForm.value_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                </div>
                                
                                <div class="col-lg-4" v-if="this.manuelLiabilityForm.amount_type == 1 || this.manuelLiabilityForm.amount_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">Amount</label>
                                    <CurrencyInput type="text" class="form-control text-end" name="amount" v-model="manuelLiabilityForm.amount" @focus="$event.target.select()"/>
                                </div>
                                <div class="col-lg-6" v-if="this.manuelLiabilityForm.amount_type == 1 || this.manuelLiabilityForm.amount_type == 2">
                                    <label class="fw-bold form-label mt-3">
                                        <span>Amount Type</span>
                                    </label>
                                    <Select2 v-model="manuelLiabilityForm.amount_type" :options="filteredAmountTypeList" :settings="{ width: '100%', dropdownParent: '#add_cc_liability_modal .modal-content' }"/>
                                </div>
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3">Description</label> 
                                    <textarea rows="3" class="form-control" name="description" v-model="manuelLiabilityForm.description"></textarea>
                                </div>
                                <div class="col-lg-12">
                                    <div class="mt-3 alert alert-primary d-flex align-items-center p-3 mb-0">
                                        <span class="svg-icon svg-icon-primary svg-icon-1 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                                <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column pe-0 pe-sm-10">
                                            <span> Periodic <b> {{manuelLiabilityForm.period_month}} </b> liabilities will be created. </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-flush mt-3" id="kt_contacts_list">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-success btn-lg"
                                                @click="this.addManuelLiability()">
                                                <span class="indicator-label">Add Liability</span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Document Details Modal -->
    <div class="modal fade" tabindex="-1" id="document_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Document Details                   
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="documentDetailsModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                  <div class="row align-items-center mx-0 w-100"> 
                      <div class="col-lg-12 px-4 text-end">
                        <button type="button" v-if="!this.selectedDocument.is_deleted" class="btn btn-sm btn-light-danger me-3" style="font-size:11px !important;" @click="onDeleteDocument(this.selectedDocument)">
                            Delete File
                        </button>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                  <div class="row">
                                      <div class="col-lg-6">
                                        <div>
                                          <span>
                                            Importance: 
                                          </span>
                                          <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.importance == 0">Normal</span>
                                          <span class="badge badge-sm badge-secondary" v-if="this.selectedDocument.importance == 1">Low</span>
                                          <span class="badge badge-sm badge-warning" v-if="this.selectedDocument.importance == 2">High</span>
                                          <span class="badge badge-sm badge-danger" v-if="this.selectedDocument.importance == 3">Very High</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="text-end"> 
                                              <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.document_source == 0">Incoming Document</span>
                                              <span class="badge badge-sm badge-info" v-if="this.selectedDocument.document_source == 1">Outgoing Document</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                    <div class="row">
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>Document Name :</span>
                                        &nbsp; 
                                        <span class="badge badge-sm badge-secondary"> {{ this.selectedDocument.document_name }} </span>
                                      </div>
                                      <div class="col-lg-4">  
                                        <span class="bullet bg-secondary me-3 bullet-md"></span>
                                        <span>Document No :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.document_no }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>File Name :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.name }} </span>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.document_source == 0"> Incoming Document </span>
                                            <span v-if="this.selectedDocument.document_source == 1"> Outgoing Document </span>
                                          </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                          <span >Source Company :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_company }} </span>
                                        </div>
                                        <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source Person :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_person }} </span>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Size :</span>
                                          &nbsp; 
                                          <span> {{ calculateSize(this.selectedDocument.size) }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Created At :</span>
                                          &nbsp; 
                                          <span> {{ previewDate(this.selectedDocument.created_at) }} </span>
                                        </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md "></span>
                                          <span>Status :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.status == 0"> Active </span>
                                            <span class="text-danger" v-if="this.selectedDocument.status == 1"> Deleted File </span>
                                          </span>
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>  
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                <div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span>
                                        Does it contain personal data? (GDPR Content): 
                                      </span>
                                      &nbsp; 
                                      <span v-if="this.selectedDocument.have_personal_data == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_personal_data == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span> Have GDPR permissions been obtained? </span> &nbsp; 
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                      {{ this.selectedDocument.gdpr_description }}
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
  

import { DxScheduler} from 'devextreme-vue/scheduler';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxColumn, DxSearchPanel, DxPaging } from 'devextreme-vue/data-grid';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
 
export default {
  name: "Liabilities",
  components: {
    DxScheduler, DxDataGrid, DxFilterRow, DxHeaderFilter, DxColumn, DxSearchPanel, DxFileUploader, DxPaging
  },
  data() {
    return {   
        userData: this.$getUser(),
        localCurrency: this.$getUser().currency_code,
        views: ['week', 'workWeek', 'month', 'agenda'],
        currentDate: new Date(),
        isOpenActionsDropdown: false,
        liabilities: [],
        users: [],
        summary: {},
        totals: {},
        activeView: 'list',
        activeDetailTab: 'general',
        isViewCtierias: false,
        liabilityModalType: 'addItem',
        liabilityTransactionModalType: 'add',        
        activeTransactionTab: 'add',
        isAmountTypeDisabled: true,
        isStatusDisabled: true,
        isAmountDisabled: true,
        isValueDateDisabled: true,
        isDocumentViewOpen: false,
        liabilityGroupList: [{id: '-1', text: 'All Liability Groups'}],
        accountingPeriodList: [{id: '-1', text: 'All Accounting Periods'}],
        transactionTypeList: [
            {id: 0, text: 'Select an option'},
            {id: 1, text: 'Update'},
            {id: 2, text: 'Description'},
            {id: 3, text: 'Add Document'},
        ],
        transactionSelectionList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Sales Invoice'},
            { id: 2, text: 'Purchase Invoice'},
            { id: 3, text: 'Sales Return Invoice'},
            { id: 4, text: 'Purchase Return Invoice'},
            { id: 5, text: 'Expense'},
            { id: 6, text: 'Incoming Cheque'},
            { id: 7, text: 'Outgoing Cheque'},
        ],
        amountTypeList: [
            {id: -1, text: 'ALL'},
            {id: 0, text: 'No Amount'},
            {id: 1, text: 'Payment'},
            {id: 2, text: 'Collection'}, 
        ],
        sourceList: [
            {id: -1, text: 'ALL'},
            {id: 1, text: 'Standard'},
            {id: 2, text: 'Transaction'},
            {id: 3, text: 'User Manuel'},
        ],
        assignmentStatusList: [
            {id: 1, text: 'Waiting', color: 'warning' },
            {id: 2, text: 'Completed', color: 'success' },
            {id: 3, text: 'Canceled', color: 'danger' },
            {id: 0, text: 'All Assignment', color: '' },
        ],
        statusList: [
            {id: -2, text: 'Not Completed', color: 'secondary'},
            {id: -1, text: 'All Status', color: 'secondary'},
            {id: 0, text: 'Awaiting', color: 'secondary'},
            {id: 1, text: 'Pending', color: 'warning'},
            {id: 2, text: 'Calculated', color: 'info'},
            {id: 3, text: 'Paid', color: 'primary'},
            {id: 4, text: 'Not Paid', color: 'light-danger'},
            {id: 5, text: 'Completed', color: 'success'},
            {id: 6, text: 'Hidden', color: 'dark'},
            {id: 7, text: 'Canceled', color: 'danger'},
        ],
        valueCriteriaList: [
            {id: 0, text: 'No Criteria'},
            {id: 1, text: 'Incorporated In (Day/Month)'}, 
            {id: 2, text: 'Accounting Start Period (Day/Month)'}, 
            {id: 3, text: 'Accounting End Period (Day/Month)'}, 
            {id: 4, text: 'Accounting Fee (Day/Month)'}, 
            {id: 5, text: 'VAT Start Period (Day/Month)'}, 
            {id: 6, text: 'VAT End Period (Day/Month)'}, 
            {id: 7, text: 'Payroll'}
        ],
        importanceList: [ 
            { id: 0, text: 'Normal' },
            { id: 1, text: 'Low' },
            { id: 2, text: 'High' },
            { id: 3, text: 'Very High' }
        ],
        documentSourceList: [
            { id: -1, text: 'Select an option' },
            { id: 0, text: 'Incoming Document' },
            { id: 1, text: 'Outgoing Document' }
        ], 
        havePersonelDataList: [
            { id: 0, text: 'No' },
            { id: 1, text: 'Yes' }
        ],
        gpdrObtainList: [
            { id: 0, text: 'No' },
            { id: 1, text: 'Yes' }
        ],
        liabilityTypeList: [],
        liabilityTransactionList: [],
        accountingPeriodStartYear: null,
        accountingPeriodEndYear: null,
        form: {
            company_id: '-1',
            company_type_id: '-1',
            period_id: '-1',
            liability_group_id: '-1',
            start_date: moment().startOf('month'),
            end_date: moment().endOf('month'),
            source: '-1',
            amount_type: -1,
            periodSelection: {
                start_date: null,
                end_date: null,
            },
            status: -2,
            is_show_completed: false,
            transaction_type: 0,
            assignment_status: 0
        },
        manuelLiabilityForm: {
            period_id: '-1',
            liability_id: '-1',
            created_date: new Date(),
            value_date: new Date(),
            amount: 0.00,
            description: null,
            value_criteria: 0,
            payment_day_option: 0,
            period_month: 0,
            amount_type: 0
        },
        editTransactionForm: {
            id: null,
            status: 0,
            value_date: new Date(),
            amount: 0,
            description: null,
            amount_type: 1,
            assignment_status: 0
        },
        uploadForm: {
            document_type_id: -1,
            importance: 0,
            document_source: -1,
            source_company: null,
            source_person: null,
            have_personal_data: 0,
            have_gdpr_permission: 0,
            gdpr_description: null,
            document_name: null,
            document_no: null,
            document_description: null
        },
        selectedLiabilityItem: {},
        downloadForm: {
            download_reason: null
        },
        requestedFile: null,
        selectedDocument: null,
        companyActivePeriodId: null,
        documentLocation: null,
        isFileManagerReady: false
    };
  },
  computed: {
   previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm:ss')
        }
      }
    },
    previewOnlyDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    assignmentStatusName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.assignmentStatusList.find(element => {
            return element.id == value
          }).text;
        }
      }
    },
    assignmentStatusColor(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.assignmentStatusList.find(element => {
            return element.id == value
          }).color;
        }
      }
    },
    filteredAssignmentList(){
        return this.assignmentStatusList.filter(p => { 
            return p.id != -1;
        });
    },
    filteredLiabilityStatusList(){
        return this.statusList.filter(p => { 
            return p.id != -1 && p.id != -2;
        });
    },
    filteredAmountTypeList(){
        return this.amountTypeList.filter(p => { 
            return p.id != -1;
        });
    },
    liabilityStatusName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).text;
        }
      }
    },
    liabilityStatusColor(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).color;
        }
      }
    },
    calculateSize(){
      return (bytes)=>{
            if (bytes) 
            {
                const decimals = 2;
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
            }
        } 
    },
    getLiabilityTransactionFilteredList(){
        return this.liabilityTransactionList.filter(p => { 
            return p.description != '' && p.description != null && p.description != undefined;
        });
    },
    filteredAccountingPeriodList(){
        return this.accountingPeriodList.filter(p => { 
            return p.id != '-1';
        });
    },
  },
  methods: {     
    getData(){
        this.liabilityGroupList = [];
        const parameters = {
            session_id: this.$getUser().session_id,
        };  
        
        const requestOne =  axios.post('/api/Liabilities/GetLiabilityLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {              
            this.liabilityGroupList = responses[0].data.liability_groups;
            this.companyAccounts = responses[0].data.company_accounts;
            this.liabilityGroupList.unshift({ id: '-1', text: 'All Liability Groups'});
            this.companyAccounts.unshift({ id: '-1', text: 'All Companies'});
            this.form.company_id = this.companyAccounts[0].id;
            if(this.$getSessionStorage("selected_company") !== null && this.$getSessionStorage("selected_company") !== '' && this.$getSessionStorage("selected_company") !== undefined) {
                if(this.$getSessionStorage("selected_company").id !== null && this.$getSessionStorage("selected_company").id !== '' && this.$getSessionStorage("selected_company").id !== undefined) {
                    this.companyAccounts.forEach(element => {
                        if(element.id == this.$getSessionStorage("selected_company").id) {
                            this.form.company_id = element.id;
                        }
                    });
                }
            }
            this.getLiabilities();
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    onClickDropdown(item) {
        this.liabilities.forEach(element => {
            if (item.id !== element.id) {
                element.isOpenActionsDropdown = false;
            }
        });
        item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    },
    getAccountingYears(event) {
        this.form.company_type_id = event.company_type_id;      
        const parameters = {
            session_id: this.$getUser().session_id,
            id: this.form.company_id,
        };
        axios.post('/api/Accounts/GetCompanyAccountingYears', parameters, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.accountingPeriodList = response.data;
            this.accountingPeriodList.unshift({ id: '-1', text: 'All Accounting Periods'});
            this.form.period_id = (this.form.company_id !== '-1' && event.active_period_id !== '') ? event.active_period_id : '-1';
            this.companyActivePeriodId = event.active_period_id != '' ? event.active_period_id : '-1';
            this.$Progress.finish();
            this.getLiabilities();
        })
        .catch(function () {
            this.$Progress.fail();
        });
    },
    getLiabilities() {
        this.liabilities = [];
        this.$Progress.start();
        const parameters = {
            company_id:  this.form.company_id,
            session_id: this.$getUser().session_id,
            period_id: this.form.period_id,
            liability_group_id: this.form.liability_group_id,
            source: this.form.source,
            transaction_type: this.form.transaction_type,
            amount_type: this.form.amount_type,
            start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: this.form.end_date !== null ? moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss') : null,
            status: this.form.status,
            assignment_status: this.form.assignment_status,
        };
        axios.post('/api/Liabilities/Get', parameters, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.liabilities = response.data.liabilities;
            this.summary = response.data.summary;
            this.totals = response.data.totals;
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.fail();
        }); 
    },
    onChangeDateCriteria(type) {
        this.form.period_id = '-1';
        this.form.source = '-1';
        this.form.amount_type = -1;
        this.form.status = this.form.is_show_completed ? -1 : -2;
        /* Past 1 Month */
        if(type == 1) { 
            this.form.start_date = moment().subtract(1, 'months').startOf('month');
            this.form.end_date = moment().subtract(1, 'months').endOf('month');
        }

        /* On This Month */
        if(type == 2) {
            this.form.start_date = moment().startOf('month');
            this.form.end_date = moment().endOf('month');
        }

        /* Next 1 Month */
        if(type == 3) { 
            this.form.start_date = moment().add(1, 'months').startOf('month');
            this.form.end_date = moment().add(1, 'months').endOf('month');
        }

        /* Next 3 Month */
        if(type == 4) {            
            this.form.start_date = moment().add(1, 'months').startOf('month');
            this.form.end_date = moment().add(3, 'months').endOf('month'); 
        }

        /* Next All */
        if(type == 5) {            
            this.form.start_date = moment();
            this.form.end_date = null; 
        }

        this.getLiabilities();
    },    
    setCriteriasToDefault() {   
        this.form = {
            company_id: '-1',
            company_type_id: '-1',
            period_id: '-1',
            liability_group_id: '-1',
            start_date: moment().startOf('month'),
            end_date: moment().endOf('month'),
            source: '-1',
            amount_type: -1,
            periodSelection: {
                start_date: null,
                end_date: null,
            },
            status: -2,
            transaction_type: 0,
            assignment_status: 0
        };
        this.liabilities = [];
    }, 
    onClearEditTransactionForm() {
        this.isAmountDisabled = true;
        this.isAmountTypeDisabled = true;
        this.isValueDateDisabled = true;
        this.liabilityTransactionModalType = 'add';
        this.editTransactionForm = {
            liability_id: this.selectedLiabilityItem.id,
            status: this.selectedLiabilityItem.status,
            value_date: this.selectedLiabilityItem.value_date,
            amount: this.selectedLiabilityItem.amount,
            description: null,
            amount_type: this.selectedLiabilityItem.amount_type,
            transaction_type: 0,
            source: 1
        };
    },
    onChangeAccountingYear(e){
        this.form['periodSelection'] = e;
        this.form.start_date = moment(e.start_date);
        this.form.end_date = moment(e.end_date);
    },
    openLiabilityTransactionModal(item) {
        this.isAmountDisabled = true;
        this.isStatusDisabled = true;
        this.isAmountTypeDisabled = true;
        this.isValueDateDisabled = true;
        this.isDocumentViewOpen = false;
        this.liabilityTransactionModalType = 'add';
        this.activeTransactionTab = 'add';
        this.selectedLiabilityItem = item;

        this.editTransactionForm = {
            liability_id: item.id,
            status: item.status,
            value_date: item.value_date,
            amount: item.amount.replace(',', ''),
            description: null,
            amount_type: item.amount_type,
            transaction_type: 0,
            source: 1
        };
        
        this.uploadForm = {
            document_type_id: -1,
            importance: 0,
            document_source: -1,
            source_company: null,
            source_person: null,
            have_personal_data: 0,
            have_gdpr_permission: 0,
            gdpr_description: null,
            document_name: null,
            document_no: null,
            document_description: null
        };
        this.$refs.detailDataGrid.instance.clearFilter();
    },    
    onEditTransactionData(item) {
        this.isAmountDisabled = true;
        this.isStatusDisabled = true;
        this.isAmountTypeDisabled = true;
        this.isValueDateDisabled = true;
        this.activeTransactionTab = 'add';
        this.liabilityTransactionModalType = 'edit';
        this.editTransactionForm.transaction_id = item.id;
        this.editTransactionForm.value_date = item.value_date;
        this.editTransactionForm.amount = item.amount.replace(',', '');
        this.editTransactionForm.amount_type = item.amount_type;
        this.editTransactionForm.status = item.status;
        this.editTransactionForm.description = item.description;
    },
    getLiabilityTransactions(id, isRefresh){
        const parameters = {
            session_id: this.$getUser().session_id,
            liability_id: id
        };
        axios.post('/api/Liabilities/GetLiabilityTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.liabilityTransactionList = response.data;

                if(isRefresh) {
                    this.getLiabilities();
                }
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    createLiabilityTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            liability_id: this.editTransactionForm.liability_id,
            amount_type: this.editTransactionForm.amount_type,
            status: this.editTransactionForm.status,
            last_transaction_description: this.editTransactionForm.description,
            current_amount: this.editTransactionForm.amount != null && this.editTransactionForm.amount != '' ? this.editTransactionForm.amount : 0,
            current_value_date: moment(this.editTransactionForm.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            assignment_status: 0,
        }; 
        axios.post('/api/Liabilities/CreateLiabilityTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$refs.closeUpdateModal.click();
                this.getLiabilities();
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    updateLiabilityTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            id: this.editTransactionForm.transaction_id,
            last_transaction_description: this.editTransactionForm.description,
            current_amount: this.editTransactionForm.amount,
            current_value_date: moment(this.editTransactionForm.value_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };  
        axios.post('/api/Liabilities/UpdateLiabilityTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$refs.closeUpdateModal.click();
                this.getLiabilities();
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onDeleteTransactionData(id) { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
        }).then((result) => { 
            if (result.isConfirmed) {
                const queryParameter = {
                    id: id,
                    session_id: this.$getUser().session_id,
                    company_id: this.selectedLiabilityItem.company_id
                }; 
                axios.post('/api/Liabilities/DeleteLiabilityTransaction', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$refs.closeUpdateModal.click();
                        this.getLiabilities();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        })  
    },
    onChangeHideCriteria() {
        if (this.form.is_show_completed) this.form.status = -2;
        else this.form.status = -1;
        this.getLiabilities();
    },
    onChangeCriteriaView() {
        this.isViewCtierias = !this.isViewCtierias;
        if (this.isViewCtierias && this.form.end_date == null) {this.form.end_date = moment().endOf('month'); }
    },
    getUploadHeaders(){
        return { 
            sid: this.$getUser().session_id,
            cid: this.selectedLiabilityItem.company_id,
            ctd: this.$getUser().controller_account_id,
            dos: this.uploadForm.document_source == -1 ? null : this.uploadForm.document_source,
            ups: 2, /* Upload Source */
            hgp: this.uploadForm.have_gdpr_permission,
            hpd: this.uploadForm.have_personal_data,
            imp: this.uploadForm.importance,
            lid: this.selectedLiabilityItem.id,
            lci: this.documentLocation != null ? this.documentLocation.id : null,
            mid: this.documentLocation != null ? (!this.documentLocation.main_location ? null : this.documentLocation.main_location) : null,
            dti: this.documentLocation != null ? this.documentLocation.document_type_id : null,
        };
    },
    onPersonalDataChange(){
        this.uploadForm.have_gdpr_permission = 0;
        this.uploadForm.gdpr_description = null;
    },
    onUploadCompleted(e){
        const parameters = { 
            session_id: this.$getUser().session_id,
            fid: e.request.responseText,
            liability_id: this.editTransactionForm.liability_id,
            amount_type: this.editTransactionForm.amount_type,
            status: this.editTransactionForm.status,
            assignment_status: 0,
            amount: this.editTransactionForm.amount != null && this.editTransactionForm.amount != '' ? this.editTransactionForm.amount : 0,
            value_date: moment(this.editTransactionForm.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            liability_description: this.editTransactionForm.description,
            source_company: this.uploadForm.source_company,
            source_person: this.uploadForm.source_person,
            document_name: this.uploadForm.document_name,
            document_no: this.uploadForm.document_no,
            description: this.uploadForm.document_description,
            gdpr_description: this.uploadForm.gdpr_description
        }; 
        axios.post('/api/Folders/UpdateDocumentForLiabilities', parameters, {'Content-Type': 'application/json'})
          .then(() => {
                this.$swal("Success.", "File uploaded successfully..!", 'success');  
                this.$refs.closeUpdateModal.click(); 
                this.getLiabilities();
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });    
    },  
    onChangeDocumentView() {
        this.isDocumentViewOpen = !this.isDocumentViewOpen;
        this.isFileManagerReady = false;
        const that = this;
        if(this.isDocumentViewOpen == true) {
            const parameters = { 
                session_id: this.$getUser().session_id,
                location: 5, //General
                location_type: 1, //Liabilities
                location_subtype: 0,
                account_id: null,
                company_account_id: this.$getUser().company_account_id,
                controller_account_id: this.$getUser().controller_account_id,
                is_transaction: null
            }; 
            axios.post('/api/Folders/GetDocumentLocations', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.length > 0) { 
                    this.documentLocation = response.data[0];
                    // this.$refs.fileUploader.instance.reset();
                } else {
                    this.documentLocation = null;
                }
            }) 
            .finally(function () {
                that.isFileManagerReady = true;
            });
        }
    },
    getLiabilityDocuments(liabilityItem){
        this.requestedFile = null;
        const parameters = { 
          session_id: this.$getUser().session_id,
          liability_id: liabilityItem.id,
        }; 
        axios.post('/api/Liabilities/GetLiabilityDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    liabilityItem.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    getLiabilityDescriptions(liabilityItem){
        const parameters = {
            session_id: this.$getUser().session_id,
            liability_id: liabilityItem.id,
            is_have_description: 1
        };
        axios.post('/api/Liabilities/GetLiabilityTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                liabilityItem.descriptions = response.data;
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onRowPrepared(e){ 
        if(e.rowType === 'data') {
            if (e.data.is_deleted == '1') {
                e.rowElement.classList.add('bg-file-deleted');  
            }
        } 
    }, 
    onDownloadFile(item){
      const config = { 
            'Content-type': 'application/json',
            responseType: 'blob',
            headers: {
                sid: this.$getUser().session_id,
                cid: this.selectedLiabilityItem.company_id,
                ctd: this.$getUser().controller_account_id,
                fid: item.folder_id
            }
      }; 
      const parameters = {
          name: item.name,
          fid: item.fid,
          id: item.id,
          rsn: this.downloadForm.download_reason
      };
      axios.post('/api/Folders/Download', parameters, config)
          .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', parameters.name);
                  document.body.appendChild(link);
                  link.click();
                //   this.onOpenAccordion(item);
                  this.$refs.downloadModal.click();
                  this.downloadForm = {
                    download_reason: null
                  };
              }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          }); 
    },
    onRequestFile(item){
      this.requestedFile = item;
      this.downloadForm = {
        download_reason: null
      };
    },
    onChangeAssignmentStatus(item, assignment_status) {        
        this.$swal.fire({
            title: 'Are you sure you want to change assignment status?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
        }).then((result) => { 
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    liability_id: item.id,
                    amount_type: item.amount_type,
                    status: item.status,
                    last_transaction_description: null,
                    current_amount: item.amount != null && item.amount != '' ? Math.abs(parseFloat(item.amount.replace(',', ''))) : 0,
                    current_value_date: moment(item.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    assignment_status: assignment_status,
                }; 
                axios.post('/api/Liabilities/CreateLiabilityTransaction', parameters, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        item.isOpenActionsDropdown = false;
                        this.getLiabilities();
                    }
                })
                .catch(function (error) {
                    console.log(error); 
                });
            }
        })
    },
    getOperationGroupUsers(liabilityItem) {
        const parameters = { 
          session_id: this.$getUser().session_id,
          company_id: liabilityItem.company_id,
          liability_group_id: liabilityItem.liability_group_id
        }; 
        axios.post('/api/Liabilities/GetOperationGroupUsers', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                liabilityItem.users = response.data;
            }
        }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });
    },
    openAddLiabilityModal() {
        this.manuelLiabilityForm = {
            period_id: this.companyActivePeriodId,
            liability_id: '-1',
            created_date: moment(),
            value_date: moment(),
            amount: 0.00,
            description: null,
            value_criteria: 0,
            payment_day_option: 0,
            period_month: 1,
            amount_type: 0
        };

        const params = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };
        axios.post('/api/Liabilities/GetManuelLiabilityTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.liabilityTypeList = response.data;
            this.liabilityTypeList.unshift({id: '-1', text: 'Select an option'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onChangeLiabilityType(event) {
        if (event.liability_id != '-1') {
            this.manuelLiabilityForm.period_month = event.period_month;
            this.manuelLiabilityForm.amount_type = event.amount_type;
            this.manuelLiabilityForm.amount = event.amount;  
        }
    },
    addManuelLiability() {
        const parameters = {
            session_id: this.$getUser().session_id,
            period_id: this.manuelLiabilityForm.period_id,
            liability_id: this.manuelLiabilityForm.liability_id,
            at: moment(this.manuelLiabilityForm.created_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            value_date: moment(this.manuelLiabilityForm.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            amount: this.manuelLiabilityForm.amount_type == 0 ? 0 : this.manuelLiabilityForm.amount,
            description: this.manuelLiabilityForm.description,
            value_criteria: this.manuelLiabilityForm.value_criteria,
            payment_day_option: this.manuelLiabilityForm.payment_day_option,
            period_month: this.manuelLiabilityForm.period_month,
            amount_type: this.manuelLiabilityForm.amount_type
        };
        axios.post('/api/Liabilities/CreateManuelLiability', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.$refs.closeAddModal.click();
                this.getLiabilities();
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onOpenDetail(item, detailData, liabilityItem){
      if(item == null){
        item = detailData.data;
      } 
      this.selectedDocument = item;
      this.selectedDocument.liabilityItem = liabilityItem;

      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null,
        last_transaction_description: 'File details viewed.',
        status: 3,
        transaction_source: 'AUTO TRANSACTION',
        download_reason: null
      }; 
      axios.post('/api/Folders/SetCustomTransaction', parameters, {'Content-Type': 'application/json'})
        .then(() => {

         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onDeleteDocument(item){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id,
                      fnm: item.name,
                      fid: item.fid,
                      cid: item.liabilityItem.company_id,
                      ctd: this.$getUser().controller_account_id
                  }; 
                  axios.post('/api/Folders/DeleteDocument', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                              this.getLiabilityDocuments(item.liabilityItem);
                              this.$refs.documentDetailsModal.click();
                          }
                      }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
             }
        });   
    },
    openChatBi(item){
        const liabilityDetails = {
            id: item.id,
            liability_group_id: item.liability_group_id,
            company_id: item.company_id,
            amount: item.amount,
            liability_type_name: item.liability_type_name,
            liability_group_name: item.liability_group_name,
            value_date: item.value_date,
            amount_type: item.amount_type
        };
        this.emitter.emit('openChatBi', liabilityDetails);
    },
    getUnreadMessageCount(item) {
        this.liabilities.forEach(element => {
            if(element.id == item) {
                if (parseInt(element.message_count) > -1) {
                    element.message_count = element.message_count + 1;
                    element.last_message_time = moment().format('YYYY-MM-DD[T]HH:mm:ss');
                }
                else { element.message_count = 0; }
            }
        });
    }
  },
  created() {
    this.getData();
  },
  mounted() { 
    this.emitter.on("ReceiveLiabilityMessage", (item) => {
      this.getUnreadMessageCount(item);
    });
    this.emitter.on("onReadLiabilityMessage", () => {
      this.getLiabilities();
    });
  }
}; 
</script>