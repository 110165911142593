<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
            Company Dashboard Folders
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600">Settings</li> 
              <li class="breadcrumb-item text-gray-600">Company Dashboard Folders</li> 
          </ul> 
      </div>
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">
         <!-- FILE & FOLDER MANAGER SECTION -->
         <div class="card mb-5 mb-xl-10 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm" 
              style="background-image: url('assets/theme/media/svg/shapes/abstract-2.svg')">
                  <div class="card-body pt-6 pb-6">
                  <div class="d-flex flex-stack">
                      <div class="me-7 mb-6">
                          <div class="symbol symbol-90px symbol-lg-90px symbol-fixed position-relative">
                              <img src="assets/theme/media/avatars/blank.png" alt="image">
                              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                          </div>
                      </div>
                      <div class="flex-grow-1">
                          <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                              <div class="d-flex flex-column">
                                  <div class="d-flex align-items-center mb-2">
                                      <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{ userData.name_surname }}</a>
                                      <a href="#">
                                          <span class="svg-icon svg-icon-1 svg-icon-primary">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                  <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                                                  <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                                              </svg>
                                          </span>
                                      </a>
                                  </div>
                                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                      <span class="text-gray-400"> {{ userData.company_name }} </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

         <!-- FOLDERS VIEW -->
         <div class="card" v-if="this.activeMainView == 'folders'">
              <div class="card-header pt-3">
                <div class="card-title">
                    
                </div>
                <div class="card-toolbar">  
                    <div class="d-flex justify-content-end">
                      <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#add_folder_modal">
                        <span class="svg-icon svg-icon-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        New Folder
                      </button>
                      <button type="button" class="btn btn-primary" @click="getDocumentTypesForUpload()" data-bs-toggle="modal" data-bs-target="#upload_modal" v-if="this.activeFolder != null">
                          <span class="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                              <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor"></path>
                            </svg>
                          </span>
                          Upload Files
                      </button>
                        </div> 
                    </div>
              </div>
              <div class="card-body" style="min-height: 700px !important;"> 
                  <div class="d-flex flex-stack">
                    <div class="badge badge-lg badge-light-primary">
                      <div class="d-flex align-items-center flex-wrap text-gray-900">
                        <span class="svg-icon svg-icon-2 svg-icon-muted me-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z" fill="currentColor"/><path d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z" fill="currentColor"/><path opacity="0.3" d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z" fill="currentColor"/><path opacity="0.3" d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z" fill="currentColor"/></svg></span>
                          <a href="#" class="text-gray-700" @click="this.getData()">All Folders</a>
                        <span v-for="bcItem in parentFolders" v-bind:key="bcItem" @click="this.onOpenFolder(bcItem)">
                          <span class="svg-icon svg-icon-2 svg-icon-muted mx-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor"></path></svg></span>
                          <a href="#" class="text-gray-900"> {{ bcItem.name }}</a> 
                        </span>
                      </div>
                    </div>
                  </div> 
                  <div class="table-responsive mt-4"> 
                      <div  v-if="this.lists">
                          <div class="px-3 py-4" v-if="parentFolders.length > 0" style="display: none;">
                              <div class="card shadow-sm bg-hover-lighten" @click="this.onOpenFolder(this.activeFolder, true)">
                                  <div class="card-header">
                                      <h3 class="card-title py-3">
                                          <div class="d-flex align-items-center">
                                              <div class="d-flex align-items-center flex-grow-1">
                                                  <div class="symbol symbol-45px me-5"> 
                                                      <i class="fas fa-folder fs-2x me-4" style="color: rgb(0, 158, 247);"></i> 
                                                  </div> 
                                                  <div class="d-flex flex-column">
                                                      <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold">
                                                          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"/>
                                                          <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"/>
                                                          </svg>
                                                          </span>
                                                      </a>
                                                  </div>
                                              </div>
                                          </div>
                                      </h3> 
                                  </div> 
                              </div>
                          </div>


                          <!-- FOLDERS -->
                          <div class="px-3 py-3" v-for="(item, index) in searchableFoldersList" v-bind:key="item">
                              <div class="card shadow-sm bg-hover-lighten cursor-default">
                                  <div class="card-header">
                                      <h3 class="card-title py-3" @click="onOpenFolder(item)">
                                          <div class="d-flex align-items-center">
                                              <div class="d-flex align-items-center flex-grow-1">
                                                  <div class="symbol symbol-45px me-5">
                                                    <i :class="item.icon + ' fs-2x me-4'" :style="'color:' + item.color"></i>
                                                  </div> 
                                                  <div class="d-flex flex-column">
                                                      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bold">
                                                        {{ item.name }}
                                                      </a>
                                                      <small class="mt-1 fs-8"> {{ item.description }} </small>
                                                  </div>
                                              </div>
                                          </div>
                                      </h3>
                                      <div class="card-toolbar">
                                          <!-- <div class="d-flex flex-wrap mx-10" @click="onOpenFolder(item)">
                                              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
                                                  <div class="fs-6 text-gray-800 fw-bold text-center"> 
                                                      <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> 
                                                          File Size Limit: {{ calculateSize(item.size_limit) }}   
                                                      </span>
                                                  </div>
                                                  <div class="fs-6 text-gray-800 fw-bold text-center"> 
                                                      <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> 
                                                          {{ previewDate(item.created_at) }} 
                                                      </span>
                                                  </div>
                                              </div>
                                          </div> -->
                                          <button type="button" class="btn btn-sm btn-light-primary me-3" style="font-size:11px !important;" @click="openEditModal(item)" data-bs-toggle="modal" data-bs-target="#edit_folder_modal">
                                              Edit Folder 
                                          </button>
                                          <div class="collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_folder_'+ index" aria-expanded="true">
                                              <span class="svg-icon svg-icon-1 svg-icon-primary rotate-180">
                                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path></svg>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- <div class="card-body p-1">
                                      <div class="row">
                                          <div class="col-lg-6">
                                            <span class="badge badge-sm badge-warning">
                                              Status
                                            </span>
                                          </div>
                                        <div class="col-lg-6">
                                              <div class="text-end me-10"> 
                                                  Test
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->
                                  <div :id="'kt_accordion_folder_' + index" class="collapse">
                                      <div class="card-body d-flex align-items-end"> 
                                          <div class="row align-items-center mx-0 w-100">
                                            <div class="col-lg-12">
                                              <div class="d-flex justify-content-end">
                                                <div class="ms-2 pb-4">
                                                    <button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" @click="openItemActionMenu(item)"> 
                                                      <span class="svg-icon svg-icon-5 m-0">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                          <rect x="17" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                          <rect x="3" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-250px py-4" 
                                                        style="position: absolute; right: 0px;"
                                                        :class="{ 'show': item.isOpenActions }"> 
                                                      <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3" @click="openEditModal(item)" data-bs-toggle="modal" data-bs-target="#edit_folder_modal">Edit</a>
                                                      </div>
                                                      <div class="menu-item px-3">
                                                        <a href="#" target="_self" class="cursor-pointer menu-link px-3" data-bs-toggle="modal" data-bs-target="#set_document_type_modal" @click="getDocumentTypes(item)">Set Document Types</a>
                                                      </div> 
                                                      <div class="menu-item px-3">
                                                        <a href="#" target="_self" class="cursor-pointer menu-link px-3" @click="deleteFolder(item)">Delete</a>
                                                      </div> 
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                            <div class="card">
                                              <div class="row mt-4"> 
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                                  <span >Size :</span>
                                                  &nbsp; 
                                                  <span> {{ calculateSize(item.size_limit) }} </span>
                                                </div>
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                  <span>Created At :</span>
                                                  &nbsp; 
                                                  <span> {{ previewDate(item.created_at) }}</span>
                                                </div>
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                                  <span>Allowed Ext :</span>
                                                  &nbsp; 
                                                  <span> {{ item.allowed_ext }} </span>
                                                </div>
                                                <div class="col-lg-4 mt-3">  
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                  <span>Blocked Ext :</span>
                                                  &nbsp; 
                                                  <span> {{ item.blocked_ext }} </span>
                                                </div>
                                                <div class="col-lg-12 mt-3 mb-4">
                                                    <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                    <span>Description :</span>
                                                    &nbsp; 
                                                    <span> 
                                                      <span> {{ item.description}} </span>
                                                    </span>
                                                </div>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>  

                          <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-10" v-if="this.lists.length == 0 && this.isPreparementsReady"> 
                                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                                    <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                                    <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                                    <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="currentColor"></path>
                                  </svg>
                                </span>
                                <div class="d-flex flex-column pe-0 pe-sm-10">
                                  <h4 class="fw-semibold">No folders added yet.</h4>
                                  <span>Please use New Folder button to add more folders..!</span>
                                </div> 
                          </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    </div>
                    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    </div>
                  </div>
              </div>
         </div> 
    </div> 

    <!-- Add Folder Modal -->
    <div class="modal fade" tabindex="-1" id="add_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Add Folder      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeAddModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">Size Limit</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">Upload size limits for files (Bytes)</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">Allowed Extensions</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">Blocked Extensions</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">Folder Description</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">Description for folder can be typed here.</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-success w-100 mt-4" @click="onCreateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        Create Folder
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> Icon Selection </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                Reset
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Edit Folder Modal -->
    <div class="modal fade" tabindex="-1" id="edit_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Update Folder      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">Size Limit</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">Upload size limits for files (Bytes)</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">Allowed Extensions</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">Blocked Extensions</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">Type seperating via comma. E.g. <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">Folder Description</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">Description for folder can be typed here.</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-primary w-100 mt-4" @click="onUpdateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        Update Folder
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> Icon Selection </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                Reset
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Set Document Type Modal -->
    <div class="modal fade" tabindex="-1" id="set_document_type_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            Set Document Type
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeDocumentTypeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                    <div class="card">
                      <table class="table table-striped gy-7 gs-7"> 
                        <tbody>
                          <tr v-for="item in documentTypeslist" v-bind:key="item">
                            <td class="align-middle">
                              <button class="btn btn-sm btn-danger" @click="onDocumentTypeSave(item)" v-if="item.isAdded">
                                  Remove
                              </button>
                               <button class="btn btn-sm btn-success" @click="onDocumentTypeSave(item)" v-if="!item.isAdded">
                                  Add
                              </button>
                            </td>
                            <td class="align-middle text-gray-800">{{ item.code }}</td>
                            <td class="align-middle text-gray-900">{{ item.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Document Details Modal -->
    <div class="modal fade" tabindex="-1" id="document_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      Document Details                   
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="documentDetailsModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                  <div class="row align-items-center mx-0 w-100"> 
                      <div class="col-lg-12 px-4 text-end">
                        <button type="button" v-if="!this.selectedDocument.is_deleted" class="btn btn-sm btn-light-danger me-3" style="font-size:11px !important;" @click="onDeleteDocument(this.selectedDocument)">
                            Delete File
                        </button>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                  <div class="row">
                                      <div class="col-lg-6">
                                        <div>
                                          <span>
                                            Importance: 
                                          </span>
                                          <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.importance == 0">Normal</span>
                                          <span class="badge badge-sm badge-secondary" v-if="this.selectedDocument.importance == 1">Low</span>
                                          <span class="badge badge-sm badge-warning" v-if="this.selectedDocument.importance == 2">High</span>
                                          <span class="badge badge-sm badge-danger" v-if="this.selectedDocument.importance == 3">Very High</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="text-end"> 
                                              <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.document_source == 0">Incoming Document</span>
                                              <span class="badge badge-sm badge-info" v-if="this.selectedDocument.document_source == 1">Outgoing Document</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                    <div class="row">
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>Document Name :</span>
                                        &nbsp; 
                                        <span class="badge badge-sm badge-secondary"> {{ this.selectedDocument.document_name }} </span>
                                      </div>
                                      <div class="col-lg-4">  
                                        <span class="bullet bg-secondary me-3 bullet-md"></span>
                                        <span>Document No :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.document_no }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>File Name :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.name }} </span>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.document_source == 0"> Incoming Document </span>
                                            <span v-if="this.selectedDocument.document_source == 1"> Outgoing Document </span>
                                          </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                          <span >Source Company :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_company }} </span>
                                        </div>
                                        <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Source Person :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_person }} </span>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Size :</span>
                                          &nbsp; 
                                          <span> {{ calculateSize(this.selectedDocument.size) }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>Created At :</span>
                                          &nbsp; 
                                          <span> {{ previewDate(this.selectedDocument.created_at) }} </span>
                                        </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md "></span>
                                          <span>Status :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.status == 0"> Active </span>
                                            <span class="text-danger" v-if="this.selectedDocument.status == 1"> Deleted File </span>
                                          </span>
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>  
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                <div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span>
                                        Does it contain personal data? (GDPR Content): 
                                      </span>
                                      &nbsp; 
                                      <span v-if="this.selectedDocument.have_personal_data == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_personal_data == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span> Have GDPR permissions been obtained? </span> &nbsp; 
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 1"> YES </span>
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 0"> NO </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                      {{ this.selectedDocument.gdpr_description }}
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>
      </div>
    </div> 
    
  </div> 
</template> 
<style setup>
.color-input .box {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}
#dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #66BB6A;
  padding: 10px;
}

.passive-dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #E57373;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dx-fileuploader-content > .dx-fileuploader-upload-button {
  width: 100% !important;
  background-color: #009ef7;
  color: white;
  padding: 4px;
  font-weight: 600;
}
.dx-fileuploader-content > .dx-fileuploader-upload-button:hover {
  width: 100% !important;
  background-color: #f1faff;
  color: #009ef7;
  padding: 4px;
  font-weight: 600;
}
</style>
<script>
// Component Alias: `DSHF001`  
import axios from 'axios';
import moment from 'moment';
import icons from '../../../assets/icons/icons.json';
  


export default {
  name: "Documents",
  components:{ },
  data() {
    return { 
      activeMainView: 'folders',
      activeTransactionTab: 'Add',
      collapsedFolder: null,
      downloadForm: {
        download_reason: null
      },
      requestedFile: null,
      isFirstLaunch: true,
      documentTypeCriteriaList: [],
      filterForm: {
          document_type_id: '-1',
          start_date: moment().subtract(10, 'days'),
          end_date: new Date(),
      },
      userData: this.$getUser(),
      lists: [],
      searchbar: '',
      folderSearch: '',
      isListProgressing: false, 
      icons: icons,
      form: {
        name: null,
        description: null,
        icon: 'fas fa-folder',
        color: '#009ef7',
        size_limit: 1048576,
        blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
        allowed_ext: null
      },
      uploadForm: {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      },
      isViewIconModal: false,
      parentFolders: [],
      activeFolder: null,
      isPreparementsReady: false,
      documentTypeslist: [],
      importanceList: [ 
        { id: 0, text: 'Normal' },
        { id: 1, text: 'Low' },
        { id: 2, text: 'High' },
        { id: 3, text: 'Very High' }
      ],
      documentSourceList: [
        { id: -1, text: 'Select an option' },
        { id: 0, text: 'Incoming Document' },
        { id: 1, text: 'Outgoing Document' }
      ], 
      havePersonelDataList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      gpdrObtainList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      documentTypesForUpload: [],
      documents: [],
      transactions: [],
      transactionForm: {
        status: -1,
        last_transaction_description: null
      },
      statusList: [
        { id: -1, text: 'Select an option'},
        { id: 14, text: 'Description Input'}
      ],
      selectedDocument: null
    };
  },
  computed: { 
    convertStringToISO(){
      return (value)=>{
       if (value) {
          value = value.replace(/ı/g, 'i');
          value = value.replace(/İ/g, 'I');
          value = value.replace(/ş/g, 's');
          value = value.replace(/Ş/g, 'S');
          value = value.replace(/ç/g, 'c');
          value = value.replace(/Ç/g, 'C');
          value = value.replace(/ö/g, 'o');
          value = value.replace(/Ö/g, 'O');
          value = value.replace(/ü/g, 'u');
          value = value.replace(/Ü/g, 'U');
          value = value.replace(/ğ/g, 'g');
          value = value.replace(/Ğ/g, 'G');
          return value;
        }
      }
    },
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    searchableFoldersList() {
        return this.lists.filter(p => { 
            return p.name.toLowerCase().indexOf(this.folderSearch.toLowerCase()) != -1;
        });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    calculateSize(){
      return (bytes)=>{
            if (bytes) 
            {
                const decimals = 2;
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
            }
        } 
    },
    getDocumentTypeID(){
      return (value)=>{
       if (value) {
          if(value == -1){
            return null;
          }
          else{
            var transactionID = null;
            this.documentTypesForUpload.forEach((item) => {
              if(item.id == value){
                transactionID = item.document_type_id;
              }
            });

            return transactionID;
          }
        }
      }  
    }
  },
  methods: {
    getData() { 
       this.isFirstLaunch = false;
       this.selectedDocument = null;
       this.isPreparementsReady = true;
       this.folderSearch = '';
       this.parentFolders = [];
       this.activeFolder = null;
       this.documents = [];
       this.requestedFile = null;
       const parameters = { 
         session_id: this.$getUser().session_id
       }; 
       axios.post('/api/Folders/GetFolders', parameters, {'Content-Type': 'application/json'})
         .then((response) => {
            response.data.forEach((item) => { 
              item.isOpenActions = false;
            });
            this.lists = response.data;
            this.form = {
              name: null,
              description: null,
              icon: 'fas fa-folder',
              color: '#009ef7',
              size_limit: 1048576,
              blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
              allowed_ext: null
            };
            this.isPreparementsReady = false;

            if(!this.lists.length > 0){
                this.isFirstLaunch = true;
            }

            this.collapsedFolder = null;
          }) 
         .catch(function (error) {
             console.log(error);
             this.Progress.finish();
         });          
    }, 
    onIconSelection(item){
      this.form.icon = item;  
    }, 
    onCreateFolder(){ 
      
      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        parent: parent == null ? null : parent.id,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/CreateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been created successfully..!", 'success');   
              this.$refs.closeAddModal.click(); 
              if(parent == null){
                this.getData();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    onUpdateFolder(){ 

      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        id: this.form.id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/UpdateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been saved successfully..!", 'success');  
              this.$refs.closeUpdateModal.click(); 
              if(parent == null){
                this.getData();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    openItemActionMenu(item){
      if(item.isOpenActions){
        item.isOpenActions = !item.isOpenActions;
      }
      else{
        this.lists.forEach((listItem) => { listItem.isOpenActions = false; });
        item.isOpenActions = !item.isOpenActions;
      }
      
    },
    openEditModal(item){
      const parameters = { 
        session_id: this.$getUser().session_id,
        id: item.id
      }; 
      axios.post('/api/Folders/GetFolderById', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
            this.form = {
              id: response.data.id,
              name: response.data.name,
              description: response.data.description,
              icon: response.data.icon,
              color: response.data.color,
              size_limit: response.data.size_limit,
              blocked_ext: response.data.blocked_ext,
              allowed_ext: response.data.allowed_ext
            };
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    deleteFolder(item) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this data?",
          confirmButtonColor: "#E53935",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          icon: 'warning'
        })
        .then((result) => {
          if (result.isConfirmed) {

                var parent = null;
                if(this.parentFolders.length > 0){
                  parent = this.parentFolders[this.parentFolders.length - 1];
                }
              
                const parameters = { 
                  session_id: this.$getUser().session_id,
                  id: item.id
                }; 
                axios.post('/api/Folders/DeleteFolder', parameters, {'Content-Type': 'application/json'})
                  .then((response) => {
                       if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            this.$swal("Deleted.", "Folder has been deleted..!", 'warning');  
                            if(parent == null){
                              this.getData();
                            }
                            else{
                              this.onOpenFolder(parent);
                            }
                       }
                  }) 
                  .catch(function (error) {
                      console.log(error);
                      this.Progress.finish();
                  });          
          }
        });
    },
    onOpenFolder(item, UseParentID){  
      if(item == null || item == '' || item == undefined){
        this.getData();
        return;
      }
      this.selectedDocument = null;
      this.collapsedFolder = null;
      this.requestedFile = null;
      this.isPreparementsReady = false;
      this.folderSearch = '';
      if(UseParentID){  
        if(item.parent == null || item.parent == ''){
            this.getData();
            return;
        }

        this.parentFolders.forEach((folderItem) => { 
          if(folderItem.id == item.parent){
             item = folderItem;
          }
        });
      }
      this.lists = [];
      const parameters = { 
        session_id: this.$getUser().session_id,
        parent: item.id
      }; 
      axios.post('/api/Folders/GetSubFolders', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              response.data.forEach((dataItem) => { 
                dataItem.isOpenActions = false;
              });
              this.lists = response.data;
              this.form = {
                name: null,
                description: null,
                icon: 'fas fa-folder',
                color: '#009ef7',
                size_limit: 1048576,
                blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
                allowed_ext: null
              };
 
              this.activeFolder = item;
              this.isPreparementsReady = true;
              this.onFeedBreadCrumbs(item);
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onFeedBreadCrumbs(item){
      var isExists = false;
      this.parentFolders.forEach((parentItem, index) => { 
        if(parentItem.id == item.id){
          isExists = true;
          this.parentFolders.splice(index + 1, this.parentFolders.length);
        }
      });
      if(!isExists){
        this.parentFolders.push(item);
      }
    },
    getDocumentTypes(folder){
      this.collapsedFolder = folder;
      this.documentTypeslist = [];
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypeslist = response.data;
            //this.documentTypeslist.unshift({ id: null, code: '-', name: 'Select None'});
            this.documentTypeslist.forEach((item) => { 
              item['folder_id'] = folder.id;
            });

            const parameters = { 
              session_id: this.$getUser().session_id,
              folder_id: folder.id
            }; 
            axios.post('/api/Folders/GetDocumentTypesByFolderID', parameters, {'Content-Type': 'application/json'})
              .then((folderTypeResponse) => {
                this.documentTypeslist.forEach((typeItem) => { 
                    folderTypeResponse.data.forEach((folderTypeItem) => { 
                        if(typeItem.id == folderTypeItem.document_type_id){
                          typeItem['isAdded'] = true;
                        }
                    });
                }); 
               }) 
              .catch(function (error) {
                  console.log(error);
                  this.Progress.finish();
              });          
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onDocumentTypeSave(typeSelection){
        const parameters = { 
          session_id: this.$getUser().session_id,
          folder_id: typeSelection.folder_id,
          document_type_id: typeSelection.id
        }; 
        axios.post('/api/Folders/UpdateDocumentType', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.getDocumentTypes(this.collapsedFolder);
                //this.$refs.closeDocumentTypeModal.click(); 
            }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
        }); 
    },
    getDocumentTypesForUpload(){
      this.documentTypesForUpload = [];
      this.uploadForm =  {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      };
      
      const parameters = { 
        session_id: this.$getUser().session_id,
        folder_id: this.activeFolder.id
      }; 
      axios.post('/api/Folders/GetDocumentTypesByFolderID', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypesForUpload = response.data;
            this.documentTypesForUpload.unshift({ id: -1, text: 'Select an option'}); 

            this.$refs.fileUploader.instance.reset();
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onPersonalDataChange(){
        this.uploadForm.have_gdpr_permission = 0;
        this.uploadForm.gdpr_description = null;
    },
    getDocumentTypesList(){
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypeCriteriaList = response.data;
            this.documentTypeCriteriaList.unshift({ id: '-1', text: 'All'});
            this.filterForm['document_type_id'] = '-1';
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    }, 
    onOpenDetail(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.selectedDocument = item;

      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null,
        last_transaction_description: 'File details viewed.',
        status: 3,
        transaction_source: 'AUTO TRANSACTION',
        download_reason: null
      }; 
      axios.post('/api/Folders/SetCustomTransaction', parameters, {'Content-Type': 'application/json'})
        .then(() => {

         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onOpenAccordion(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.transactions = [];
      this.selectedDocument = item;
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null
      }; 
      axios.post('/api/TransactionsDocument/GetTransactionsDocumentBy', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.transactions = response.data;
            this.transactionForm = {
              status: -1,
              last_transaction_description: null
            };
            this.activeTransactionTab = 'Add';
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    saveTransactionData(){
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/CreateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been saved successfully..!", 'success');  
                  this.$refs.closeTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onDeleteTransactionData(item, documentItem){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id
                  }; 
                  axios.post('/api/Folders/DeleteTransaction', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                              this.onOpenAccordion(documentItem);
                          }
                      }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
             }
        });   
    },
    onEditTransactionData(item){ 
      this.transactionForm = {
          status: item.status == null || item.status == 0 ? -1 : item.status,
          last_transaction_description: item.last_transaction_description,
          id: item.id
      };
      if(item.status == 14) { this.transactionForm.status_text = 'Description Input' }
      if(item.status == 1) { this.transactionForm.status_text = 'Upload'; }
      if(item.status == 2) { this.transactionForm.status_text = 'Download'; }
      if(item.status == 3) { this.transactionForm.status_text = 'Viewed'; }
      if(item.status == 4) { this.transactionForm.status_text = 'Forwarded'; }
      if(item.status == 5) { this.transactionForm.status_text = 'Submitted for Internal Confirmation'; }
      if(item.status == 6) { this.transactionForm.status_text = 'Submitted for External Confirmation'; }
      if(item.status == 7) { this.transactionForm.status_text = 'Confirmed'; }
      if(item.status == 8) { this.transactionForm.status_text = 'Rejected'; }
      if(item.status == 9) { this.transactionForm.status_text = 'Revision Requested'; }
      if(item.status == 10) { this.transactionForm.status_text = 'Description Requested'; }
      if(item.status == 11) { this.transactionForm.status_text = 'Desription Added'; }
      if(item.status == 12) { this.transactionForm.status_text = 'Deletion Requested'; }
      if(item.status == 13) { this.transactionForm.status_text = 'Deleted'; }
    },
    updateTransactionData(){
      const parameters = { 
        id: this.transactionForm.id,
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/UpdateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been updated successfully..!", 'success');  
                  this.$refs.closeEditTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },  
    resetFileManager() {
        this.isFirstLaunch = true;
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date()
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.parentFolders = [];
        this.activeFolder = null;
        this.documents = [];
        this.transactions = [];
        this.lists = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
    },
    setCriteriasToDefault() {
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date(),
            document_type_id: '-1'
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.transactions = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
    }
  },
  created() {
      this.activeMainView = 'folders';
      this.getData();
      this.resetFileManager();
  }
}; 
</script>