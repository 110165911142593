<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Help Page
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PagesList' }">Help Pages</router-link></li>
                <li class="breadcrumb-item text-gray-600">Create Help Page</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/PagesList">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400">
                 <span class="ms-2 fs-7"> Return back to List</span>
                </button>
            </router-link>
        </div>
    </div>
    <div class="content flex-row-fluid" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row"> 
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Code
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Name
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Location
                                                        </label>
                                                        <Select2 v-model="form.location" :options="locationList" :settings="{width: '100%'}"/>
                                                    </div> 
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="View">
                                                            Is View?
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isView" v-model="form.isView">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-4" v-if="isAggreementContent">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="required">
                                                            Is Required?
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isRequired" v-model="form.isRequired">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-4" v-if="isAggreementContent">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Order
                                                        </label>
                                                        <input type="number" class="form-control" id="content_order" v-model="form.content_order">
                                                    </div>
                                                    <div class="col-lg-6" v-if="isAggreementContent">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Country
                                                            </label>
                                                            <Select2 v-model="form.country" :options="countryList"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6" v-if="isAggreementContent">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Release Date                                                         
                                                        </label> 
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.release_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">   
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Menu Selection
                                                        </label>
                                                        <div class="col-lg-12 fv-row fv-plugins-icon-container">    
                                                            <DxSelectBox :data-source="this.menuList" :grouped="true" display-expr="text" value-expr="id" :drop-down-options="dropDownOptions"
                                                                :search-enabled="true" v-model="form.menu_selection" @item-click="onChangeMenuSelection()"> 
                                                            </DxSelectBox>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Description</span>
                                                        </label>
                                                        <textarea rows="4" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                        <div class="px-10"> 
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                                        <div class="p-5">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_item" @click="openItemModal('addItem')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :disabled="isAggreementContent && itemList.length > 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        Add Content
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                                        data-bs-target="#kt_modal_add_item" @click="openItemModal('editItem')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                </svg>
                                                        </span>
                                                        Edit
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteItemData()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"> 
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        Remove
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllItems()">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        Remove All (!)
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive min-h-250px">
                                                    <DxDataGrid 
                                                        :show-borders="true" 
                                                        key-expr="id" 
                                                        :data-source="itemList" 
                                                        :allow-column-reordering="true"  
                                                        :allow-column-resizing="true" 
                                                        :column-auto-width="true" 
                                                        :row-alternatin-enabled="true"
                                                        :hover-state-enabled="true" 
                                                        :show-row-lines="true" 
                                                        @exporting="this.$onExporting($event , 'Pages')"> 
        
                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="false"/>
                                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="1000"/>
                                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                            
                                                        />
                                                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                        <DxColumn data-field="is_group" caption="Group" alignment="right" cell-template="is_group-cell-template"/> 
                                                        <DxColumn data-field="is_order" caption="Order" alignment="right" cell-template="is_order-cell-template"/>
                                                        <DxColumn data-field="title" caption="Title"  cell-template="title-cell-template"/>
                                                        <DxColumn data-field="width" caption="Width" alignment="left" cell-template="width-cell-template"/>
                                                        <DxColumn data-field="is_active" caption="Active" alignment="center" cell-template="is_active-cell-template"/> 
                                                        <DxColumn data-field="content" caption="Content" cell-template="content-cell-template"/> 

                                                        <!-- Cell Templates -->
                                                        <template #is-selected-cell-template="{ data }"> 
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template #is_group-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.is_group }}
                                                            </span>
                                                        </template>
                                                        <template #is_order-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.is_order }}
                                                            </span>
                                                        </template>
                                                        <template #title-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.title }}
                                                            </span>
                                                        </template>
                                                        <template #width-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.width }}
                                                            </span>
                                                        </template>
                                                        <template #is_active-cell-template="{ data }"> 
                                                            <span v-if="data.data.is_active == 1">
                                                                <i class="las la-check fs-2 text-success"></i>
                                                            </span>  
                                                            <span v-if="data.data.is_active == 0">
                                                                <i class="las la-times fs-2 text-danger"></i>
                                                            </span> 
                                                        </template>
                                                        <template #content-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.content }}
                                                            </span>
                                                        </template>
                                                </DxDataGrid>
                                                </div>
                                                <div class="row" v-if="itemList && itemList.length > 0">
                                                    <div
                                                        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                        <div class="dataTables_info" role="status" aria-live="polite">
                                                            {{ itemList.length }} Records
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-xxl-stretch tab-pane fade show active mt-3" id="kt_accounting_1" role="tabpanel">
                        <div class="px-10"> 
                            <div class="row">
                                <div class="col-lg-12"> 
                                <div class="d-grid gap-2 mb-2">
                                    <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on" :disabled="itemList && itemList.length == 0">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> Save Help Page </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>                   
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-xxl">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-11">
                        <span v-if="this.modalType == 'addItem'"> Add </span>
                        <span v-if="this.modalType == 'editItem'"> Edit </span> Editor              
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card card-flush">
                                        <div class="card-body pt-0 pb-0">
                                            <form class="form">
                                                <div class="card-body border-top">
                                                    <div class="row mb-2">
                                                        <div class="col-lg-6 mb-2">
                                                            <label class="form-label fw-bold fs-6 required">
                                                                <span>Title</span>
                                                            </label>
                                                            <div class="fv-row">
                                                                <input type="text" class="form-control " name="title"
                                                                    v-model="contentForm.title">                                                    
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mb-2">
                                                            <label class="form-label fw-bold fs-6 required">
                                                                <span>Width</span>
                                                            </label>
                                                            <div class="fv-row">
                                                                <Select2 v-model="contentForm.width" :options="widthList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-end px-0">
                                                        <button class="btn btn-light-primary btn-sm" data-bs-toggle="modal"
                                                            @click="openCreateMediaModal()" type="button" data-bs-target="#kt_modal_add_new_media">
                                                            <span class="svg-icon svg-icon-2">
                                                                <span class="svg-icon svg-icon-cyan svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                                                <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                                                                </svg>
                                                                </span>
                                                            </span>
                                                            Media Library
                                                        </button>
                                                    </div>
                                                    <div class="row mb-2 mb-8">
                                                        <label class="form-label fw-bold fs-6 required">
                                                            <span>Content</span>
                                                        </label>
                                                        <div class="widget-container">
                                                            <Editor v-if="htmlEditorKey !== null && htmlEditorKey !== '' && htmlEditorKey !== undefined" 
                                                                v-model="contentForm.content"
                                                                :api-key="htmlEditorKey"
                                                                :init="{
                                                                    inline: false,
                                                                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
                                                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | fullscreen  preview  print | insertfile image media pageembed link anchor | a11ycheck ltr rtl | showcomments addcomment | uploadimage',
                                                                    min_height: 200, 
                                                                    valid_elements: '*[*]',
                                                                    extended_valid_elements: '*[*]',
                                                                    valid_children: '+a[div|h1|h2|h3|h4|h5|h6|p|#text]',
                                                                    custom_elements: 'style,link,~link',
                                                                    force_br_newlines: true,
                                                                    forced_root_block: false,
                                                                    relative_urls: false,
                                                                    remove_script_host: false,
                                                                    convert_urls: false,
                                                                    fontsize_formats: '8pt 10pt 11pt 12pt 13pt 14pt 18pt 20pt 24pt 32pt 36pt 40pt 44pt 46pt 48pt 50pt 54pt 60pt 72pt 76pt 80pt 84pt 86pt 90pt 100pt',
                                                                    allow_html_in_named_anchor: true,
                                                                    allow_unsafe_link_target: true,
                                                                }"
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row mb-2">
                                                        <div class="col-lg-6 mt-3">
                                                            <label class="form-label fw-bold fs-6">
                                                                <span>Group</span>
                                                            </label>
                                                            <div class=" fv-row">
                                                                <CurrencyInput type="text" class="form-control" name="start_from" v-model="contentForm.is_group" :precision="0" @focus="$event.target.select()" :min="1"/>                                                      
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 mt-3">
                                                            <label class="form-label fw-bold fs-6">
                                                                <span>Order</span>
                                                            </label>
                                                            <div class=" fv-row">
                                                                <CurrencyInput type="text" class="form-control" name="start_from" v-model="contentForm.is_order" :precision="0" @focus="$event.target.select()" :min="1"/>                                                       
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            <span>Active</span>
                                                        </label>
                                                        <div>
                                                            <input class="form-check-input" type="checkbox" value="" id="is_active" v-model="contentForm.is_active">
                                                        </div>                                                
                                                    </div>
                                                </div>  
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-flush mt-4" id="kt_contacts_list">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'"
                                                @click="this.createContentItem()" :disabled="this.isModalButtonDisabled">
                                                <span class="indicator-label">Add Item</span> 
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                                @click="this.editContentItem()" :disabled="this.isModalButtonDisabled">
                                                <span class="indicator-label">Update</span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_new_media" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false" >
        <AddNewMedia ref="AddNewMedia"/>
    </div>
  </div>
</template>

<style>
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}
</style>

<script>
// Component Alias: `EXTU001`
import axios from 'axios';
  
import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager,DxExport, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
import { DxSelectBox } from 'devextreme-vue/select-box';
import AddNewMedia from '../Pages/Modals/AddNewMedia.vue';
//import moment from 'moment';

  

 
export default {
  name: "PagesCreate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager,DxExport, DxColumn, DxSelectBox, AddNewMedia, DxScrolling },
  data() {
    return {
      selectedItems: [{ text: 'Html' }],
      sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
      headerValues: [false, 1, 2, 3, 4, 5],
      lists: [],
      itemList: [],
      isOpenActionsDropdown: false, 
      isProgressing: false,
      form: { 
          id: null,
          name: null,
          code: null,
          description: null,
          location: 1,
          menu_selection: '-1',
          is_view: 1,
          isView: true,
          content_order: 0,
          isRequired: false,
          is_required: 0,
          country: '-1',
          session_id: null,
          tmp_session_id: null,
          release_date: new Date()
      },
      countryList: [],
      contentForm: { 
          title: null,
          content: null,
          width: 'col-lg-12',
          is_active: true,
          is_group: 0,
          is_order: 0,
          valueText: null,
      },
      locationList: [
        { id: 1, text: 'Company'},
       /*{ id: 2, text: 'Controller'},*/
      ],
      widthList: [
        { id:'col-lg-1', text: '1 Birim (col-lg-1)'},
        { id:'col-lg-2', text: '2 Birim (col-lg-2)'},
        { id:'col-lg-3', text: '3 Birim (col-lg-3)'},
        { id:'col-lg-4', text: '4 Birim (col-lg-4)'},
        { id:'col-lg-5', text: '5 Birim (col-lg-5)'},
        { id:'col-lg-6', text: '6 Birim (col-lg-6)'},
        { id:'col-lg-7', text: '7 Birim (col-lg-7)'},
        { id:'col-lg-8', text: '8 Birim (col-lg-8)'},
        { id:'col-lg-9', text: '9 Birim (col-lg-9)'},
        { id: 'col-lg-10', text: '10 Birim (col-lg-10)'},
        { id: 'col-lg-11', text: '11 Birim (col-lg-11)'},
        { id: 'col-lg-12', text: '12 Birim (col-lg-12) (Tam Genişlik)'},
      ],
      rowList: [
        { id: '-1', text: 'Select an option'},
        { id: 1, text: '1'},
        { id: 2, text: '2'},
        { id: 3, text: '3'},
        { id: 4, text: '4'},
        { id: 5, text: '5'},
        { id: 6, text: '6'},
      ],
      menuList: [
        {key: 'Accounts',
         items: [        
            { id: '/BankHelppage', text: 'Bank Account'},
            { id: '/CashHelppage', text: 'Cash Account'},
            { id: '/ChequeHelppage', text: 'Cheque Account'},
            { id: '/CustomerHelppage', text: 'Customer Account'},
            { id: '/ExpenseHelppage', text: 'Expense Account'},
            { id: '/FixedAssetHelppage', text: 'Fixed Asset Account'},
            { id: '/OtherHelppage', text: 'Other Account'},
            { id: '/PersonnelHelppage', text: 'Personnel Account'},
            { id: '/StockHelppage', text: 'Stock Account'},
            { id: '/SupplierHelppage', text: 'Supplier Account'}]
        },
        {key: 'Transactions',
         items: [
            { id: '/BankTransactionHelppage', text: 'Bank Transaction'},
            { id: '/BalanceClosingHelppage', text: 'Balance Closing Operations'},
            { id: '/CashTransactionHelppage', text: 'Cash Transaction'},
            { id: '/ChequeOperationHelppage', text: 'Cheque Operation'},
            { id: '/ChequeTransactionHelppage', text: 'Cheque Transaction'},
            { id: '/ExpenseTransactionHelppage', text: 'Expense Transaction'},
            { id: '/InvoiceHelppage', text: 'Invoice Transaction'},
            { id: '/OffsettingTransactionHelppage', text: 'Offsetting Transaction'}]
        },
        {key: 'Accounting',
         items: [        
            { id: '/AccountingVoucherHelppage', text: 'Accounting Voucher Operations'},
            { id: '/ClosingOperationsHelppage', text: 'Closing Operations'},
            { id: '/ComparativeBalanceSheetHelppage', text: 'Comparative Balance-Sheet'},
            { id: '/ComparativeProfitHelppage', text: 'Comparative Profit & Loss'},
            { id: '/GeneralBalanceSheetHelppage', text: 'General Balance-Sheet'},
            { id: '/GeneralProfitHelppage', text: 'General Profit & Loss Statement'},
            { id: '/OpeningOperationsHelppage', text: 'Opening Operations'},
            { id: '/ProfitOperationsHelppage', text: 'Profit & Loss Operations'},
            { id: '/VATOperationsHelppage', text: 'VAT Operations'}
            ]
        },
        {key: 'Reports',
         items: [     
            { id: '/AccountTransactionsHelppage', text: 'Account Transactions'} , 
            { id: '/AccountTransactionsConsolidationHelppage', text: 'Account Transactions (Consolidation)'},
            { id: '/CollectionHelppage', text: 'Collection List'}, 
            { id: '/CollectedHelppage', text: 'Collected List'},
            { id: '/DueHelppage', text: 'Due List'},
            { id: '/GeneralAccountReportHelppage', text: 'General Account Report'},
            { id: '/GeneralPolicyReportHelppage', text: 'General Policy Report'},
            { id: '/GeneralStockReportHelppage', text: 'General Stock Report'},
            { id: '/PaidHelppage', text: 'Paid List'},
            { id: '/PaymentHelppage', text: 'Payment List'},
            { id: '/RatesHelppage', text: 'Rates'},
            { id: '/StockCostHelppage', text: 'Stock Cost List'},
            { id: '/StockTransactionListHelppage', text: 'Stock Transaction List'},]
        },
        {key: 'Documents',
         items: [  
            { id: '/DocumentHelppage', text: 'Document'}]
        },
        {key: 'Liabilities',
         items: [    
            { id: '/LiabilitiesHelppage', text: 'Liabilities'}]
        },
        {key: 'List Types',
         items: [    
            { id: '/BankAccountDetailHelppage', text: 'Bank Account Detail'},
            { id: '/BranchHelppage', text: 'Branch'},
            { id: '/CustomerTypeHelppage', text: 'Customer Type'},
            { id: '/ExpenseCenterHelppage', text: 'Expense Center'},
            { id: '/ExpenseTypeHelppage', text: 'Expense Type'},
            { id: '/OtherAccountTypeHelppage', text: 'Other Account Type'},
            { id: '/PaymentTypeHelppage', text: 'Payment Method'},
            { id: '/PolicyBranchFactorsHelppage', text: 'Policy Branch Factors'},
            { id: '/PriceTypeHelppage', text: 'Price Type'},
            { id: '/ProductGroupsHelppage', text: 'Product Groups'},
            { id: '/ProductTypesHelppage', text: 'Product Types'},
            { id: '/SalesChannelHelppage', text: 'Sales Channel'},
            { id: '/ShippingTypeHelppage', text: 'Shipping Type'},
            { id: '/StockTrackingGroupHelppage', text: 'Stock Tracking Group'},
            { id: '/SourceCodeHelppage', text: 'Source Code'},
            { id: '/SourceCode2Helppage', text: 'Source Code 2'},
            { id: '/UnitTypeHelppage', text: 'Unit Type'},
            { id: '/Unit2TypeHelppage', text: 'Unit 2 Type'},]
        },
        {key: 'Settings',
         items: [    
            { id: '/AccessAuthorizationHelppage', text: 'Access Authorization'},
            { id: '/AccountSettingsHelppage', text: 'Account Settings'},
            { id: '/AccountingSettingsHelppage', text: 'Accounting Settings'},
            { id: '/AccountingIntegrationHelppage', text: 'Accounting Integration'},
            { id: '/BalanceCLosingHelppage', text: 'Balance Closing Parameters'},
            { id: '/CompanyInfosHelppage', text: 'Company Infos'},
            { id: '/CompanySettingsHelppage', text: 'Company Settings'},
            { id: '/CostsManagementHelppage', text: 'Costs Management'},
            { id: '/DataImportSettingsHelppage', text: 'Data Import Settings'},
            { id: '/DataTransferSettingsHelppage', text: 'Data Transfer'},
            { id: '/InvoiceSettingsHelppage', text: 'Document Settings'},
            { id: '/InsuranceParametersHelppage', text: 'Insurance Parameters'},
            { id: '/MailSettingsHelppage', text: 'Mail Settings'},
            { id: '/NumeratorSettingsHelppage', text: 'Numerator Settings'},
            { id: '/Re-CostingHelppage', text: 'Re-Costing'},
            { id: '/SalesRoleHelppage', text: 'Sales Role'},
            { id: '/TurnoverAndDifferenceListHelppage', text: 'Turnover/Difference List'},
            { id: '/UsersManagementHelppage', text: 'Users Management'},
            { id: '/DeleteMultipleAccountsHelppage', text: 'Delete Multiple Accounts'},
            { id: '/DeleteMultiplePoliciesHelppage', text: 'Delete Multiple Policies'},
            { id: '/UserGroupHelppage', text: 'User Group'},
            { id: '/SessionManagementHelppage', text: 'Session Management'},
            ]
        },
        {key: 'Aggrements',
         items: [    
            { id: '/GeneralDataProtectionHelppage', text: 'General Data Protection Regulation (GDPR)'},
            { id: '/MembershipAgreementHelppage', text: 'Membership Agreement'},
            { id: '/PrivacyPolicyHelppage', text: 'Privacy Policy'},
            { id: '/TermsConditionsHelppage', text: 'Terms and Conditions'}]
        },
        {key: 'Licence',
        items: [
            { id: '/LicenceGuideHelppage', text: 'Licence Guide'}]
        },
        {key: 'Insurance',
        items: [
            { id: '/PoliciesHelppage', text: 'Policies'}]
        }
      ],
      isModalButtonDisabled : false,
      isItemSaving: false,
      modalType: 'addItem',
      htmlEditorKey: ""
     };
  },
  computed: { 
    isItemButtonDisabled() {
       return this.itemList.find(item =>item.isSelected == true );
    },
    isAggreementContent() {
        return (this.form.menu_selection == '/GeneralDataProtectionHelppage' || this.form.menu_selection == '/MembershipAgreementHelppage' || this.form.menu_selection == '/PrivacyPolicyHelppage' || this.form.menu_selection == '/TermsConditionsHelppage');
    }
  },
  methods: {
    getData(){
        this.$setSessionStorage('refreshList', true);
        const parameters = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };        
        const requestOne =  axios.post('/api/Lists/GetCountryList', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.get('/api/Auth/GetHEK');
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.countryList = responses[0].data;
            this.countryList.unshift({id: '-1', text: 'Select an option'});
            
            this.htmlEditorKey = this.$unlock(responses[1].data);
        }))
        .catch(errors => { 
            console.error(errors);
        });
    },
    saveData(){
        const parameters = { 
          session_id: this.$getUser().session_id,
          code: this.form.code,
          name: this.form.name,
          description: this.form.description,
          location : this.form.location,
          menu_selection : this.form.menu_selection,
          is_view : this.form.isView == true ? 1 : 0,
          content_order : (this.form.content_order == null || this.form.content_order == '') ? 0 : this.form.content_order,
          is_required : this.form.isRequired == true ? 1 : 0,
          country : this.form.country,          
          release_date : this.form.release_date,
          tmp_session_id : this.$getSessionStorage('tmp_session_id')
        };
        if (parameters.country == '-1' && this.isAggreementContent){
            this.$swal("Failed", "Country is required!", 'error');
            this.isProgressing = false;
         return;
        }
        const that = this;
        axios.post('/api/Pages/CreatePages', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
             if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('record_id', response.data); 
                this.$swal("Success", "Data saved succesfully..!", 'success'); 
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'PagesUpdate' });
             }
        }) 
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });      
    },
    onClickViewPopup() {
        this.$refs.PopupsModal;
    },
    onClickSave(){
      this.contentForm.content = this.contentForm.valueText;
    },
    onSelectItem(item) {
        this.itemList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });     
    },
    openItemModal(value) {
        document.addEventListener('focusin', (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        });
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}
        this.modalType = value;
        this.isItemSaving = false;
        this.contentForm = {
            id: null,
            title: null,
            content: '',
            width: 'col-lg-12',
            is_group: 0,
            is_order: 0,
            is_active: true,
            valueText: null
        };
        if (this.modalType == 'editItem') {
            this.itemList.forEach((item) => {
                if (item.isSelected) {
                    this.contentForm.id = item.id;
                    this.contentForm.title = item.title;
                    this.contentForm.content = item.content;
                    this.contentForm.width = item.width;
                    this.contentForm.is_group = item.is_group;
                    this.contentForm.is_order = item.is_order;
                    this.contentForm.is_active = item.is_active == 1 ? true : false;
                    this.contentForm.valueText =  item.content
                }
            });
        }
    },
    createContentItem(){ 
        this.isModalButtonDisabled = true;
        this.isItemSaving = true;
        const that = this;
        const params = { 
          session_id: this.$getUser().session_id,
          tmp_session_id: this.$getSessionStorage('tmp_session_id'),
          title : this.contentForm.title,
          content : this.contentForm.content,
          width : this.contentForm.width,
          is_group : this.contentForm.is_group,
          is_active : this.contentForm.is_active ? 1 : 0,
          is_order : this.contentForm.is_order,
        };
        axios.post('/api/Pages/AddPagesListItem', params, {'Content-Type': 'application/json'})
         .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.itemList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeModal.click();
            } 
            this.isItemSaving = false;
        })
        .catch(function () { 
            this.isItemSaving = false;
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
    },
    editContentItem(){  
        const params = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            title: this.contentForm.title,
            content: this.contentForm.content,
            width: this.contentForm.width,
            is_group: this.contentForm.is_group,
            is_order: this.contentForm.is_order,
            is_active: this.contentForm.is_active,
            id: this.contentForm.id
        }; 
        this.isModalButtonDisabled = true;
        this.isItemSaving = true;
        const that = this;
        params.is_active = params.is_active ? 1 : 0;
        axios.post('/api/Pages/UpdatePagesListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.itemList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeModal.click();
            } 
            this.isItemSaving = false;
        })
        .catch(function () { 
            this.isItemSaving = false;
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
      },
    deleteItemData(){
        this.itemList.forEach((item) => {
            if (item.isSelected) {
                this.contentForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    id: this.contentForm.id
                }; 
                axios.post('/api/Pages/DeletePagesListItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.itemList = response.data;
                        this.$refs.closeModal.click();
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllItems(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                }; 
                axios.post('/api/Pages/DeletePagesListItemAll', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.itemList = [];
                        this.$refs.closeModal.click();
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    openCreateMediaModal() {
        this.$refs.AddNewMedia.getData();
        this.$refs.closeModal.click();
    },
    onChangeMenuSelection() {
        this.form.content_order =  0;
        this.form.is_required =  0;
        this.form.isRequired =  false;
        this.form.country =  '-1';
    },
  },
  created() {
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.getData(); 
  }
}; 
</script>
