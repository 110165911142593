<template>
    <div>        
        <div class="modal-body" :class="{'min-h-900px': isFrameLoading, 'overlay overlay-block': this.isFrameLoading == true && (this.report_id !== null && this.report_id !== '' && this.report_id !== '-1')}">
            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'https://localhost:5001/Home/Designer?id=' + this.id + '&report_id='+ this.report_id + '&report_type=' + this.report_type" 
                    style="width:100% !important; height: 100% !important;min-height:700px;"
                    v-if="this.is_local">
            </iframe>
            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'/reports/Home/Designer?id=' + this.id + '&report_id='+ this.report_id" 
                    style="width:100% !important; height: 100% !important;min-height:700px;"
                    v-if="!this.is_local">
            </iframe>
            
            <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isFrameLoading == true && (this.report_id !== null && this.report_id !== '' && this.report_id !== '-1')">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span class="d-flex text-primary ms-5">Document loading. Please wait...</span>
            </div>
        </div>
    </div>
</template>


<script>
    
    
    export default {
        name: "DesignerModal", 
        data() {
            return {
                id: this.$getSessionStorage("cp_record_id"),
                report_id: this.$getSessionStorage("report_design_id"),
                report_type: this.$getSessionStorage("report_type"),
                is_local: false,
                isFrameLoading: true
            };
        },
        methods: {
            getData() {
                if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
                {
                    this.is_local = true;
                }
                else{
                    this.is_local = false;
                }
            },
            iframeloadhelper() {
                this.isFrameLoading = false;
            }
        },
        mounted() { 
            this.getData();
        }
    };
</script>